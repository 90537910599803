import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import Colors from '../../styles/Colors'

type NameValueItemProps = {
  label?: string
  value?: string
  valueColor?: 'default' | 'green' | 'red'
  Icon?: React.ReactNode
  classes?: {
    value?: string
  }
}

const NameValueItem: React.FC<NameValueItemProps> = ({
  label,
  value,
  valueColor,
  Icon,
  classes
}: NameValueItemProps) => {
  const styles = useStyles()

  return (
    <div className="inline">
      {Icon !== undefined ? <div className={styles.icon}>{Icon}</div> : null}
      <div className={styles.content}>
        {label && (
          <Typography variant="subtitle2" component="div" color="textSecondary" className="font-weight-regular">
            {label}
          </Typography>
        )}
        {value !== undefined ? (
          <Typography
            variant="subtitle2"
            className={clsx(styles.value, classes?.value, {
              [styles.valueSpacing]: Boolean(label),
              [styles.valueRed]: valueColor === 'red',
              [styles.valueGreen]: valueColor === 'green'
            })}>
            {value}
          </Typography>
        ) : null}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1.5)
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    value: {
      fontWeight: 600
    },
    valueSpacing: {
      marginTop: theme.spacing(0.5)
    },
    valueRed: {
      color: Colors.Red
    },
    valueGreen: {
      color: Colors.Green
    }
  })
)

export default NameValueItem
