import User from '../types/User'

class RoleService {
  stripeRole?: User['stripeRole']

  private hasProfessionalOrBusinessPlan = () => this.stripeRole === 'professional' || this.stripeRole === 'business'

  private hasBusinessPlan = () => this.stripeRole === 'business'

  canViewAllStatistics = () => this.hasProfessionalOrBusinessPlan()

  canManageCalendar = () => this.hasBusinessPlan()

  canAccessAllPlaces = () => this.hasProfessionalOrBusinessPlan()

  canCreatePlace = (placesLength: number) =>
    (!this.stripeRole && placesLength < 1) || this.hasProfessionalOrBusinessPlan()

  canManageDocuments = () => this.hasBusinessPlan()

  canManageReceipts = () => this.hasBusinessPlan()

  canAddBooking = () => this.hasBusinessPlan()

  canAddRecurringTransactions = () => this.hasBusinessPlan()
}

export default RoleService
