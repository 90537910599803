import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ComingSoonPromo from '../components/ComingSoonPromo'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'

const Permissions: React.FC = () => {
  const { t } = useTranslation()

  return (
    <NavigationDrawerContainer>
      <Typography variant="h3">{t('permissions')}</Typography>

      <ComingSoonPromo />
    </NavigationDrawerContainer>
  )
}

export default Permissions
