import {
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  fade,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { publish } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { IconClose, IconDocuments } from '../assets/Svgs'
import BoxContainer from '../components/BoxContainer'
import InputText from '../components/inputs/InputText'
import FeaturePromoUpgrade from '../components/promo/FeaturePromoUpgrade'
import Spacing from '../components/Spacing'
import StepsActions from '../components/steps/StepsActions'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import GuestRentPlace from '../types/GuestRentPlace'
import { getRentLabel } from '../utils/RentUtils'

type AddGuestValues = {
  firstName: string
  lastName?: string
  email?: string
  phoneNumber?: string
  phoneNumberPrefix?: string
}

const AddGuest: React.FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const { guestId } = useParams<{ guestId?: string }>()
  const { role } = AppService

  const isEditing = guestId !== 'new'

  const defaultInitialValues = useRef<AddGuestValues>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneNumberPrefix: '+39'
  })
  const [initialValues, setInitialValues] = useState<AddGuestValues>(defaultInitialValues.current)
  const [loading, setLoading] = useState<boolean>(false)
  const [guestsRents, setGuestsRents] = useState<GuestRentPlace[]>([])

  const validationSchema = useRef(
    Yup.object({
      firstName: Yup.string().required(t('required_field')),
      email: Yup.string().email(t('email_invalid'))
    })
  )

  const handleDocumentsSelected = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!guestId) return

      event.stopPropagation()
      event.preventDefault()

      history.push(RoutesPaths.GuestDocumentsList.replace(':guestId', guestId), {
        isModal: true,
        title: t('guest_documents')
      })
    },
    [history, guestId, t]
  )

  const handleGuestRentSelected = useCallback(
    (guestRent: GuestRentPlace) => {
      history.push(
        RoutesPaths.EditRentDialog.replace(':placeId', guestRent.place.id).replace(':rentId', guestRent.id),
        { isModal: true }
      )
    },
    [history]
  )

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSubmit = useCallback(
    async (values: AddGuestValues, { setSubmitting }) => {
      let error = false

      if (user) {
        try {
          const { db } = AppService
          if (guestId && isEditing) {
            const success = await db.updateGuest(guestId, {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              phoneNumberPrefix: values.phoneNumberPrefix
            })
            if (success) {
              publish('guestUpdated', { guestId })
            }
          } else {
            const id = await db.createGuest(
              {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                phoneNumberPrefix: values.phoneNumberPrefix
              },
              user.id
            )
            if (id) {
              publish('guestCreated', { guestId: id })
            }
          }
        } catch (err) {
          error = true
        }
      } else {
        error = true
      }

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        handleClose()
      }

      setSubmitting(false)
    },
    [guestId, isEditing, enqueueSnackbar, t, user, handleClose]
  )

  const handleDeleteSelected = useCallback(() => {
    if (guestId) {
      const { db } = AppService
      db.deleteGuest(guestId)

      publish('guestDeleted', { guestId })
    }

    handleClose()
  }, [guestId, handleClose])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!(guestId && isEditing)) return

      if (!didCancel) setLoading(true)

      const { db } = AppService

      const result = await db.getGuest(guestId)
      if (user && result) {
        const guestsRentsPlacesResult = await db.getGuestsRentsPlaces(user?.id, [result?.id])
        setGuestsRents(guestsRentsPlacesResult)
      }

      if (!didCancel) {
        if (result) {
          setInitialValues({
            firstName: result.firstName,
            lastName: result.lastName,
            email: result.email,
            phoneNumber: result.phoneNumber,
            phoneNumberPrefix: result.phoneNumberPrefix || '+39'
          })
        }
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [guestId, isEditing, user])

  return (
    <Dialog
      open
      aria-labelledby="add-rent-dialog-title"
      aria-describedby="add-rent-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.current}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
          return (
            <Form>
              <DialogTitle disableTypography id="alert-dialog-title">
                <div className={clsx(styles.title, 'inline', 'justify-between')}>
                  <div className="inline">
                    <Typography variant="h3" component="div">
                      {t(isEditing ? 'edit_guest' : 'add_guest')}
                    </Typography>
                  </div>

                  <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
                    <IconClose />
                  </IconButton>
                </div>
              </DialogTitle>

              <DialogContent>
                <InputText
                  label={t('first_name')}
                  placeholder={t('first_name')}
                  id="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={Boolean(errors.firstName) && touched.firstName}
                  disabled={isSubmitting}
                  loading={loading}
                  helperText={errors.firstName && touched.firstName ? errors.firstName : ' '}
                  fullFlex
                  smallInput
                />
                <Spacing size={0.5} />
                <InputText
                  label={t('last_name')}
                  placeholder={t('last_name')}
                  id="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={Boolean(errors.lastName) && touched.lastName}
                  disabled={isSubmitting}
                  loading={loading}
                  helperText={errors.lastName && touched.lastName ? errors.lastName : ' '}
                  fullFlex
                  smallInput
                />
                <Spacing size={0.5} />
                <InputText
                  label={t('email')}
                  placeholder={t('email')}
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={Boolean(errors.email) && touched.email}
                  disabled={isSubmitting}
                  loading={loading}
                  helperText={errors.email && touched.email ? errors.email : ' '}
                  fullFlex
                  smallInput
                />
                <Spacing size={0.5} />
                <div className="inline">
                  <InputText
                    label={t('prefix')}
                    placeholder={t('prefix')}
                    id="phoneNumberPrefix"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumberPrefix}
                    disabled={isSubmitting}
                    loading={loading}
                    smallInput
                    className={styles.prefixInput}
                  />
                  <Spacing horizontal size={1} />
                  <InputText
                    label={t('phone_number')}
                    placeholder={t('phone_number')}
                    id="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    disabled={isSubmitting}
                    loading={loading}
                    fullFlex
                    smallInput
                  />
                </div>
                {!role.canManageReceipts() && (
                  <React.Fragment>
                    <Spacing size={2} />
                    <FeaturePromoUpgrade maxWidth={false} text={t('receipts_business_promo')} />
                  </React.Fragment>
                )}

                <Spacing size={2} />

                {role.canManageDocuments() && isEditing ? (
                  <React.Fragment>
                    <Spacing size={2} />
                    <BoxContainer>
                      <Spacing size={1} />
                      <div className="inline justify-between">
                        <div className="inline">
                          <Spacing size={2} horizontal />
                          <Typography variant="h6">{t('guest_documents')}</Typography>
                        </div>

                        <div className="inline">
                          <Button className="inline" variant="text" onClick={handleDocumentsSelected}>
                            <IconDocuments className="icon-primary flex-shrink-0" />
                            <Spacing size={1} horizontal />
                            <Typography variant="subtitle2" color="secondary" align="left" className="lowercase">
                              {t('view_documents')}
                            </Typography>
                          </Button>
                          <Spacing size={2} horizontal />
                        </div>
                      </div>
                      <Spacing size={1} />
                    </BoxContainer>
                  </React.Fragment>
                ) : null}

                <Spacing size={4} />

                {guestsRents.length > 0 && (
                  <Grid container spacing={4}>
                    {guestsRents.map(guestRent => (
                      <Grid item xs={12} md={4} key={guestRent.id}>
                        <Card
                          className={clsx(styles.rentCard, 'pointer')}
                          onClick={() => handleGuestRentSelected(guestRent)}>
                          <CardContent>
                            <Typography variant="overline" color="textSecondary" component="div">
                              {guestRent.place.name}
                            </Typography>
                            <Spacing size={1} />
                            <Typography variant="body1" component="div">
                              {getRentLabel(t, guestRent)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </DialogContent>

              <StepsActions
                variant="dialog"
                showOutlinedBackButton={isEditing}
                outlinedBackButtonText={t('delete')}
                outlinedButtonColor="red"
                submitButton
                continueButtonText={t(isEditing ? 'save' : 'add')}
                submitting={isSubmitting}
                onGoBack={handleDeleteSelected}
              />
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    rentCard: {
      backgroundColor: fade(theme.palette.background.default, 0.6),
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    prefixInput: {
      width: 120
    }
  })
)

export default AddGuest
