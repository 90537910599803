import { Checkbox, CheckboxProps } from '@material-ui/core'
import React from 'react'
import { IconCheckbox, IconCheckboxChecked } from '../../assets/Svgs'

type InputCheckboxProps = CheckboxProps

const InputCheckbox: React.FC<InputCheckboxProps> = (props: InputCheckboxProps) => {
  return <Checkbox {...props} icon={<IconCheckbox />} checkedIcon={<IconCheckboxChecked />} />
}

export default InputCheckbox
