import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import {
  Theme,
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Autocomplete } from '@material-ui/lab'
import ReactDOMServer from 'react-dom/server'
import html2pdf from 'html2pdf.js'
import { TFunction } from 'i18next'
import { useSnackbar } from 'notistack'
import { IconArrowDownSvg, IconClose, IconReceipt } from '../assets/Svgs'
import Colors from '../styles/Colors'
import AppService from '../services/AppService'
import Guest from '../types/Guest'
import { formatNumber, formatPhoneNumber, getGuestDisplayName } from '../utils/TextUtils'
import { getCurrencySymbol } from '../utils/CurrencyUtils'
import { useUser } from '../hooks/useUser'
import Transaction from '../types/Transaction'
import Place from '../types/place/Place'
import { formatDate } from '../utils/DateUtils'
import { emailIsValid } from '../utils/CommonUtils'
import PlaceType from '../types/place/PlaceType'
import Rent from '../types/place/Rent'
import Spacing from './Spacing'
import ColorIcon from './ColorIcon'
import InputText from './inputs/InputText'
import AppLoader from './AppLoader'
import PdfReceipt, { ReceiptData } from './PdfReceipt'

const generateAndUploadReceipt = async (t: TFunction, userId: string, data: ReceiptData) => {
  const { db, storage } = AppService

  let error = false
  let downloadUrl: string | undefined

  try {
    const receiptNumber = await db.getReceiptNumber(userId)

    const fileName = `${t('receipt').toLowerCase()}_${receiptNumber}_${t(
      'rent_installment'
    ).toLowerCase()}_${formatDate(data.paidOn, 'dd-MM-yyyy')}.pdf`
    const PdfOptions = {
      filename: fileName,
      margin: [20, 20],
      image: { type: 'png', quality: 0.9 },
      html2canvas: { dpi: 240, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    const PdfHtmlElement = ReactDOMServer.renderToStaticMarkup(
      <PdfReceipt {...data} receiptNumber={receiptNumber} t={t} />
    )

    const worklet = html2pdf().set(PdfOptions).from(PdfHtmlElement).toPdf()
    const PdfFile: Blob = await worklet.output('blob')

    downloadUrl = await storage.uploadReceipt(PdfFile, fileName)

    if (downloadUrl) {
      await db.incrementReceiptNumber(userId)
    }
  } catch (err) {
    error = true
  }

  return { downloadUrl, error }
}

type RentReceiptProps = {
  open: boolean
  rentId?: string
  transactionId?: string
  guestsIds?: string[]
  onClose?: () => void
}

const RentReceipt: React.FC<RentReceiptProps> = ({
  open,
  rentId,
  transactionId,
  guestsIds,
  onClose
}: RentReceiptProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { user } = useUser()
  const { role } = AppService
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [guests, setGuests] = useState<Guest[]>([])
  const [guest, setGuest] = useState<Guest | null>(null)
  const [transaction, setTransaction] = useState<Transaction | undefined>()
  const [place, setPlace] = useState<Place | undefined>()
  const [rent, setRent] = useState<Rent | undefined>()
  const [submittingEmail, setSubmittingEmail] = useState<boolean>(false)
  const [submittingWhatsapp, setSubmittingWhatsapp] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const canManage = role.canManageReceipts()

  const handleGuestChange = useCallback((event, value: Guest) => {
    setGuest(value)
  }, [])

  const handleEmailChange = useCallback(event => {
    setEmail(event.target.value)
  }, [])

  const handlePhoneNumberChange = useCallback(event => {
    setPhoneNumber(event.target.value)
  }, [])

  const getReceiptData = useCallback((): ReceiptData => {
    return {
      amount: transaction?.amount || 0,
      guestName: guest ? getGuestDisplayName(guest) : '',
      paidOn: transaction?.paidOn || new Date(),
      rentFrom: rent?.from || new Date(),
      rentTo: rent?.to || new Date(),
      placeName: place?.name || '',
      placeType: place?.type || PlaceType.Apartment,
      userName: user?.name || user?.email || '',
      userTaxId: user?.taxId,
      currency: user?.currency
    }
  }, [
    guest,
    place?.name,
    place?.type,
    rent?.from,
    rent?.to,
    transaction?.amount,
    transaction?.paidOn,
    user?.currency,
    user?.email,
    user?.name,
    user?.taxId
  ])

  const getReceiptUrl = useCallback(async () => {
    if (user && transactionId) {
      const { downloadUrl, error } = await generateAndUploadReceipt(t, user.id, getReceiptData())

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      }

      return error ? undefined : downloadUrl
    }

    return
  }, [enqueueSnackbar, getReceiptData, t, transactionId, user])

  const getReceiptMessageBody = useCallback(
    (url?: string) => {
      return encodeURI(`${t('receipt_message_body')}: ${url}`)
    },
    [t]
  )

  const handleSendEmailSelected = useCallback(async () => {
    if (!email) return

    setSubmittingEmail(true)

    const receiptUrl = await getReceiptUrl()

    if (receiptUrl) {
      const emailLink = `mailto:${email}?subject=${''}&body=${getReceiptMessageBody(receiptUrl)}`

      window.location.href = emailLink
    }

    setSubmittingEmail(false)
  }, [email, getReceiptMessageBody, getReceiptUrl])

  const handleSendWhatsappSelected = useCallback(async () => {
    if (!phoneNumber) return

    setSubmittingWhatsapp(true)

    const receiptUrl = await getReceiptUrl()

    if (receiptUrl) {
      const whatsappLink = `https://wa.me/${formatPhoneNumber(phoneNumber)}?text${getReceiptMessageBody(receiptUrl)}`

      window.location.href = whatsappLink
    }

    setSubmittingWhatsapp(false)
  }, [getReceiptMessageBody, getReceiptUrl, phoneNumber])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      history.goBack()
    }
  }, [history, onClose])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoading(true)

      const { db } = AppService
      if (transactionId) {
        const transactionResult = await db.getTransaction(transactionId)

        if (transactionResult && !didCancel) {
          setTransaction(transactionResult)
        }
      }
      if (user && rentId) {
        const rentResult = await db.getRentBase(user.id, rentId)
        if (rentResult) {
          const placeResult = await db.getPlace(rentResult.placeId)
          if (placeResult && !didCancel) {
            setPlace(placeResult)
          }
          if (!didCancel) {
            setRent(rentResult)
          }
        }
      }
      if (guestsIds) {
        const guests: Guest[] = []

        for (const guestId of guestsIds) {
          const guest = await db.getGuest(guestId)
          if (guest) {
            guests.push(guest)
          }
        }

        if (!didCancel) {
          setGuests(guests)
          if (guests.length > 0) {
            setGuest(guests[0])
          }
        }
      }

      if (!didCancel) setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [guestsIds, rentId, transactionId, user])

  useEffect(() => {
    if (guest) {
      setEmail(guest?.email || '')
      setPhoneNumber(
        `${guest?.phoneNumberPrefix ? `${guest?.phoneNumberPrefix}` : ''}${
          guest?.phoneNumber ? guest?.phoneNumber : ''
        }`
      )
    }
  }, [guest])

  const invalidEmail = !emailIsValid(email)

  return (
    <Dialog
      open={open}
      aria-labelledby="add-expense-dialog-title"
      aria-describedby="add-expense-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'sm'}
      onClose={handleClose}>
      <DialogTitle disableTypography id="alert-dialog-title">
        <div className={clsx(styles.title, 'inline', 'justify-between')}>
          <div className="inline">
            <ColorIcon Icon={<IconReceipt />} color={theme.palette.primary.main} withBackground />
            <Spacing horizontal size={2} />
            <Typography variant="h3" component="div">
              {t('receipt')}
            </Typography>
          </div>

          <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
            <IconClose />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="inline">
          <Typography color="textSecondary">{t('from_mr_mrs')}</Typography>
          <Spacing horizontal size={2} />
          {guest ? (
            <Autocomplete<Guest, false, true>
              options={guests}
              value={guest}
              disabled={loading}
              disableClearable
              className={clsx('fullflex', styles.guestSelect)}
              getOptionLabel={option => getGuestDisplayName(option)}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleGuestChange}
              renderInput={params => (
                <InputText {...params} placeholder={`${t('addrent.select_guests_placeholder')}*`} />
              )}
              popupIcon={<IconArrowDownSvg />}
            />
          ) : null}
        </div>
        <Spacing size={1} />
        <div className="inline">
          <Typography color="textSecondary">{`${t('received')} ${getCurrencySymbol(user?.currency)}`}</Typography>
          <Spacing horizontal size={2} />
          <Typography>{transaction?.amount ? formatNumber(transaction.amount, 2) : ''}</Typography>
        </div>
        <Spacing size={1} />
        <div className="inline">
          <Typography color="textSecondary">{t('on_date')}</Typography>
          <Spacing horizontal size={2} />
          <Typography>{formatDate(transaction?.paidOn || new Date())}</Typography>
        </div>
        <Spacing size={1} />
        <div className="inline">
          <Typography color="textSecondary">{t('for_rental_place')}</Typography>
          <Spacing horizontal size={2} />
          <Typography>{place?.name}</Typography>
        </div>

        <Spacing size={8} />

        <div className={clsx(isSmDown ? 'colstretch' : 'inline', { [styles.inputContainer]: !isSmDown })}>
          <InputText
            value={email}
            disabled={loading}
            className={clsx(styles.input, styles.inputSize)}
            rootClassName={styles.inputSize}
            onChange={handleEmailChange}
            error={Boolean(email) && invalidEmail}
            helperText={Boolean(email) && invalidEmail ? t('invalid_email') : ' '}
            FormHelperTextProps={{
              className: styles.inputHelperText
            }}
          />
          <Spacing horizontal={!isSmDown} size={2} />
          <Button
            variant="outlined"
            color="primary"
            className={styles.submitButton}
            onClick={handleSendEmailSelected}
            disabled={!canManage || loading || submittingEmail || invalidEmail || !Boolean(email)}>
            {submittingEmail ? <AppLoader size={20} /> : t('send_email')}
          </Button>
        </div>

        <Spacing size={isSmDown ? 6 : 4} />

        <div className={clsx(isSmDown ? 'colstretch' : 'inline', { [styles.inputContainer]: !isSmDown })}>
          <InputText
            value={phoneNumber}
            disabled={loading}
            className={clsx(styles.input, styles.inputSize)}
            rootClassName={styles.inputSize}
            onChange={handlePhoneNumberChange}
            FormHelperTextProps={{
              className: styles.inputHelperText
            }}
          />
          <Spacing horizontal={!isSmDown} size={2} />
          <Button
            variant="outlined"
            color="primary"
            className={styles.submitButton}
            onClick={handleSendWhatsappSelected}
            disabled={!canManage || loading || submittingWhatsapp || !Boolean(phoneNumber)}>
            {submittingWhatsapp ? <AppLoader size={20} /> : t('send_whatsapp')}
          </Button>
        </div>
      </DialogContent>

      <Spacing size={8} />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    guestSelect: {
      height: 32,
      '& > div': {
        height: 32,
        '& .MuiFormControl-root': {
          height: 32,
          '& .MuiAutocomplete-inputRoot': {
            height: 32,
            '& input': {
              padding: `0 4px`
            }
          }
        }
      }
    },
    inputContainer: {
      padding: 4,
      border: `1px solid ${Colors.IconGray}`,
      borderRadius: theme.shape.borderRadius
    },
    input: {
      '& .MuiInputBase-root': {
        height: 40,
        '& .MuiInputBase-input': {
          height: 40
        }
      },
      [theme.breakpoints.up('sm')]: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }
    },
    inputHelperText: {
      marginTop: 8
    },
    inputSize: {
      height: 40
    },
    submitButton: {
      minHeight: 40,
      minWidth: 200,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto'
      }
    }
  })
)

export default RentReceipt
