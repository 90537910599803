import { addYears, addMonths, addWeeks } from 'date-fns'
import { useSnackbar } from 'notistack'
import { publish } from 'pubsub-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import EditTransactionDialog, { EditTransactionValues } from '../components/edit-transaction/EditTransactionDialog'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Transaction from '../types/Transaction'
import { formatDate } from '../utils/DateUtils'
import { getTransactionRecurrenceAmount, getTransactionRecurrenceMinDate } from '../utils/TransactionsUtils'

const AddTransaction: React.FC = () => {
  const history = useHistory()
  const { user } = useUser()
  const { placeId, transactionId } = useParams<{ placeId: string; transactionId?: string }>()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(false)
  const [transaction, setTransaction] = useState<Transaction | undefined>()

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const handleDelete = useCallback(
    async (id: string) => {
      const { db } = AppService

      const deleteSuccess = await db.deleteTransaction(id)

      if (deleteSuccess) {
        enqueueSnackbar(t('expense_deleted'), { variant: 'warning' })
        publish('transactionDeleted', { transactionId: id })
      }

      return deleteSuccess
    },
    [enqueueSnackbar, t]
  )

  const handleSubmit = useCallback(
    async (transactionValues: EditTransactionValues, id?: string) => {
      if (!user) return false

      let hasErrors = false

      try {
        const { db } = AppService

        const transactionData: Omit<Transaction, 'id' | 'created'> = {
          description: transactionValues.description,
          isRent: false,
          amount: transactionValues.amount || 0,
          paid: transactionValues.paid,
          dueDate: transactionValues.dueDate || new Date(),
          paidOn: transactionValues.paidOn || undefined,
          categories: transactionValues.categories,
          placeId
        }

        if (id) {
          await db.updateTransaction(id, transactionData)

          enqueueSnackbar(t('expense_updated'), { variant: 'success' })

          publish('transactionUpdated', { transactionId: id })
        } else {
          if (!transactionValues.recurrence || transactionValues.recurrence === 'none') {
            const transactionResultId = await db.createTransaction(transactionData, user?.id, placeId)
            publish('transactionCreated', { transactionId: transactionResultId })

            enqueueSnackbar(t('expense_added'), { variant: 'success' })
          } else {
            const transactionsRecurrenceAmount = getTransactionRecurrenceAmount(
              transactionValues.recurrence,
              transactionData.dueDate,
              transactionValues.repeatUntil ||
                getTransactionRecurrenceMinDate(transactionValues.recurrence, transactionData.dueDate)
            )

            const recurrentTransactions = [...Array(transactionsRecurrenceAmount).keys()].map(i => {
              const transactionDueDate =
                transactionValues.recurrence === 'year'
                  ? addYears(transactionData.dueDate, i)
                  : transactionValues.recurrence === 'month'
                  ? addMonths(transactionData.dueDate, i)
                  : addWeeks(transactionData.dueDate, i)
              return {
                ...transactionData,
                dueDate: transactionDueDate,
                description: `${transactionData.description} - ${formatDate(transactionDueDate)}`
              }
            })

            const transactionsResultIds = await db.createTransactions(recurrentTransactions, user.id)

            for (const id of transactionsResultIds) {
              publish('transactionCreated', { transactionId: id })
            }

            enqueueSnackbar(t('expenses_added'), { variant: 'success' })
          }
        }
      } catch (err) {
        hasErrors = true
      }

      return !hasErrors
    },
    [enqueueSnackbar, placeId, t, user]
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!(transactionId && user)) return

      if (!didCancel) setLoading(true)

      const { db } = AppService

      const transactionResult = await db.getTransaction(transactionId)

      if (!didCancel) {
        setTransaction(transactionResult)
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [transactionId, user])

  return (
    <EditTransactionDialog
      open
      transaction={transaction}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      placeId={placeId}
      loading={loading}
    />
  )
}

export default AddTransaction
