import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

const IndicatorSize = 8

type TagProps = {
  text?: string
  status?: 'default' | 'success' | 'error' | 'warning' | string
  backgroundColor?: 'default' | 'contrast' | 'paper'
  className?: string
}

const Tag: React.FC<TagProps> = ({ text, status = 'default', backgroundColor = 'default', className }: TagProps) => {
  const styles = useStyles()

  return (
    <div className={clsx(styles.root, backgroundColor, className)}>
      <div className={clsx(styles.indicator, status)} />
      <Typography className={styles.text}>{text || ''}</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexShrink: 0,
      backgroundColor: theme.palette.background.default,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderRadius: theme.shape.borderRadius,
      '&.contrast': {
        backgroundColor: theme.palette.divider
      },
      '&.paper': {
        backgroundColor: theme.palette.background.paper
      }
    },
    indicator: {
      width: IndicatorSize,
      height: IndicatorSize,
      borderRadius: IndicatorSize,
      marginRight: IndicatorSize,
      backgroundColor: theme.palette.grey[400],
      '&.success': {
        backgroundColor: theme.palette.success.main
      },
      '&.error': {
        backgroundColor: theme.palette.error.main
      },
      '&.warning': {
        backgroundColor: theme.palette.warning.main
      }
    },
    text: {
      fontSize: 11,
      fontWeight: 600,
      textTransform: 'uppercase'
    }
  })
)

export default Tag
