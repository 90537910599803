import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../hooks/useUser'
import AppService from '../../services/AppService'
import Guest from '../../types/Guest'
import { getGuestDisplayName } from '../../utils/TextUtils'
import InputMultipleSelectAdd from '../InputMultipleSelectAdd'
import InputText from '../inputs/InputText'

type GuestValues = {
  firstName: string
  lastName: string
  email: string
}

type EditGuestsInputProps = {
  value: Guest[]
  error?: boolean
  helperText?: string
  disabled?: boolean
  onChange: (guests: Guest[]) => void
}

const EditGuestsInput: React.FC<EditGuestsInputProps> = ({
  value,
  error,
  helperText,
  disabled,
  onChange
}: EditGuestsInputProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()

  const [loadingGuests, setLoadingGuests] = useState<boolean>(true)
  const [guests, setGuests] = useState<Guest[]>([])
  const [guestValues, setGuestValues] = useState<GuestValues>({
    firstName: '',
    lastName: '',
    email: ''
  })
  const [guestTouched, setGuestTouched] = useState<Record<string, boolean>>({
    firstName: false,
    lastName: false
  })

  const closeAndResetAddGuest = useCallback(() => {
    setGuestValues({
      firstName: '',
      lastName: '',
      email: ''
    })
    setGuestTouched({
      firstName: false,
      lastName: false
    })
  }, [])

  const handleGuestValueChange = useCallback((id: string, value: string) => {
    setGuestValues(current => ({
      ...current,
      [id]: value
    }))
  }, [])

  const handleGuestValueBlur = useCallback((id: string) => {
    setGuestTouched(current => ({
      ...current,
      [id]: true
    }))
  }, [])

  const handleAddGuestSubmit = useCallback(async () => {
    setGuestTouched(current => Object.keys(current).reduce((obj, k) => ({ ...obj, [k]: true }), current))

    if (!(guestValues.firstName && guestValues.lastName && user)) return false

    let hasErrors = false
    let guestId: string | undefined
    const guestData = {
      firstName: guestValues.firstName,
      lastName: guestValues.lastName,
      email: guestValues.email ? guestValues.email : undefined
    }

    try {
      const { db } = AppService
      guestId = await db.createGuest(guestData, user.id)
    } catch (err) {
      hasErrors = true
    }
    if (!hasErrors && guestId) {
      const newGuest: Guest = {
        ...guestData,
        id: guestId,
        created: new Date()
      }
      setGuests(current => current.concat(guestId ? [newGuest] : []))
      closeAndResetAddGuest()
      onChange(value.concat([newGuest]))
    } else {
      enqueueSnackbar(t('error'), { variant: 'error' })
    }

    return !hasErrors
  }, [guestValues, closeAndResetAddGuest, enqueueSnackbar, t, user, value, onChange])

  const handleGuestsChange = useCallback(
    (newGuests: Guest[]) => {
      onChange(newGuests)
    },
    [onChange]
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoadingGuests(true)

      if (user) {
        const dbGuests = await AppService.db.getGuests(user.id)
        if (!didCancel) {
          setGuests(dbGuests)
        }
      }

      if (!didCancel) setLoadingGuests(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [user])

  return (
    <InputMultipleSelectAdd<Guest>
      label={t('guests_label')}
      value={value}
      items={guests}
      addLabel={t('add_guest')}
      maxNumberOfItems={10}
      error={error}
      helperText={helperText}
      maxSingleSelectionErrorText={user?.stripeRole !== 'business' ? t('guests_limit_promo') : undefined}
      AddContent={
        <React.Fragment>
          <div className="inline">
            <InputText
              placeholder={t('first_name')}
              onChange={event => handleGuestValueChange('firstName', event.target.value)}
              onBlur={() => handleGuestValueBlur('firstName')}
              className={styles.spacingRightSmall}
              error={!Boolean(guestValues.firstName) && guestTouched.firstName}
              fullFlex
              smallInput
            />
            <InputText
              placeholder={t('last_name')}
              onChange={event => handleGuestValueChange('lastName', event.target.value)}
              onBlur={() => handleGuestValueBlur('lastName')}
              error={!Boolean(guestValues.lastName) && guestTouched.lastName}
              fullFlex
              smallInput
            />
          </div>

          <div className={styles.spacingSmallHalf} />

          <InputText
            placeholder={t('email')}
            onChange={event => handleGuestValueChange('email', event.target.value)}
            fullFlex
            smallInput
          />
        </React.Fragment>
      }
      loading={loadingGuests}
      getLabel={item => getGuestDisplayName(item)}
      onResetAdd={closeAndResetAddGuest}
      onChange={handleGuestsChange}
      onSubmit={handleAddGuestSubmit}
      placeholder={t('addrent.select_guests_placeholder')}
      disabled={disabled}
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacingRightSmall: {
      marginRight: theme.spacing(1)
    },
    spacingSmallHalf: {
      height: theme.spacing(1.5),
      flexShrink: 0
    }
  })
)

export default EditGuestsInput
