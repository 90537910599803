import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  fade
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Formik, Form, FormikProps } from 'formik'
import { publish } from 'pubsub-js'
import { useUser } from '../hooks/useUser'
import StepsActions from '../components/steps/StepsActions'
import AppService from '../services/AppService'
import InputText from '../components/inputs/InputText'
import InputUpload from '../components/inputs/InputUpload'
import Spacing from '../components/Spacing'
import { downloadURI, getFileNameWithoutExtension } from '../utils/CommonUtils'
import { IconClose, IconExpenses, IconGuests, IconPlaces, IconRent } from '../assets/Svgs'
import Colors from '../styles/Colors'
import { getGuestDisplayName } from '../utils/TextUtils'
import { formatDate } from '../utils/DateUtils'
import { RoutesPaths } from '../Routes'

const DocumentMaximumSizeMB = 4

type DocumentLinkedEntity = {
  id: string
  name: string
  type: 'place' | 'transaction' | 'guest'
  additionalData?: Record<string, unknown>
}

export type AddDocumentLocationState = { transactionId?: string; placeId?: string; guestId?: string }

type AddDocumentValues = {
  name: string
  document: File | string | undefined
}

const AddDocument: React.FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const {
    state: { transactionId, placeId, guestId }
  } = useLocation<AddDocumentLocationState>()

  const { documentId } = useParams<{ documentId?: string }>()

  const isEditing = documentId !== 'new'

  const defaultInitialValues = useRef<AddDocumentValues>({
    name: '',
    document: undefined
  })
  const [initialValues, setInitialValues] = useState<AddDocumentValues>(defaultInitialValues.current)
  const [loading, setLoading] = useState<boolean>(false)
  const [documentData, setDocumentData] =
    useState<{ sizeBytes: number; downloadUrl: string; linkedEntities: DocumentLinkedEntity[] } | undefined>()

  const validationSchema = useRef(
    Yup.object({
      name: Yup.string().required(t('required_field')),
      document: Yup.mixed().required(t('required_field'))
    })
  )
  const formRef = useRef<FormikProps<AddDocumentValues> | null>(null)

  const handleDocumentClick = useCallback(async () => {
    const downloadUrl =
      typeof formRef.current?.values.document === 'string' ? formRef.current?.values.document : undefined

    if (downloadUrl) {
      downloadURI(downloadUrl, formRef.current?.values.name || '')
    }
  }, [])

  const handleFileChange = useCallback((id, file) => {
    formRef.current?.setFieldValue('document', file)
    if (!formRef.current?.values.name) {
      formRef.current?.setFieldValue('name', getFileNameWithoutExtension(file.name))
    }
  }, [])

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSubmit = useCallback(
    async (values: AddDocumentValues, { setSubmitting }) => {
      let error = false

      if (user) {
        try {
          const { db, storage } = AppService

          let downloadUrl = typeof values.document === 'string' ? values.document : undefined
          const sizeBytes = (typeof values.document !== 'string' ? values.document?.size : documentData?.sizeBytes) || 0
          if (!downloadUrl && values.document !== undefined && typeof values.document !== 'string') {
            downloadUrl = await storage.uploadDocument(values.document)

            if (downloadUrl && documentData?.downloadUrl) {
              await storage.deleteFile(documentData.downloadUrl)
            }
          }

          if (documentId && isEditing) {
            const success = await db.updateDocument(documentId, {
              name: values.name,
              downloadUrl,
              sizeBytes,
              ...(placeId !== undefined ? { placeId } : undefined),
              ...(transactionId !== undefined ? { transactionId } : undefined),
              ...(guestId !== undefined ? { guestId } : undefined)
            })
            if (success) {
              publish('documentUpdated', { documentId })
            }
          } else {
            if (downloadUrl) {
              const id = await db.createDocument(
                {
                  name: values.name,
                  downloadUrl,
                  sizeBytes,
                  placeIds: placeId ? [placeId] : [],
                  transactionIds: transactionId ? [transactionId] : [],
                  guestIds: guestId ? [guestId] : []
                },
                user.id
              )
              if (id) {
                publish('documentCreated', { documentId: id })
              }
            } else {
              error = true
            }
          }
        } catch (err) {
          error = true
        }
      } else {
        error = true
      }

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        handleClose()
      }

      setSubmitting(false)
    },
    [
      documentData?.downloadUrl,
      documentData?.sizeBytes,
      documentId,
      enqueueSnackbar,
      guestId,
      handleClose,
      isEditing,
      placeId,
      t,
      transactionId,
      user
    ]
  )

  const handleDeleteSelected = useCallback(() => {
    if (documentId && documentData) {
      const { db } = AppService
      db.deleteDocument(documentId, documentData.downloadUrl)

      publish('documentDeleted', { documentId })
    }

    handleClose()
  }, [documentData, documentId, handleClose])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!(documentId && isEditing)) return

      if (!didCancel) setLoading(true)

      const { db } = AppService

      const result = await db.getDocument(documentId)

      if (!didCancel) {
        if (result) {
          const linkedEntities: DocumentLinkedEntity[] = []

          if (result.placeIds.length > 0) {
            const placeResults = await Promise.all(result.placeIds.map(placeId => db.getPlace(placeId)))

            for (const placeResult of placeResults) {
              if (placeResult) {
                linkedEntities.push({
                  id: placeResult.id,
                  name: placeResult.name,
                  type: 'place'
                })
              }
            }
          }
          if (result.transactionIds.length > 0) {
            const transactionResults = await Promise.all(
              result.transactionIds.map(transactionId => db.getTransaction(transactionId))
            )
            for (const transactionResult of transactionResults) {
              if (transactionResult) {
                linkedEntities.push({
                  id: transactionResult.id,
                  name: `${transactionResult.description} - ${formatDate(transactionResult.dueDate)}`,
                  type: 'transaction',
                  additionalData: {
                    isExpense: Boolean(transactionResult.placeId),
                    plceId: transactionResult.placeId
                  }
                })
              }
            }
          }
          if (result.guestIds.length > 0) {
            const guestResults = await Promise.all(result.guestIds.map(guestId => db.getGuest(guestId)))
            for (const guestResult of guestResults) {
              if (guestResult) {
                linkedEntities.push({
                  id: guestResult.id,
                  name: getGuestDisplayName(guestResult),
                  type: 'guest'
                })
              }
            }
          }

          setInitialValues({
            name: result.name,
            document: result.downloadUrl
          })
          setDocumentData({
            sizeBytes: result.sizeBytes,
            downloadUrl: result.downloadUrl,
            linkedEntities
          })
        }
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [documentId, isEditing, user])

  return (
    <Dialog
      open
      aria-labelledby="add-rent-dialog-title"
      aria-describedby="add-rent-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.current}
        onSubmit={handleSubmit}
        innerRef={r => (formRef.current = r)}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
          return (
            <Form>
              <DialogTitle disableTypography id="alert-dialog-title">
                <div className={clsx(styles.title, 'inline', 'justify-between')}>
                  <div className="inline">
                    <Typography variant="h3" component="div">
                      {t(isEditing ? 'edit_document' : 'add_document')}
                    </Typography>
                  </div>

                  <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
                    <IconClose />
                  </IconButton>
                </div>
              </DialogTitle>

              <DialogContent>
                <InputUpload
                  id="document"
                  label={t('upload_document')}
                  tooltipText={isEditing && typeof values.document === 'string' ? t('click_to_download') : undefined}
                  fullWidth
                  accept="*"
                  className={styles.uploadInput}
                  file={values.document}
                  maxSizeMb={DocumentMaximumSizeMB}
                  onFileChange={handleFileChange}
                  error={Boolean(errors.document) && touched.document}
                  disabled={isSubmitting}
                  loading={loading}
                  sizeBytes={documentData?.sizeBytes}
                  helperText={errors.document && touched.document ? errors.document : ' '}
                  onClick={isEditing && typeof values.document === 'string' ? handleDocumentClick : undefined}
                />

                <Spacing size={4} />

                <InputText
                  label={t('name')}
                  placeholder={t('name')}
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={Boolean(errors.name) && touched.name}
                  disabled={isSubmitting}
                  loading={loading}
                  helperText={errors.name && touched.name ? errors.name : ' '}
                  fullFlex
                  smallInput
                />

                {(documentData?.linkedEntities || []).length > 0 && (
                  <React.Fragment>
                    <Spacing size={4} />
                    <Typography variant="button">{t('linked_to')}</Typography>
                    <Spacing size={1} />
                    <div className="inline flex-wrap">
                      {documentData?.linkedEntities.map(linkedEntity => {
                        const Icon =
                          linkedEntity.type === 'place' ? (
                            <IconPlaces className={styles.linkedEntityIconFillDefault} />
                          ) : linkedEntity.type === 'transaction' ? (
                            (linkedEntity?.additionalData as any).isExpense ? (
                              <IconExpenses />
                            ) : (
                              <IconRent />
                            )
                          ) : linkedEntity.type === 'guest' ? (
                            <IconGuests className={styles.linkedEntityIconFillDefault} />
                          ) : null
                        return (
                          <React.Fragment key={linkedEntity.id}>
                            <Button
                              onClick={() => {
                                if (linkedEntity.type === 'place') {
                                  history.push(RoutesPaths.PlaceDetail.replace(':placeId', linkedEntity.id))
                                } else if (linkedEntity.type === 'transaction' && linkedEntity.additionalData) {
                                  history.push(
                                    RoutesPaths.EditTransactionDialog.replace(
                                      ':placeId',
                                      linkedEntity.additionalData.placeId as string
                                    ).replace(':transactionId', linkedEntity.id)
                                  )
                                } else if (linkedEntity.type === 'guest') {
                                  RoutesPaths.EditGuestDialog.replace(':guestId', linkedEntity.id)
                                }
                              }}
                              variant="contained"
                              className={clsx(Icon && styles.linkedEntityButton)}>
                              {Icon ? (
                                <div className={clsx('inline', styles.linkedEntityIcon)}>
                                  {Icon}
                                  <Spacing size={1.5} horizontal />
                                </div>
                              ) : null}
                              <Typography variant="subtitle1">{linkedEntity.name}</Typography>
                            </Button>
                            <Spacing size={1} horizontal />
                          </React.Fragment>
                        )
                      })}
                    </div>
                    <Spacing size={2} />
                  </React.Fragment>
                )}
              </DialogContent>

              <StepsActions
                variant="dialog"
                showOutlinedBackButton={isEditing}
                outlinedBackButtonText={t('delete')}
                outlinedButtonColor="red"
                submitButton
                continueButtonText={t(isEditing ? 'save' : 'add')}
                submitting={isSubmitting}
                onGoBack={handleDeleteSelected}
              />
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    uploadInput: {
      height: 160,
      [theme.breakpoints.down('sm')]: {
        height: 120
      }
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    linkedEntityButton: {
      paddingLeft: 16,
      backgroundColor: fade(theme.palette.text.primary, 0.05),
      marginBottom: 8
    },
    linkedEntityIcon: {
      '& svg': {
        width: 20,
        height: 20
      }
    },
    linkedEntityIconFillDefault: {
      '& path': {
        fill: theme.palette.text.primary
      }
    }
  })
)

export default AddDocument
