import React, { useCallback } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Button, ButtonProps } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { RoutesPaths } from '../../Routes'

type UpgradeButtonProps = {
  fullWidth?: boolean
  className?: string
  selfCenter?: boolean
  size?: ButtonProps['size']
  onClick?: () => void
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  fullWidth,
  className,
  selfCenter,
  size,
  onClick
}: UpgradeButtonProps) => {
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const handleUpgradeSelected = useCallback(() => {
    if (onClick) onClick()
    history.push(RoutesPaths.Subscriptions)
  }, [history, onClick])

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth={fullWidth}
      onClick={handleUpgradeSelected}
      size={size}
      className={clsx(styles.button, selfCenter && styles.selfCenter, className)}>
      {t('upgrade')}
    </Button>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      flexShrink: 0
    },
    selfCenter: {
      alignSelf: 'center'
    }
  })
)

export default UpgradeButton
