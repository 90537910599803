import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { publish } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { IconArrowDownSvg, IconClose } from '../assets/Svgs'
import AlertDialog from '../components/dialogs/AlertDialog'
import InputText from '../components/inputs/InputText'
import InputUpload from '../components/inputs/InputUpload'
import { PlaceMaximumPhotoSizeMB } from '../components/new-place/NewPlaceStepMainInfo'
import Spacing from '../components/Spacing'
import StepsActions from '../components/steps/StepsActions'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import Place from '../types/place/Place'
import PlaceType from '../types/place/PlaceType'

type TypeOption = {
  id: string
  label: string
  description: string
}

type EditPlaceValues = {
  name: string
  type: PlaceType | null
  mainPicture: File | string | undefined
  addressText: string | undefined
  notes?: string
  latitude?: number
  longitude?: number
  rooms?: number
  bedrooms?: number
  bathrooms?: number
}

const EditPlace: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const { placeId } = useParams<{ placeId?: string }>()
  const { t } = useTranslation()
  const { user } = useUser()

  const defaultInitialValues = useRef<EditPlaceValues>({
    name: '',
    type: null,
    mainPicture: undefined,
    addressText: '',
    rooms: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
    notes: ''
  })
  const [initialValues, setInitialValues] = useState<EditPlaceValues>(defaultInitialValues.current)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [place, setPlace] = useState<Place | undefined>(undefined)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)
  const [submittingDelete, setSubmittingDelete] = useState<boolean>(false)

  const formRef = useRef<FormikProps<EditPlaceValues> | null>(null)
  const validationSchema = useRef(
    Yup.object({
      name: Yup.string().required(t('required_field')),
      type: Yup.string().nullable().required(t('required_field'))
    })
  )
  const typeOptions = useRef<TypeOption[]>(
    Object.values(PlaceType).map(typeId => ({
      id: typeId,
      label: t(`place.type.${typeId}.label`),
      description: t(`place.type.${typeId}.description`)
    }))
  )

  const handleDeleteSelected = useCallback(() => {
    setDeleteConfirmOpen(true)
  }, [])

  const handleDeleteConfirmClose = useCallback(() => {
    setDeleteConfirmOpen(false)
  }, [])

  const handleTypeOptionChange = useCallback((event: React.ChangeEvent<{}>, value: TypeOption | null) => {
    formRef.current?.setFieldValue('type', value ? value.id : null)
  }, [])

  const handleMainPictureChange = useCallback((id?: string, file?: File) => {
    formRef.current?.setFieldValue('mainPicture', file)
  }, [])

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const handleDeleteConfirm = useCallback(async () => {
    handleDeleteConfirmClose()

    if (placeId) {
      const { functions, db } = AppService

      setSubmittingDelete(true)

      const success = await functions.deletePlaceData(placeId)

      setSubmittingDelete(false)

      if (success) {
        db.deletePlacesCacheKeys()
        publish('placeDeleted', { placeId })
        history.replace(RoutesPaths.Places)
      } else {
        enqueueSnackbar(t('error'), { variant: 'error' })
      }
    }
  }, [placeId, handleDeleteConfirmClose, history, enqueueSnackbar, t])

  const handleSubmit = useCallback(
    async (values: EditPlaceValues, { setSubmitting }) => {
      if (!placeId) return

      let error = false

      try {
        const { storage, db } = AppService

        let pictureUrl = place?.pictureUrl

        if (values.mainPicture !== undefined && typeof values.mainPicture !== 'string') {
          pictureUrl = await storage.uploadPlaceImage(values.mainPicture)
        }

        const success = await db.updatePlace(placeId, {
          name: values.name,
          type: values.type || undefined,
          pictureUrl,
          address: Boolean(values.addressText) ? values.addressText : undefined,
          latitude: values.latitude,
          longitude: values.longitude,
          rooms: values.rooms,
          bedrooms: values.bedrooms,
          bathrooms: values.bathrooms,
          notes: values.notes
        })
        if (success) {
          publish('placeUpdated', { placeId })
        }
      } catch (err) {
        error = true
      }

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        handleClose()
      }

      setSubmitting(false)
    },
    [placeId, t, place?.pictureUrl, enqueueSnackbar, handleClose]
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      let hasErrors = false
      let item: Place | undefined

      if (placeId && user) {
        if (!didCancel) setLoading(true)

        try {
          const { db } = AppService
          const placeResult = await db.getPlace(placeId)

          item = placeResult
        } catch (err) {
          hasErrors = true
        }
      } else {
        hasErrors = true
      }

      if (!didCancel) {
        setLoading(false)
        setError(hasErrors)
        setPlace(item)
        if (item) {
          setInitialValues({
            name: item.name,
            type: item.type,
            mainPicture: item.pictureUrl,
            addressText: item.address,
            latitude: item.latitude,
            longitude: item.longitude,
            rooms: item.rooms,
            bedrooms: item.bedrooms,
            bathrooms: item.bathrooms,
            notes: item.notes
          })
        }
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [placeId, user])

  const renderTypeOption = useCallback(
    (item: TypeOption | null) =>
      !item ? null : (
        <div>
          <Typography variant="subtitle1" component="div">
            {item.label}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="div" className={styles.optionDescription}>
            {item.description}
          </Typography>
        </div>
      ),
    [styles.optionDescription]
  )

  return (
    <Dialog
      open
      aria-labelledby="edit-place-dialog-title"
      aria-describedby="edit-place-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.current}
        onSubmit={handleSubmit}
        innerRef={r => (formRef.current = r)}>
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
          const submitting = submittingDelete || isSubmitting

          return (
            <Form>
              <DialogTitle disableTypography id="alert-dialog-title">
                <div className={clsx(styles.title, 'inline', 'justify-between')}>
                  <div className="inline">
                    <Typography variant="h3" component="div">
                      {t('edit_place')}
                    </Typography>
                  </div>

                  <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
                    <IconClose />
                  </IconButton>
                </div>
              </DialogTitle>

              <DialogContent>
                {error ? (
                  <React.Fragment>
                    <Typography variant="body1" component="div">
                      {t('error')}
                    </Typography>

                    <Spacing size={2} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <InputText
                      label={t('name')}
                      placeholder={t('name')}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.name) && touched.name}
                      helperText={errors.name && touched.name ? errors.name : ' '}
                      disabled={submitting}
                      loading={loading}
                    />
                    <Spacing size={1} />
                    {loading ? (
                      <React.Fragment>
                        <Typography variant="body1" component="div">
                          {t('place_type')}
                        </Typography>
                        <Spacing size={1} />
                        <Skeleton variant="rect" className={styles.loader} />
                      </React.Fragment>
                    ) : (
                      <Autocomplete<TypeOption | null, false, true, false>
                        options={typeOptions.current}
                        id="type"
                        disableClearable={true}
                        disabled={submitting}
                        value={(typeOptions.current.find(t => t.id === values.type) || null) as TypeOption}
                        onBlur={handleBlur}
                        renderInput={params => (
                          <InputText
                            {...params}
                            label={t('place_type')}
                            placeholder={t('place_type')}
                            error={Boolean(errors.type) && touched.type}
                            helperText={errors.type && touched.type ? errors.type : ' '}
                          />
                        )}
                        placeholder={t('choose_one')}
                        getOptionSelected={(option, value) => option?.id === value?.id}
                        getOptionLabel={item => (item ? item.label : '')}
                        onChange={handleTypeOptionChange}
                        renderOption={renderTypeOption}
                        popupIcon={<IconArrowDownSvg />}
                      />
                    )}
                    <Spacing size={1} />
                    <InputText
                      label={t('notes_for_guests')}
                      placeholder={t('notes_for_guests_placeholder')}
                      name="notes"
                      multiline
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.notes) && touched.notes}
                      helperText={errors.notes && touched.notes ? errors.notes : ' '}
                      disabled={submitting}
                    />
                    <Spacing size={4} />
                    <InputUpload
                      id="mainPicture"
                      fullWidth
                      className={styles.mainPictureInput}
                      file={values.mainPicture}
                      imageOnly
                      maxSizeMb={PlaceMaximumPhotoSizeMB}
                      loading={loading}
                      disabled={submitting}
                      onFileChange={handleMainPictureChange}
                    />
                  </React.Fragment>
                )}
              </DialogContent>

              <StepsActions
                variant="dialog"
                showOutlinedBackButton
                outlinedBackButtonText={t('delete')}
                leftButtonSubmitting={submittingDelete}
                outlinedButtonColor="red"
                submitButton
                continueButtonText={t('save')}
                submitting={submitting}
                hidden={error}
                onGoBack={handleDeleteSelected}
              />
            </Form>
          )
        }}
      </Formik>

      <AlertDialog
        open={Boolean(deleteConfirmOpen)}
        actionDelete
        title={t('confirm_delete_place')}
        confirmButtonText={t('delete')}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteConfirmClose}
      />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    optionDescription: {
      marginTop: theme.spacing(0.5)
    },
    mainPictureInput: {
      height: 240,
      [theme.breakpoints.down('sm')]: {
        height: 200
      }
    },
    loader: {
      height: 46,
      marginBottom: 18,
      borderRadius: theme.shape.borderRadius
    }
  })
)

export default EditPlace
