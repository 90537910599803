import { Button, createStyles, fade, IconButton, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconArrowDownSvg } from '../../assets/Svgs'

type BackButtonProps = {
  disabled?: boolean
  iconOnly?: boolean
  color?: 'default' | 'primary'
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const BackButton: React.FC<BackButtonProps> = ({
  disabled,
  iconOnly,
  color = 'default',
  className,
  onClick
}: BackButtonProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return iconOnly ? (
    <IconButton
      component="div"
      className={clsx(styles.iconButton, className, { [styles.iconButtonPrimary]: color === 'primary' })}
      disabled={disabled}
      onClick={onClick}>
      <IconArrowDownSvg className={clsx(styles.icon, { [styles.iconPrimary]: color === 'primary' })} />
    </IconButton>
  ) : (
    <Button
      variant="text"
      color="primary"
      startIcon={<IconArrowDownSvg className={styles.icon} />}
      onClick={onClick}
      disabled={disabled}
      component="div"
      className={className}>
      {t('back')}
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 24,
      height: 24,
      flexShrink: 0,
      transform: 'rotate(90deg)',
      '& path': {
        fill: theme.palette.grey[500]
      }
    },
    iconButton: {
      width: 40,
      height: 40,
      padding: 0,
      backgroundColor: fade(theme.palette.grey[500], 0.2),
      '&:hover': {
        backgroundColor: fade(theme.palette.grey[500], 0.4)
      }
    },
    iconButtonPrimary: {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.4)
      }
    },
    iconPrimary: {
      '& path': {
        fill: theme.palette.primary.main
      }
    }
  })
)

export default BackButton
