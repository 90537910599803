import { useMediaQuery, useTheme } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ListTable, { ListTableRowItem } from '../../data-display/ListTable'
import { RoutesPaths } from '../../Routes'
import Guest from '../../types/Guest'
import GuestRentPlace from '../../types/GuestRentPlace'

type GuestsListProps = {
  loading: boolean
  items: Guest[]
  guestsRents: GuestRentPlace[]
}

const GuestsList: React.FC<GuestsListProps> = ({ loading, items, guestsRents }: GuestsListProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleItemSelected = useCallback(
    (id: string) => {
      history.push(RoutesPaths.EditGuestDialog.replace(':guestId', id), { isModal: true })
    },
    [history]
  )

  const columns = [
    {
      field: 'firstName',
      headerName: t('first_name')
    },
    {
      field: 'lastName',
      headerName: t('last_name')
    }
  ]
    .concat(
      isSmUp
        ? [
            {
              field: 'email',
              headerName: t('email')
            }
          ]
        : []
    )
    .concat(
      isMdUp
        ? [
            {
              field: 'rents',
              headerName: t('active_rents')
            }
          ]
        : []
    )

  const rows: ListTableRowItem[] = items.map(i => ({
    id: i.id,
    items: {
      firstName: { label: i.firstName },
      lastName: { label: i.lastName },
      email: { label: i.email },
      rents: {
        label: guestsRents
          .reduce((arr, item) => (item.guestsIds.includes(i.id) ? arr.concat([item.place.name]) : arr), [] as string[])
          .join(', ')
      }
    }
  }))

  return <ListTable loading={loading} columns={columns} rows={rows} onItemSelected={handleItemSelected} />
}

export default GuestsList
