import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'

type ToolbarProps = {
  LeftComponent?: React.ReactNode
  RightComponent?: React.ReactNode
  CenterComponent?: React.ReactNode
}

const Toolbar: React.FC<ToolbarProps> = ({ LeftComponent, RightComponent, CenterComponent }: ToolbarProps) => {
  const styles = useStyles()

  return (
    <div className={styles.toolbar}>
      {LeftComponent}

      <div className={styles.toolbarCenterContainer}>{CenterComponent}</div>

      {RightComponent}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 1,
      minHeight: 40,
      [theme.breakpoints.down('sm')]: {
        minHeight: 32
      }
    },
    toolbarCenterContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: -1
    }
  })
)

export default Toolbar
