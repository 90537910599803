import { fade, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { useUser } from '../../hooks/useUser'
import Colors from '../../styles/Colors'
import { formatCurrency } from '../../utils/CurrencyUtils'
import Spacing from '../Spacing'

type StatisticsSummaryProps = {
  total: number
  items: { label: string; value?: string; suffix?: string }[]
  contrastText?: boolean
  backgroundColor?: string
  textColor?: string
}

const StatisticsSummary: React.FC<StatisticsSummaryProps> = ({
  total,
  items,
  contrastText,
  backgroundColor,
  textColor
}: StatisticsSummaryProps) => {
  const styles = useStyles()
  const { user } = useUser()

  return (
    <div className={styles.root} style={{ backgroundColor }}>
      <div className="inline justify-between">
        <div />

        <Typography
          variant="body1"
          className={clsx(styles.heavyText, { [styles.contrastText]: contrastText })}
          style={{ color: textColor }}>
          {formatCurrency(total, user?.currency)}
        </Typography>
      </div>

      <Spacing size={2} />

      <div className={clsx('colstretch', styles.itemsContainer)}>
        {items.map((item, index) => (
          <Typography
            key={index}
            variant="subtitle2"
            component="span"
            className={clsx(styles.item, { [styles.contrastText]: contrastText })}>
            <Typography variant="subtitle2" component="span">
              {item.label}
            </Typography>
            {item.value ? (
              <Typography
                variant="subtitle2"
                className={clsx(styles.item, styles.heavyText, 'spacing-left-xs', {
                  [styles.contrastText]: contrastText
                })}
                component="span"
                style={{ color: textColor }}>
                {item.value}
              </Typography>
            ) : null}
            {item.suffix ? (
              <Typography
                variant="subtitle2"
                className={clsx('spacing-left-xs', {
                  [styles.contrastText]: contrastText
                })}
                component="span">
                {item.suffix}
              </Typography>
            ) : null}
          </Typography>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.background.paper : fade(theme.palette.background.paper, 0.6),
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
    },
    item: {
      fontWeight: 400,
      color: theme.palette.text.secondary
    },
    contrastText: {
      color: Colors.White
    },
    heavyText: {
      fontWeight: 700
    },
    itemsContainer: {
      '& > span:not(:last-child)': {
        marginBottom: theme.spacing(0.5)
      }
    }
  })
)

export default StatisticsSummary
