import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { Switch, useLocation } from 'react-router-dom'

type RoutingSwitchProps = PropsWithChildren<{}>

const RoutingSwitch: React.FC<RoutingSwitchProps> = ({ children }: RoutingSwitchProps) => {
  const location = useLocation()

  const previousLocation = useRef(location)

  const isModal = !!(location.state && (location.state as any).isModal && previousLocation.current !== location)

  useEffect(() => {
    if (!location.state || !(location.state as any).isModal) {
      previousLocation.current = location
    }
  }, [location])

  return <Switch location={isModal ? (previousLocation.current as any) : location}>{children}</Switch>
}

export default RoutingSwitch
