import { Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { endOfMonth } from 'date-fns'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ImageRentalsChartEmpty } from '../../assets/Images'
import { useUser } from '../../hooks/useUser'
import Colors from '../../styles/Colors'
import Rent from '../../types/place/Rent'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import {
  getRentsTransactionsByPaymentStatus,
  getRentsTransactionsSummaryPeriodDateRanges,
  getRentsTransactionsSummaryPeriodDates,
  getRentsTransactionSummaryCashed,
  getTransactionsAmountForPeriod
} from '../../utils/RentUtils'
import Spacing from '../Spacing'
import ChartLegend from './ChartLegend'
import ChartTooltip from './ChartTooltip'

type PlaceDetailRentalsChartProps = {
  rents: Rent[]
}

const PlaceDetailRentalsChart: React.FC<PlaceDetailRentalsChartProps> = ({ rents }: PlaceDetailRentalsChartProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()

  const isEmpty = rents.length === 0

  const { paidTransactions, delayedTransactions, unpaidTransactions } = getRentsTransactionsByPaymentStatus(rents)

  const periodDates = useRef(getRentsTransactionsSummaryPeriodDates())

  const { startDate, endDate } = getRentsTransactionsSummaryPeriodDateRanges(periodDates.current)

  const data: any[] = periodDates.current.map(p => ({
    name: p,
    date: p,
    paid: getTransactionsAmountForPeriod(p, endOfMonth(p), paidTransactions),
    delayed: getTransactionsAmountForPeriod(p, endOfMonth(p), delayedTransactions),
    unpaid: getTransactionsAmountForPeriod(p, endOfMonth(p), unpaidTransactions)
  }))

  return (
    <div className={styles.root}>
      {isEmpty ? (
        <img src={ImageRentalsChartEmpty} className={styles.empty} />
      ) : (
        <React.Fragment>
          <div className="colstretch">
            <Typography variant="body2">
              {`${t('cashed')}: `}
              <strong>{formatCurrency(getRentsTransactionSummaryCashed(rents), user?.currency)}</strong>
            </Typography>

            <Spacing size={1} />

            <ChartLegend
              items={[
                {
                  color: Colors.Green,
                  label: t('paid'),
                  value: getTransactionsAmountForPeriod(startDate, endDate, paidTransactions),
                  isCurrency: true
                },
                {
                  color: Colors.Orange,
                  label: t('delayed'),
                  value: getTransactionsAmountForPeriod(startDate, endDate, delayedTransactions),
                  isCurrency: true
                },
                {
                  color: Colors.Red,
                  label: t('unpaid_male'),
                  value: getTransactionsAmountForPeriod(startDate, endDate, unpaidTransactions),
                  isCurrency: true
                }
              ]}
            />
          </div>

          <Spacing size={2} />

          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorPaid" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={Colors.Green} stopOpacity={0.2} />
                  <stop offset="90%" stopColor={Colors.Green} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorUnpaid" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={Colors.Red} stopOpacity={0.2} />
                  <stop offset="90%" stopColor={Colors.Red} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorDelayed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={Colors.Orange} stopOpacity={0.2} />
                  <stop offset="90%" stopColor={Colors.Orange} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                interval={1}
                tickFormatter={value => formatDate(value, 'MMM')}
              />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip content={<ChartTooltip />} />
              <Area
                type="monotone"
                dataKey="paid"
                strokeWidth={2}
                stroke={Colors.Green}
                fillOpacity={1}
                fill="url(#colorPaid)"
              />
              <Area
                type="monotone"
                dataKey="unpaid"
                strokeWidth={2}
                stroke={Colors.Red}
                fillOpacity={1}
                fill="url(#colorUnpaid)"
              />
              <Area
                type="monotone"
                dataKey="delayed"
                strokeWidth={2}
                stroke={Colors.Orange}
                fillOpacity={1}
                fill="url(#colorDelayed)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1
    },
    empty: {
      width: '100%',
      height: '100%'
    }
  })
)

export default PlaceDetailRentalsChart
