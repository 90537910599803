import { Theme, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IconArrowDownSvg } from '../../assets/Svgs'
import { NewPlaceStepValues1 } from '../../pages/NewPlace'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import PlaceType from '../../types/place/PlaceType'
import InputText from '../inputs/InputText'
import InputUpload from '../inputs/InputUpload'
import Spacing from '../Spacing'

export const PlaceMaximumPhotoSizeMB = 1

type TypeOption = {
  id: string
  label: string
  description: string
}

type NewPlaceStepMainInfoProps = FormStepComponentBase<NewPlaceStepValues1>

const NewPlaceStepMainInfo: React.FC<NewPlaceStepMainInfoProps> = ({
  submitting,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}: NewPlaceStepMainInfoProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const typeOptions = useRef<TypeOption[]>(
    Object.values(PlaceType).map(typeId => ({
      id: typeId,
      label: t(`place.type.${typeId}.label`),
      description: t(`place.type.${typeId}.description`)
    }))
  )

  const renderTypeOption = useCallback(
    (item: TypeOption | null) =>
      !item ? null : (
        <div>
          <Typography variant="subtitle1" component="div">
            {item.label}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="div" className={styles.optionDescription}>
            {item.description}
          </Typography>
        </div>
      ),
    [styles.optionDescription]
  )

  const handleTypeOptionChange = useCallback(
    (event: React.ChangeEvent<{}>, value: TypeOption | null) => {
      setFieldValue('type', value ? value.id : null)
    },
    [setFieldValue]
  )

  const handleMainPictureChange = useCallback(
    (id?: string, file?: File) => {
      setFieldValue('mainPicture', file)
    },
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <InputText
        label={t('newplace.step_0.name_label')}
        placeholder={`${t('choose_name')}*`}
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(errors.name) && touched.name}
        helperText={errors.name && touched.name ? errors.name : ' '}
        disabled={submitting}
      />
      <Spacing size={1} />
      <Autocomplete<TypeOption | null, false, true, false>
        options={typeOptions.current}
        id="type"
        disableClearable={true}
        value={(typeOptions.current.find(t => t.id === values.type) || null) as TypeOption}
        onBlur={handleBlur}
        renderInput={params => (
          <InputText
            {...params}
            label={t('newplace.step_0.type_label')}
            placeholder={`${t('choose_one')}*`}
            error={Boolean(errors.type) && touched.type}
            helperText={errors.type && touched.type ? errors.type : ' '}
          />
        )}
        placeholder={t('choose_one')}
        getOptionSelected={(option, value) => option?.id === value?.id}
        getOptionLabel={item => (item ? item.label : '')}
        onChange={handleTypeOptionChange}
        renderOption={renderTypeOption}
        popupIcon={<IconArrowDownSvg />}
      />
      <Spacing size={2} />
      <InputUpload
        id="mainPicture"
        fullWidth
        className={styles.mainPictureInput}
        file={values.mainPicture}
        imageOnly
        maxSizeMb={PlaceMaximumPhotoSizeMB}
        onFileChange={handleMainPictureChange}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionDescription: {
      marginTop: theme.spacing(0.5)
    },
    mainPictureInput: {
      height: 240,
      [theme.breakpoints.down('sm')]: {
        height: 200
      }
    }
  })
)

export default NewPlaceStepMainInfo
