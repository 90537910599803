import { addMonths, differenceInCalendarDays, endOfMonth, setDate, startOfDay, startOfMonth, sub } from 'date-fns'
import differenceInMonths from 'date-fns/differenceInMonths'
import isValid from 'date-fns/isValid'
import { TFunction } from 'i18next'
import Rent from '../types/place/Rent'
import Transaction from '../types/Transaction'
import { formatCurrency } from './CurrencyUtils'
import { formatDate, formatDurationToString } from './DateUtils'
import { capitalizeFirstLetter, lowercaseFirstLetter } from './TextUtils'

export const getRentLabel = (t: TFunction, rent: Rent) =>
  t(rent.isBooking ? 'booking_from_to' : 'rent_from_to', {
    startDate: formatDate(rent.from),
    endDate: formatDate(rent.to)
  })

export const getNightsDuration = (startDate?: Date | null, endDate?: Date | null) => {
  if (!(startDate && endDate && isValid(startDate) && isValid(endDate))) {
    return 0
  }

  return differenceInCalendarDays(endDate, startDate)
}

export const getMonthsDaysDuration = (
  startDate?: Date | null,
  endDate?: Date | null
): { months: number; days: number } => {
  if (!(startDate && endDate && isValid(startDate) && isValid(endDate))) {
    return {
      months: 0,
      days: 0
    }
  }

  const monthsDiff = differenceInMonths(endDate, startDate)
  return {
    months: monthsDiff,
    days: differenceInCalendarDays(sub(endDate, { months: monthsDiff }), startDate)
  }
}

export const getMonthsDaysDurationLabel = (t: TFunction, startDate?: Date | null, endDate?: Date | null): string => {
  if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
    return capitalizeFirstLetter(
      formatDurationToString(getMonthsDaysDuration(startDate, endDate), {
        format: ['months', 'days'],
        delimiter: ` ${t('and')} `
      })
    )
  }

  return '––'
}

export const getRentContractDurationDatesRangeLabel = (
  t: TFunction,
  startDate?: Date | null,
  endDate?: Date | null
): string => {
  if (startDate && endDate) {
    return `(${t('from_item', { item: formatDate(startDate, 'P'), interpolation: { escapeValue: false } })} ${t(
      'to_item',
      { item: formatDate(endDate, 'P'), interpolation: { escapeValue: false } }
    )})`
  }

  return ''
}

export const getRentAmountForRecurrencePeriod = (
  totalAmount?: Rent['totalAmount'],
  recurrence?: Rent['recurrence'],
  startDate?: Date | null,
  endDate?: Date | null
): {
  amount: number
  isNotRound?: boolean
} => {
  const rentDuration = getMonthsDaysDuration(startDate, endDate)

  return {
    amount:
      totalAmount && totalAmount !== 0
        ? totalAmount / (recurrence === 'night' ? rentDuration.days - 1 : rentDuration.months)
        : 0,
    isNotRound: recurrence === 'month' && rentDuration.days > 0
  }
}

export const getRentRecurrenceLabel = (
  t: TFunction,
  recurringPayment: Rent['recurringPayment'],
  recurringPaymentValue: Rent['recurringPaymentValue'],
  recurringPaymentDay: Rent['recurringPaymentDay']
): string => {
  if (!recurringPayment) return t('payment_does_not_repeat_label')

  return t('every', {
    value: `${lowercaseFirstLetter(
      t(`periods.month.${recurringPaymentValue && recurringPaymentValue > 1 ? 'plural' : 'noun'}`, {
        amount: recurringPaymentValue
      })
    )}, ${t('day_of_month', { day: recurringPaymentDay })}`
  })
}

export const getDefaultRentTransactions = (
  t: TFunction,
  totalAmount?: Rent['totalAmount'],
  startDate?: Date | null,
  endDate?: Date | null,
  recurringPayment?: Rent['recurringPayment'],
  recurringPaymentValue?: Rent['recurringPaymentValue'],
  recurringPaymentDay?: Rent['recurringPaymentDay'],
  isBooking?: boolean
): Transaction[] => {
  const defaultDate = startDate || new Date()
  const getDefaultDescription = () => t(isBooking ? 'night_stay' : 'rent_installment')

  if (!recurringPayment) {
    const paid = differenceInCalendarDays(new Date(), defaultDate) >= 0
    return [
      {
        id: 'transaction_unique',
        isRent: true,
        description: getDefaultDescription(),
        amount: totalAmount || 0,
        paid,
        paidOn: paid ? defaultDate : undefined,
        dueDate: defaultDate,
        created: new Date(),
        categories: [],
        isBooking
      }
    ]
  }

  const rentDuration = getMonthsDaysDuration(startDate, endDate)
  const numberOfTransactions = Math.floor(rentDuration.months / (recurringPaymentValue || 1))

  if (numberOfTransactions === 0) return []

  const transactionAmount = (totalAmount || 0) / numberOfTransactions
  const dueDate =
    recurringPaymentDay !== undefined && recurringPaymentDay !== 0
      ? setDate(defaultDate, recurringPaymentDay)
      : defaultDate

  return [...Array(numberOfTransactions).keys()].map(index => {
    const transactionDueDate = addMonths(dueDate, index * (recurringPaymentValue || 1))
    const paid = differenceInCalendarDays(new Date(), transactionDueDate) >= 0
    return {
      id: `transaction_${index}`,
      isRent: true,
      description: getDefaultDescription(),
      amount: transactionAmount,
      paid,
      paidOn: paid ? transactionDueDate : undefined,
      dueDate: transactionDueDate,
      created: new Date(),
      categories: [],
      isBooking
    }
  })
}

export const getRentTotalCashed = (rent: Rent) =>
  rent.transactions.reduce((total, transaction) => total + (transaction.paid ? transaction.amount : 0), 0)

export const getRentNextTransaction = (rentTransactions: Transaction[]) => {
  const nowDate = new Date()
  return rentTransactions
    .filter(t => t.isRent && !t.paid && differenceInCalendarDays(t.dueDate, nowDate) > 0)
    .sort((a, b) => {
      const diffA = differenceInCalendarDays(a.dueDate, nowDate)
      const diffB = differenceInCalendarDays(b.dueDate, nowDate)
      return diffA - diffB
    })[0]
}

export const getTransactionPaymentLabel = (
  transaction: Transaction,
  t: TFunction,
  currency?: string,
  nowDate = new Date()
): string =>
  transaction.paid
    ? `${t('paid_on')}: ${formatDate(transaction.dueDate)}`
    : `${formatCurrency(transaction.amount, currency)} ${
        differenceInCalendarDays(nowDate, transaction.dueDate) > 0
          ? t('at_date', { date: formatDate(transaction.dueDate) })
          : t('in', {
              value: lowercaseFirstLetter(getMonthsDaysDurationLabel(t, nowDate, transaction.dueDate))
            })
      }`

export const getRentNextPaymentLabel = (rent: Rent, t: TFunction, currency?: string): string => {
  const nowDate = new Date()
  let label = ''

  if (rent.nextTransaction) {
    label = getTransactionPaymentLabel(rent.nextTransaction, t, currency, nowDate)
  }

  return label
}

export const getRentStatus = (
  rent: Rent,
  t?: TFunction
): {
  label?: string
  status: 'completed' | 'in_progress'
} => {
  const isEnded = differenceInCalendarDays(new Date(), rent.to) > 0
  const status = isEnded ? 'completed' : 'in_progress'
  return {
    label: t ? t(status) : undefined,
    status
  }
}

export const getTransactionsByTrendStatus = (transactions: Transaction[]) => {
  return {
    inTransactions: transactions.filter(t => Boolean(t.rentId) && t.paid),
    outTransactions: transactions.filter(t => !t.isRent && Boolean(t.placeId) && t.paid)
  }
}

export const getTransactionsByPaymentStatus = (transactions: Transaction[]) => {
  const paidTransactions = transactions.filter(
    t => t.isRent && t.paid && t.paidOn && startOfDay(t.paidOn).getTime() <= startOfDay(t.dueDate).getTime()
  )
  const delayedTransactions = transactions.filter(
    t => t.isRent && t.paid && t.paidOn && startOfDay(t.paidOn).getTime() > startOfDay(t.dueDate).getTime()
  )
  const unpaidTransactions = transactions.filter(t => t.isRent && !t.paid && new Date().getTime() > t.dueDate.getTime())

  return { paidTransactions, delayedTransactions, unpaidTransactions }
}

export const getRentsTransactionsByPaymentStatus = (rents: Rent[]) => {
  const allTransactions = rents.reduce((arr, r) => arr.concat(r.transactions), [] as Transaction[])

  const paidTransactions = allTransactions.filter(
    t => t.paid && t.paidOn && startOfDay(t.paidOn).getTime() <= startOfDay(t.dueDate).getTime()
  )
  const delayedTransactions = allTransactions.filter(
    t => t.paid && t.paidOn && startOfDay(t.paidOn).getTime() > startOfDay(t.dueDate).getTime()
  )
  const unpaidTransactions = allTransactions.filter(t => !t.paid && new Date().getTime() > t.dueDate.getTime())

  return { paidTransactions, delayedTransactions, unpaidTransactions }
}

export const getRentsTransactionsSummaryPeriodDates = (months = 12) =>
  [...Array(months).keys()]
    .map(i =>
      startOfMonth(
        sub(new Date(), {
          months: i
        })
      )
    )
    .reverse()

export const getRentsTransactionsSummaryPeriodDateRanges = (periodDates?: Date[]) => {
  if (!periodDates) {
    periodDates = getRentsTransactionsSummaryPeriodDates()
  }

  return { startDate: periodDates[0], endDate: endOfMonth(periodDates[periodDates.length - 1]) }
}

export const getRentsTransactionSummaryCashed = (rents: Rent[]) => {
  const { paidTransactions, delayedTransactions } = getRentsTransactionsByPaymentStatus(rents)

  const { startDate, endDate } = getRentsTransactionsSummaryPeriodDateRanges()

  return (
    getTransactionsAmountForPeriod(startDate, endDate, paidTransactions) +
    getTransactionsAmountForPeriod(startDate, endDate, delayedTransactions)
  )
}

export const getCurrentPeriodTransaction = (dueDate: Date, periodStartDate: Date, periodEndDate: Date) =>
  dueDate.getTime() > periodStartDate.getTime() && dueDate.getTime() <= periodEndDate.getTime()

export const getTransactionsAmountForPeriod = (
  periodStartDate: Date,
  periodEndDate: Date,
  transactions: Transaction[]
) =>
  transactions
    .filter(t => getCurrentPeriodTransaction(t.paidOn || t.dueDate, periodStartDate, periodEndDate))
    .reduce((sum, t) => sum + t.amount, 0)

export const getPaidPlaceTransactions = (transactions: Transaction[]) =>
  transactions.filter(t => t.paid && Boolean(t.paidOn))

export const getRentDurationLessThanAMonth = (from?: Date | null, to?: Date | null) =>
  from && to && differenceInMonths(to, from) < 1

export const sortRents = (rents: Rent[]) => rents.sort((a, b) => b.created.getTime() - a.created.getTime())
