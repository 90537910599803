import { ButtonBase, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconAddEvent, IconRent } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { RoutesPaths } from '../../Routes'
import Colors from '../../styles/Colors'
import Rent from '../../types/place/Rent'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { getRentLabel, getRentNextPaymentLabel, getRentStatus, getRentTotalCashed } from '../../utils/RentUtils'
import { getGuestDisplayName } from '../../utils/TextUtils'
import ColorIcon from '../ColorIcon'
import NameValueItem from '../items/NameValueItem'
import Spacing from '../Spacing'
import Tag from '../Tag'
import UserAvatar from '../UserAvatar'

type PlaceDetailRentItemProps = {
  rent: Rent
  placeId: string
}

const PlaceDetailRentItem: React.FC<PlaceDetailRentItemProps> = ({ rent, placeId }: PlaceDetailRentItemProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const history = useHistory()

  const handleRentItemSelected = useCallback(() => {
    history.push(RoutesPaths.EditRentDialog.replace(':placeId', placeId).replace(':rentId', rent?.id), {
      isModal: true
    })
  }, [history, rent?.id, placeId])

  const nextRentLabel = getRentNextPaymentLabel(rent, t, user?.currency)
  const rentStatus = getRentStatus(rent, t)

  return (
    <ButtonBase className={styles.root} onClick={handleRentItemSelected}>
      <div className={styles.header}>
        <Typography variant="button" component="div" className="break-word">
          {getRentLabel(t, rent)}
        </Typography>

        <Tag
          text={rentStatus.label}
          status={rentStatus.status === 'in_progress' ? 'success' : 'default'}
          backgroundColor="paper"
        />
      </div>

      <div className={styles.spacing} />

      <div className="inline">
        <NameValueItem
          Icon={
            <ColorIcon Icon={rent.isBooking ? <IconAddEvent /> : <IconRent />} color={Colors.Green} withBackground />
          }
          label={t('total_cashed')}
          value={formatCurrency(getRentTotalCashed(rent), user?.currency)}
          valueColor="green"
        />

        {nextRentLabel && (
          <React.Fragment>
            <div className={styles.divider} />

            <div className="colstretch">
              <Typography variant="subtitle2" component="div" color="textSecondary" className="font-weight-regular">
                {t('next_rent')}
              </Typography>
              <div className="spacing-block-vertical-xxs" />
              <Typography variant="subtitle2" className="font-weight-regular">
                {nextRentLabel}
              </Typography>
            </div>
          </React.Fragment>
        )}
      </div>

      {rent.guests.length > 0 && <Spacing size={2} />}

      <div className="inline justify-between">
        {rent.guests.length > 0 && (
          <div className="inline">
            <div className="inline">
              <div className={clsx('inline', styles.guestsAvatarsContainer)}>
                {rent.guests.slice(0, 3).map((guest, index) => {
                  const isNumberPlaceholder = index === 2 && rent.guests.length > 3
                  return (
                    <UserAvatar
                      key={guest.id}
                      name={isNumberPlaceholder ? `+${rent.guests.length - 2}` : getGuestDisplayName(guest)}
                      showSecondChar={isNumberPlaceholder}
                      size="small"
                      variant="default"
                    />
                  )
                })}
              </div>
              <Spacing size={1} horizontal />
              <Typography variant="overline" component="div">
                {rent.guests.map(g => getGuestDisplayName(g)).join(', ')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </ButtonBase>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      flex: 1,
      borderRadius: theme.shape.borderRadius * 2,
      textAlign: 'left'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    spacing: {
      height: theme.spacing(2)
    },
    divider: {
      height: 24,
      width: 1,
      flexShrink: 0,
      backgroundColor: theme.palette.divider,
      margin: `0 ${theme.spacing(2.5)}px`
    },
    guestsAvatarsContainer: {
      '& > div:not(:first-child)': {
        marginLeft: -8
      }
    }
  })
)

export default PlaceDetailRentItem
