import { createStyles, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { AppLogoSvgIta } from '../assets/Svgs'
import { RoutesPaths } from '../Routes'

type AppLogoProps = {
  clickDisabled?: boolean
  className?: string
}

const AppLogo: React.FC<AppLogoProps> = ({ clickDisabled, className }: AppLogoProps) => {
  const styles = useStyles()
  const history = useHistory()

  const handleClick = useCallback(() => history.push(RoutesPaths.Places), [history])

  return (
    <div
      onClick={clickDisabled ? undefined : handleClick}
      className={clsx(styles.root, className, { [styles.pointer]: !clickDisabled })}>
      <AppLogoSvgIta />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer'
    },
    root: {
      '& svg': {
        width: 140,
        '& path:not(:first-child)': {
          fill: theme.palette.secondary.main
        }
      }
    }
  })
)

export default AppLogo
