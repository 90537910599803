import {
  Button,
  Divider,
  fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Theme,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconAddCircleOutlined, IconArrowDownSvg } from '../assets/Svgs'
import AppLoader from './AppLoader'
import InputCheckbox from './inputs/InputCheckbox'
import FeaturePromoUpgrade from './promo/FeaturePromoUpgrade'

type InputMultipleSelectAddProps<T extends { id: string }> = {
  value: T[]
  items: T[]
  addLabel: string
  AddContent: React.ReactNode
  loading: boolean
  getLabel: (item: T) => string
  renderLabel?: (item: T, isLastItem?: boolean, isSelected?: boolean) => React.ReactNode
  onResetAdd: () => void
  onChange: (items: T[]) => void
  onSubmit: () => Promise<boolean>
  label?: string
  maxNumberOfItems?: number
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  maxSingleSelectionErrorText?: string
}

const SelectLoader = () => <AppLoader size={20} style={{ marginRight: 16, flexShrink: 0 }} />

const InputMultipleSelectAdd = <T extends { id: string }>({
  value,
  items,
  addLabel,
  AddContent,
  loading,
  getLabel,
  onResetAdd,
  onChange,
  onSubmit,
  renderLabel,
  label,
  maxNumberOfItems,
  placeholder,
  disabled,
  error,
  helperText,
  maxSingleSelectionErrorText
}: InputMultipleSelectAddProps<T>) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [selectAddAnchorEl, setSelectAddAnchorEl] = useState<HTMLDivElement | null>(null)

  const selectRef = useRef<HTMLDivElement | null>(null)

  const handleSelectOpen = useCallback(() => setSelectOpen(true), [])

  const handleSelectClose = useCallback(() => setSelectOpen(false), [])

  const handleSelectAddClose = useCallback(() => setSelectAddAnchorEl(null), [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (!(event.target.value && Array.isArray(event.target.value))) return
      const selection: string[] = event.target.value

      if (selection.length > 1 && maxSingleSelectionErrorText) {
        enqueueSnackbar(maxSingleSelectionErrorText, {
          variant: 'warning',
          preventDuplicate: true,
          action: key => <FeaturePromoUpgrade onClick={() => closeSnackbar(key)} />
        })
        return
      }
      if (maxNumberOfItems && selection.length > maxNumberOfItems) {
        enqueueSnackbar(
          `${t('max_items_selected', { amount: maxNumberOfItems })}${
            selection.includes('add') ? ` ${t('max_items_selected_add_hint')}` : ''
          }`,
          { variant: 'warning' }
        )
        return
      }

      if (selection.includes('add')) {
        handleSelectClose()
        setSelectAddAnchorEl(selectRef.current)
      } else {
        const selectedItems = items.filter(g => selection.includes(g.id))
        onChange(selectedItems)
      }
    },
    [
      maxSingleSelectionErrorText,
      maxNumberOfItems,
      enqueueSnackbar,
      closeSnackbar,
      t,
      handleSelectClose,
      items,
      onChange
    ]
  )

  const closeAndResetAdd = useCallback(() => {
    handleSelectOpen()
    handleSelectAddClose()
    onResetAdd()
  }, [handleSelectOpen, handleSelectAddClose, onResetAdd])

  const handleAddSubmit = useCallback(async () => {
    const success = await onSubmit()

    if (success) {
      closeAndResetAdd()
    }
  }, [onSubmit, closeAndResetAdd])

  const selectAddOpen = Boolean(selectAddAnchorEl)

  return (
    <React.Fragment>
      {label ? (
        <Typography variant="body1" component="div" className={styles.label}>
          {label}
        </Typography>
      ) : null}
      <FormControl variant="outlined" fullWidth error={error}>
        <Select
          multiple
          displayEmpty
          disabled={disabled || loading}
          ref={r => (selectRef.current = r as HTMLDivElement)}
          value={(value || []).map(g => g.id)}
          open={selectOpen}
          onChange={handleChange}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          renderValue={(valueItem: unknown) => {
            const selected = valueItem as string[]
            if (selected.length === 0) {
              return (
                <InputBase
                  fullWidth
                  placeholder={placeholder}
                  className={styles.selectPlaceholder}
                  inputProps={{
                    autoComplete: 'off',
                    ['data-lpignore']: 'true'
                  }}
                  disabled={loading}
                />
              )
            }

            const selectedItems = selected.reduce((arr, itemId) => {
              const item = items.find(i => i.id === itemId)
              return item ? arr.concat(item) : arr
            }, [] as T[])

            if (renderLabel) {
              return (
                <div className={clsx('inline', styles.wrap)}>
                  {selectedItems.map((item, index) => {
                    const isLastItem = index === selectedItems.length - 1

                    return renderLabel(item, isLastItem, true)
                  })}
                </div>
              )
            }

            return (
              <Typography variant="body1" component="div">
                {selectedItems.map(i => (getLabel ? getLabel(i) : '')).join(', ')}
              </Typography>
            )
          }}
          inputProps={{ 'aria-label': 'Without label', id: 'multiple-select-input' }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            classes: {
              list: styles.selectMenu
            }
          }}
          IconComponent={loading ? SelectLoader : undefined}
          classes={{ select: styles.select }}>
          {items.length > 0 && <div className={styles.selectSpacing} />}
          {items.map(item => (
            <MenuItem key={item.id} value={item.id} classes={{ selected: styles.selectItemSelected }}>
              <InputCheckbox checked={(value || []).some(g => item.id === g.id)} className={styles.selectIconSpacing} />
              <ListItemText primary={renderLabel ? renderLabel(item) : getLabel ? getLabel(item) : ''} />
            </MenuItem>
          ))}
          {items.length > 0 && <div className={styles.selectSpacing} />}
          <MenuItem value="add" className={styles.selectAdd}>
            <Divider />
            <div className={clsx('fullflex', 'inline', styles.selectAddContent)}>
              <IconAddCircleOutlined className={styles.selectIconSpacing} />
              {addLabel}
            </div>
          </MenuItem>
        </Select>
        <FormHelperText>{helperText || ' '}</FormHelperText>
        <Popover
          id={selectAddOpen ? 'guests-select-add-popover' : undefined}
          open={selectAddOpen}
          anchorEl={selectAddAnchorEl}
          onClose={handleSelectAddClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          PaperProps={{
            style: {
              width: selectRef.current ? selectRef.current.clientWidth : undefined
            }
          }}
          transitionDuration={0}>
          <div className={styles.selectAddPopoverContent}>
            <div className={clsx('inline', styles.selectAddPopoverHeader)}>
              <div className="inline">
                <IconButton
                  component="div"
                  className={styles.selectAddPopoverHeaderIconButton}
                  onClick={closeAndResetAdd}>
                  <IconArrowDownSvg className={styles.selectAddPopoverHeaderIcon} />
                </IconButton>
                <Typography variant="body2" className={styles.selectAddPopoverHeaderLabel}>
                  {addLabel}
                </Typography>
              </div>

              <Button
                variant="contained"
                color="primary"
                className={styles.selectAddPopoverHeaderButton}
                onClick={handleAddSubmit}>
                {t('add')}
              </Button>
            </div>

            <Divider />

            <div className={styles.spacingSmall} />

            {AddContent}

            <div className={styles.spacingSmall} />
            <div className={styles.spacingSmall} />
          </div>
        </Popover>
      </FormControl>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: theme.spacing(1)
    },
    select: {
      whiteSpace: 'unset',
      textOverflow: 'unset',
      minHeight: 20
    },
    selectPlaceholder: {
      cursor: 'pointer',
      height: 18,
      '& > input': {
        padding: 0
      }
    },
    selectMenu: {
      paddingTop: 0,
      paddingBottom: 0
    },
    selectSpacing: {
      height: theme.spacing(1),
      flexShrink: 0
    },
    selectItemSelected: {
      backgroundColor: 'transparent',
      '& .MuiListItemText-root > span': {
        fontWeight: 600
      }
    },
    selectAdd: {
      height: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      fontWeight: 600,
      color: theme.palette.primary.main,
      padding: 0,
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    selectAddContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    selectIconSpacing: {
      marginRight: theme.spacing(2)
    },
    selectAddPopoverContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    selectAddPopoverHeader: {
      height: 64,
      justifyContent: 'space-between'
    },
    selectAddPopoverHeaderLabel: {
      fontWeight: 600,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1)
      }
    },
    selectAddPopoverHeaderButton: {
      height: 32,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    selectAddPopoverHeaderIcon: {
      width: 24,
      height: 24,
      flexShrink: 0,
      transform: 'rotate(90deg)',
      '& path': {
        fill: theme.palette.grey[500]
      }
    },
    selectAddPopoverHeaderIconButton: {
      width: 32,
      height: 32,
      padding: 0,
      '&:hover': {
        backgroundColor: fade(theme.palette.grey[500], 0.4)
      },
      [theme.breakpoints.down('sm')]: {
        width: 24,
        height: 24
      }
    },
    spacingSmall: {
      height: theme.spacing(2),
      flexShrink: 0
    },
    wrap: {
      flexWrap: 'wrap'
    }
  })
)

export default InputMultipleSelectAdd
