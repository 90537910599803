import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { fade, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { TFunction } from 'i18next'
import { getCurrencySymbol } from '../utils/CurrencyUtils'
import { formatDate } from '../utils/DateUtils'
import Colors from '../styles/Colors'
import { formatNumber } from '../utils/TextUtils'
import PlaceType from '../types/place/PlaceType'
import Spacing from './Spacing'

export type ReceiptData = {
  amount: number
  guestName: string
  placeName: string
  placeType: PlaceType
  paidOn: Date
  rentFrom: Date
  rentTo: Date
  userName: string
  userTaxId?: string
  currency?: string
}

type PdfReceiptProps = ReceiptData & { receiptNumber?: number; t: TFunction }

const PdfReceipt: React.FC<PdfReceiptProps> = ({
  amount,
  guestName,
  placeName,
  placeType,
  paidOn,
  rentFrom,
  rentTo,
  userName,
  userTaxId,
  currency,
  receiptNumber = 1,
  t
}: PdfReceiptProps) => {
  const styles = useStyles()

  return (
    <div>
      <div className={clsx(styles.inline, styles.justifyBetween)}>
        <div />

        <div className={clsx(styles.col, styles.alignEnd)}>
          <Typography>{`${t('receipt')} #: ${receiptNumber}-${new Date().getFullYear()}`}</Typography>
          <Spacing size={1} />
          <Typography>{`${t('receipt_date')}: ${formatDate(new Date(), 'dd/MM/yyyy')}`}</Typography>
        </div>
      </div>

      <Spacing size={4} />

      <div className={clsx(styles.inline, styles.justifyBetween)}>
        <div className={clsx(styles.inline, styles.fullflex)}>
          <Typography className={styles.labelShort}>
            <strong>{t('from')}</strong>
          </Typography>
          <Spacing horizontal size={1} />
          <Typography className={clsx(styles.field, styles.fullflex)}>{` ${guestName}`}</Typography>
          <Spacing horizontal size={1} />
        </div>

        <Typography>
          <strong>{t('received')}</strong>
        </Typography>
      </div>

      <Spacing size={2} />

      <div className={styles.inline}>
        <Typography>
          <strong className={styles.labelShort}>{getCurrencySymbol(currency)}</strong>
        </Typography>
        <Spacing horizontal size={1} />
        <Typography className={clsx(styles.field, styles.fullflex)}>{formatNumber(amount, 2)}</Typography>
      </div>

      <Spacing size={2} />

      <div className={styles.inline}>
        <Typography className={styles.labelMedium}>
          <strong>{t('for_rental_of')}</strong>
        </Typography>
        <Spacing horizontal size={1} />
        <Typography className={clsx(styles.field, styles.fullflex)}>{`${placeType} - ${placeName}`}</Typography>
      </div>

      <Spacing size={3} />

      <div className={styles.inline}>
        <Typography className={styles.labelMedium}>
          <strong>{t('paid_on')}</strong>
        </Typography>
        <Spacing horizontal size={1} />
        <Typography className={clsx(styles.field, styles.fullflex)}>{`${formatDate(paidOn, 'dd/MM/yyyy')}`}</Typography>
      </div>

      <Spacing size={1} />

      <div className={styles.inline}>
        <Typography className={styles.labelMedium}>
          <strong>{t('reference_period')}</strong>
        </Typography>
        <Spacing horizontal size={1} />
        <Typography className={clsx(styles.field, styles.fullflex)}>{`${formatDate(
          rentFrom,
          'dd/MM/yyyy'
        )} - ${formatDate(rentTo, 'dd/MM/yyyy')}`}</Typography>
      </div>

      <Spacing size={8} />

      <div className={clsx(styles.inline, styles.justifyBetween)}>
        <div />

        <div className={clsx(styles.col, styles.alignEnd)}>
          <Typography>{userName}</Typography>
          {userTaxId ? (
            <React.Fragment>
              <Spacing size={1} />
              <Typography>{userTaxId}</Typography>
            </React.Fragment>
          ) : null}
        </div>
      </div>

      <Spacing size={8} />
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    inline: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    justifyBetween: {
      justifyContent: 'space-between'
    },
    col: {
      display: 'flex',
      flexDirection: 'column'
    },
    alignEnd: {
      alignItems: 'flex-end'
    },
    field: {
      backgroundColor: fade(Colors.Blue, 0.2),
      padding: `4px 8px`,
      borderRadius: 8
    },
    fullflex: {
      flex: 1
    },
    labelShort: {
      width: 40,
      display: 'block'
    },
    labelMedium: {
      width: 120,
      display: 'block'
    }
  })
)

export default PdfReceipt
