import { createStyles, fade, Theme } from '@material-ui/core'
import Colors from './Colors'

const GlobalStyles = (theme: Theme) =>
  createStyles({
    '@global': {
      'html, body, body #root': {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden'
      },
      '*::selection': { background: theme.palette.primary.main, color: theme.palette.primary.contrastText },
      '#root': {
        display: 'flex'
      },
      'a, a:visited': {
        color: theme.palette.action.active
      },
      '.link': {
        ...theme.typography.subtitle1,
        color: `${theme.palette.primary.main} !important`,
        '&:hover': {
          color: `${theme.palette.primary.light} !important`
        }
      },
      '.inline': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      '.colstretch': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      },
      '.contentcenter': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '.align-start': {
        alignItems: 'flex-start'
      },
      '.align-end': {
        alignItems: 'flex-end'
      },
      '.align-center': {
        alignItems: 'center'
      },
      '.justify-between': {
        justifyContent: 'space-between'
      },
      '.justify-center': {
        justifyContent: 'center'
      },
      '.uppercase': {
        textTransform: 'uppercase'
      },
      '.lowercase': {
        textTransform: 'lowercase'
      },
      '.fullflex': {
        flex: 1
      },
      '.text-emphasis': {
        fontWeight: 500
      },
      '.spacing-right-xxs': {
        marginRight: theme.spacing(0.5)
      },
      '.spacing-left-xs': {
        marginLeft: theme.spacing(1)
      },
      '.spacing-right-xs': {
        marginRight: theme.spacing(1)
      },
      '.spacing-left-s': {
        marginLeft: theme.spacing(2)
      },
      '.spacing-right-s': {
        marginRight: theme.spacing(2)
      },
      '.spacing-block-vertical-xxs': {
        height: theme.spacing(0.5),
        flexShrink: 0
      },
      '.spacing-block-vertical-xs': {
        height: theme.spacing(1),
        flexShrink: 0
      },
      '.spacing-block-vertical-s': {
        height: theme.spacing(2),
        flexShrink: 0
      },
      '.spacing-block-vertical-m': {
        height: theme.spacing(4),
        flexShrink: 0
      },
      '.font-weight-regular': {
        fontWeight: 400
      },
      '.rounded': {
        borderRadius: '100%'
      },
      '.pointer': {
        cursor: 'pointer'
      },
      '.break-word': {
        overflowWrap: 'break-word'
      },
      '.flex-wrap': {
        flexWrap: 'wrap'
      },
      '.text-center': {
        textAlign: 'center'
      },
      '.positive': {
        color: Colors.Green
      },
      '.negative': {
        color: Colors.Red
      },
      '.relative': {
        position: 'relative'
      },
      '.overflow-hidden': {
        overflow: 'hidden'
      },
      '.rotate180': {
        transform: 'rotate(180deg)'
      },
      '.icon-button-contained': {
        backgroundColor: `${fade(theme.palette.text.primary, 0.1)}`,
        '&:hover': {
          backgroundColor: `${fade(theme.palette.text.primary, 0.05)}`
        }
      },
      '.icon-primary': {
        '& path': {
          fill: `${theme.palette.primary.main} !important`
        }
      },
      '.icon-secondary': {
        '& path': {
          fill: `${theme.palette.text.secondary} !important`
        }
      },
      '.flex-shrink-0': {
        flexShrink: 0
      },
      '.align-self-stretch': {
        alignSelf: 'stretch'
      },
      '.text-secondary': {
        color: theme.palette.text.secondary
      }
    }
  })

export default GlobalStyles
