import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const BlurOverlay: React.FC = () => {
  const styles = useStyles()

  return <div className={styles.root} />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backdropFilter: `saturate(40%) blur(3px)`,
      zIndex: theme.zIndex.appBar - 2
    }
  })
)

export default BlurOverlay
