import createPalette from '@material-ui/core/styles/createPalette'
import AppPaletteBase from './AppPaletteBase'
import Colors from './Colors'

const AppPaletteDark = createPalette({
  type: 'dark',
  ...AppPaletteBase,
  secondary: {
    main: Colors.DarkBlueFaded
  },
  text: {
    ...AppPaletteBase.text,
    secondary: Colors.LightGray
  }
})

export default AppPaletteDark
