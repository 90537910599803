import { Grid, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'

type PlaceDetailContainerProps = {
  LeftColumn?: React.ReactNode
  RightColumn?: React.ReactNode
}

const PlaceDetailContainer: React.FC<PlaceDetailContainerProps> = ({
  LeftColumn,
  RightColumn
}: PlaceDetailContainerProps) => {
  const styles = useStyles()

  return (
    <React.Fragment>
      <div className={styles.spacing} />

      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          {LeftColumn}
        </Grid>

        <Grid item xs={12} md={5}>
          {RightColumn}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      height: theme.spacing(2.5),
      flexShrink: 0
    }
  })
)

export default PlaceDetailContainer
