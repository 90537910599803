import { Theme, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type BoxContainerProps = {
  height?: number
  loading?: boolean
  className?: string
  bottomSpacing?: number
  loaderHeight?: number
}

const BoxContainer: React.FC<PropsWithChildren<BoxContainerProps>> = ({
  children,
  height,
  loading,
  className,
  bottomSpacing,
  loaderHeight
}: PropsWithChildren<BoxContainerProps>) => {
  const styles = useStyles()
  const theme = useTheme()

  return loading ? (
    <Skeleton
      variant="rect"
      className={clsx(styles.loader, className)}
      style={{ height: height || loaderHeight, marginBottom: bottomSpacing ? theme.spacing(bottomSpacing) : undefined }}
    />
  ) : (
    <React.Fragment>
      <div
        className={clsx(styles.box, className)}
        style={{ height, marginBottom: bottomSpacing ? theme.spacing(bottomSpacing) : undefined }}>
        {children}
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden'
    },
    loader: {
      borderRadius: theme.shape.borderRadius * 2
    }
  })
)

export default BoxContainer
