import {
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputBaseProps,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import clsx from 'clsx'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { lineClamp } from '../../utils/StyleUtils'
import { getDecimalSeparator } from '../../utils/TextUtils'
import ReadOnlyField from '../ReadOnlyField'

const normalizeValue = (value: number, min?: number, max?: number) =>
  min !== undefined && value < min ? min : max !== undefined && value > max ? max : value

type InputNumberProps = Omit<InputBaseProps, 'onChange' | 'value'> & {
  id: string
  label?: string
  value?: number
  error?: boolean
  helperText?: string
  showModifierButtons?: boolean
  decimals?: number
  step?: number
  min?: number
  max?: number
  variant?: 'default' | 'naked'
  rootClassName?: string
  readOnly?: boolean
  currency?: string
  loading?: boolean
  onChange?: (id: string, value: number | undefined) => void
}

const ModifierButton = ({
  decrease,
  onModifyValue
}: {
  decrease?: boolean
  onModifyValue: (decrease?: boolean) => void
}) => {
  const styles = useStyles()

  return (
    <IconButton color="primary" centerRipple onClick={() => onModifyValue(decrease)} className={styles.rounded}>
      {decrease ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
    </IconButton>
  )
}

const InputNumber: React.FC<InputNumberProps> = ({
  id,
  label,
  value,
  placeholder,
  error,
  helperText,
  showModifierButtons,
  decimals,
  step = 1,
  min,
  max,
  variant = 'default',
  rootClassName,
  readOnly,
  currency,
  loading,
  onChange,
  ...rest
}: InputNumberProps) => {
  const styles = useStyles()

  const handleModifyValue = useCallback(
    (decrease?: boolean) => {
      const newValue = value !== undefined ? value + step * (decrease ? -1 : 1) : step
      if (onChange) onChange(id, normalizeValue(newValue, min, max))
    },
    [onChange, id, step, value, min, max]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      const parsedValue = parseFloat(value)
      const newValue = isNaN(parsedValue) ? undefined : normalizeValue(parsedValue, min, max)
      if (onChange) onChange(id, newValue)
    },
    [id, onChange, min, max]
  )

  const handleNumberFormatChange = useCallback(
    (values: NumberFormatValues) => {
      if (values) {
        if (onChange) onChange(id, values.floatValue)
      }
    },
    [onChange, id]
  )

  const withValueLimit = useCallback(
    ({ floatValue }) =>
      (min === undefined || (min !== undefined && floatValue >= min)) &&
      (max === undefined || (max !== undefined && floatValue <= max)),
    [min, max]
  )

  return (
    <FormControl error={error} fullWidth={rest.fullWidth} className={rootClassName}>
      <div className={variant === 'naked' ? 'inline' : styles.root}>
        {label && (
          <Typography
            variant={variant === 'naked' ? 'subtitle1' : 'body1'}
            className={variant === 'naked' ? styles.labelNaked : styles.label}>
            {label}
          </Typography>
        )}
        {loading ? (
          <Skeleton variant="rect" className={styles.loader} />
        ) : readOnly ? (
          <ReadOnlyField value={`${currency && value ? formatCurrency(value, currency) : value}`} />
        ) : (
          <React.Fragment>
            {showModifierButtons && variant === 'naked' && (
              <ModifierButton decrease onModifyValue={handleModifyValue} />
            )}
            {variant === 'naked' ? (
              <InputBase
                {...rest}
                onChange={handleChange}
                placeholder={placeholder}
                value={value !== undefined ? value : ''}
                className={styles.fieldNaked}
                classes={{ input: styles.inputNaked }}
              />
            ) : (
              <NumberFormat
                InputProps={{
                  ...rest,
                  startAdornment: rest.startAdornment ? (
                    rest.startAdornment
                  ) : showModifierButtons ? (
                    <ModifierButton decrease onModifyValue={handleModifyValue} />
                  ) : null,
                  endAdornment: rest.endAdornment ? (
                    rest.endAdornment
                  ) : showModifierButtons ? (
                    <ModifierButton onModifyValue={handleModifyValue} />
                  ) : null,
                  classes: {
                    root: clsx(rest.className, { [styles.inputModifierButtons]: showModifierButtons }),
                    input: clsx({ [styles.inputInputModifierButtons]: showModifierButtons })
                  }
                }}
                variant="outlined"
                autoComplete="off"
                decimalScale={decimals}
                decimalSeparator={getDecimalSeparator()}
                isAllowed={withValueLimit}
                customInput={TextField}
                error={error}
                onValueChange={handleNumberFormatChange}
                placeholder={placeholder}
                value={value !== undefined ? value : ''}
              />
            )}
            {showModifierButtons && variant === 'naked' && <ModifierButton onModifyValue={handleModifyValue} />}
          </React.Fragment>
        )}
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      marginBottom: theme.spacing(1)
    },
    fieldNaked: {
      width: 40
    },
    inputNaked: {
      textAlign: 'center'
    },
    labelNaked: {
      marginRight: theme.spacing(1),
      width: 160,
      ...lineClamp(2),
      [theme.breakpoints.down('sm')]: {
        width: 120
      }
    },
    rounded: {
      borderRadius: '50%'
    },
    loader: {
      height: 47,
      borderRadius: theme.shape.borderRadius
    },
    inputModifierButtons: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: 120
    },
    inputInputModifierButtons: {
      textAlign: 'center'
    }
  })
)

export default InputNumber
