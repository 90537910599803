import { ImagePlaceDefault } from '../assets/Images'
import { convertTimestampsToDates } from '../services/DbService'
import Place from '../types/place/Place'
import Product from '../types/products/Product'
import ProductPrice from '../types/products/ProductPrice'

export const mapProductData = (id: string, data: Record<string, any>): Product => ({
  id: id,
  active: data.active,
  description: data.description,
  name: data.name,
  role: data.role,
  metadata: data.metadata,
  isComingSoon: data.metadata?.isComingSoon === 'true',
  prices: []
})

export const mapProductPriceData = (id: string, data: Record<string, any>): ProductPrice => {
  return {
    id: id,
    active: data.active,
    type: data.type,
    currency: data.currency,
    unitAmount: data.unit_amount,
    isPremiumApp: data.metadata?.isPremiumApp === 'true',
    metadata: data.metadata,
    description: data.description,
    recurring: data.recurring
      ? {
          interval: data.recurring.interval,
          intervalCount: data.recurring.interval_count,
          usageType: data.recurring.usage_type
        }
      : undefined
  }
}

export const mapPlaceData = (id: string, data?: Record<string, any>): Place | undefined => {
  return data
    ? convertTimestampsToDates({
        id,
        ...data,
        pictureUrl: data.pictureUrl || ImagePlaceDefault
      } as Place)
    : undefined
}
