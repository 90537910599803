import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { IconClose } from '../assets/Svgs'
import Container from '../components/Container'
import ContentContainer from '../components/ContentContainer'
import Header from '../components/header/Header'
import NewPlaceStepAdditionalInfo from '../components/new-place/NewPlaceStepAdditionalInfo'
import NewPlaceStepAddress from '../components/new-place/NewPlaceStepAddress'
import NewPlaceStepMainInfo from '../components/new-place/NewPlaceStepMainInfo'
import StepsActions from '../components/steps/StepsActions'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import FormStepComponentBase from '../types/forms/FormStepComponentBase'
import PlaceType from '../types/place/PlaceType'

type NewPlaceValues = {
  name: string
  type: PlaceType | null
  mainPicture: File | undefined
  addressText: string | undefined
  notes?: string
  latitude?: number
  longitude?: number
  rooms?: number
  bedrooms?: number
  bathrooms?: number
}

export type NewPlaceStepValues1 = Pick<NewPlaceValues, 'name' | 'type' | 'mainPicture'>
export type NewPlaceStepValues2 = Pick<NewPlaceValues, 'addressText' | 'latitude' | 'longitude'>
export type NewPlaceStepValues3 = Pick<NewPlaceValues, 'rooms' | 'bedrooms' | 'bathrooms' | 'notes'>

type NewPlaceStepInitialValues = NewPlaceStepValues1 | NewPlaceStepValues2 | NewPlaceStepValues3

type NewPlaceStep = {
  id: number
  title: string
  Component:
    | React.FC<FormStepComponentBase<NewPlaceStepValues1>>
    | React.FC<FormStepComponentBase<NewPlaceStepValues2>>
    | React.FC<FormStepComponentBase<NewPlaceStepValues3>>
  initialValues: NewPlaceStepInitialValues
  validationSchema: Yup.ObjectSchema<{}>
}

const NewPlace: React.FC = () => {
  const styles = useStyles()
  const { user } = useUser()
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation<{ isFirstPlace?: boolean }>()

  const steps = useRef<NewPlaceStep[]>(
    [...Array(3).keys()].map(i => ({
      id: i,
      title:
        i === 0 && !location.state?.isFirstPlace
          ? t('newplace.step_0.title_existing')
          : t(`newplace.step_${i}.title`, { userName: user?.name }),
      Component: i === 0 ? NewPlaceStepMainInfo : i === 1 ? NewPlaceStepAddress : NewPlaceStepAdditionalInfo,
      initialValues:
        i === 0
          ? { name: '', type: null, mainPicture: undefined }
          : i === 1
          ? { addressText: '' }
          : { rooms: undefined, bedrooms: undefined, bathrooms: undefined, notes: undefined },
      validationSchema: Yup.object(
        i === 0
          ? {
              name: Yup.string().required(t('required_field')),
              type: Yup.string().nullable().required(t('required_field'))
            }
          : {}
      )
    }))
  )

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [initialValues, setInitialValues] = useState<NewPlaceStepInitialValues>(
    steps.current[currentStepIndex].initialValues
  )

  const formValues = useRef<Partial<NewPlaceValues> | undefined>()

  const isLastStep = currentStepIndex === steps.current.length - 1

  const handleExit = useCallback(() => {
    history.push(RoutesPaths.Places)
  }, [history])

  const handlePreviousStep = useCallback(() => {
    const newStep = currentStepIndex >= 1 ? currentStepIndex - 1 : 0
    setCurrentStepIndex(newStep)
    const newInitialValues = steps.current[newStep].initialValues
    setInitialValues({
      ...newInitialValues,
      ...(formValues.current
        ? Object.keys(formValues.current).reduce(
            (obj, key) =>
              Object.keys(newInitialValues).includes(key)
                ? { ...obj, [key]: (formValues.current as Record<string, unknown>)[key] }
                : obj,
            {} as Record<string, unknown>
          )
        : undefined)
    })
  }, [currentStepIndex])

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      formValues.current = { ...formValues.current, ...values }
      if (!isLastStep) {
        setCurrentStepIndex(current => current + 1)
        return
      }

      let error = false
      if (formValues.current && formValues.current.name && formValues.current.type && user) {
        try {
          const { storage, db } = AppService

          let pictureUrl: string | undefined
          if (formValues.current.mainPicture) {
            pictureUrl = await storage.uploadPlaceImage(formValues.current.mainPicture)
          }
          await db.createPlace(
            {
              name: formValues.current.name,
              type: formValues.current.type,
              pictureUrl,
              address: Boolean(formValues.current.addressText) ? formValues.current.addressText : undefined,
              latitude: formValues.current.latitude,
              longitude: formValues.current.longitude,
              rooms: formValues.current.rooms,
              bedrooms: formValues.current.bedrooms,
              bathrooms: formValues.current.bathrooms,
              notes: formValues.current.notes
            },
            user.id
          )
        } catch (err) {
          error = true
        }
      } else {
        error = true
      }

      if (!error) {
        handleExit()
      }

      setSubmitting(false)
    },
    [isLastStep, handleExit, user]
  )

  const currentStep: NewPlaceStep | undefined = steps.current[currentStepIndex]

  return !user ? null : (
    <Container noPadding relative backgroundPaper>
      <Header
        leftActionsSmDown={[
          {
            handler: handleExit,
            Icon: IconClose
          }
        ]}
        actionsMdUp={[
          {
            handler: handleExit,
            label: t('exit')
          }
        ]}
        progress={((currentStepIndex + 1) / steps.current.length) * 100}
      />

      <div className={styles.contentContainerWrapper}>
        <ContentContainer>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={currentStep.validationSchema}
            onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
              return (
                <Form>
                  {!currentStep ? null : (
                    <div key={currentStep.id}>
                      <Typography variant="h2" className={styles.title}>
                        {currentStep.title}
                      </Typography>
                      <Typography variant="overline" color="secondary" component="div" className={styles.stepLabel}>
                        {t('step', { index: currentStep.id + 1 })}
                      </Typography>
                      <currentStep.Component
                        submitting={isSubmitting}
                        values={values as any}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  )}

                  <StepsActions
                    showBackButton={currentStepIndex > 0}
                    submitButton
                    continueButtonText={isLastStep ? t('insert') : undefined}
                    submitting={isSubmitting}
                    onGoBack={handlePreviousStep}
                  />
                </Form>
              )
            }}
          </Formik>
        </ContentContainer>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    contentContainerWrapper: {
      padding: `${theme.spacing(6)}px 0`
    },
    title: {
      whiteSpace: 'pre-line',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    stepLabel: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1.5)
    }
  })
)
export default NewPlace
