import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageComingSoon } from '../assets/Svgs'
import Spacing from './Spacing'

const ComingSoonPromo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="colstretch align-center">
      <Spacing size={12} />

      <ImageComingSoon />

      <Spacing size={6} />

      <Typography variant="button" component="div" align="center">
        {t('coming_soon_promo_title')}
      </Typography>
      <Spacing size={1.5} />
      <Typography variant="body2" component="div" align="center" color="textSecondary">
        {t('coming_soon_promo_subtitle')}
      </Typography>
    </div>
  )
}

export default ComingSoonPromo
