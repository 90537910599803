import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getMonthsDaysDurationLabel, getRentContractDurationDatesRangeLabel } from '../../utils/RentUtils'

type RentContractDurationLabelProps = {
  from?: Date | null
  to?: Date | null
  showDatesRange?: boolean
}

const RentContractDurationLabel: React.FC<RentContractDurationLabelProps> = ({
  from,
  to,
  showDatesRange
}: RentContractDurationLabelProps) => {
  const { t } = useTranslation()

  const durationLabel = getMonthsDaysDurationLabel(t, from, to)
  const datesRangeLabel = getRentContractDurationDatesRangeLabel(t, from, to)

  return (
    <Typography variant="body1">
      <span className="text-emphasis">{durationLabel}</span>
      {showDatesRange && datesRangeLabel ? ` ${datesRangeLabel}` : null}
    </Typography>
  )
}

export default RentContractDurationLabel
