import numbro from 'numbro'
import AppService from '../services/AppService'
import User from '../types/User'
import Guest from './../types/Guest'

export const getGuestDisplayName = (guest: Guest) => {
  return `${guest.firstName}${guest.lastName ? ` ${guest.lastName}` : ''}`
}

export const getDecimalSeparator = (): string => {
  const numberWithDecimalSeparator = 1.1
  return (
    Intl.NumberFormat(AppService.getDeviceCulture())
      .formatToParts(numberWithDecimalSeparator)
      .find(part => part.type === 'decimal')?.value || '.'
  )
}

export const formatNumber = (
  number: number,
  numberOfDecimals = 0,
  optionalDecimalSeparator = false,
  thousandSeparated = false,
  forceSign = false,
  trimMantissa = false
): string => {
  let formattedNumber = ''

  try {
    const formatOptions = {
      mantissa: numberOfDecimals,
      optionalMantissa: optionalDecimalSeparator,
      thousandSeparated: thousandSeparated,
      forceSign: forceSign,
      trimMantissa: trimMantissa
    }

    formattedNumber = numbro(number).format(formatOptions)
  } catch (error) {}

  return formattedNumber
}

export const capitalizeFirstLetter = (text: string) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
}
export const lowercaseFirstLetter = (text: string) => {
  return text ? text.charAt(0).toLowerCase() + text.slice(1) : ''
}

export const getUserDisplayName = (user?: User) => user?.name || user?.email?.split('@')[0] || ''

export const formatPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber?.replace(/^[\+0]+/g, '')
}
