import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useEffect, useState } from 'react'
import App from './App'
import AppPaletteDark from './styles/AppPaletteDark'
import AppPaletteLight from './styles/AppPaletteLight'
import getAppTheme from './styles/AppTheme'

const AppContainer: React.FC = () => {
  const [prefersDarkMode, setPrefersDarkMode] =
    useState<boolean>(false) /* useMediaQuery('(prefers-color-scheme: dark)') */

  useEffect(() => {
    const themeTypeChangedEventToken = subscribe(
      'themeTypeChanged',
      (eventName: string, { isDark }: { isDark: boolean }) => setPrefersDarkMode(isDark)
    )

    return () => {
      unsubscribe(themeTypeChangedEventToken)
    }
  }, [])

  return (
    <ThemeProvider theme={getAppTheme(prefersDarkMode ? AppPaletteDark : AppPaletteLight)}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

export default AppContainer
