import { Button, createStyles, Fab, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconAdd, ImageTransactionsCategoriesEmpty, raster_IconDocument } from '../assets/Svgs'
import DocumentsList from '../components/documents-list/DocumentsList'
import EmptyContentPromoDialog from '../components/EmptyContentPromoDialog'
import InputSearch from '../components/inputs/InputSearch'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Document from '../types/Document'

const Documents: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const { user } = useUser()
  const { t } = useTranslation()
  const { role } = AppService

  const canManage = role.canManageDocuments()

  const [items, setItems] = useState<Document[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const initialized = useRef<boolean>(false)

  const handleNewItemClick = useCallback(() => {
    if (canManage) {
      history.push(RoutesPaths.EditDocumentDialog.replace(':documentId', 'new'), { isModal: true })
    }
  }, [canManage, history])

  const handleSearchTextChange = useCallback((value: string) => setSearchText(value), [])

  const fetchItems = useCallback(async (searchTextFilter: string, userId: string) => {
    let items: Document[] = []

    try {
      const { db } = AppService
      items = await db.getDocuments(userId)

      if (searchTextFilter !== '') {
        items = items.filter(item => item.name.toLowerCase().includes(searchTextFilter.toLowerCase()))
      }
    } catch (err) {}

    return { items }
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel && !initialized.current) {
        setLoading(true)
      }

      const result = await fetchItems(searchText, user.id)

      if (!didCancel) {
        setItems(result.items)
        setLoading(false)

        initialized.current = true
      }
    }

    fetchData()

    const documentCreatedEventToken = subscribe('documentCreated', () => fetchData())
    const documentUpdatedEventToken = subscribe('documentUpdated', () => fetchData())
    const documentDeletedEventToken = subscribe(
      'documentDeleted',
      (eventName: string, { documentId }: { documentId: string }) =>
        setItems(current => current.filter(i => i.id !== documentId))
    )

    return () => {
      didCancel = true

      unsubscribe(documentCreatedEventToken)
      unsubscribe(documentUpdatedEventToken)
      unsubscribe(documentDeletedEventToken)
    }
  }, [fetchItems, searchText, user])

  return (
    <NavigationDrawerContainer showBackdrop={!canManage}>
      {!canManage && (
        <EmptyContentPromoDialog
          title={t('documents_promo_title')}
          description={t('documents_promo_description')}
          image={raster_IconDocument}
          gradient={'linear-gradient(283.85deg, rgba(0, 83, 201, 0.4) 0%, rgba(215, 151, 232, 0.4) 100%)'}
        />
      )}

      <React.Fragment>
        <Hidden mdUp>
          <Fab
            color="primary"
            aria-label={t('add')}
            disabled={!canManage}
            className={styles.addButtonFloating}
            onClick={handleNewItemClick}>
            <IconAdd />
          </Fab>
        </Hidden>

        <div className={styles.toolbar}>
          <InputSearch
            value={searchText}
            onChange={handleSearchTextChange}
            disabled={items.length === 0 && searchText === ''}
          />
          <Hidden smDown>
            <Button variant="contained" color="primary" onClick={handleNewItemClick} disabled={!canManage}>
              {t('add')}
            </Button>
          </Hidden>
        </div>

        <Spacing size={5} />

        {items.length === 0 && !loading ? (
          <div className={styles.noResults}>
            {searchText !== '' ? (
              <Typography variant="body1" component="div">
                {t('no_results')}
              </Typography>
            ) : (
              <React.Fragment>
                <ImageTransactionsCategoriesEmpty />

                <div className="spacing-block-vertical-m" />

                <Typography variant="h6" component="div">
                  {t('add_document_hint_title')}
                </Typography>

                <div className="spacing-block-vertical-xs" />

                <Typography variant="caption" component="div" color="textSecondary">
                  {t('add_document_hint_subtitle')}
                </Typography>
              </React.Fragment>
            )}
          </div>
        ) : (
          <DocumentsList loading={loading} items={items} />
        )}
      </React.Fragment>

      <Spacing size={5} smDownSize={10} />
    </NavigationDrawerContainer>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    addButtonFloating: {
      position: 'fixed',
      bottom: 24,
      right: 16,
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    },
    noResults: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1
    },
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      columnGap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr'
      }
    }
  })
)

export default Documents
