import { Button, createStyles, Fab, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconAdd, ImageGuestsEmpty } from '../assets/Svgs'
import GuestsList from '../components/guests-list/GuestsList'
import InputSearch from '../components/inputs/InputSearch'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Guest from '../types/Guest'
import GuestRentPlace from '../types/GuestRentPlace'

const Guests: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const { user } = useUser()
  const { t } = useTranslation()

  const [guests, setGuests] = useState<Guest[]>([])
  const [guestsRents, setGuestsRents] = useState<GuestRentPlace[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const initialized = useRef<boolean>(false)

  const handleNewGuestClick = useCallback(
    () => history.push(RoutesPaths.EditGuestDialog.replace(':guestId', 'new'), { isModal: true }),
    [history]
  )

  const handleSearchTextChange = useCallback((value: string) => setSearchText(value), [])

  const fetchGuests = useCallback(async (searchTextFilter: string, userId: string, fetchGuestsRents?: boolean) => {
    let items: Guest[] = []
    let guestsRents: GuestRentPlace[] | undefined

    try {
      const { db } = AppService
      items = await db.getGuests(userId)
      if (fetchGuestsRents) {
        guestsRents = await db.getGuestsRentsPlaces(
          userId,
          items.map(i => i.id)
        )
      }

      if (searchTextFilter !== '') {
        items = items.filter(
          item =>
            item.firstName.toLowerCase().includes(searchTextFilter.toLowerCase()) ||
            item.lastName?.toLowerCase().includes(searchTextFilter.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchTextFilter.toLowerCase())
        )
      }
    } catch (err) {}

    return { items, guestsRents }
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel && !initialized.current) {
        setLoading(true)
      }

      const result = await fetchGuests(searchText, user.id, !initialized.current)

      if (!didCancel) {
        if (result.guestsRents !== undefined) {
          setGuestsRents(result.guestsRents)
        }
        setGuests(result.items)
        setLoading(false)

        initialized.current = true
      }
    }

    fetchData()

    const guestCreatedEventToken = subscribe('guestCreated', () => fetchData())
    const guestUpdatedEventToken = subscribe('guestUpdated', () => fetchData())
    const guestDeletedEventToken = subscribe('guestDeleted', (eventName: string, { guestId }: { guestId: string }) =>
      setGuests(current => current.filter(g => g.id !== guestId))
    )

    return () => {
      didCancel = true

      unsubscribe(guestCreatedEventToken)
      unsubscribe(guestUpdatedEventToken)
      unsubscribe(guestDeletedEventToken)
    }
  }, [fetchGuests, searchText, user])

  return (
    <NavigationDrawerContainer>
      <Hidden mdUp>
        <Fab color="primary" aria-label={t('add')} className={styles.addButtonFloating} onClick={handleNewGuestClick}>
          <IconAdd />
        </Fab>
      </Hidden>

      <div className={styles.toolbar}>
        <InputSearch
          value={searchText}
          onChange={handleSearchTextChange}
          disabled={guests.length === 0 && searchText === ''}
        />
        <Hidden smDown>
          <Button variant="contained" color="primary" onClick={handleNewGuestClick}>
            {t('add')}
          </Button>
        </Hidden>
      </div>

      <Spacing size={5} />

      {guests.length === 0 && !loading ? (
        <div className={styles.noResults}>
          {searchText !== '' ? (
            <Typography variant="body1" component="div">
              {t('no_results')}
            </Typography>
          ) : (
            <React.Fragment>
              <ImageGuestsEmpty />

              <div className="spacing-block-vertical-m" />

              <Typography variant="h6" component="div">
                {t('add_guest_hint_title')}
              </Typography>

              <div className="spacing-block-vertical-xs" />

              <Typography variant="caption" component="div" color="textSecondary">
                {t('add_guest_hint_subtitle')}
              </Typography>
            </React.Fragment>
          )}
        </div>
      ) : (
        <GuestsList loading={loading} items={guests} guestsRents={guestsRents} />
      )}

      <Spacing size={5} smDownSize={10} />
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    addButtonFloating: {
      position: 'fixed',
      bottom: 24,
      right: 16,
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    },
    noResults: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1
    }
  })
)

export default Guests
