import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { createStyles, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { publish } from 'pubsub-js'
import Document from '../../types/Document'
import { RoutesPaths } from '../../Routes'
import ListTable, { ListTableColumn, ListTableRowItem } from '../../data-display/ListTable'
import { formatDate } from '../../utils/DateUtils'
import { IconDownload, IconRemoveCircleOutlined } from '../../assets/Svgs'
import { downloadURI } from '../../utils/CommonUtils'
import AppService from '../../services/AppService'
import AlertDialog from '../dialogs/AlertDialog'

type DocumentsListProps = {
  loading: boolean
  items: Document[]
  compact?: boolean
  onUnlink?: (id: string) => void
}

const DocumentsList: React.FC<DocumentsListProps> = ({ items, loading, compact, onUnlink }: DocumentsListProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [deleteConfirm, setDeleteConfirm] = useState<{ id: string; downloadUrl: string } | undefined>(undefined)

  const handleDeleteConfirmClose = useCallback(() => {
    setDeleteConfirm(undefined)
  }, [])

  const handleDeleteConfirm = useCallback(() => {
    if (!deleteConfirm) return

    const { id, downloadUrl } = deleteConfirm

    if (onUnlink) {
      onUnlink(id)
    } else {
      const { db } = AppService
      db.deleteDocument(id, downloadUrl)

      publish('documentDeleted', { documentId: id })
    }
    handleDeleteConfirmClose()
  }, [deleteConfirm, handleDeleteConfirmClose, onUnlink])

  const handleItemSelected = useCallback(
    (id: string) => {
      history.push(RoutesPaths.EditDocumentDialog.replace(':documentId', id), { isModal: true })
    },
    [history]
  )

  const columns: ListTableColumn[] = (
    [
      {
        field: 'name',
        headerName: t('name')
      }
    ] as ListTableColumn[]
  )
    .concat(
      isSmDown
        ? []
        : [
            {
              field: 'uploadedOn',
              headerName: t('uploaded_on'),
              width: 200
            }
          ]
    )
    .concat([
      {
        field: 'actions',
        headerName: '',
        width: 100
      }
    ])

  const rows: ListTableRowItem[] = items.map(i => ({
    id: i.id,
    items: {
      name: { label: i.name },
      uploadedOn: { label: formatDate(i.created) },
      actions: {
        Component: (
          <div className={clsx('inline', styles.actionsContainer)}>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                downloadURI(i.downloadUrl, i.name || '')
              }}>
              <IconDownload className="icon-primary" />
            </IconButton>
            {!isSmDown && (
              <IconButton
                onClick={e => {
                  e.stopPropagation()

                  setDeleteConfirm({ id: i.id, downloadUrl: i.downloadUrl })
                }}>
                <IconRemoveCircleOutlined className={styles.removeIcon} />
              </IconButton>
            )}
          </div>
        )
      }
    }
  }))

  return (
    <React.Fragment>
      <ListTable
        loading={loading}
        columns={columns}
        rows={rows}
        showHeader={!compact}
        transparent={compact}
        onItemSelected={handleItemSelected}
      />
      <AlertDialog
        open={Boolean(deleteConfirm)}
        actionDelete
        title={t(onUnlink ? 'confirm_unlink_document' : 'confirm_delete_document')}
        confirmButtonText={t(onUnlink ? 'unlink' : 'delete')}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteConfirmClose}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsContainer: {
      justifyContent: 'flex-end'
    },
    removeIcon: {
      '& path': {
        fill: `${theme.palette.error.main} !important`
      }
    }
  })
)

export default DocumentsList
