import firebase from 'firebase'

class FunctionsService {
  functions: firebase.functions.Functions | undefined

  constructor(functions?: firebase.functions.Functions) {
    this.functions = functions
  }

  getCustomerPortalUrl = async (): Promise<string | undefined> => {
    let url: string | undefined

    try {
      const functionRef = this.functions?.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

      if (functionRef) {
        const { data } = await functionRef({ returnUrl: `${window.location.origin}/subscriptions` })
        url = data.url
      }
    } catch (err) {}

    return url
  }

  deletePlaceData = async (placeId: string): Promise<boolean> => {
    let success = true

    try {
      const functionRef = this.functions?.httpsCallable('deletePlaceData')

      if (functionRef) {
        await functionRef({ placeId })
      }
    } catch (err) {
      success = false
    }

    return success
  }
}

export default FunctionsService
