import { ClickAwayListener, Grid, Theme, Typography } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import parse from 'autosuggest-highlight/parse'
import GoogleMapReact, { Coords } from 'google-map-react'
import { throttle } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconArrowDownSvg, IconLocation } from '../../assets/Svgs'
import { NewPlaceStepValues2 } from '../../pages/NewPlace'
import AppService from '../../services/AppService'
import { MapStylesDark } from '../../styles/MapStyles'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import InputText from '../inputs/InputText'
import Spacing from '../Spacing'

export const DefaultMapCenter: Coords = {
  lat: 45.4642035,
  lng: 9.189982
}
const DefaultMapZoom = 12

type NewPlaceStepAddressProps = FormStepComponentBase<NewPlaceStepValues2>

interface PlaceType {
  place_id: string
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: { length: number; offset: number }[]
  }
}

const NewPlaceStepAddress: React.FC<NewPlaceStepAddressProps> = ({
  submitting,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue
}: NewPlaceStepAddressProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const [value, setValue] = useState<PlaceType | null>(null)
  const [options, setOptions] = useState<PlaceType[]>([])
  const [mapCenter, setMapCenter] = useState<Coords>(DefaultMapCenter)
  const [zoom, setZoom] = useState<number>(DefaultMapZoom)
  const [gestureHandling, setGestureHandling] = useState<'none' | 'greedy'>('none')
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false)

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null)
  const geocodingService = useRef<google.maps.Geocoder | null>(null)
  const valueSelected = useRef<boolean>(false)

  const setDefaultZoom = useCallback(() => setZoom(DefaultMapZoom), [])

  const fetchPlaces = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        autocompleteService.current?.getPlacePredictions(request, callback)
      }, 400),
    []
  )

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input' || reason === 'clear') setFieldValue('addressText', value)
      if (value === '') {
        setFieldValue('latitude', undefined)
        setFieldValue('longitude', undefined)
      }
    },
    [setFieldValue]
  )

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string | PlaceType | null) => {
      setOptions(current => (newValue ? (typeof newValue === 'string' ? current : [newValue, ...current]) : current))
      if (typeof newValue !== 'string') {
        valueSelected.current = true
        setValue(newValue)
        setDefaultZoom()
        if (!newValue) {
          setFieldValue('addressText', '')
          return
        }

        setFieldValue('addressText', newValue.structured_formatting.main_text)

        geocodingService.current?.geocode(
          {
            placeId: newValue.place_id
          },
          (results, status) => {
            if (status !== 'OK') return
            if (!results[0]) return

            const result = results[0] as google.maps.GeocoderResult

            const coords: Coords = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() }
            setFieldValue('latitude', coords.lat)
            setFieldValue('longitude', coords.lng)

            setMapCenter(coords)
          }
        )
      }
    },
    [setDefaultZoom, setFieldValue]
  )

  const handleMapCenterChange = useCallback(
    (mapValue: GoogleMapReact.ChangeEventValue) => {
      setMapCenter(mapValue.center)
      if (!googleApiLoaded) {
        return
      }
      geocodingService.current?.geocode(
        { location: { lat: mapValue.center.lat, lng: mapValue.center.lng } },
        (results, status) => {
          if (status !== 'OK') return
          if (!results[0]) return

          const result = results[0] as google.maps.GeocoderResult

          setFieldValue('addressText', result.formatted_address)
          setFieldValue('latitude', result.geometry.location.lat())
          setFieldValue('longitude', result.geometry.location.lng())
        }
      )
    },
    [googleApiLoaded, setFieldValue]
  )

  const handleDisableMapGestures = useCallback(() => setGestureHandling('none'), [])

  const handleEnableMapGestures = useCallback(() => setGestureHandling('greedy'), [])

  const renderInput = useCallback(
    params => (
      <InputText
        {...params}
        label={t('newplace.step_1.address_label')}
        placeholder={t('newplace.step_1.address_placeholder')}
        name="addressText"
        error={Boolean(errors.addressText) && touched.addressText}
        helperText={errors.addressText && touched.addressText ? errors.addressText : ' '}
      />
    ),
    [errors.addressText, touched.addressText, t]
  )

  const renderPlaceOption = useCallback(
    (option: PlaceType) => {
      const matches = option.structured_formatting.main_text_matched_substrings
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match: any) => [match.offset, match.offset + match.length])
      )

      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={styles.icon} />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
            <Typography variant="body2" color="textSecondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      )
    },
    [styles.icon]
  )

  const handleGoogleApiLoaded = useCallback(() => {
    if (!autocompleteService.current && window.google?.maps?.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!geocodingService.current && window.google?.maps?.Geocoder) {
      geocodingService.current = new window.google.maps.Geocoder()
    }
    setGoogleApiLoaded(true)
  }, [])

  useEffect(() => {
    let active = true

    if (!autocompleteService.current) {
      return undefined
    }

    if (values.addressText === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    if (valueSelected.current) {
      valueSelected.current = false
      return
    }

    fetchPlaces({ input: values.addressText || '' }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, values.addressText, fetchPlaces])

  return (
    <React.Fragment>
      <Autocomplete
        autoComplete
        includeInputInList
        options={options}
        value={value}
        inputValue={values.addressText || ''}
        filterOptions={(options: PlaceType[]) => options}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        noOptionsText={(values.addressText || '').length === 0 ? t('start_typing_address') : t('no_results')}
        renderInput={renderInput}
        renderOption={renderPlaceOption}
        getOptionLabel={(option: PlaceType) => option.structured_formatting.main_text}
        disabled={submitting}
        popupIcon={<IconArrowDownSvg />}
      />

      <Spacing size={3} />

      <ClickAwayListener onClickAway={handleDisableMapGestures}>
        <div className={styles.mapContainer} onClick={handleEnableMapGestures} onMouseEnter={handleEnableMapGestures}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: AppService.googleMapsApiKey, libraries: ['places'] }}
            zoom={zoom}
            center={mapCenter}
            defaultCenter={DefaultMapCenter}
            defaultZoom={DefaultMapZoom}
            options={{ gestureHandling, styles: MapStylesDark }}
            onGoogleApiLoaded={handleGoogleApiLoaded}
            onChange={handleMapCenterChange}
          />
          <div className={styles.centerMarker}>
            <IconLocation />
          </div>
        </div>
      </ClickAwayListener>

      <Spacing size={1.5} />

      <Typography variant="caption" component="div" className={styles.caption}>
        {t('newplace.step_1.map_tip')}
      </Typography>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2)
    },
    mapContainer: {
      height: 320,
      width: '100%',
      position: 'relative'
    },
    centerMarker: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    caption: {
      color: theme.palette.grey[500]
    }
  })
)

export default NewPlaceStepAddress
