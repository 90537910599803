import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Typography, Theme } from '@material-ui/core'
import clsx from 'clsx'
import UpgradeButton from './promo/UpgradeButton'
import Spacing from './Spacing'

export type EmptyContentPromoProps = {
  title: string
  description: string
  image?: string
  gradient?: string
  variant?: 'default' | 'dialog'
}

const EmptyContentPromo: React.FC<EmptyContentPromoProps> = ({
  title,
  description,
  image,
  gradient = 'linear-gradient(283.85deg, rgba(136, 145, 248, 0.5) 0%, rgba(19, 100, 207, 0.5) 100%)',
  variant = 'default'
}: EmptyContentPromoProps) => {
  const styles = useStyles()

  return (
    <div className={'colstretch'}>
      {image && (
        <div className={clsx(styles.imageContainer, 'contentcenter')} style={{ background: gradient }}>
          <img className={clsx(styles.image, { [styles.imageRadius]: variant === 'default' })} src={image} />
        </div>
      )}

      <div className={clsx('colstretch', styles.info)}>
        <Spacing size={2} />

        <Typography variant="h3" align="center">
          {title}
        </Typography>

        <Spacing size={1} />

        <Typography variant="body1" color="textSecondary" align="center">
          {description}
        </Typography>

        <Spacing size={4} />

        <UpgradeButton selfCenter />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      height: 216
    },
    imageRadius: {
      borderRadius: 12
    },
    image: {
      height: 168
    },
    info: {
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(2)}px`
      }
    }
  })
)

export default EmptyContentPromo
