import { TypographyOptions } from '@material-ui/core/styles/createTypography'

const AppTypography: TypographyOptions = {
  fontFamily: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Roboto"',
    '"Oxygen"',
    '"Ubuntu"',
    '"Cantarell"',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Arial"',
    '"sans-serif"'
  ].join(','),
  // Tell Material-UI what the font-size on the html element is.
  htmlFontSize: 10,
  h1: {
    fontSize: 40,
    fontWeight: 900,
    lineHeight: 'normal'
  },
  h2: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: 'normal'
  },
  h3: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 'normal'
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 'normal'
  },
  h5: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'normal'
  },
  h6: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 'normal'
  },
  body1: {
    fontSize: 16,
    lineHeight: 'normal'
  },
  body2: {
    fontSize: 14,
    lineHeight: 'normal'
  },
  caption: {
    fontSize: 12,
    lineHeight: 'normal'
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none'
  },
  overline: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: 'normal'
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 'normal'
  },
  subtitle2: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 'normal'
  }
}

export default AppTypography
