import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageRentalsEmpty } from '../../assets/Svgs'

const PlaceDetailEmpty: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.empty}>
      <ImageRentalsEmpty />

      <div className={styles.emptySpacing} />

      <Typography variant="h6" component="div" className={styles.emptyTextSpacing}>
        {t('placedetail.rentals_empty_title')}
      </Typography>
      <Typography variant="caption" color="textSecondary" component="div">
        {t('placedetail.rentals_empty_title')}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      padding: `${theme.spacing(4)}px 0`
    },
    emptySpacing: {
      height: theme.spacing(3)
    },
    emptyTextSpacing: {
      marginBottom: theme.spacing(1)
    }
  })
)

export default PlaceDetailEmpty
