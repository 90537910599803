const Colors = {
  Blue: '#4494ff',
  DarkBlue: '#334d6e',
  DarkBlueFaded: '#8cbdff',
  Gray: '#707683',
  LightGray: '#c4c4c4',
  MediumGray: '#F5F6F8',
  DarkGray: '#6a707e',
  SubtleBlueGray: '#C1CFE0',
  DisabledGrey: '#90a0b7',
  Green: '#2ed47a',
  Red: '#f7685b',
  Black: '#000000',
  White: '#ffffff',
  Orange: '#ffb946',
  IconGray: '#C2CFE0',
  Purple: '#885AF8'
}

export default Colors
