import React from 'react'
import { useParams } from 'react-router-dom'
import RentReceipt from '../components/RentReceipt'

const RentReceiptDialog: React.FC = () => {
  const { rentId, transactionId } = useParams<{ rentId: string; transactionId: string }>()

  return <RentReceipt open rentId={rentId} transactionId={transactionId} />
}

export default RentReceiptDialog
