import {
  Button,
  Collapse,
  FormHelperText,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconAddCircleOutlined, IconClose, IconEdit, IconReceipt } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { AddRentStepValues3 } from '../../pages/AddRent'
import AppService from '../../services/AppService'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import Guest from '../../types/Guest'
import Transaction from '../../types/Transaction'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import { getNightsDuration, getRentRecurrenceLabel } from '../../utils/RentUtils'
import { getGuestDisplayName } from '../../utils/TextUtils'
import BookingConfirmation from '../BookingConfirmation'
import EditGuestsInput from '../edit-guests-input/EditGuestsInput'
import EditTransactionDialog from '../edit-transaction/EditTransactionDialog'
import InputText from '../inputs/InputText'
import PromoDialog from '../PromoDialog'
import RentContractDurationLabel from '../rent/RentContractDurationLabel'
import RentReceipt from '../RentReceipt'
import Spacing from '../Spacing'
import TransactionListItem from '../transactions/TransactionListItem'

const NumberOfTransactionsToDisplay = 3

export type AddRentStepSummaryProps = FormStepComponentBase<AddRentStepValues3> & {
  rentId?: string
  isBooking?: boolean
}

const AddRentStepSummary: React.FC<AddRentStepSummaryProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  editDisabled,
  isEdit,
  loading,
  isBooking,
  rentId
}: AddRentStepSummaryProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [editTransactionDialog, setEditTransactionDialog] = useState<{ isRent: boolean } | undefined>()
  const [viewAllTransactions, setViewAllTransactions] = useState<boolean>(false)
  const [editTransactionDialogItem, setEditTransactionDialogItem] = useState<Transaction | undefined>(undefined)
  const [editGuestsEnabled, setEditGuestsEnabled] = useState<boolean>(false)
  const [rentReceiptDialog, setRentReceiptDialog] =
    useState<{ rentId: string; transactionId: string; guestsIds: string[] } | undefined>(undefined)
  const [bookingConfirmationDialog, setBookingConfirmationDialog] =
    useState<{ rentId: string; guestsIds: string[] } | undefined>(undefined)
  const [promoDialog, setPromoDialog] =
    useState<{ title: string; description: string; promoFor: 'professional' | 'business' } | undefined>(undefined)

  const nights = getNightsDuration(values.from, values.to)

  const handleBookingConfirmationDialogClose = useCallback(() => {
    setBookingConfirmationDialog(undefined)
  }, [])

  const handleBookingConfirmationSelected = useCallback(() => {
    if (!rentId) return

    setBookingConfirmationDialog({
      rentId,
      guestsIds: values.guests.map(g => g.id)
    })
  }, [rentId, values.guests])

  const handleRentReceiptDialogClose = useCallback(() => {
    setRentReceiptDialog(undefined)
  }, [])

  const handlePromoDialogClose = useCallback(() => {
    setPromoDialog(undefined)
  }, [])

  const handleRentReceiptDialogOpen = useCallback(
    (transactionId: string) => {
      if (!rentId) return

      const { role } = AppService
      if (!role.canManageReceipts()) {
        setPromoDialog({
          title: t('receipts_promo_title'),
          description: t('receipts_promo_description'),
          promoFor: 'business'
        })
      } else {
        setRentReceiptDialog({
          rentId,
          transactionId,
          guestsIds: values.guests.map(g => g.id)
        })
      }
    },
    [rentId, t, values.guests]
  )

  const handleGuestsEditToggle = useCallback(() => {
    setEditGuestsEnabled(current => !current)
  }, [])

  const handleNewTransactionSelected = useCallback((isRent: boolean) => {
    setEditTransactionDialog({ isRent })
  }, [])

  const handleEditTransactionClose = useCallback(() => {
    setEditTransactionDialog(undefined)
  }, [])

  const handleViewAllTransactionsToggle = useCallback(() => {
    setViewAllTransactions(current => !current)
  }, [])

  const handleTransactionEdit = useCallback(
    (id: string) => {
      if (!values.transactions) return

      const transactionToEdit = values.transactions.find(t => t.id === id)

      if (transactionToEdit) {
        setEditTransactionDialog({ isRent: transactionToEdit.isRent })
        setEditTransactionDialogItem(transactionToEdit)
      }
    },
    [values.transactions]
  )

  const handleTransactionPaidToggle = useCallback(
    (id: string, paid: boolean) => {
      if (!values.transactions) return

      setFieldValue(
        'transactions',
        values.transactions.map(t => (t.id === id ? { ...t, paid, paidOn: paid ? new Date() : undefined } : t))
      )
    },
    [setFieldValue, values.transactions]
  )

  const handleTransactionDeleted = useCallback(
    async (id: string) => {
      const newTransactions = values.transactions?.filter(t => t.id !== id) || []

      setFieldValue('transactions', newTransactions)
      setFieldValue(
        'totalAmount',
        newTransactions.filter(t => t.isRent).reduce((sum, t) => sum + (t.amount ? t.amount : 0), 0)
      )
      return true
    },
    [setFieldValue, values.transactions]
  )

  const handleGuestsChange = useCallback(
    (newGuests: Guest[]) => {
      setFieldValue('guests', newGuests)
    },
    [setFieldValue]
  )

  const handleTransactionChange = useCallback(
    async (transactionValues: Transaction, id?: string) => {
      let newTransactions = values.transactions || []
      if (id) {
        newTransactions = newTransactions.map(t =>
          t.id === id
            ? {
                ...t,
                ...transactionValues
              }
            : t
        )
      } else {
        newTransactions.push(transactionValues)
      }

      setFieldValue('transactions', newTransactions)
      setFieldValue(
        'totalAmount',
        newTransactions.filter(t => t.isRent).reduce((sum, t) => sum + (t.amount ? t.amount : 0), 0)
      )

      return true
    },
    [setFieldValue, values.transactions]
  )

  useEffect(() => {
    if (!editTransactionDialog) {
      setEditTransactionDialogItem(undefined)
    }
  }, [editTransactionDialog])

  const rentTransactions = values.transactions
    ?.filter(t => t.isRent)
    .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime())
  const extrasTransactions = values.transactions
    ?.filter(t => !t.isRent)
    .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime())

  return (
    <div>
      {isBooking ? (
        <React.Fragment>
          <div className={clsx({ inline: !isSmDown, [styles.spacingBlock]: !isSmDown })}>
            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('check_in')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{values.from ? formatDate(values.from) : '––'}</span>
              </Typography>
            </div>

            <Spacing horizontal size={2} />

            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('check_out')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{values.to ? formatDate(values.to) : '––'}</span>
              </Typography>
            </div>
          </div>

          <div className={clsx({ inline: !isSmDown, [styles.spacingBlock]: !isSmDown })}>
            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('periods.night.noun_plural')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{getNightsDuration(values.from, values.to)}</span>
              </Typography>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRightSmall}>
                x
              </Typography>
              <Typography variant="body1" className={styles.spacingRightSmall}>
                <span className="text-emphasis">{formatCurrency(values.totalAmount / nights, user?.currency)}</span>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`/${t('periods.night.noun')}`}
              </Typography>
            </div>

            <Spacing horizontal size={2} />

            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('total')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{formatCurrency(values.totalAmount, user?.currency)}</span>
              </Typography>
            </div>
          </div>

          <div className={clsx({ inline: !isSmDown, [styles.spacingBlock]: !isSmDown })}>
            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('adults')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{values.adults ? values.adults : '––'}</span>
              </Typography>
            </div>

            <Spacing horizontal size={2} />

            <div className={clsx('fullflex inline', { [styles.spacingBlock]: isSmDown })}>
              <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
                {t('children')}
              </Typography>
              <Typography variant="body1" className={styles.spacingRightLarge}>
                <span className="text-emphasis">{values.children ? values.children : '––'}</span>
              </Typography>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={clsx('inline', styles.spacingBlock)}>
            <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
              {t('addrent.contract_duraton')}
            </Typography>
            <RentContractDurationLabel from={values.from} to={values.to} showDatesRange />
          </div>
          <div className={clsx('inline', styles.spacingBlock)}>
            <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
              {t('addrent.total')}
            </Typography>
            <Typography variant="body1" className={styles.spacingRightLarge}>
              <span className="text-emphasis">{formatCurrency(values.totalAmount, user?.currency)}</span>
            </Typography>
          </div>
        </React.Fragment>
      )}
      <div className={clsx('inline', styles.spacingBlock)}>
        <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
          {t('payment')}
        </Typography>
        <Typography variant="body1">
          <span className="text-emphasis">
            {values.paymentMethod ? `${t('with')} ${t(`paymentmethod.${values.paymentMethod}`)} ` : ''}
            {getRentRecurrenceLabel(
              t,
              values.recurringPayment,
              values.recurringPaymentValue,
              values.recurringPaymentDay
            )}
          </span>
        </Typography>
      </div>

      {editGuestsEnabled ? (
        <div className="inline align-end">
          <div className="colstretch fullflex">
            <EditGuestsInput
              value={values.guests}
              error={touched.guests && Boolean(errors.guests)}
              helperText={touched.guests && errors.guests ? (errors.guests as string) : ' '}
              disabled={loading}
              onChange={handleGuestsChange}
            />
          </div>
          <Spacing size={2} horizontal />
          <div className="colstretch">
            <div className={clsx(styles.inputIconContainer, 'inline')}>
              <IconButton size="small" onClick={handleGuestsEditToggle}>
                <IconClose />
              </IconButton>
            </div>
            <FormHelperText> </FormHelperText>
          </div>
        </div>
      ) : (
        <div className={clsx('inline', styles.spacingBlock)}>
          <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>
            {t('guests_label')}
          </Typography>
          <Typography variant="body1">
            <span className="text-emphasis">
              {!values.guests || (values.guests && values.guests.length === 0)
                ? '––'
                : values.guests.map(g => getGuestDisplayName(g)).join(', ')}
            </span>
          </Typography>
          {isEdit && editDisabled && (
            <React.Fragment>
              <Spacing size={2} horizontal />
              <IconButton size="small" onClick={handleGuestsEditToggle}>
                <IconEdit />
              </IconButton>
            </React.Fragment>
          )}
        </div>
      )}

      {isBooking && (
        <React.Fragment>
          {rentId && (
            <div className={clsx('inline', styles.spacingBlock)}>
              <Tooltip
                title={
                  values.guests.length === 0 ? (
                    <Typography>
                      <strong>{t('booking_confirmation')}</strong>
                      {`: ${t('booking_confirmation_guest_missing_tip')}`}
                    </Typography>
                  ) : (
                    ''
                  )
                }>
                <div>
                  <Button
                    variant="outlined"
                    disabled={values.guests.length === 0}
                    color={values.guests.length === 0 ? 'default' : 'primary'}
                    className={clsx({ 'text-secondary': values.guests.length === 0 })}
                    onClick={values.guests.length === 0 ? undefined : handleBookingConfirmationSelected}
                    startIcon={<IconReceipt className={values.guests.length > 0 ? 'icon-primary' : 'icon-secondary'} />}
                    classes={{ root: styles.buttonDisabled }}>
                    {t('booking_confirmation')}
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}

          <div className={clsx('inline', styles.spacingBlock, styles.fullBlock)}>
            <InputText
              label={t('notes_for_guests')}
              placeholder={t('notes_for_guests_placeholder')}
              name="bookingNotes"
              multiline
              fullFlex
              value={values.bookingNotes || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.bookingNotes) && touched.bookingNotes}
              helperText={errors.bookingNotes && touched.bookingNotes ? errors.bookingNotes : ' '}
            />
          </div>
        </React.Fragment>
      )}

      <div className={styles.spacingTopLarge}>
        <div className="inline">
          {isBooking ? (
            !editDisabled && (
              <Button
                variant="text"
                color="primary"
                className={styles.addTransactionButton}
                onClick={() => handleNewTransactionSelected(true)}>
                <IconAddCircleOutlined className="spacing-right-xs" />
                {t('add_transaction')}
              </Button>
            )
          ) : (
            <Typography variant="subtitle1" component="div">
              {t(`transaction_${rentTransactions && rentTransactions.length > 1 ? 'plural' : 'singular'}`, {
                transactions: rentTransactions ? rentTransactions.length : 0
              })}
            </Typography>
          )}
          {!isBooking && (
            <React.Fragment>
              {!editDisabled ? (
                <IconButton
                  className={clsx(styles.addTransactionButton, 'rounded', 'spacing-left-xs')}
                  onClick={() => handleNewTransactionSelected(true)}>
                  <IconAddCircleOutlined />
                </IconButton>
              ) : (
                <div className={styles.addTransactionButtonPlaceholder} />
              )}
            </React.Fragment>
          )}
        </div>

        {rentTransactions && (
          <div className={clsx(styles.spacingBlock, styles.transactionsList)}>
            {rentTransactions.map(transaction => (
              <TransactionListItem
                key={transaction.id}
                isRent
                hasGuests={values.guests.length > 0}
                transaction={transaction}
                isBooking={isBooking}
                onEdit={handleTransactionEdit}
                onPaidToggle={handleTransactionPaidToggle}
                onRentReceiptSelected={handleRentReceiptDialogOpen}
              />
            ))}
          </div>
        )}
      </div>

      <div className={styles.spacingBlock}>
        {!extrasTransactions || (extrasTransactions && extrasTransactions.length === 0 && !editDisabled) ? (
          <Button
            variant="text"
            color="primary"
            className={styles.addTransactionButton}
            onClick={() => handleNewTransactionSelected(false)}>
            <IconAddCircleOutlined className="spacing-right-xs" />
            {t('add_extra')}
          </Button>
        ) : (
          <div className="inline">
            {(!editDisabled || (editDisabled && (extrasTransactions || []).length > 0)) && (
              <Typography variant="subtitle1" component="div">
                {t(`extra_amount`, {
                  amount: extrasTransactions ? extrasTransactions.length : 0
                })}
              </Typography>
            )}
            {!editDisabled && (
              <IconButton
                className={clsx(styles.addTransactionButton, 'rounded', 'spacing-left-xs')}
                onClick={() => handleNewTransactionSelected(false)}>
                <IconAddCircleOutlined />
              </IconButton>
            )}
          </div>
        )}

        {extrasTransactions && (
          <div className={clsx(styles.spacingBlock, styles.transactionsList)}>
            {extrasTransactions.slice(0, NumberOfTransactionsToDisplay).map(transaction => (
              <TransactionListItem
                key={transaction.id}
                transaction={transaction}
                onEdit={handleTransactionEdit}
                onPaidToggle={handleTransactionPaidToggle}
              />
            ))}
            {extrasTransactions.length > 3 && (
              <div>
                <Collapse in={viewAllTransactions}>
                  {extrasTransactions
                    .slice(NumberOfTransactionsToDisplay, extrasTransactions.length)
                    .map(transaction => (
                      <TransactionListItem
                        key={transaction.id}
                        transaction={transaction}
                        onEdit={handleTransactionEdit}
                        onPaidToggle={handleTransactionPaidToggle}
                      />
                    ))}
                </Collapse>

                <div className="spacing-block-vertical-xs" />
                <Button variant="text" color="primary" size="small" onClick={handleViewAllTransactionsToggle}>
                  {t(viewAllTransactions ? 'hide' : 'see_all_f')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <EditTransactionDialog
        open={Boolean(editTransactionDialog)}
        onClose={handleEditTransactionClose}
        onSubmit={handleTransactionChange}
        onDelete={handleTransactionDeleted}
        transaction={editTransactionDialogItem}
        editDisabled={editDisabled}
        deleteDisabled={rentTransactions && rentTransactions.length <= 1}
        isRent={editTransactionDialog?.isRent}
        isBooking={isBooking}
      />

      <RentReceipt
        open={Boolean(rentReceiptDialog)}
        rentId={rentReceiptDialog?.rentId}
        transactionId={rentReceiptDialog?.transactionId}
        guestsIds={rentReceiptDialog?.guestsIds}
        onClose={handleRentReceiptDialogClose}
      />
      <BookingConfirmation
        open={Boolean(bookingConfirmationDialog)}
        rentId={bookingConfirmationDialog?.rentId}
        guestsIds={bookingConfirmationDialog?.guestsIds}
        onClose={handleBookingConfirmationDialogClose}
      />
      <PromoDialog
        open={Boolean(promoDialog)}
        title={promoDialog?.title}
        description={promoDialog?.description}
        promoFor={promoDialog?.promoFor}
        onClose={handlePromoDialogClose}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacingRightSmall: {
      marginRight: theme.spacing(0.5)
    },
    spacingRight: {
      marginRight: theme.spacing(2.5)
    },
    spacingRightLarge: {
      marginRight: theme.spacing(5)
    },
    spacingBlock: {
      marginBottom: theme.spacing(3)
    },
    spacingTopLarge: {
      marginTop: theme.spacing(5)
    },
    addTransactionButton: {
      '& svg': {
        width: 20,
        height: 20,
        '& path': {
          fill: theme.palette.primary.main
        }
      }
    },
    addTransactionButtonPlaceholder: {
      height: 44
    },
    transactionsList: {
      '& .transaction-list-item:last-child .MuiDivider-root': {
        display: 'none'
      }
    },
    inputIconContainer: {
      height: 47
    },
    buttonDisabled: {
      '&.Mui-disabled': {
        color: theme.palette.text.secondary
      }
    },
    fullBlock: {
      alignItems: 'stretch !important'
    }
  })
)

export default AddRentStepSummary
