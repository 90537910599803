import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { TFunction } from 'i18next'
import ReactDOMServer from 'react-dom/server'
import html2pdf from 'html2pdf.js'
import { formatDate } from '@fullcalendar/react'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Rent from '../types/place/Rent'
import Guest from '../types/Guest'
import { formatPhoneNumber, getGuestDisplayName } from '../utils/TextUtils'
import { emailIsValid } from '../utils/CommonUtils'
import { IconClose, IconArrowDownSvg, IconEventConfirmed } from '../assets/Svgs'
import Colors from '../styles/Colors'
import { getNightsDuration } from '../utils/RentUtils'
import Place from '../types/place/Place'
import PdfBookingConfirmation, { BookingConfirmationData } from './PdfBookingConfirmation'
import AppLoader from './AppLoader'
import ColorIcon from './ColorIcon'
import InputText from './inputs/InputText'
import Spacing from './Spacing'

const generateAndUploadBookingConfirmation = async (t: TFunction, userId: string, data: BookingConfirmationData) => {
  const { db, storage } = AppService

  let error = false
  let downloadUrl: string | undefined

  try {
    const confirmationNumber = await db.getConfirmationNumber(userId)

    const fileName = `${t('booking_confirmation').toLowerCase()}_${confirmationNumber}.pdf`
    const PdfOptions = {
      filename: fileName,
      margin: [20, 20],
      image: { type: 'png', quality: 0.9 },
      html2canvas: { dpi: 240, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }
    const PdfHtmlElement = ReactDOMServer.renderToStaticMarkup(
      <PdfBookingConfirmation {...data} confirmationNumber={confirmationNumber} t={t} />
    )

    const worklet = html2pdf().set(PdfOptions).from(PdfHtmlElement).toPdf()
    const PdfFile: Blob = await worklet.output('blob')

    downloadUrl = await storage.uploadBookingConfirmation(PdfFile, fileName)

    if (downloadUrl) {
      await db.incrementConfirmationNumber(userId)
    }
  } catch (err) {
    error = true
  }

  return { downloadUrl, error }
}

type BookingConfirmationProps = {
  open: boolean
  rentId?: string
  guestsIds?: string[]
  onClose?: () => void
}

const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
  open,
  rentId,
  guestsIds,
  onClose
}: BookingConfirmationProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { user } = useUser()
  const { role } = AppService
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [guests, setGuests] = useState<Guest[]>([])
  const [guest, setGuest] = useState<Guest | null>(null)
  const [place, setPlace] = useState<Place | undefined>()
  const [rent, setRent] = useState<Rent | undefined>()
  const [submittingEmail, setSubmittingEmail] = useState<boolean>(false)
  const [submittingWhatsapp, setSubmittingWhatsapp] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [includeNotes, setIncludeNotes] = useState<boolean>(true)

  const canManage = role.canAddBooking()

  const handleIncludeNotesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIncludeNotes(checked)
  }, [])

  const handleGuestChange = useCallback((event, value: Guest) => {
    setGuest(value)
  }, [])

  const handleEmailChange = useCallback(event => {
    setEmail(event.target.value)
  }, [])

  const handlePhoneNumberChange = useCallback(event => {
    setPhoneNumber(event.target.value)
  }, [])

  const getConfirmationData = useCallback((): BookingConfirmationData => {
    return {
      from: rent?.from || new Date(),
      to: rent?.to || new Date(),
      guestName: guest ? getGuestDisplayName(guest) : '',
      placeName: place?.name || '',
      adults: rent?.adults,
      children: rent?.children,
      bookingNotes: includeNotes ? rent?.bookingNotes : undefined
    }
  }, [guest, includeNotes, place?.name, rent?.adults, rent?.bookingNotes, rent?.children, rent?.from, rent?.to])

  const getConfirmationUrl = useCallback(async () => {
    if (user && rent) {
      const { downloadUrl, error } = await generateAndUploadBookingConfirmation(t, user.id, getConfirmationData())

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      }

      return error ? undefined : downloadUrl
    }

    return
  }, [enqueueSnackbar, getConfirmationData, rent, t, user])

  const getConfirmationMessageBody = useCallback(
    (url?: string) => {
      return encodeURI(`${t('booking_confirmation_body')}: ${url}`)
    },
    [t]
  )

  const handleSendEmailSelected = useCallback(async () => {
    if (!email) return

    setSubmittingEmail(true)

    const confirmationUrl = await getConfirmationUrl()

    if (confirmationUrl) {
      const emailLink = `mailto:${email}?subject=${''}&body=${getConfirmationMessageBody(confirmationUrl)}`

      window.location.href = emailLink
    }

    setSubmittingEmail(false)
  }, [email, getConfirmationMessageBody, getConfirmationUrl])

  const handleSendWhatsappSelected = useCallback(async () => {
    if (!phoneNumber) return

    setSubmittingWhatsapp(true)

    const confirmationUrl = await getConfirmationUrl()

    if (confirmationUrl) {
      const whatsappLink = `https://wa.me/${formatPhoneNumber(phoneNumber)}?text${getConfirmationMessageBody(
        confirmationUrl
      )}`

      window.location.href = whatsappLink
    }

    setSubmittingWhatsapp(false)
  }, [getConfirmationMessageBody, getConfirmationUrl, phoneNumber])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      history.goBack()
    }
  }, [history, onClose])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoading(true)

      if (canManage) {
        const { db } = AppService
        if (user && rentId) {
          const rentResult = await db.getRentBase(user.id, rentId)

          if (rentResult && !didCancel) {
            const placeResult = await db.getPlace(rentResult.placeId)
            if (placeResult && !didCancel) {
              setPlace(placeResult)
            }
            if (!didCancel) {
              setRent(rentResult)
            }
          }
        }
        if (guestsIds) {
          const guests: Guest[] = []

          for (const guestId of guestsIds) {
            const guest = await db.getGuest(guestId)
            if (guest) {
              guests.push(guest)
            }
          }

          if (!didCancel) {
            setGuests(guests)
            if (guests.length > 0) {
              setGuest(guests[0])
            }
          }
        }
      }

      if (!didCancel) setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [canManage, guestsIds, rentId, user])

  useEffect(() => {
    if (guest) {
      setEmail(guest?.email || '')
      setPhoneNumber(
        `${guest?.phoneNumberPrefix ? `${guest?.phoneNumberPrefix}` : ''}${
          guest?.phoneNumber ? guest?.phoneNumber : ''
        }`
      )
    }
  }, [guest])

  const invalidEmail = !emailIsValid(email)
  const nights = getNightsDuration(rent?.from, rent?.to)

  return (
    <Dialog
      open={open}
      aria-labelledby="add-expense-dialog-title"
      aria-describedby="add-expense-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'sm'}
      onClose={handleClose}>
      <DialogTitle disableTypography id="alert-dialog-title">
        <div className={clsx(styles.title, 'inline', 'justify-between')}>
          <div className="inline">
            <ColorIcon Icon={<IconEventConfirmed />} color={theme.palette.primary.main} withBackground />
            <Spacing horizontal size={2} />
            <Typography variant="h3" component="div">
              {t('booking_confirmation')}
            </Typography>
          </div>

          <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
            <IconClose />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {canManage ? (
          <React.Fragment>
            <div className="inline">
              <Typography color="textSecondary">{t('to_mr_mrs')}</Typography>
              <Spacing horizontal size={2} />
              {guest ? (
                <Autocomplete<Guest, false, true>
                  options={guests}
                  value={guest}
                  disabled={loading}
                  disableClearable
                  className={clsx('fullflex', styles.guestSelect)}
                  getOptionLabel={option => getGuestDisplayName(option)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={handleGuestChange}
                  renderInput={params => (
                    <InputText {...params} placeholder={`${t('addrent.select_guests_placeholder')}*`} />
                  )}
                  popupIcon={<IconArrowDownSvg />}
                />
              ) : null}
            </div>

            <Spacing size={2} />

            <div className={clsx({ inline: !isSmDown })}>
              <div className="inline fullflex">
                <Typography color="textSecondary">{t('check_in')}</Typography>
                <Spacing horizontal size={2} />
                <Typography>{formatDate(rent?.from || new Date())}</Typography>
              </div>

              {isSmDown && <Spacing size={2} />}

              <div className="inline fullflex">
                <Typography color="textSecondary">{t('check_out')}</Typography>
                <Spacing horizontal size={2} />
                <Typography>{formatDate(rent?.to || new Date())}</Typography>
              </div>
            </div>

            <Spacing size={2} />

            <div className="inline fullflex">
              <Typography color="textSecondary">{t('periods.night.noun_plural')}</Typography>
              <Spacing horizontal size={2} />
              <Typography>{nights}</Typography>
            </div>

            <Spacing size={2} />

            <div className={clsx({ inline: !isSmDown })}>
              <div className="inline fullflex">
                <Typography color="textSecondary">{t('adults')}</Typography>
                <Spacing horizontal size={2} />
                <Typography>{rent?.adults || '––'}</Typography>
              </div>

              {isSmDown && <Spacing size={2} />}

              <div className="inline fullflex">
                <Typography color="textSecondary">{t('children')}</Typography>
                <Spacing horizontal size={2} />
                <Typography>{rent?.children || '––'}</Typography>
              </div>
            </div>

            {rent?.bookingNotes && (
              <React.Fragment>
                <Spacing size={2} />

                <div className={clsx({ [styles.bookingNotesDisabled]: !includeNotes })}>
                  <div className="fullflex">
                    <Typography color="textSecondary">{t('notes_for_guests')}</Typography>
                  </div>

                  <Spacing size={1} />

                  <Typography className="fullflex">{rent?.bookingNotes}</Typography>
                </div>

                <Spacing size={4} />

                <div className="inline fullflex">
                  <Typography variant="body1" className="fullflex">
                    {t('include_booking_notes')}
                  </Typography>

                  <Switch
                    name="includeNotes"
                    color="primary"
                    disabled={loading}
                    checked={includeNotes}
                    onChange={handleIncludeNotesChange}
                    className="spacing-left-s"
                  />
                </div>
              </React.Fragment>
            )}

            <Spacing size={6} />

            <div className={clsx(isSmDown ? 'colstretch' : 'inline', { [styles.inputContainer]: !isSmDown })}>
              <InputText
                value={email}
                disabled={loading}
                className={clsx(styles.input, styles.inputSize)}
                rootClassName={styles.inputSize}
                fullFlex
                onChange={handleEmailChange}
                error={Boolean(email) && invalidEmail}
                helperText={Boolean(email) && invalidEmail ? t('invalid_email') : ' '}
              />
              <Spacing horizontal={!isSmDown} size={2} />
              <Button
                variant="outlined"
                color="primary"
                className={styles.submitButton}
                onClick={handleSendEmailSelected}
                disabled={loading || submittingEmail || invalidEmail || !Boolean(email)}>
                {submittingEmail ? <AppLoader size={20} /> : t('send_email')}
              </Button>
            </div>

            <Spacing size={2} />

            <div className={clsx(isSmDown ? 'colstretch' : 'inline', { [styles.inputContainer]: !isSmDown })}>
              <InputText
                value={phoneNumber}
                disabled={loading}
                className={clsx(styles.input, styles.inputSize)}
                rootClassName={styles.inputSize}
                fullFlex
                onChange={handlePhoneNumberChange}
              />
              <Spacing horizontal={!isSmDown} size={2} />
              <Button
                variant="outlined"
                color="primary"
                className={styles.submitButton}
                onClick={handleSendWhatsappSelected}
                disabled={loading || submittingWhatsapp || !Boolean(phoneNumber)}>
                {submittingWhatsapp ? <AppLoader size={20} /> : t('send_whatsapp')}
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>{/* TODO send booking confirmation promo */}</React.Fragment>
        )}
      </DialogContent>

      <Spacing size={8} />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    guestSelect: {
      height: 32,
      '& > div': {
        height: 32,
        '& .MuiFormControl-root': {
          height: 32,
          '& .MuiAutocomplete-inputRoot': {
            height: 32,
            '& input': {
              padding: `0 4px`
            }
          }
        }
      }
    },
    inputContainer: {
      padding: 4,
      border: `1px solid ${Colors.IconGray}`,
      borderRadius: theme.shape.borderRadius
    },
    input: {
      '& .MuiInputBase-root': {
        height: 40,
        '& .MuiInputBase-input': {
          height: 40
        }
      },
      [theme.breakpoints.up('sm')]: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }
    },
    inputSize: {
      height: 40
    },
    submitButton: {
      minHeight: 40,
      minWidth: 200,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto'
      }
    },
    bookingNotesDisabled: {
      opacity: 0.6
    }
  })
)

export default BookingConfirmation
