import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

type TransactionCategoryColorProps = {
  color: string
  size?: 'default' | 'small'
  className?: string
}

const TransactionCategoryColor: React.FC<TransactionCategoryColorProps> = ({
  color,
  size = 'default',
  className
}: TransactionCategoryColorProps) => {
  const styles = useStyles()

  return (
    <div
      className={clsx(styles.color, className, { [styles.colorSmall]: size === 'small' })}
      style={{ backgroundColor: color }}
    />
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    color: {
      height: 28,
      width: 28,
      borderRadius: '100%',
      flexShrink: 0
    },
    colorSmall: {
      height: 16,
      width: 16
    }
  })
)

export default TransactionCategoryColor
