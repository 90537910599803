import { fade, List, ListItem, ListItemIcon, Theme, Typography, Tooltip, TooltipProps } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import ColorIcon from './ColorIcon'
import Spacing from './Spacing'

export type PopoverMenuItem = {
  text?: string
  Icon?: React.ReactNode
  iconColor?: string
  Component?: React.ReactNode
  disabled?: boolean
  tooltip?: string | NonNullable<React.ReactNode>
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>
  handler?: () => void
}

type PopoverMenuProps = {
  items: PopoverMenuItem[]
  className?: string
  Content?: React.ReactNode
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({ items, className, Content }: PopoverMenuProps) => {
  const styles = useStyles()

  return (
    <List className={clsx(styles.container, className)}>
      {Content}

      {Content && <Spacing size={1} />}

      {items.map((item, index) => (
        <React.Fragment key={item.text || index}>
          <Tooltip title={item.tooltip || ''} {...item.tooltipProps}>
            <div className="fullflex">
              <ListItem button disabled={item.disabled} className={styles.listItem} onClick={item.handler}>
                {item.Component ? (
                  item.Component
                ) : (
                  <React.Fragment>
                    <ListItemIcon
                      className={styles.listItemIcon}
                      style={item.iconColor ? { backgroundColor: fade(item.iconColor, 0.2) } : undefined}>
                      <ColorIcon color={item.iconColor} Icon={item.Icon} />
                    </ListItemIcon>
                    <Typography variant="h6" className={styles.listItemText}>
                      {item.text}
                    </Typography>
                  </React.Fragment>
                )}
              </ListItem>
            </div>
          </Tooltip>
        </React.Fragment>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: 12,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.5),
      minWidth: 180
    },
    listItem: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
      borderRadius: 6
    },
    listItemIcon: {
      width: 32,
      height: 32,
      minWidth: 'unset',
      borderRadius: 4,
      backgroundColor: fade(theme.palette.text.secondary, 0.2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1.5),
      '& > div, svg': {
        width: 24,
        height: 24
      }
    },
    listItemText: {
      color: theme.palette.text.primary
    }
  })
)

export default PopoverMenu
