import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type ContentContainerProps = {
  backgroundPaper?: boolean
}

const ContentContainer: React.FC<PropsWithChildren<ContentContainerProps>> = ({
  children,
  backgroundPaper
}: PropsWithChildren<ContentContainerProps>) => {
  const styles = useStyles()

  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={12} sm={6} className={clsx(styles.container, { [styles.backgroundPaper]: backgroundPaper })}>
        {children}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(2.5)}px`
      }
    },
    backgroundPaper: {
      backgroundColor: theme.palette.background.paper
    }
  })
)

export default ContentContainer
