import firebase from 'firebase'
import AppService from './AppService'
import FirebaseService from './FirebaseService'

class AuthService {
  auth: firebase.auth.Auth | undefined
  processingAuth?: firebase.auth.UserCredential

  constructor(auth?: firebase.auth.Auth) {
    this.auth = auth
  }

  register = async (authResult: firebase.auth.UserCredential): Promise<boolean> => {
    if (!authResult.user) return false

    const { db } = AppService
    await db.createUser({
      id: authResult.user.uid,
      email: authResult.user.email || undefined,
      name: authResult.user.displayName || undefined,
      pictureUrl: authResult.user.photoURL || undefined
    })

    return true
  }

  login = (authResult: firebase.auth.UserCredential): boolean => {
    if (!authResult.user) return false

    if (authResult.additionalUserInfo?.isNewUser) {
      FirebaseService.analytics?.logEvent('sign_up', { method: authResult.credential?.signInMethod })
    } else {
      FirebaseService.analytics?.logEvent('login', { method: authResult.credential?.signInMethod })
    }

    this.processingAuth = authResult

    return true
  }

  logout = async () => {
    await this.auth?.signOut()
    return true
  }

  getAuthUserData = async () => {
    await firebase.auth().currentUser?.getIdToken(true)
    const decodedToken = await firebase.auth().currentUser?.getIdTokenResult()
    return decodedToken
  }
}

export default AuthService
