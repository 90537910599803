import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NewPlaceStepValues3 } from '../../pages/NewPlace'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import InputNumber from '../inputs/InputNumber'
import InputText from '../inputs/InputText'
import Spacing from '../Spacing'

type NewPlaceStepAdditionalInfoProps = FormStepComponentBase<NewPlaceStepValues3>

const NewPlaceStepAdditionalInfo: React.FC<NewPlaceStepAdditionalInfoProps> = ({
  submitting,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}: NewPlaceStepAdditionalInfoProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const handleNumberChange = useCallback(
    (id: string, value: number | undefined) => {
      setFieldValue(id, value)
    },
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <Typography variant="body1" component="div" className={styles.sectionLabel}>
        {t('newplace.step_2.rooms_section_label')}
      </Typography>
      <InputNumber
        label={t('newplace.step_2.rooms_label')}
        id="rooms"
        variant="naked"
        placeholder={'1'}
        showModifierButtons
        value={values.rooms}
        min={1}
        max={100}
        onChange={handleNumberChange}
        onBlur={handleBlur}
        error={Boolean(errors.rooms) && touched.rooms}
        helperText={errors.rooms && touched.rooms ? errors.rooms : ' '}
        disabled={submitting}
      />

      <Typography variant="body1" component="div" className={styles.sectionLabel}>
        {t('newplace.step_2.bedrooms_section_label')}
      </Typography>
      <InputNumber
        label={t('newplace.step_2.bedrooms_label')}
        id="bedrooms"
        variant="naked"
        placeholder={'1'}
        showModifierButtons
        value={values.bedrooms}
        min={1}
        max={100}
        onChange={handleNumberChange}
        onBlur={handleBlur}
        error={Boolean(errors.bedrooms) && touched.bedrooms}
        helperText={errors.bedrooms && touched.bedrooms ? errors.bedrooms : ' '}
        disabled={submitting}
      />

      <Typography variant="body1" component="div" className={styles.sectionLabel}>
        {t('newplace.step_2.bathrooms_section_label')}
      </Typography>
      <InputNumber
        label={t('newplace.step_2.bathrooms_label')}
        id="bathrooms"
        variant="naked"
        placeholder={'1'}
        showModifierButtons
        value={values.bathrooms}
        min={1}
        max={100}
        onChange={handleNumberChange}
        onBlur={handleBlur}
        error={Boolean(errors.bathrooms) && touched.bathrooms}
        helperText={errors.bathrooms && touched.bathrooms ? errors.bathrooms : ' '}
        disabled={submitting}
      />

      <Spacing size={2} />

      <InputText
        label={t('notes_for_guests')}
        placeholder={t('notes_for_guests_placeholder')}
        name="notes"
        multiline
        value={values.notes || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(errors.notes) && touched.notes}
        helperText={errors.notes && touched.notes ? errors.notes : ' '}
        disabled={submitting}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionLabel: {
      color: theme.palette.grey[500]
    }
  })
)

export default NewPlaceStepAdditionalInfo
