import { createStyles, makeStyles } from '@material-ui/styles'
import React, { PropsWithChildren } from 'react'

const ContainerFullPage: React.FC<PropsWithChildren<{}>> = ({ children }: PropsWithChildren<{}>) => {
  const styles = useStyles()
  return <div className={styles.root}>{children}</div>
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

export default ContainerFullPage
