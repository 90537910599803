import {
  Button,
  Hidden,
  IconButton,
  lighten,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { IconArrowLeft, IconDocuments, IconEdit } from '../assets/Svgs'
import BoxContainer from '../components/BoxContainer'
import BackButton from '../components/buttons/BackButton'
import PlaceDetailRentalsChart from '../components/charts/PlaceDetailRentalsChart'
import PlaceDetailTotalsChart from '../components/charts/PlaceDetailTotalsChart'
import Header from '../components/header/Header'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import PlaceDetailAddButton from '../components/place-detail/PlaceDetailAddButton'
import PlaceDetailContainer from '../components/place-detail/PlaceDetailContainer'
import PlaceDetailEmpty from '../components/place-detail/PlaceDetailEmpty'
import PlaceDetailRentItem from '../components/place-detail/PlaceDetailRentItem'
import PlaceDetailTransactionItem from '../components/place-detail/PlaceDetailTransactionItem'
import Spacing from '../components/Spacing'
import StatisticsContainer from '../components/StatisticsContainer'
import Toolbar from '../components/Toolbar'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import Place from '../types/place/Place'
import Rent from '../types/place/Rent'
import Transaction from '../types/Transaction'
import { sortRents } from '../utils/RentUtils'

enum PlaceDetailTabs {
  Rent = 'rent',
  Expenses = 'expenses'
}

const PlaceDetail: React.FC = () => {
  const styles = useStyles()
  const { placeId } = useParams<{ placeId: string }>()
  const { user } = useUser()
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const { role } = AppService

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const tabs = useRef([
    {
      id: PlaceDetailTabs.Rent,
      label: t('rents')
    },
    {
      id: PlaceDetailTabs.Expenses,
      label: t('expenses')
    }
  ])

  const [place, setPlace] = useState<Place | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingTab, setLoadingTab] = useState<boolean>(true)
  const [placeRents, setPlaceRents] = useState<Rent[]>([])
  const [placeTransactions, setPlaceTransactions] = useState<Transaction[]>([])
  const [selectedTabId, setSelectedTabId] = useState<string>(tabs.current[0].id)

  const initialized = useRef<boolean>(false)

  const handleEditSelected = useCallback(() => {
    history.push(RoutesPaths.EditPlaceDialog.replace(':placeId', placeId), { isModal: true })
  }, [history, placeId])

  const handleExit = useCallback(() => {
    history.push(RoutesPaths.Places)
  }, [history])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTabId(newValue)
  }

  const handleDocumentsSelected = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      event.preventDefault()

      if (role.canManageDocuments()) {
        history.push(RoutesPaths.PlaceDocumentsList.replace(':placeId', placeId), {
          isModal: true,
          title: t('place_documents')
        })
      } else {
        history.push(RoutesPaths.PromoDialog, {
          isModal: true,
          title: t('documents_promo_title'),
          description: t('documents_promo_description'),
          promoFor: 'business'
        })
      }
    },
    [history, placeId, role, t]
  )

  const fetchPlace = useCallback(async () => {
    let item: Place | undefined

    try {
      const { db } = AppService
      item = await db.getPlace(placeId)
    } catch (err) {}

    return { item }
  }, [placeId])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel) {
        setLoadingTab(true)
        if (!initialized.current) {
          setLoading(true)
        }
      }

      if (!initialized.current) {
        const result = await fetchPlace()

        setPlace(result.item)
        initialized.current = true
      }

      const { db } = AppService

      const rentsResult = await db.getRents(user.id, placeId)

      if (!didCancel) {
        setPlaceRents(rentsResult)
      }
      const transactionsResult = await db.getPlaceTransactions(user.id, placeId)

      if (!didCancel) {
        setPlaceTransactions(transactionsResult)
      }

      if (!didCancel) {
        setLoadingTab(false)
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [fetchPlace, user, placeId])

  useEffect(() => {
    const fetchRent = async (rentId: string, isUpdate?: boolean) => {
      if (!user) return

      try {
        const { db } = AppService
        const rent = await db.getRent(user.id, rentId)

        if (rent) {
          setPlaceRents(current =>
            isUpdate ? current.map(c => (c.id === rentId ? rent : c)) : sortRents(current.concat([rent]))
          )
        }
      } catch (err) {}
    }
    const fetchTransaction = async (transactionId: string, isUpdate?: boolean) => {
      if (!user) return

      try {
        const { db } = AppService
        const transaction = await db.getTransaction(transactionId)

        if (transaction) {
          setPlaceTransactions(current =>
            isUpdate ? current.map(c => (c.id === transactionId ? transaction : c)) : current.concat([transaction])
          )
        }
      } catch (err) {}
    }
    const rentCreatedEventToken = subscribe('rentCreated', (eventName: string, { rentId }: { rentId: string }) =>
      fetchRent(rentId)
    )
    const rentUpdatedEventToken = subscribe('rentUpdated', (eventName: string, { rentId }: { rentId: string }) =>
      fetchRent(rentId, true)
    )
    const rentDeletedEventToken = subscribe('rentDeleted', (eventName: string, { rentId }: { rentId: string }) =>
      setPlaceRents(current => current.filter(r => r.id !== rentId))
    )
    const transactionCreatedEventToken = subscribe(
      'transactionCreated',
      (eventName: string, { transactionId }: { transactionId: string }) => fetchTransaction(transactionId)
    )
    const transactionUpdatedEventToken = subscribe(
      'transactionUpdated',
      (eventName: string, { transactionId }: { transactionId: string }) => fetchTransaction(transactionId, true)
    )
    const transactionDeletedEventToken = subscribe(
      'transactionDeleted',
      (eventName: string, { transactionId }: { transactionId: string }) =>
        setPlaceTransactions(current => current.filter(t => t.id !== transactionId))
    )
    const placeUpdatedEventToken = subscribe('placeUpdated', async () => {
      const { item } = await fetchPlace()
      if (item) setPlace(item)
    })

    return () => {
      unsubscribe(rentCreatedEventToken)
      unsubscribe(rentUpdatedEventToken)
      unsubscribe(rentDeletedEventToken)
      unsubscribe(transactionCreatedEventToken)
      unsubscribe(transactionUpdatedEventToken)
      unsubscribe(transactionDeletedEventToken)
      unsubscribe(placeUpdatedEventToken)
    }
  }, [user, fetchPlace])

  return (
    <NavigationDrawerContainer smDownHidden>
      <Hidden mdUp>
        <React.Fragment>
          <Header
            title={place?.name}
            leftActionsSmDown={[
              {
                Icon: IconArrowLeft,
                handler: handleExit
              }
            ]}
          />

          <div className={styles.spacingSmall} />
        </React.Fragment>
      </Hidden>

      <Toolbar
        LeftComponent={isSmDown ? null : <BackButton iconOnly onClick={handleExit} />}
        CenterComponent={
          <Tabs
            variant={isSmDown ? 'fullWidth' : 'standard'}
            value={selectedTabId}
            onChange={handleTabChange}
            TabIndicatorProps={{ children: <div /> }}
            aria-label={t('places')}
            classes={{ root: styles.tabs }}>
            {tabs.current.map(tab => (
              <Tab value={tab.id} label={tab.label} key={tab.id} />
            ))}
          </Tabs>
        }
        RightComponent={<PlaceDetailAddButton placeId={placeId} />}
      />

      <PlaceDetailContainer
        LeftColumn={
          <React.Fragment>
            <BoxContainer height={160} loading={loading} className={styles.main}>
              {place ? (
                <React.Fragment>
                  <div className={styles.detailContent}>
                    <Typography variant="h3" component="div" className={styles.detailName}>
                      {place.name}
                    </Typography>
                  </div>
                  <img src={place.pictureUrl} alt={t('places')} className={styles.detailPicture} />
                </React.Fragment>
              ) : null}
              {place && (
                <IconButton onClick={handleEditSelected} className={styles.editButton}>
                  <IconEdit />
                </IconButton>
              )}
            </BoxContainer>

            <React.Fragment>
              <Spacing size={2} />
              <BoxContainer>
                <Spacing size={1} />
                <div className="inline justify-between">
                  <div className="inline">
                    <Spacing size={2} horizontal />
                    <Typography variant="h6">{t('place_documents')}</Typography>
                  </div>

                  <div className="inline">
                    <Button className="inline" variant="text" onClick={handleDocumentsSelected}>
                      <IconDocuments className="icon-primary flex-shrink-0" />
                      <Spacing size={1} horizontal />
                      <Typography variant="subtitle2" color="secondary" align="left" className="lowercase">
                        {t('view_documents')}
                      </Typography>
                    </Button>
                    <Spacing size={2} horizontal />
                  </div>
                </div>
                <Spacing size={1} />
              </BoxContainer>
              <Spacing size={2} />
            </React.Fragment>

            {selectedTabId === PlaceDetailTabs.Rent ? (
              !loadingTab && placeRents.length === 0 ? (
                <PlaceDetailEmpty />
              ) : loadingTab ? (
                [...Array(2).keys()].map(i => <BoxContainer key={i} loading height={120} bottomSpacing={2} />)
              ) : (
                placeRents.map(placeRent => (
                  <BoxContainer key={placeRent.id} bottomSpacing={2}>
                    <PlaceDetailRentItem rent={placeRent} placeId={placeId} />
                  </BoxContainer>
                ))
              )
            ) : selectedTabId === PlaceDetailTabs.Expenses ? (
              !loadingTab && placeTransactions.length === 0 ? (
                <PlaceDetailEmpty />
              ) : loadingTab ? (
                [...Array(2).keys()].map(i => <BoxContainer key={i} loading height={120} bottomSpacing={2} />)
              ) : (
                placeTransactions.map(placeTransaction => (
                  <BoxContainer key={placeTransaction.id} bottomSpacing={2}>
                    <PlaceDetailTransactionItem transaction={placeTransaction} placeId={placeId} />
                  </BoxContainer>
                ))
              )
            ) : null}
          </React.Fragment>
        }
        RightColumn={
          <React.Fragment>
            <BoxContainer height={352} loading={loading} bottomSpacing={2}>
              <StatisticsContainer
                title={t('total')}
                rounded
                RightHeaderComponent={<Typography variant="body2">{t('last_x_months', { amount: 12 })}</Typography>}
                Component={<PlaceDetailTotalsChart rents={placeRents} transactions={placeTransactions} />}
              />
            </BoxContainer>

            {selectedTabId === PlaceDetailTabs.Rent && (
              <BoxContainer height={352} loading={loading} bottomSpacing={2}>
                <StatisticsContainer
                  title={t('rents')}
                  rounded
                  RightHeaderComponent={<Typography variant="body2">{t('last_x_months', { amount: 12 })}</Typography>}
                  Component={<PlaceDetailRentalsChart rents={placeRents} />}
                />
              </BoxContainer>
            )}
          </React.Fragment>
        }
      />
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      position: 'relative'
    },
    detailContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 16,
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.55))`,
      borderRadius: theme.shape.borderRadius * 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    detailPicture: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: theme.shape.borderRadius * 2
    },
    detailName: {
      color: Colors.White
    },
    spacingSmall: {
      height: theme.spacing(2),
      flexShrink: 0
    },
    tabs: {
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    editButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      backgroundColor: lighten(theme.palette.primary.main, 0.8),
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.6)
      }
    }
  })
)

export default PlaceDetail
