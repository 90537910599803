import { ButtonBase, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconExpenses } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { RoutesPaths } from '../../Routes'
import Colors from '../../styles/Colors'
import Transaction from '../../types/Transaction'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { getTransactionPaymentDateLabel, getTransactionStatus } from '../../utils/TransactionsUtils'
import ColorIcon from '../ColorIcon'
import Tag from '../Tag'
import TransactionCategoryColor from '../transactions/TransactionCategoryColor'

type PlaceDetailExpenseItemProps = {
  transaction: Transaction
  placeId: string
}

const PlaceDetailExpenseItem: React.FC<PlaceDetailExpenseItemProps> = ({
  transaction,
  placeId
}: PlaceDetailExpenseItemProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const history = useHistory()

  const handleItemSelected = useCallback(() => {
    history.push(
      RoutesPaths.EditTransactionDialog.replace(':placeId', placeId).replace(':transactionId', transaction?.id),
      { isModal: true }
    )
  }, [history, transaction?.id, placeId])

  const transactionStatus = getTransactionStatus(t, transaction)

  return (
    <ButtonBase className={styles.root} onClick={handleItemSelected}>
      <div className={styles.header}>
        <div className="colstretch">
          <div className={clsx('inline', styles.headerDetailContainer)}>
            {transaction.categories.map((c, i) =>
              c.color ? (
                <TransactionCategoryColor
                  key={c.id}
                  color={c.color}
                  size="small"
                  className={i === transaction.categories.length - 1 ? 'spacing-right-xs' : 'spacing-right-xxs'}
                />
              ) : null
            )}

            <Typography variant="button" component="div" className="break-word">
              {transaction.description}
            </Typography>
          </div>
          <div className="spacing-block-vertical-xxs" />
          <Typography variant="subtitle2" component="div" color="textSecondary">
            {getTransactionPaymentDateLabel(t, transaction)}
          </Typography>
        </div>

        <Tag
          text={transactionStatus.label}
          status={
            transactionStatus.status === 'paid'
              ? 'success'
              : transactionStatus.status === 'delayed'
              ? 'warning'
              : transactionStatus.status === 'unpaid'
              ? 'error'
              : 'default'
          }
          backgroundColor="paper"
        />
      </div>

      <div className="spacing-block-vertical-s" />

      <div className="inline">
        <ColorIcon Icon={<IconExpenses />} color={Colors.Red} withBackground />
        <Typography
          variant="body1"
          component="div"
          className={clsx(styles.transactionAmount, 'spacing-left-xs')}>{`-${formatCurrency(
          transaction.amount,
          user?.currency
        )}`}</Typography>
      </div>
    </ButtonBase>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      flex: 1,
      borderRadius: theme.shape.borderRadius * 2,
      textAlign: 'left'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    transactionAmount: {
      color: Colors.Red,
      fontWeight: 600
    },
    headerDetailContainer: {
      flexWrap: 'wrap'
    }
  })
)

export default PlaceDetailExpenseItem
