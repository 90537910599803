import { createMuiTheme, fade, Theme } from '@material-ui/core'
import { Palette } from '@material-ui/core/styles/createPalette'
import createTypography from '@material-ui/core/styles/createTypography'
// eslint-disable-next-line import/no-unresolved
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import { AutocompleteClassKey } from '@material-ui/lab'
import { CSSProperties } from 'react'
import { IconArrowDownSvg } from '../assets/Svgs'
import AppTypography from './AppTypography'
import Colors from './Colors'

const borderRadius = 4

interface Overrides extends CoreOverrides {
  MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined
}

const getAppTheme = (palette: Palette): Theme => {
  const theme = createMuiTheme({
    palette,
    typography: {
      ...createTypography(palette, AppTypography),
      htmlFontSize: 10
    },
    shape: {
      borderRadius: borderRadius
    },
    overrides: {
      MuiButton: {
        root: {
          padding: '6px 24px'
        },
        text: {
          padding: '8px 12px'
        },
        textSizeSmall: {
          padding: `0 12px`,
          marginLeft: -12,
          fontSize: 16
        },
        outlinedPrimary: {
          border: `2px solid ${palette.primary.main}`,
          height: 40,
          '&:hover': { border: `2px solid ${palette.primary.main}` }
        },
        contained: {
          backgroundColor: `${fade(palette.text.primary, 0.1)}`,
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: `${fade(palette.text.primary, 0.05)}`
          }
        },
        containedSizeSmall: {
          padding: `0 12px`,
          fontSize: 16
        }
      },
      MuiLinearProgress: {
        root: {
          height: 8
        },
        bar: {
          borderBottomRightRadius: 2,
          borderTopRightRadius: 2
        }
      },
      MuiOutlinedInput: {
        input: {
          padding: 14
        },
        multiline: {
          padding: 14,
          minHeight: 96,
          alignItems: 'flex-start'
        }
      },
      MuiIconButton: {
        root: {
          height: 40,
          width: 40,
          padding: 0,
          borderRadius: borderRadius,
          '& svg': {
            flexShrink: 0
          }
        },
        sizeSmall: {
          height: 32,
          width: 32
        }
      },
      MuiDrawer: {
        paper: {
          backgroundColor: palette.background.default,
          boxShadow: `6px 0 18px 0 rgba(0, 0, 0, 0.06)`
        },
        paperAnchorDockedLeft: {
          borderRight: 'none'
        }
      },
      MuiAutocomplete: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            padding: 4,
            paddingLeft: 8
          },
          '&.Mui-disabled > .MuiAutocomplete-endAdornment path': {
            fill: fade(palette.text.primary, 0.2)
          }
        },
        endAdornment: {
          top: `calc(50% - 16px)`
        },
        popupIndicator: {
          padding: 4,
          width: 32,
          height: 32,
          '& path': {
            fill: palette.text.primary
          }
        },
        clearIndicator: {
          marginRight: 4,
          width: 32,
          height: 32,
          '& svg': {
            width: 24,
            height: 24
          }
        }
      },
      MuiPopover: {
        paper: {
          boxShadow: `0 16px 32px 2px rgba(97, 97, 97, 0.1), 0 8px 16px 2px rgba(97, 97, 97, 0.1)`
        }
      },
      MuiDialogTitle: {
        root: {
          padding: `40px 64px 16px 64px`
        }
      },
      MuiDialogContent: {
        root: {
          padding: `16px 64px`
        }
      },
      MuiDialogActions: {
        root: {
          padding: `16px 64px 32px`
        }
      },
      MuiDialog: {
        container: {
          '& .MuiPaper-rounded': {
            borderRadius: 12
          }
        }
      },
      MuiTabs: {
        root: {
          height: 40,
          minHeight: 40,
          backgroundColor: `rgba(194, 207, 224, 0.4)`,
          borderRadius: 6
        },
        flexContainer: {
          height: 40,
          minHeight: 40
        },
        indicator: {
          height: 'auto',
          top: 0,
          bottom: 0,
          backgroundColor: 'transparent',
          display: 'flex',
          zIndex: 1,
          '& > div': {
            margin: 2,
            borderRadius: borderRadius,
            backgroundColor: palette.background.default,
            flex: 1
          }
        }
      },
      MuiTab: {
        root: {
          zIndex: 2,
          minHeight: 40,
          '&.Mui-selected .MuiTab-wrapper': {
            fontWeight: 'bold'
          }
        },
        wrapper: {
          ...AppTypography.subtitle2
        }
      },
      MuiFab: {
        root: {
          borderRadius: borderRadius,
          width: 40,
          height: 40,
          '& svg': {
            width: 32,
            height: 32
          }
        }
      },
      MuiSelect: {
        icon: {
          '& path': {
            fill: palette.text.primary
          },
          '&.Mui-disabled path': {
            fill: fade(palette.text.primary, 0.2)
          }
        },
        iconOutlined: {
          right: 11
        }
      },
      MuiCheckbox: {
        root: {
          width: 18,
          height: 18,
          padding: 0,
          '& svg': {
            width: 18,
            height: 18
          }
        }
      },
      MuiSvgIcon: {
        root: {
          width: 20,
          height: 20
        }
      },
      MuiSwitch: {
        root: {
          height: 48,
          width: 64
        },
        track: {
          borderRadius: 48
        },
        switchBase: {
          borderRadius: '50%',
          padding: 8,
          top: 6,
          left: 6,
          height: 36,
          width: 36,
          '&.Mui-checked': {
            left: 2
          }
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: Colors.White,
            '& + .MuiSwitch-track': {
              backgroundColor: palette.primary.main,
              opacity: 1
            }
          }
        }
      }
    } as Overrides,
    props: {
      MuiButton: {
        disableElevation: true
      },
      MuiIconButton: {
        centerRipple: false
      },
      MuiSelect: {
        IconComponent: IconArrowDownSvg
      },
      MuiCircularProgress: {
        thickness: 2.4
      },
      MuiPopover: {
        transitionDuration: 0
      }
    }
  })

  theme.typography = {
    ...theme.typography,
    h2: {
      ...theme.typography.h2,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.4rem'
      }
    }
  }
  theme.overrides = {
    ...theme.overrides,
    MuiTabs: {
      ...theme.overrides?.MuiTabs,
      root: {
        ...theme.overrides?.MuiTabs?.root,
        [theme.breakpoints.down('sm')]: {
          height: 32,
          minHeight: 32
        }
      },
      flexContainer: {
        ...theme.overrides?.MuiTabs?.flexContainer,
        height: 32,
        minHeight: 32
      }
    },
    MuiTab: {
      ...theme.overrides?.MuiTab,
      root: {
        ...theme.overrides?.MuiTab?.root,
        [theme.breakpoints.down('sm')]: {
          minHeight: 32
        }
      }
    },
    MuiDialogContent: {
      ...theme.overrides?.MuiDialogContent,
      root: {
        ...theme.overrides?.MuiDialogContent?.root,
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
        }
      }
    },
    MuiDialogTitle: {
      ...theme.overrides?.MuiDialogTitle,

      root: {
        ...theme.overrides?.MuiDialogTitle?.root,
        [theme.breakpoints.down('sm')]: {
          padding: `16px 32px 16px 32px`
        }
      }
    }
  }

  return theme
}

export default getAppTheme
