import { format } from 'date-fns'
import formatDuration from 'date-fns/formatDuration'
import isValid from 'date-fns/isValid'
import startOfToday from 'date-fns/startOfToday'
import { dateFnsLocales } from '../i18n/i18nConfig'
import AppService from '../services/AppService'

export const formatDate = (date: Date, formatStr = 'PP'): string => {
  return isValid(date)
    ? format(date, formatStr, {
        locale: dateFnsLocales[AppService.getDeviceCulture()]
      })
    : ''
}

export const formatDurationToString = (
  duration: Duration,
  options?: {
    format?: string[] | undefined
    zero?: boolean | undefined
    delimiter?: string | undefined
  }
): string => formatDuration(duration, { locale: dateFnsLocales[AppService.getDeviceCulture()], ...options })

export const getStartOfToday = () => startOfToday()
