import { CSSProperties } from '@material-ui/styles'

export function lineClamp(lines: number): CSSProperties {
  return {
    lineClamp: lines,
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box'
  }
}
