import { Typography } from '@material-ui/core'
import React from 'react'
import ContainerFullPage from './ContainerFullPage'

type ErrorMessageProps = {
  errorMessage?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }: ErrorMessageProps) => {
  return (
    <ContainerFullPage>
      <Typography variant="overline" color="error">
        {errorMessage}
      </Typography>
    </ContainerFullPage>
  )
}

export default ErrorMessage
