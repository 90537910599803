import { FormHelperText, ButtonBase, IconButton, Theme, Typography, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconFile, IconPublishSvg } from '../../assets/Svgs'
import Colors from '../../styles/Colors'
import { getHumanFileSize, isFileMimeTypeImage, isFileUrlExtensionImage } from '../../utils/CommonUtils'
import AlertDialog from '../dialogs/AlertDialog'
import Spacing from '../Spacing'

type InputUploadProps = {
  id?: string
  file?: File | string
  label?: string
  fullWidth?: boolean
  className?: string
  maxSizeMb?: number
  accept?: string
  loading?: boolean
  disabled?: boolean
  imageOnly?: boolean
  error?: boolean
  helperText?: string
  tooltipText?: string
  sizeBytes?: number
  onClick?: () => void
  onFileChange?: (id?: string, file?: File) => void
}

const InputUpload: React.FC<InputUploadProps> = ({
  id,
  file,
  label,
  fullWidth,
  className,
  maxSizeMb,
  accept,
  loading,
  disabled,
  imageOnly,
  error,
  helperText,
  tooltipText,
  sizeBytes,
  onClick,
  onFileChange
}: InputUploadProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState<boolean>(false)

  const handleCloseConfirmRemove = useCallback(() => {
    setConfirmRemoveOpen(false)
  }, [])

  const handleConfirmRemove = useCallback(() => {
    handleCloseConfirmRemove()
    if (onFileChange) onFileChange(id, undefined)
  }, [handleCloseConfirmRemove, onFileChange, id])

  const handleRemoveFile = useCallback(() => {
    setConfirmRemoveOpen(true)
  }, [])

  const handleFileSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files && event.target.files[0] ? event.target.files[0] : undefined

      const element = event.target as HTMLInputElement
      element.value = ''

      if (selectedFile && imageOnly && !isFileMimeTypeImage(selectedFile)) {
        enqueueSnackbar(t('unsupported_file_type'), { variant: 'error' })
        if (onFileChange) onFileChange(id, undefined)
        return
      }
      if (selectedFile && maxSizeMb !== undefined && selectedFile.size / 1024 / 1024 > maxSizeMb) {
        enqueueSnackbar(t(imageOnly ? 'maximum_picture_size' : 'maximum_file_size', { amount: maxSizeMb }), {
          variant: 'error'
        })
        if (onFileChange) onFileChange(id, undefined)
        return
      }

      if (onFileChange) onFileChange(id, selectedFile)
    },
    [imageOnly, maxSizeMb, onFileChange, id, enqueueSnackbar, t]
  )

  return (
    <React.Fragment>
      {file !== undefined && !loading ? (
        <React.Fragment>
          <div
            className={clsx(styles.container, styles.fileContainer, className, {
              [styles.fullWidth]: fullWidth,
              [styles.containerError]: error
            })}>
            <Tooltip title={tooltipText || ''}>
              <div className={clsx(styles.fileInnerContainer)}>
                <ButtonBase
                  className={clsx('inline', 'fullflex', styles.fileButton)}
                  disabled={!onClick}
                  onClick={onClick}>
                  {(typeof file !== 'string' && isFileMimeTypeImage(file)) ||
                  (typeof file === 'string' && isFileUrlExtensionImage(file)) ? (
                    <img
                      className={clsx(styles.preview, imageOnly && 'fullflex')}
                      src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                      alt={`preview-${id}`}
                    />
                  ) : (
                    <IconFile className={styles.fileIcon} />
                  )}
                  {!imageOnly && (
                    <React.Fragment>
                      <Spacing size={2} horizontal />
                      <div className="colstretch">
                        {typeof file !== 'string' && (
                          <React.Fragment>
                            <Typography variant="body2">{file.name}</Typography>
                            <Spacing size={1} />
                          </React.Fragment>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          {getHumanFileSize(typeof file !== 'string' ? file.size : sizeBytes || 0)}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                </ButtonBase>
              </div>
            </Tooltip>
            <IconButton
              className={clsx(styles.removeButton, { [styles.removeButtonDisabled]: disabled })}
              size="small"
              onClick={handleRemoveFile}
              disabled={disabled}>
              <CloseIcon />
            </IconButton>
          </div>
          <AlertDialog
            open={confirmRemoveOpen}
            onClose={handleCloseConfirmRemove}
            title={t('confirm_remove_file')}
            confirmButtonText={t('remove')}
            onConfirm={handleConfirmRemove}
          />
        </React.Fragment>
      ) : (
        <ButtonBase
          className={clsx(styles.container, className, {
            [styles.fullWidth]: fullWidth,
            [styles.containerError]: error
          })}>
          <input
            id={`file-input-${id}`}
            type="file"
            accept={accept || 'image/*'}
            onChange={handleFileSelected}
            className={styles.input}
            disabled={loading || disabled}
          />
          <label htmlFor={`file-input-${id}`} className={clsx(styles.label, { [styles.labelDisabled]: disabled })}>
            {loading ? (
              <Skeleton variant="rect" className={styles.loader} />
            ) : (
              <div className={clsx('inline', styles.labelButton)}>
                <IconPublishSvg className={styles.labelButtonIcon} />
                <Typography variant="button" color="primary" component="div">
                  {label || t('upload_picture')}
                </Typography>
              </div>
            )}
          </label>
        </ButtonBase>
      )}
      {helperText && (
        <React.Fragment>
          <Spacing size={0.5} />
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      borderRadius: theme.shape.borderRadius,
      border: `1px dashed ${theme.palette.grey[500]}`,
      position: 'relative'
    },
    containerError: {
      borderColor: theme.palette.error.main
    },
    fullWidth: {
      width: '100%'
    },
    input: {
      display: 'none'
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      flex: 1,
      padding: 16
    },
    labelDisabled: {
      cursor: 'default'
    },
    labelButton: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    labelButtonIcon: {
      marginRight: theme.spacing(1),
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    fileContainer: {
      borderStyle: 'solid',
      borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center'
    },
    fileInnerContainer: {
      flex: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    preview: {
      maxHeight: '100%',
      objectFit: 'contain',
      flex: 0
    },
    fileButton: {
      height: '100%'
    },
    removeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate(50%, -50%)',
      width: 32,
      height: 32,
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short
      }),
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey.A100
      },
      '& svg': {
        width: 20,
        height: 20,
        '& path': {
          fill: Colors.Black
        }
      }
    },
    removeButtonDisabled: {
      backgroundColor: `${theme.palette.grey[300]} !important`,
      opacity: 0.6
    },
    loader: {
      width: '100%',
      height: '100%'
    },
    fileIcon: {
      height: 48,
      width: 48,
      flexShrink: 0,
      '& path': {
        fill: theme.palette.text.primary
      }
    }
  })
)

export default InputUpload
