import {
  AppBar,
  ButtonBase,
  darken,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useHistory, useLocation } from 'react-router'
import {
  IconCalendar,
  IconDashboard,
  IconDocuments,
  IconFolders,
  IconGuests,
  IconMenu,
  IconNotifications,
  IconPermissions,
  IconPlaces,
  IconSettings,
  IconStatistics,
  IconSubscriptions
} from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { RoutesPaths } from '../../Routes'
import AppService from '../../services/AppService'
import Colors from '../../styles/Colors'
import { getUserRoleLabel, userHasAnActiveProduct } from '../../utils/ProductsUtils'
import AppLogo from '../AppLogo'
import BackdropOverlay from '../BackdropOverlay'
import Spacing from '../Spacing'
import UserMenu from '../UserMenu'

const DrawerWidth = 272

type DrawerListItem = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  pathDefinition: string
  text?: string
  Component?: React.ReactNode
  subPaths?: string[]
  isHomePath?: boolean
  isComingSoon?: boolean
}

type NavigationDrawerContainerProps = {
  smDownHidden?: boolean
  showBackdrop?: boolean
}

const NavigationDrawerContainer: React.FC<PropsWithChildren<NavigationDrawerContainerProps>> = ({
  children,
  smDownHidden,
  showBackdrop
}: PropsWithChildren<NavigationDrawerContainerProps>) => {
  const styles = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const { user } = useUser()
  const { t } = useTranslation()

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleWhatsNewSelected = useCallback(() => {
    history.push(RoutesPaths.WhatsNew)
  }, [history])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const menuItems: DrawerListItem[] = [
    {
      text: t('places'),
      icon: IconPlaces,
      pathDefinition: RoutesPaths.Places,
      subPaths: [RoutesPaths.PlaceDetail],
      isHomePath: true
    },
    {
      text: t('guests'),
      icon: IconGuests,
      pathDefinition: RoutesPaths.Guests
    },
    {
      text: t('statistics'),
      icon: IconStatistics,
      pathDefinition: RoutesPaths.Statistics
    },
    {
      text: t('transactions_categories_label'),
      icon: IconFolders,
      pathDefinition: RoutesPaths.TransactionsCategories
    },
    {
      text: t('documents'),
      icon: IconDocuments,
      pathDefinition: RoutesPaths.Documents
    },
    {
      text: t('calendar'),
      icon: IconCalendar,
      pathDefinition: RoutesPaths.Calendar
    },
    {
      text: t('settings'),
      icon: IconSettings,
      pathDefinition: RoutesPaths.Settings
    },
    {
      text: t('dashboard'),
      icon: IconDashboard,
      pathDefinition: RoutesPaths.Dashboard,
      isComingSoon: true
    },
    {
      text: t('notifications'),
      icon: IconNotifications,
      pathDefinition: RoutesPaths.Notifications,
      isComingSoon: true
    },
    {
      text: t('permissions'),
      icon: IconPermissions,
      pathDefinition: RoutesPaths.Permissions,
      isComingSoon: true
    },
    {
      icon: IconSubscriptions,
      pathDefinition: RoutesPaths.Subscriptions,
      Component: (
        <div className="inline">
          <div className="colstretch">
            <Typography variant="subtitle2" className={styles.listItemText}>
              {t('subscriptions')}
            </Typography>
            {userHasAnActiveProduct(user?.stripeRole) ? (
              <React.Fragment>
                <Spacing size={0.5} />
                <Typography variant="overline" className={styles.highlightText}>
                  {getUserRoleLabel(user?.stripeRole)}
                </Typography>
              </React.Fragment>
            ) : user?.isPremiumApp ? (
              <React.Fragment>
                <Spacing size={0.5} />
                <Typography variant="overline" className={styles.highlightText}>
                  {t('premium_discount_hint')}
                </Typography>
              </React.Fragment>
            ) : null}
          </div>

          {/* Temporarily hide because upgrade is not available yet */}
          {/* user?.stripeRole === 'professional' && (
            <Typography variant="caption" component="div" className={styles.upgradeTag}>
              {t('upgrade')}
            </Typography>
          ) */}
        </div>
      )
    }
  ]

  const drawer = (
    <div className={styles.drawerContent}>
      <div>
        <div className={styles.drawerHeader}>
          <AppLogo />
        </div>
        <List>
          {menuItems.map(item => {
            const isSelected =
              matchPath(location.pathname, { path: item.pathDefinition }) ||
              (item.subPaths && item.subPaths.some(subPath => matchPath(location.pathname, { path: subPath }))) ||
              (item.isHomePath && matchPath(location.pathname, { path: '/', exact: true }))
            return (
              <div
                className={clsx(styles.listItemContainer, { [styles.listItemContainerSelected]: isSelected })}
                key={item.pathDefinition}>
                {isSelected && (
                  <React.Fragment>
                    <div className={styles.listItemIndicator} />
                    <div className={styles.listItemGradient} />
                  </React.Fragment>
                )}
                <ListItem
                  button
                  className={styles.listItem}
                  classes={{ disabled: styles.listItemDisabled }}
                  onClick={() => history.push(item.pathDefinition)}>
                  <ListItemIcon className={styles.listItemIcon}>
                    <item.icon />
                  </ListItemIcon>
                  {item.Component !== undefined ? item.Component : null}
                  {item.text !== undefined && (
                    <Typography variant="subtitle2" className={styles.listItemText}>
                      {item.text}
                    </Typography>
                  )}
                  {item.isComingSoon && (
                    <Typography variant="overline" className={styles.listItemComingSoon}>
                      {t('coming_soon')}
                    </Typography>
                  )}
                </ListItem>
              </div>
            )
          })}
        </List>
      </div>

      <div className={styles.drawerBottomActionsContainer}>
        <div className="colstretch align-center">
          <ButtonBase onClick={handleWhatsNewSelected} className={clsx('inline', styles.whatsNewButton)}>
            <div className={styles.indicator} />
            <Spacing horizontal size={0.5} />
            <Typography variant="overline" component="div" align="center" className={styles.textPrimary}>
              {t('news')}
            </Typography>
          </ButtonBase>
        </div>
        <Spacing size={1.5} />
        <Typography variant="caption" color="textSecondary" align="center">{`${t('version')} ${
          AppService.version
        }`}</Typography>
        <Spacing size={1} />
        <UserMenu />
      </div>
    </div>
  )

  return (
    <div className={clsx(styles.root, { [styles.rootSm]: isSmDown })}>
      {smDownHidden && isSmDown ? null : (
        <React.Fragment>
          <Hidden mdUp implementation="css">
            <React.Fragment>
              <AppBar position="fixed" color="default">
                <Toolbar className={styles.appBarToolbar}>
                  <div className={styles.appBarToolbarLogo}>
                    <AppLogo />
                  </div>

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={styles.menuIconButton}>
                    <IconMenu className={styles.menuIcon} />
                  </IconButton>

                  <UserMenu iconOnly />
                </Toolbar>
              </AppBar>

              <div className={styles.toolbar} />
            </React.Fragment>
          </Hidden>

          <nav className={styles.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: styles.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}>
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: styles.drawerPaper
                }}
                variant="permanent"
                open>
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </React.Fragment>
      )}

      <div className={clsx('colstretch relative fullflex', styles.contentWrapper)}>
        {showBackdrop && <BackdropOverlay />}
        <div
          className={clsx(styles.contentContainer, {
            [styles.contentContainerSmDownHidden]: smDownHidden && isSmDown
          })}>
          {children}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      overflow: 'hidden',
      flex: 1
    },
    rootSm: {
      flexDirection: 'column'
    },
    contentWrapper: {
      overflowY: 'auto'
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: theme.palette.background.paper,
      padding: `${theme.spacing(2)}px ${theme.spacing(4.5)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
      }
    },
    contentContainerSmDownHidden: {
      paddingTop: 0
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
      width: DrawerWidth,
      flexShrink: 0
    },
    drawerContent: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-between'
    },
    drawerPaper: {
      width: DrawerWidth
    },
    drawerHeader: {
      ...theme.mixins.toolbar,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(3)
    },
    drawerBottomActionsContainer: {
      padding: theme.spacing(1.5),
      marginTop: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    listItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      position: 'relative'
    },
    listItem: {
      padding: `${theme.spacing(1.75)}px ${theme.spacing(3)}px`
    },
    listItemIndicator: {
      width: theme.spacing(0.5),
      flexShrink: 0,
      backgroundColor: theme.palette.primary.main
    },
    listItemIcon: {
      minWidth: 34,
      '& svg': {
        width: 20,
        height: 20,
        '& path': {
          fill: theme.palette.grey[200]
        }
      }
    },
    listItemText: {
      color: theme.palette.secondary.main
    },
    listItemGradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '60%',
      backgroundImage: `linear-gradient(to right, rgba(68, 148, 255, 0.2), rgba(255, 255, 255, 0))`
    },
    listItemContainerSelected: {
      '& $listItem': {
        paddingLeft: theme.spacing(2.5)
      },
      '& $listItemText': {
        color: theme.palette.primary.main
      },
      '& $listItemIcon svg path': {
        fill: theme.palette.primary.main
      }
    },
    listItemComingSoon: {
      fontSize: 9,
      fontWeight: 700,
      borderRadius: 10,
      height: 20,
      backgroundColor: theme.palette.type === 'dark' ? darken(Colors.IconGray, 0.6) : Colors.IconGray,
      padding: `0 8px`,
      marginLeft: 'auto',
      textTransform: 'uppercase',
      color: Colors.White,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    listItemDisabled: {
      opacity: `1 !important`,
      '& > div, > h6': {
        opacity: 0.8
      }
    },
    appBarToolbar: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    appBarToolbarLogo: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    menuIcon: {
      width: 32,
      height: 32,
      flexShrink: 0,
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    menuIconButton: {
      height: 56,
      width: 56
    },
    activeSubscriptionIcon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(0.25),
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    highlightText: {
      color: theme.palette.primary.main
    },
    upgradeTag: {
      marginLeft: theme.spacing(1.5),
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderRadius: theme.shape.borderRadius
    },
    whatsNewButton: {
      borderRadius: theme.shape.borderRadius,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    textPrimary: {
      color: theme.palette.primary.main
    },
    indicator: {
      width: 4,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
)

export default NavigationDrawerContainer
