import { Button, createStyles, fade, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppLogoSvgItaLarge,
  ImageHomeHeroShapeLeft,
  ImageHomeHeroShapeRight,
  ImagePreviewHeroIta
} from '../assets/Svgs'
import AppLoader from '../components/AppLoader'
import AppLogo from '../components/AppLogo'
import Auth from '../components/auth/Auth'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import { PrivacyPolicyUrl, SupportEmail, TermsAndConditionsUrl } from '../utils/CommonUtils'
import Places from './Places'

const Home: React.FC = () => {
  const styles = useStyles()
  const { user, loadingUser } = useUser()
  const { t } = useTranslation()

  return loadingUser ? (
    <div className={styles.loaderContainer}>
      <AppLoader size={48} />
    </div>
  ) : user ? (
    <Places />
  ) : (
    <div className={styles.root}>
      <div className={styles.heroBackground} />

      <ImageHomeHeroShapeLeft className={clsx(styles.heroShape, styles.heroShapeLeft)} />
      <ImageHomeHeroShapeRight className={clsx(styles.heroShape, styles.heroShapeRight)} />

      <div className={clsx(styles.content, 'colstretch fullflex')}>
        <div className={styles.header}>
          <AppLogo />
        </div>

        <Grid container>
          <Grid item xs={12}>
            <div className={clsx(styles.heroContainer, 'colstretch')}>
              <AppLogoSvgItaLarge className={styles.heroTextTitle} />
              <Spacing size={3} />
              <Typography
                variant="h3"
                component="h1"
                className={styles.heroTextSubtitle}
                dangerouslySetInnerHTML={{ __html: t('hero_text') }}
              />
            </div>
          </Grid>
        </Grid>

        <Spacing size={6} />

        <div className={styles.authContainer}>
          <Auth />
        </div>

        <ImagePreviewHeroIta className={styles.previewHero} />

        <div className={styles.footer}>
          <AppLogo className={styles.footerLogo} />

          <div className={styles.footerLinksContainer}>
            <Button
              variant="text"
              href={TermsAndConditionsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footerLink}>
              {t('terms_and_conditions')}
            </Button>
            <Button
              variant="text"
              href={PrivacyPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footerLink}>
              {t('privacy_policy')}
            </Button>
          </div>

          <Button
            variant="outlined"
            color="primary"
            href={`mailto:${SupportEmail}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.footerButtonSingle}>
            {t('contact_us')}
          </Button>

          <Typography variant="caption" color="textSecondary" align="center" className={styles.version}>{`${t(
            'version'
          )} ${AppService.version}`}</Typography>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    root: {
      position: 'relative',
      overflowY: 'auto',
      flex: 1,
      backgroundColor: Colors.White
    },
    heroBackground: {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.22) 7.62%, rgba(68, 148, 255, 0.4) 25.5%, #FFFFFF 70.92%)`,
      height: '1000px'
    },
    heroShape: {
      zIndex: 3,
      position: 'absolute',
      top: 0,
      width: '50%'
    },
    heroShapeLeft: {
      left: 0,
      transform: 'translate(0, 20%)'
    },
    heroShapeRight: {
      right: 0,
      transform: 'translate(0, -10%)'
    },
    previewHero: {
      width: '100%',
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(8)}px`,
      [theme.breakpoints.down('sm')]: {
        height: 350,
        margin: `0`
      }
    },
    heroContainer: {
      alignItems: 'center',
      padding: `0 ${theme.spacing(4)}px`
    },
    heroTextTitle: {
      width: '100%'
    },
    heroTextSubtitle: {
      fontWeight: 'normal',
      textAlign: 'center',
      color: '#323C47',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20
      }
    },
    content: {
      zIndex: 10,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    header: {
      padding: `${theme.spacing(9)}px ${theme.spacing(8)}px`,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(6)}px ${theme.spacing(0)}px`,
        justifyContent: 'center'
      }
    },
    authContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        '& .firebaseui-idp-button': {
          boxShadow: 'none',
          borderRadius: theme.shape.borderRadius,
          position: 'relative',
          transition: 'transform ease 0.2s',
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            transition: 'background-color ease 0.2s'
          },
          '&:hover': {
            transform: 'scale(1.02)',
            '&::after': {
              backgroundColor: fade(Colors.White, 0.2)
            }
          }
        },
        '& .firebaseui-container': {
          maxWidth: 'unset'
        },
        '& .firebaseui-idp-list': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        },
        '& .firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item': {
          marginBottom: 0,
          '&:not(:last-child)': {
            marginRight: theme.spacing(3)
          }
        }
      }
    },
    footer: {
      width: '100%',
      display: 'grid',
      gridRowGap: 16,
      gridColumnGap: 16,
      gridAutoColumns: '1fr',
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: '1fr 2fr 1fr',
      padding: `${theme.spacing(8)}px ${theme.spacing(8)}px ${theme.spacing(6)}px`,
      backgroundColor: '#EAEEF4',
      alignItems: 'center',
      justifyItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(6)}px`,
        gridRowGap: theme.spacing(4),
        justifyItems: 'center',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto'
      }
    },
    footerLinksContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    footerLink: {
      ...theme.typography.overline,
      color: '#323C47',
      '&:visited': {
        color: '#323C47 !important'
      }
    },
    footerLogo: {
      [theme.breakpoints.up('md')]: {
        justifySelf: 'start'
      }
    },
    footerButtonSingle: {
      '&:visited': {
        color: `${theme.palette.primary.main} !important`
      },
      [theme.breakpoints.up('md')]: {
        justifySelf: 'end'
      }
    },
    version: {
      [theme.breakpoints.up('md')]: {
        gridColumnStart: 2
      }
    }
  })
)

export default Home
