import { Divider, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

type StatisticsContainerProps = {
  title: string | React.ReactNode
  TitleIcon?: React.ReactNode
  subtitle?: string
  RightHeaderComponent?: React.ReactNode
  rounded?: boolean
  Component?: React.ReactNode
  showDivider?: boolean
  classes?: { component?: string }
}

const StatisticsContainer: React.FC<StatisticsContainerProps> = ({
  title,
  TitleIcon,
  subtitle,
  RightHeaderComponent,
  rounded,
  Component,
  showDivider = true,
  classes
}: StatisticsContainerProps) => {
  const styles = useStyles()

  return (
    <div className={clsx(styles.root, { [styles.rootRounded]: rounded })}>
      <div className={styles.header}>
        <div className="inline">
          {TitleIcon}
          {typeof title === 'string' ? (
            <Typography variant="subtitle1" component="div">
              {title}
            </Typography>
          ) : (
            title
          )}
        </div>
        {subtitle ? (
          <Typography color="textSecondary" variant="body2" align="right" className="spacing-left-xs">
            {subtitle}
          </Typography>
        ) : (
          RightHeaderComponent
        )}
      </div>
      {showDivider && <Divider />}
      <div className={clsx(styles.content, classes?.component, { [styles.contentRounded]: rounded })}>{Component}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'hidden',
      '& svg.recharts-surface': {
        overflow: 'visible'
      }
    },
    rootRounded: {
      borderRadius: theme.shape.borderRadius * 2
    },
    header: {
      height: 64,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    content: {
      padding: theme.spacing(2),
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'relative'
    },
    contentRounded: {
      borderRadius: theme.shape.borderRadius * 2
    }
  })
)

export default StatisticsContainer
