import AppService from '../services/AppService'

export const DefaultCurrency = 'eur'

export const formatCurrency = (amount: number, currency = DefaultCurrency) => {
  let formattedCurrency = ''

  try {
    formattedCurrency = new Intl.NumberFormat(AppService.getDeviceCulture(), {
      style: 'currency',
      currency: currency
    }).format(amount)
  } catch (err) {}

  return formattedCurrency
}

export const getCurrencySymbol = (currency = DefaultCurrency): string => {
  let currencySymbol = ''

  try {
    currencySymbol = new Intl.NumberFormat(AppService.getDeviceCulture(), {
      style: 'currency',
      currency: currency,
      maximumSignificantDigits: 1
    })
      .format(0)
      .replace(/0/g, '')
      .trim()
  } catch (err) {}

  return currencySymbol
}
