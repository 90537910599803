import { useMediaQuery, useTheme } from '@material-ui/core'
import { endOfMonth } from 'date-fns'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Colors from '../../styles/Colors'
import Transaction from '../../types/Transaction'
import { formatDate } from '../../utils/DateUtils'
import {
  getRentsTransactionsSummaryPeriodDateRanges,
  getRentsTransactionsSummaryPeriodDates,
  getTransactionsAmountForPeriod,
  getTransactionsByPaymentStatus
} from '../../utils/RentUtils'
import ChartLegend from '../charts/ChartLegend'
import ChartTooltip from '../charts/ChartTooltip'
import Spacing from '../Spacing'

type StatisticsPlacesChartProps = {
  transactions: Transaction[]
  monthsRange: number
  canView: boolean
}

const StatisticsPlacesChart: React.FC<StatisticsPlacesChartProps> = ({
  transactions,
  monthsRange,
  canView
}: StatisticsPlacesChartProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const periodDates = useRef(getRentsTransactionsSummaryPeriodDates(monthsRange))

  const { startDate, endDate } = getRentsTransactionsSummaryPeriodDateRanges(periodDates.current)

  const { paidTransactions, delayedTransactions, unpaidTransactions } = getTransactionsByPaymentStatus(transactions)

  const totalPaid = getTransactionsAmountForPeriod(startDate, endDate, paidTransactions)
  const totalDelayed = getTransactionsAmountForPeriod(startDate, endDate, delayedTransactions)
  const totalUnpaid = getTransactionsAmountForPeriod(startDate, endDate, unpaidTransactions)

  const data: any[] = periodDates.current.map(p => ({
    name: p,
    date: p,
    paid: canView ? getTransactionsAmountForPeriod(p, endOfMonth(p), paidTransactions) : 0,
    delayed: canView ? getTransactionsAmountForPeriod(p, endOfMonth(p), delayedTransactions) : 0,
    unpaid: canView ? getTransactionsAmountForPeriod(p, endOfMonth(p), unpaidTransactions) : 0
  }))

  return (
    <div className="colstretch fullflex">
      <ChartLegend
        items={[
          {
            color: Colors.Green,
            label: t('paid'),
            value: totalPaid,
            isCurrency: true
          },
          {
            color: Colors.Orange,
            label: t('delayed'),
            value: totalDelayed,
            isCurrency: true
          },
          {
            color: Colors.Red,
            label: t('unpaid_male'),
            value: totalUnpaid,
            isCurrency: true
          }
        ]}
      />

      <Spacing size={4} />

      <ResponsiveContainer width="100%" height={isSmDown ? 240 : 320}>
        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorPaid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Colors.Green} stopOpacity={0.2} />
              <stop offset="90%" stopColor={Colors.Green} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorUnpaid" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Colors.Red} stopOpacity={0.2} />
              <stop offset="90%" stopColor={Colors.Red} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorDelayed" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Colors.Orange} stopOpacity={0.2} />
              <stop offset="90%" stopColor={Colors.Orange} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            interval={1}
            tickFormatter={value => formatDate(value, 'MMM')}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip content={<ChartTooltip />} />
          <Area
            type="monotone"
            dataKey="paid"
            strokeWidth={2}
            stroke={Colors.Green}
            fillOpacity={1}
            fill="url(#colorPaid)"
          />
          <Area
            type="monotone"
            dataKey="unpaid"
            strokeWidth={2}
            stroke={Colors.Red}
            fillOpacity={1}
            fill="url(#colorUnpaid)"
          />
          <Area
            type="monotone"
            dataKey="delayed"
            strokeWidth={2}
            stroke={Colors.Orange}
            fillOpacity={1}
            fill="url(#colorDelayed)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StatisticsPlacesChart
