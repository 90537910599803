import {
  FormHelperText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { differenceInCalendarDays } from 'date-fns'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { IconClose, IconDocuments, IconExpenses, IconRent } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { RoutesPaths } from '../../Routes'
import AppService from '../../services/AppService'
import Colors from '../../styles/Colors'
import EditTransactionRecurrence from '../../types/EditTransactionRecurrence'
import Transaction from '../../types/Transaction'
import TransactionCategory from '../../types/TransactionCategory'
import { getId } from '../../utils/CommonUtils'
import { getCurrencySymbol } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import { getDecimalSeparator } from '../../utils/TextUtils'
import { getTransactionRecurrenceAmount, getTransactionRecurrenceMinDate } from '../../utils/TransactionsUtils'
import BoxContainer from '../BoxContainer'
import ColorIcon from '../ColorIcon'
import AlertDialog from '../dialogs/AlertDialog'
import InputMultipleSelectAdd from '../InputMultipleSelectAdd'
import InputColor from '../inputs/InputColor'
import InputDate from '../inputs/InputDate'
import InputNumber from '../inputs/InputNumber'
import InputText from '../inputs/InputText'
import Spacing from '../Spacing'
import StepsActions from '../steps/StepsActions'
import TransactionCategoryColor from '../transactions/TransactionCategoryColor'

type TransactionCategoryValues = {
  label: string
  color: string | null
}

export type EditTransactionValues = {
  description: string
  isRent: boolean
  dueDate: Date | null
  amount: number | null
  paid: boolean
  categories: TransactionCategory[]
  paidOn?: Date | null
  recurrence?: EditTransactionRecurrence
  repeatUntil?: Date | null
}

type EditTransactionDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (
    values: Transaction & {
      recurrence?: EditTransactionRecurrence
      repeatUntil?: Date | null
    },
    id?: string
  ) => Promise<boolean>
  onDelete: (id: string) => Promise<boolean>
  title?: string
  TitleIcon?: React.ReactNode
  transaction?: Transaction
  descriptionPlaceholder?: string
  placeId?: string
  isRent?: boolean
  editDisabled?: boolean
  deleteDisabled?: boolean
  loading?: boolean
  isBooking?: boolean
}

const EditTransactionDialog: React.FC<EditTransactionDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onDelete,
  transaction,
  placeId,
  isRent,
  editDisabled,
  deleteDisabled,
  loading,
  isBooking
}: EditTransactionDialogProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const history = useHistory()
  const { role } = AppService

  const defaultInitialValues = useRef<EditTransactionValues>({
    description: '',
    isRent: Boolean(isRent),
    dueDate: null,
    amount: null,
    paid: false,
    paidOn: null,
    categories: [],
    recurrence: 'none',
    repeatUntil: null
  })
  const [initialValues, setInitialValues] = useState<EditTransactionValues>(defaultInitialValues.current)
  const [loadingTransactionsCategories, setLoadingTransactionsCategories] = useState<boolean>(true)
  const [transactionsCategories, setTransactionsCategories] = useState<TransactionCategory[]>([])
  const [transactionCategoryValues, setTransactionCategoryValues] = useState<TransactionCategoryValues>({
    label: '',
    color: null
  })
  const [transactionCategoryTouched, setTransactionCategoryTouched] = useState<Record<string, boolean>>({
    label: false
  })
  const [recurrenceConfirmation, setRecurrenceConfirmation] = useState<{ amount: number } | undefined>(undefined)

  const formRef = useRef<FormikProps<EditTransactionValues> | null>(null)

  const validationSchema = useRef(
    Yup.object({
      description: Yup.string().required(t('required_field')),
      amount: Yup.number().nullable().required(t('required_field')),
      paid: Yup.boolean().required(t('required_field')),
      paidOn: Yup.date()
        .nullable()
        .when('paid', {
          is: (paid: boolean) => paid === true,
          then: Yup.date().typeError(t('required_field')).required(t('required_field'))
        }),
      dueDate: Yup.date().nullable().required(t('required_field')).typeError(t('invalid_date')),
      repeatUntil: Yup.date()
        .nullable()
        .when('recurrence', {
          is: (recurrence: EditTransactionRecurrence) => recurrence && recurrence !== 'none',
          then: Yup.date().typeError(t('required_field')).required(t('required_field'))
        })
        .test({
          name: 'min',
          exclusive: false,
          params: {},
          message: t('unrecognized_value'),
          test: function (value: Date | null | undefined) {
            return (
              !this.parent.recurrence ||
              this.parent.recurrence === 'none' ||
              (value &&
                this.parent.recurrence &&
                this.parent.recurrence !== 'none' &&
                differenceInCalendarDays(
                  value,
                  getTransactionRecurrenceMinDate(this.parent.recurrence, this.parent.dueDate)
                ) >= 0)
            )
          }
        })
    })
  )

  const handleDocumentsSelected = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!transaction) return

      event.stopPropagation()
      event.preventDefault()

      history.push(RoutesPaths.TransactionDocumentsList.replace(':transactionId', transaction.id), {
        isModal: true,
        title: t('transaction_documents')
      })
    },
    [transaction, history, t]
  )

  const handleDeleteSelected = useCallback(async () => {
    if (!transaction) return

    if (deleteDisabled) {
      enqueueSnackbar(t('delete_last_rent_transaction'), { variant: 'warning' })
      return
    }

    const deleteSuccess = await onDelete(transaction.id)

    if (deleteSuccess) onClose()
  }, [onDelete, onClose, transaction, enqueueSnackbar, t, deleteDisabled])

  const handleSubmit = useCallback(
    async (values: EditTransactionValues, { setSubmitting }, skipConfirmation?: boolean) => {
      if (
        !skipConfirmation &&
        values.dueDate &&
        values.recurrence &&
        values.recurrence !== 'none' &&
        values.repeatUntil
      ) {
        const transactionsRecurrenceAmount = getTransactionRecurrenceAmount(
          values.recurrence,
          values.dueDate,
          values.repeatUntil
        )

        if (transactionsRecurrenceAmount > 1) {
          setRecurrenceConfirmation({
            amount: transactionsRecurrenceAmount
          })
          return true
        }
      }

      const success = await onSubmit(
        {
          ...values,
          id: transaction?.id || getId(),
          amount: values.amount || 0,
          dueDate: values.dueDate || new Date(),
          paidOn: values.paid ? values.paidOn || new Date() : undefined,
          created: transaction?.created || new Date(),
          isRent: Boolean(isRent),
          isBooking: Boolean(isBooking),
          ...(isRent ? undefined : { recurrence: values.recurrence, repeatUntil: values.repeatUntil })
        },
        transaction?.id
      )

      if (success) {
        onClose()
      } else {
        enqueueSnackbar(t('error'), { variant: 'error' })
      }

      setSubmitting(false)
    },
    [onSubmit, transaction?.id, transaction?.created, isRent, isBooking, onClose, enqueueSnackbar, t]
  )

  const handleRecurrenceConfirmationClose = useCallback(() => {
    setRecurrenceConfirmation(undefined)
  }, [])

  const handleRecurrenceConfirmSelected = useCallback(() => {
    if (formRef.current) {
      handleSubmit(formRef.current.values, { setSubmitting: formRef.current.setSubmitting }, true)
    }

    handleRecurrenceConfirmationClose()
  }, [handleRecurrenceConfirmationClose, handleSubmit])

  const handleDueDateChange = useCallback(date => {
    formRef.current?.setFieldValue('dueDate', date)
  }, [])

  const updateInitialValues = useCallback((editTransaction?: Transaction) => {
    setInitialValues(
      editTransaction
        ? {
            description: editTransaction.description,
            isRent: editTransaction.isRent,
            dueDate: editTransaction.dueDate,
            amount: editTransaction.amount,
            paid: editTransaction.paid,
            paidOn: editTransaction.paidOn,
            categories: editTransaction.categories,
            recurrence: 'none'
          }
        : defaultInitialValues.current
    )
  }, [])

  const closeAndResetAddTransactionCategory = useCallback(() => {
    setTransactionCategoryValues({
      label: '',
      color: null
    })
    setTransactionCategoryTouched({
      label: false
    })
  }, [])

  const handleRecurrenceChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const newRecurrence = event.target.value as EditTransactionRecurrence
      formRef.current?.setFieldValue('recurrence', newRecurrence)
      formRef.current?.setFieldValue('repeatUntil', null)
    },
    []
  )

  const handleTransactionCategoryValueChange = useCallback((id: string, value: string | null) => {
    setTransactionCategoryValues(current => ({
      ...current,
      [id]: value
    }))
  }, [])

  const handleTransactionCategoryValueBlur = useCallback((id: string) => {
    setTransactionCategoryTouched(current => ({
      ...current,
      [id]: true
    }))
  }, [])

  const handleTransactionsCategoriesChange = useCallback((items: TransactionCategory[]) => {
    formRef.current?.setFieldValue('categories', items)
  }, [])

  const handleTransactionCategoryAddSubmit = useCallback(async () => {
    setTransactionCategoryTouched(current => Object.keys(current).reduce((obj, k) => ({ ...obj, [k]: true }), current))

    if (!(transactionCategoryValues.label && user)) return false

    let hasErrors = false
    let id: string | undefined
    const data = {
      label: transactionCategoryValues.label,
      color: transactionCategoryValues.color
    }
    try {
      id = await AppService.db.createTransactionCategory(data, user.id)
    } catch (err) {
      hasErrors = true
    }
    if (!hasErrors && id) {
      const newItem: TransactionCategory = {
        ...data,
        id: id,
        created: new Date()
      }
      setTransactionsCategories(current => current.concat(id ? [newItem] : []))
      closeAndResetAddTransactionCategory()
      formRef.current?.setFieldValue('categories', (formRef.current?.values.categories || []).concat([newItem]))
    } else {
    }

    if (hasErrors) {
      enqueueSnackbar(t('error'), { variant: 'error' })
    }

    return !hasErrors
  }, [
    closeAndResetAddTransactionCategory,
    enqueueSnackbar,
    t,
    user,
    transactionCategoryValues.label,
    transactionCategoryValues.color
  ])

  useEffect(() => {
    updateInitialValues(transaction)
  }, [transaction, updateInitialValues])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoadingTransactionsCategories(true)

      if (user) {
        const dbTransactionsCategories = await AppService.db.getTransactionCategories(user.id)
        if (!didCancel) {
          setTransactionsCategories(dbTransactionsCategories)
        }
      }

      if (!didCancel) setLoadingTransactionsCategories(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [user])

  return (
    <Dialog
      open={open}
      aria-labelledby="add-expense-dialog-title"
      aria-describedby="add-expense-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'sm'}
      onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.current}
        onSubmit={handleSubmit}
        innerRef={r => (formRef.current = r)}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <React.Fragment>
                <DialogTitle disableTypography id="alert-dialog-title">
                  <div className={clsx(styles.title, 'inline', 'justify-between')}>
                    <div className="inline">
                      <ColorIcon
                        Icon={Boolean(placeId) ? <IconExpenses /> : <IconRent />}
                        color={Boolean(placeId) ? Colors.Red : Colors.Green}
                        withBackground
                        className={styles.spacingRight}
                      />
                      <Typography variant="h3" component="div">
                        {t(
                          `${isRent ? 'transaction' : Boolean(placeId) ? 'expense' : 'extra'}_${
                            Boolean(transaction) ? 'edit' : 'new'
                          }`
                        )}
                      </Typography>
                    </div>

                    <IconButton size="small" className={styles.closeButton} onClick={onClose}>
                      <IconClose />
                    </IconButton>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <InputText
                    fullWidth
                    name="description"
                    label={t('description')}
                    placeholder={t(
                      Boolean(placeId)
                        ? 'add_expense_description_placeholder'
                        : 'add_transaction_description_placeholder'
                    )}
                    disabled={isSubmitting}
                    loading={loading}
                    readOnly={editDisabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    error={Boolean(errors.description) && touched.description}
                    helperText={errors.description && touched.description ? errors.description : ' '}
                  />

                  <div className={styles.block}>
                    <InputDate
                      fullWidth
                      name="dueDate"
                      label={t('due_date')}
                      value={values.dueDate || null}
                      placeholder={`${formatDate(new Date(), 'dd/MM/yyyy')}*`}
                      onChange={handleDueDateChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.dueDate) && Boolean(touched.dueDate)}
                      helperText={errors.dueDate && touched.dueDate ? errors.dueDate : ' '}
                      classes={{ root: 'fullflex' }}
                      inputVariant="outlined"
                      disabled={isSubmitting}
                      loading={loading}
                      readOnly={editDisabled}
                      format="dd/MM/yyyy"
                      inputProps={{
                        autoComplete: 'off',
                        ['data-lpignore']: 'true'
                      }}
                    />

                    <div className={styles.block}>
                      <InputNumber
                        fullWidth
                        name="amount"
                        id="amount"
                        label={t('amount')}
                        value={values.amount !== null ? values.amount : undefined}
                        placeholder={`100${getDecimalSeparator()}00`}
                        onChange={(id, value) => setFieldValue('amount', value !== undefined ? value : null)}
                        onBlur={handleBlur}
                        error={Boolean(errors.amount) && Boolean(touched.amount)}
                        helperText={errors.amount && touched.amount ? errors.amount : ' '}
                        disabled={isSubmitting}
                        readOnly={editDisabled}
                        decimals={2}
                        max={100000000}
                        min={0.01}
                        loading={loading}
                        rootClassName={'fullflex'}
                        currency={user?.currency}
                        endAdornment={
                          <InputAdornment position="end">{getCurrencySymbol(user?.currency)}</InputAdornment>
                        }
                      />
                    </div>

                    <div className={clsx('inline', styles.block, styles.blockCenter)}>
                      <div className="inline fullflex">
                        <div className="spacing-block-vertical-xs" />
                        <Typography variant="body1">{t('paid')}</Typography>

                        <Switch
                          name="paid"
                          color="primary"
                          disabled={isSubmitting || loading}
                          checked={values.paid}
                          onChange={(e, checked) => {
                            setFieldValue('paid', checked)
                            if (!checked) setFieldValue('paidOn', null)
                          }}
                          className="spacing-left-s"
                        />
                      </div>

                      {values.paid ? (
                        <InputDate
                          fullWidth
                          name="paidOn"
                          label={t('paid_on')}
                          value={values.paidOn || null}
                          placeholder={`${formatDate(new Date(), 'dd/MM/yyyy')}`}
                          onChange={date => setFieldValue('paidOn', date)}
                          onBlur={handleBlur}
                          error={Boolean(errors.paidOn) && Boolean(touched.paidOn)}
                          helperText={errors.paidOn && touched.paidOn ? errors.paidOn : ' '}
                          loading={loading}
                          classes={{ root: 'fullflex' }}
                          inputVariant="outlined"
                          disabled={isSubmitting || loading}
                          format="dd/MM/yyyy"
                          inputProps={{
                            autoComplete: 'off',
                            ['data-lpignore']: 'true'
                          }}
                        />
                      ) : (
                        <div className={styles.paidOnPlaceholder} />
                      )}
                    </div>
                  </div>

                  {!isRent && (
                    <div className={styles.block}>
                      <InputMultipleSelectAdd<TransactionCategory>
                        label={t('transactions_categories_label')}
                        value={values.categories}
                        items={transactionsCategories}
                        addLabel={t('add_transaction_category_short')}
                        maxNumberOfItems={10}
                        AddContent={
                          <React.Fragment>
                            <InputText
                              placeholder={t('name')}
                              onChange={event => handleTransactionCategoryValueChange('label', event.target.value)}
                              onBlur={() => handleTransactionCategoryValueBlur('label')}
                              error={!Boolean(transactionCategoryValues.label) && transactionCategoryTouched.label}
                              fullFlex
                              smallInput
                            />

                            <Spacing size={2} />

                            <InputColor
                              label={t('color')}
                              onChange={color => handleTransactionCategoryValueChange('color', color)}
                            />
                          </React.Fragment>
                        }
                        renderLabel={(item, isLastItem, isSelected) => (
                          <div key={item.id} className="inline">
                            {item.color && (
                              <TransactionCategoryColor color={item.color} size="small" className="spacing-right-xxs" />
                            )}
                            <Typography
                              variant="body1"
                              className={clsx({ ['spacing-right-xxs']: !isLastItem && isSelected })}>
                              {item.label}
                              {!isLastItem && isSelected && ', '}
                            </Typography>
                          </div>
                        )}
                        loading={loadingTransactionsCategories}
                        getLabel={item => item.label}
                        onResetAdd={closeAndResetAddTransactionCategory}
                        onChange={handleTransactionsCategoriesChange}
                        onSubmit={handleTransactionCategoryAddSubmit}
                        placeholder={t('select_transactions_categories_placeholder')}
                        disabled={loading}
                      />
                    </div>
                  )}

                  {!isRent && !transaction && placeId && role.canAddRecurringTransactions() && (
                    <div className={clsx(styles.block, 'inline')}>
                      <FormControl variant="outlined" className={clsx('fullflex')}>
                        <Typography variant="body1" component="div" className={styles.label}>
                          {t('recurrence')}
                        </Typography>
                        <Select
                          disabled={loading}
                          value={values.recurrence || 'none'}
                          onChange={handleRecurrenceChange}
                          className="fullflex"
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            },
                            classes: {
                              list: styles.selectMenu
                            }
                          }}
                          renderValue={selected => {
                            const val = selected as string
                            return t(val === 'none' ? 'none_f' : `periods.${val}.recurring`)
                          }}>
                          {['none', 'week', 'month', 'year'].map(item => (
                            <MenuItem key={item} value={item}>
                              {t(item === 'none' ? `none_f` : `periods.${item}.recurring`)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText> </FormHelperText>
                      </FormControl>

                      <Spacing horizontal={!isSmDown} size={2} />

                      <div className="fullflex">
                        {!values.recurrence || values.recurrence === 'none' ? null : (
                          <InputDate
                            fullWidth
                            name="repeatUntil"
                            label={t('until')}
                            value={values.repeatUntil || null}
                            placeholder={`${formatDate(
                              getTransactionRecurrenceMinDate(values.recurrence, values.dueDate),
                              'dd/MM/yyyy'
                            )}`}
                            minDate={getTransactionRecurrenceMinDate(values.recurrence, values.dueDate)}
                            onChange={date => {
                              setFieldValue('repeatUntil', date)
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.repeatUntil) && Boolean(touched.repeatUntil)}
                            helperText={errors.repeatUntil && touched.repeatUntil ? errors.repeatUntil : ' '}
                            loading={loading}
                            classes={{ root: 'fullflex' }}
                            inputVariant="outlined"
                            disabled={isSubmitting || loading}
                            format="dd/MM/yyyy"
                            inputProps={{
                              autoComplete: 'off',
                              ['data-lpignore']: 'true'
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {role.canManageDocuments() && transaction ? (
                    <React.Fragment>
                      <Spacing size={4} />
                      <BoxContainer>
                        <Spacing size={1} />
                        <div className="inline justify-between">
                          <div className="inline">
                            <Spacing size={2} horizontal />
                            <Typography variant="h6">{t('transaction_documents')}</Typography>
                          </div>

                          <div className="inline">
                            <Button className="inline" variant="text" onClick={handleDocumentsSelected}>
                              <IconDocuments className="icon-primary flex-shrink-0" />
                              <Spacing size={1} horizontal />
                              <Typography variant="subtitle2" color="secondary" align="left" className="lowercase">
                                {t('view_documents')}
                              </Typography>
                            </Button>
                            <Spacing size={2} horizontal />
                          </div>
                        </div>
                        <Spacing size={1} />
                      </BoxContainer>
                    </React.Fragment>
                  ) : null}
                </DialogContent>

                <StepsActions
                  variant="dialog"
                  showOutlinedBackButton={editDisabled || deleteDisabled ? undefined : Boolean(transaction)}
                  showBackButton={Boolean(transaction) && !editDisabled && !deleteDisabled}
                  outlinedBackButtonText={t('delete')}
                  outlinedButtonColor="red"
                  submitButton
                  continueButtonText={t(!Boolean(placeId) ? 'confirm' : transaction ? 'save' : 'add')}
                  submitting={isSubmitting}
                  onGoBack={handleDeleteSelected}
                />
              </React.Fragment>
            </Form>
          )
        }}
      </Formik>

      <AlertDialog
        open={Boolean(recurrenceConfirmation)}
        title={t('transactions_recurrenct_confirm_title', { amount: recurrenceConfirmation?.amount })}
        confirmButtonText={t('add')}
        onClose={handleRecurrenceConfirmationClose}
        onConfirm={handleRecurrenceConfirmSelected}
      />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    spacingRight: {
      marginRight: theme.spacing(2)
    },
    block: {
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        alignItems: 'stretch !important'
      }
    },
    blockCenter: {
      alignItems: 'center'
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    paidOnPlaceholder: {
      height: 93
    },
    label: {
      marginBottom: theme.spacing(1)
    },
    selectMenu: {
      paddingTop: 0,
      paddingBottom: 0
    },
    selectItem: {
      minHeight: 40,
      '&.MuiListItem-root.Mui-disabled': {
        opacity: 1,
        '&.MuiListItem-button:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    selectItemSelected: {
      backgroundColor: 'transparent',
      '& .MuiListItemText-root > span': {
        fontWeight: 600
      }
    }
  })
)

export default EditTransactionDialog
