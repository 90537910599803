import { Theme, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { IconCalendar } from '../../assets/Svgs'
import { formatDate } from '../../utils/DateUtils'
import ReadOnlyField from '../ReadOnlyField'

type InputDateProps = KeyboardDatePickerProps & {
  error?: boolean
  classes?: {
    root?: string
  }
  readOnly?: boolean
  loading?: boolean
}

const InputDate: React.FC<InputDateProps> = ({
  error,
  label,
  classes,
  readOnly,
  loading,
  ...props
}: InputDateProps) => {
  const styles = useStyles()

  return (
    <div className={clsx(styles.root, classes?.root)}>
      {label ? (
        <Typography variant="body1" component="div" className={styles.label}>
          {label}
        </Typography>
      ) : null}
      {loading ? (
        <Skeleton variant="rect" className={styles.loader} />
      ) : readOnly ? (
        <ReadOnlyField value={formatDate(props.value as Date, 'P')} />
      ) : (
        <KeyboardDatePicker
          {...props}
          className={clsx(props.className, styles.picker, { [styles.pickerError]: error })}
          keyboardIcon={<IconCalendar />}
          InputAdornmentProps={{
            position: 'start'
          }}
          inputVariant="outlined"
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      marginBottom: theme.spacing(1)
    },
    picker: {
      '& .MuiIconButton-root': {
        padding: 4,
        '& svg': {
          width: 24,
          height: 24,
          '& path': {
            fill: theme.palette.text.primary
          }
        }
      }
    },
    pickerError: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main
      }
    },
    loader: {
      height: 47,
      marginBottom: 18,
      borderRadius: theme.shape.borderRadius
    }
  })
)

export default InputDate
