import firebase from 'firebase'
import LRU from 'lru-cache'
import Guest from '../types/Guest'
import Document from '../types/Document'
import GuestRentPlace from '../types/GuestRentPlace'
import Place from '../types/place/Place'
import Rent from '../types/place/Rent'
import CheckoutSession from '../types/products/CheckoutSession'
import Product from '../types/products/Product'
import ProductPrice from '../types/products/ProductPrice'
import UserProduct from '../types/products/UserProduct'
import Transaction from '../types/Transaction'
import TransactionCategory from '../types/TransactionCategory'
import User from '../types/User'
import { getStartOfToday } from '../utils/DateUtils'
import { mapPlaceData, mapProductData, mapProductPriceData } from '../utils/DbUtils'
import { getRentNextTransaction, getRentStatus, sortRents } from '../utils/RentUtils'
import { sortTransactions } from '../utils/TransactionsUtils'
import { AddDocumentLocationState } from '../pages/AddDocument'
import AppService from './AppService'

enum CacheKeys {
  Places = 'Places',
  PlaceRents = 'PlaceRents',
  UserTransactions = 'UserTransactions',
  PlaceTransactions = 'PlaceTransactions',
  UserActiveRents = 'UserActiveRents',
  UserRents = 'UserRents'
}

enum CollectionNames {
  Users = 'users',
  Places = 'places',
  Guests = 'guests',
  Rents = 'rents',
  Transactions = 'transactions',
  TransactionsCategories = 'transactions_categories',
  Products = 'products',
  Subscriptions = 'subscriptions',
  Prices = 'prices',
  CheckoutSessions = 'checkout_sessions',
  Documents = 'documents',
  ReceiptsCount = 'receipts_count',
  ConfirmationsCount = 'booking_confirmations_count'
}

const removeUndefinedFields = (object: Record<string, unknown>) => {
  Object.keys(object).forEach(key => {
    if (object[key] === undefined) delete object[key]
  })
  return object
}

export const convertTimestampsToDates = <T = Record<string, unknown>>(object: T): T => {
  if (!(object && typeof object === 'object' && object !== null && !Array.isArray(object))) return object

  const obj: Record<string, unknown> = object as Record<string, unknown>

  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof firebase.firestore.Timestamp) obj[key] = (obj[key] as firebase.firestore.Timestamp).toDate()
  })
  return object
}

const getServerTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

class DbService {
  db: firebase.firestore.Firestore
  cache = new LRU<string, unknown>({
    max: 500,
    maxAge: 1000 * 60 * 60 // 1 hour
  })
  userId: string | undefined

  constructor(db: firebase.firestore.Firestore) {
    this.db = db
  }

  getCacheKey = (key: string) => {
    return `${this.userId ? `${this.userId}_` : ''}${key}`
  }

  deleteAllCacheKeys = () => {
    const keys = this.cache.keys()
    for (const key of keys) {
      this.cache.del(this.getCacheKey(key))
    }
  }

  deleteChacheKeysFromPrefix = (prefix: string) => {
    const keys = this.cache.keys()
    for (const key of keys) {
      if (key.includes(prefix)) {
        this.cache.del(key)
      }
    }
  }

  deleteCacheKeys = (keys: string[]) => {
    for (const key of keys) {
      this.cache.del(this.getCacheKey(key))
    }
  }

  deleteTransactionsCacheKeys = () => {
    this.deleteCacheKeys([CacheKeys.UserTransactions])
    this.deleteChacheKeysFromPrefix(`${CacheKeys.PlaceTransactions}_`)
    this.deleteRentsCacheKeys()
  }

  deleteRentsCacheKeys = () => {
    this.deleteCacheKeys([CacheKeys.UserActiveRents, CacheKeys.UserRents])
    this.deleteChacheKeysFromPrefix(`${CacheKeys.PlaceRents}_`)
  }

  deletePlacesCacheKeys = () => {
    this.deleteCacheKeys([CacheKeys.Places])
  }

  getWithCache = async <T extends {}>(
    cacheKey: string,
    dbGetter: () => Promise<T | undefined>,
    maxAge?: number
  ): Promise<T | undefined> => {
    let result: T | undefined

    const currentCacheKey = this.getCacheKey(cacheKey)
    const cacheResult = this.cache.get(currentCacheKey) as T | undefined

    if (cacheResult) {
      result = cacheResult
    } else {
      result = await dbGetter()
      this.cache.set(currentCacheKey, result, maxAge)
    }

    return result
  }

  createUser = async (user: Omit<User, 'created'>) => {
    const { id: userId, ...userData } = user
    await this.db
      ?.collection(CollectionNames.Users)
      .doc(userId)
      .set(removeUndefinedFields({ ...userData, created: getServerTimestamp() }))

    return true
  }

  updateUser = async (id: string, data: Partial<User>) => {
    await this.db
      ?.collection(CollectionNames.Users)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields(data)
        },
        { merge: true }
      )

    return true
  }

  getUser = async (id: string): Promise<User | undefined> => {
    const userDoc = await this.db.collection(CollectionNames.Users).doc(id).get()
    if (userDoc?.exists) {
      return convertTimestampsToDates(userDoc.data() as User)
    }
  }

  createPlace = async (placeData: Omit<Place, 'id' | 'created'>, userId: string): Promise<string> => {
    const docRef = await this.db.collection(CollectionNames.Places).add(
      removeUndefinedFields({
        ...placeData,
        uid: userId,
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    )
    if (docRef) {
      this.deleteCacheKeys([CacheKeys.Places])
      return docRef.id
    } else {
      throw new Error()
    }
  }

  updatePlace = async (id: string, data: Partial<Omit<Place, 'id' | 'created'>>): Promise<boolean> => {
    await this.db
      ?.collection(CollectionNames.Places)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields(data)
        },
        { merge: true }
      )

    this.deletePlacesCacheKeys()

    return true
  }

  deletePlace = async (id: string): Promise<boolean> => {
    try {
      await this.db.collection(CollectionNames.Places).doc(id).delete()

      this.deletePlacesCacheKeys()

      return true
    } catch (err) {
      return false
    }
  }

  getPlace = async (placeId: string): Promise<Place | undefined> => {
    const querySnapshot = await this.db.collection(CollectionNames.Places).doc(placeId).get()
    const place = mapPlaceData(placeId, querySnapshot?.data())

    return place
  }

  getPlaces = async (userId: string): Promise<Place[]> => {
    return (
      (await this.getWithCache(CacheKeys.Places, async () => {
        const places: Place[] = []

        const querySnapshot = await this.db.collection(CollectionNames.Places).where('uid', '==', userId).get()
        querySnapshot?.forEach(doc => {
          const place = mapPlaceData(doc.id, doc.data())
          if (place) {
            places.push(place)
          }
        })

        return places.sort((a, b) => {
          return a.created.getTime() - b.created.getTime()
        })
      })) || []
    )
  }

  createGuest = async (guestData: Omit<Guest, 'id' | 'created'>, userId: string): Promise<string> => {
    const docRef = await this.db.collection(CollectionNames.Guests).add(
      removeUndefinedFields({
        ...guestData,
        uid: userId,
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    )
    if (docRef) return docRef.id
    throw new Error()
  }

  updateGuest = async (id: string, data: Partial<Omit<Guest, 'id' | 'created'>>): Promise<boolean> => {
    await this.db
      ?.collection(CollectionNames.Guests)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields(data)
        },
        { merge: true }
      )

    this.deleteRentsCacheKeys()

    return true
  }

  deleteGuest = async (id: string): Promise<boolean> => {
    try {
      await this.db.collection(CollectionNames.Guests).doc(id).delete()

      this.deleteRentsCacheKeys()

      return true
    } catch (err) {
      return false
    }
  }

  getGuest = async (guestId: string): Promise<Guest | undefined> => {
    let guest: Guest | undefined

    try {
      const doc = await this.db.collection(CollectionNames.Guests).doc(guestId).get()
      if (doc) {
        guest = {
          ...convertTimestampsToDates(doc.data() as Guest),
          id: doc.id
        }
      }
    } catch (err) {}

    return guest
  }

  getGuests = async (userId: string): Promise<Guest[]> => {
    const guests: Guest[] = []

    const querySnapshot = await this.db.collection(CollectionNames.Guests).where('uid', '==', userId).get()
    querySnapshot?.forEach(doc => {
      guests.push({
        ...convertTimestampsToDates(doc.data() as Guest),
        id: doc.id
      })
    })

    return guests
  }

  getGuestsRentsPlaces = async (userId: string, guestsIds: string[]): Promise<GuestRentPlace[]> => {
    let rents: GuestRentPlace[] = []

    const querySnapshot = await this.db
      ?.collection(CollectionNames.Rents)
      .where('uid', '==', userId)
      .where('guestsIds', 'array-contains-any', guestsIds)
      .get()
    querySnapshot?.forEach(doc => {
      const docData = doc.data()

      const rent = {
        ...(convertTimestampsToDates(docData) as GuestRentPlace),
        transactions: [],
        guests: [],
        id: doc.id
      }

      if (getRentStatus(rent).status !== 'completed') {
        rents.push(rent)
      }
    })

    if (rents.length > 0) {
      const placesQuerySnapshot = await this.db
        ?.collection(CollectionNames.Places)
        .where('uid', '==', userId)
        .where(
          firebase.firestore.FieldPath.documentId(),
          'in',
          rents.map(r => r.placeId)
        )
        .get()
      placesQuerySnapshot?.forEach(placeDoc => {
        const placeData = placeDoc.data()

        rents = rents.map(r => ({
          ...r,
          place:
            r.placeId === placeDoc.id
              ? {
                  id: placeDoc.id,
                  name: placeData.name,
                  pictureUrl: placeData.pictureUrl
                }
              : r.place
        }))
      })
    }

    return rents
  }

  createTransactionCategory = async (
    data: Omit<TransactionCategory, 'id' | 'created'>,
    userId: string
  ): Promise<string> => {
    const docRef = await this.db.collection(CollectionNames.TransactionsCategories).add(
      removeUndefinedFields({
        ...data,
        uid: userId,
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    )
    if (docRef) return docRef.id
    throw new Error()
  }

  updateTransactionCategory = async (
    id: string,
    data: Partial<Omit<TransactionCategory, 'id' | 'created'>>
  ): Promise<boolean> => {
    await this.db
      ?.collection(CollectionNames.TransactionsCategories)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields(data)
        },
        { merge: true }
      )

    this.deleteTransactionsCacheKeys()

    return true
  }

  deleteTransactionCategory = async (id: string): Promise<boolean> => {
    try {
      await this.db.collection(CollectionNames.TransactionsCategories).doc(id).delete()
      this.deleteTransactionsCacheKeys()
      return true
    } catch (err) {
      return false
    }
  }

  getTransactionCategory = async (id: string): Promise<TransactionCategory | undefined> => {
    let item: TransactionCategory | undefined

    try {
      const doc = await this.db.collection(CollectionNames.TransactionsCategories).doc(id).get()

      if (doc) {
        item = {
          ...convertTimestampsToDates(doc.data() as TransactionCategory),
          id: doc.id
        }
      }
    } catch (err) {}

    return item
  }

  getTransactionCategories = async (userId: string): Promise<TransactionCategory[]> => {
    const items: TransactionCategory[] = []

    const querySnapshot = await this.db
      ?.collection(CollectionNames.TransactionsCategories)
      .where('uid', '==', userId)
      .get()
    querySnapshot?.forEach(doc => {
      items.push({
        ...convertTimestampsToDates(doc.data() as TransactionCategory),
        id: doc.id
      })
    })

    return items
  }

  createRent = async (rentData: Omit<Rent, 'id' | 'created' | 'transactions'>, userId: string): Promise<string> => {
    const { guests, ...rent } = rentData

    const docRef = await this.db.collection(CollectionNames.Rents).add(
      removeUndefinedFields({
        ...rent,
        guestsIds: guests.map(g => g.id),
        uid: userId,
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
    )

    this.deleteRentsCacheKeys()

    if (docRef) return docRef.id
    throw new Error()
  }

  updateRent = async (rentData: Omit<Rent, 'created' | 'transactions'>, userId: string): Promise<string> => {
    const { id, guests, ...rent } = rentData

    const docRef = await this.db.collection(CollectionNames.Rents).doc(id)

    if (!docRef) throw new Error()

    await docRef?.update(
      removeUndefinedFields({
        ...rent,
        guestsIds: guests.map(g => g.id),
        uid: userId
      })
    )
    if (docRef) {
      this.deleteRentsCacheKeys()
      return docRef.id
    } else {
      throw new Error()
    }
  }

  deleteRent = async (id: string, userId: string): Promise<boolean> => {
    try {
      await this.db.collection(CollectionNames.Rents).doc(id).delete()

      const rentTransactionsSnapshot = await this.db
        ?.collection(CollectionNames.Transactions)
        .where('uid', '==', userId)
        .where('rentId', '==', id)
        .get()

      if (rentTransactionsSnapshot) {
        rentTransactionsSnapshot.forEach(doc => {
          doc.ref.delete()
        })
      } else {
        return false
      }

      this.deleteRentsCacheKeys()

      return true
    } catch (err) {
      return false
    }
  }

  getRent = async (userId: string, rentId: string): Promise<Rent | undefined> => {
    let guestsIds: string[] = []

    const docRef = await this.db.collection(CollectionNames.Rents).doc(rentId).get()

    if (!docRef?.exists) return

    const docData = docRef?.data()

    if (!docData) return

    guestsIds = docData.guestsIds ? [...docData.guestsIds] : []

    delete docData.guestsIds

    const rent: Rent = {
      ...(convertTimestampsToDates(docData) as Rent),
      transactions: [],
      guests: [],
      id: rentId
    }

    const transactionsSnapshot = await this.db
      ?.collection(CollectionNames.Transactions)
      .where('uid', '==', userId)
      .where('rentId', '==', rentId)
      .get()

    transactionsSnapshot?.forEach(doc => {
      rent.transactions.push({
        ...(convertTimestampsToDates(doc.data()) as Transaction),
        id: doc.id
      })
    })

    rent.nextTransaction = getRentNextTransaction(rent.transactions)

    if (guestsIds && guestsIds.length > 0) {
      rent.guests = (await Promise.all(guestsIds.map(guestId => this.getGuest(guestId)))).reduce(
        (arr, item) => (item ? arr.concat(item) : arr),
        [] as Guest[]
      )
    }

    return rent
  }

  getRentBase = async (userId: string, rentId: string): Promise<Rent | undefined> => {
    const docRef = await this.db.collection(CollectionNames.Rents).doc(rentId).get()

    if (!docRef?.exists) return

    const docData = docRef?.data()

    if (!docData) return

    delete docData.guestsIds

    const rent: Rent = {
      ...(convertTimestampsToDates(docData) as Rent),
      transactions: [],
      guests: [],
      id: rentId
    }

    return rent
  }

  getUserTransactions = async (userId: string): Promise<Transaction[]> => {
    return (
      (await this.getWithCache(CacheKeys.UserTransactions, async () => {
        const transactions: Transaction[] = []

        const query = this.db.collection(CollectionNames.Transactions).where('uid', '==', userId)

        const querySnapshot = await query.get()
        querySnapshot?.forEach(doc => {
          const docData = doc.data()

          transactions.push({
            ...(convertTimestampsToDates(docData) as Transaction),
            id: doc.id
          })
        })
        return transactions
      })) || []
    )
  }

  getUserRents = async (userId: string, onlyActive?: boolean) => {
    return (
      (await this.getWithCache(onlyActive ? CacheKeys.UserActiveRents : CacheKeys.UserRents, async () => {
        const rents: Rent[] = []

        const query = this.db.collection(CollectionNames.Rents).where('uid', '==', userId)
        if (onlyActive) {
          query?.where('to', '>', getStartOfToday())
        }

        const querySnapshot = await query?.get()
        querySnapshot?.forEach(doc => {
          const docData = doc.data()

          delete docData.guestsIds

          rents.push({
            ...(convertTimestampsToDates(docData) as Rent),
            transactions: [],
            guests: [],
            id: doc.id
          })
        })

        for (const rent of rents) {
          const transactionsSnapshot = await this.db
            ?.collection(CollectionNames.Transactions)
            .where('uid', '==', userId)
            .where('rentId', '==', rent.id)
            .where('isRent', '==', true)
            .where('paid', '==', false)
            .where('dueDate', '>', getStartOfToday())
            .orderBy('dueDate', 'asc')
            .limit(1)
            .get()

          transactionsSnapshot?.forEach(doc => {
            rent.nextTransaction = {
              ...(convertTimestampsToDates(doc.data()) as Transaction),
              id: doc.id
            }
          })
        }

        return rents
      })) || []
    )
  }

  getRents = async (userId: string, placeId: string): Promise<Rent[]> => {
    return (
      (await this.getWithCache(`${CacheKeys.PlaceRents}_${placeId}`, async () => {
        const rents: Rent[] = []
        const guestsIds: Record<string, string[]> = {}

        const querySnapshot = await this.db
          ?.collection(CollectionNames.Rents)
          .where('uid', '==', userId)
          .where('placeId', '==', placeId)
          .get()
        querySnapshot?.forEach(doc => {
          const docData = doc.data()

          guestsIds[doc.id] = docData.guestsIds || []

          delete docData.guestsIds

          rents.push({
            ...(convertTimestampsToDates(docData) as Rent),
            transactions: [],
            guests: [],
            id: doc.id
          })
        })

        for (const rent of rents) {
          const transactionsSnapshot = await this.db
            ?.collection(CollectionNames.Transactions)
            .where('uid', '==', userId)
            .where('rentId', '==', rent.id)
            .get()

          transactionsSnapshot?.forEach(doc => {
            rent.transactions.push({
              ...(convertTimestampsToDates(doc.data()) as Transaction),
              id: doc.id
            })
          })

          rent.nextTransaction = getRentNextTransaction(rent.transactions)

          if (guestsIds[rent.id] && guestsIds[rent.id].length > 0) {
            rent.guests = (await Promise.all(guestsIds[rent.id].map(guestId => this.getGuest(guestId)))).reduce(
              (arr, item) => (item ? arr.concat(item) : arr),
              [] as Guest[]
            )
          }
        }

        return sortRents(rents)
      })) || []
    )
  }

  createTransaction = async (
    transactionData: Omit<Transaction, 'id' | 'created'>,
    userId: string,
    placeId: string
  ): Promise<string> => {
    const { categories, ...transaction } = transactionData

    const docRef = await this.db.collection(CollectionNames.Transactions).add(
      removeUndefinedFields({
        ...transaction,
        categoriesIds: categories.map(c => c.id),
        uid: userId,
        placeId,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        paidOn: transaction.paid ? transaction.paidOn : null
      })
    )
    if (docRef) {
      this.deleteTransactionsCacheKeys()
      return docRef.id
    } else {
      throw new Error()
    }
  }

  deleteTransaction = async (id: string): Promise<boolean> => {
    try {
      await this.db.collection(CollectionNames.Transactions).doc(id).delete()

      return true
    } catch (err) {
      return false
    }
  }

  getTransaction = async (id: string): Promise<Transaction | undefined> => {
    const querySnapshot = await this.db.collection(CollectionNames.Transactions).doc(id).get()

    const docData = querySnapshot?.data()

    if (!docData) return

    const categoriesIds = [...docData.categoriesIds]

    delete docData.categoriesIds

    const item: Transaction = {
      ...convertTimestampsToDates(docData as Transaction),
      categories: [],
      id
    }

    if (categoriesIds.length > 0) {
      item.categories = (
        await Promise.all(categoriesIds.map(categoryId => this.getTransactionCategory(categoryId)))
      ).reduce((arr, item) => (item ? arr.concat(item) : arr), [] as TransactionCategory[])
    }

    return item
  }

  createTransactions = async (
    transactionsData: Omit<Transaction, 'id' | 'created'>[] | undefined,
    userId: string
  ): Promise<string[]> => {
    const collection = this.db.collection(CollectionNames.Transactions)
    const batch = this.db.batch()

    if (!(batch && collection && transactionsData) || (transactionsData && transactionsData.length === 0)) return []

    const ids: string[] = []

    for (const transaction of transactionsData) {
      delete (transaction as Record<string, unknown>).id

      const { categories, ...data } = transaction

      const docRef = collection.doc()
      ids.push(docRef.id)
      batch.set(
        docRef,
        removeUndefinedFields({
          ...data,
          categoriesIds: categories.map(c => c.id),
          uid: userId,
          created: firebase.firestore.FieldValue.serverTimestamp()
        })
      )
    }

    await batch.commit()

    this.deleteTransactionsCacheKeys()

    return ids
  }

  deleteTransactions = async (transactionsIds: string[]) => {
    if (transactionsIds.length === 0) return true

    const collection = this.db.collection(CollectionNames.Transactions)
    const batch = this.db.batch()

    if (!(batch && collection)) return []

    for (const transactionId of transactionsIds) {
      const transactionDocRef = collection.doc(transactionId)
      batch.delete(transactionDocRef)
    }

    await batch.commit()

    this.deleteTransactionsCacheKeys()
    this.deleteRentsCacheKeys() // TODO maybe this can be removed if all rent queries use internal db methods

    return true
  }

  getPlaceTransactions = async (userId: string, placeId: string): Promise<Transaction[]> => {
    return (
      (await this.getWithCache(`${CacheKeys.PlaceTransactions}_${placeId}`, async () => {
        const transactions: Transaction[] = []

        const querySnapshot = await this.db
          ?.collection(CollectionNames.Transactions)
          .where('uid', '==', userId)
          .where('placeId', '==', placeId)
          .get()
        querySnapshot?.forEach(doc => {
          const docData = doc.data()

          transactions.push({
            ...(convertTimestampsToDates(docData) as Transaction),
            id: doc.id
          })
        })

        const transactionsCategories = await this.getTransactionCategories(userId)

        return sortTransactions(
          transactions.map(t => {
            const categoriesIds = [...(t as any).categoriesIds]

            delete (t as { categoriesIds?: string[] }).categoriesIds
            return {
              ...t,
              categories: transactionsCategories.filter(c => categoriesIds.includes(c.id))
            }
          })
        )
      })) || []
    )
  }

  updateTransaction = async (id: string, data: Partial<Omit<Transaction, 'id' | 'created'>>) => {
    const { categories, ...dbData } = data

    await this.db
      ?.collection(CollectionNames.Transactions)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields({ ...dbData, paidOn: dbData.paid ? dbData.paidOn : null }),
          categoriesIds: (categories || []).map(c => c.id)
        },
        { merge: true }
      )

    this.deleteTransactionsCacheKeys()
    this.deleteRentsCacheKeys() // TODO maybe this can be removed if all rent queries use internal db methods

    return true
  }

  updateTransactions = async (transactions: Transaction[]) => {
    for (const transaction of transactions) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, created, ...transactionData } = transaction
      await this.updateTransaction(id, transactionData)
    }

    return true
  }

  getProducts = async () => {
    const products: Product[] = []

    const querySnapshot = await this.db.collection(CollectionNames.Products).where('active', '==', true).get()

    const productsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] = []
    querySnapshot?.forEach(doc => {
      productsDocs.push(doc)
    })

    for (const doc of productsDocs) {
      const priceSnap = await doc.ref.collection(CollectionNames.Prices).where('active', '==', true).get()

      const product: Product = mapProductData(doc.id, doc.data())

      priceSnap.forEach(priceDoc => {
        const priceData = priceDoc.data()

        product.prices.push(mapProductPriceData(priceDoc.id, priceData))
      })

      products.push(product)
    }

    return products.sort((a, b) =>
      !a.role && b.role ? -1 : a.role && !b.role ? 1 : a.role === 'business' && b.role === 'professional' ? 1 : -1
    )
  }

  getUserProducts = async (userId: string) => {
    const userProducts: UserProduct[] = []

    const querySnapshot = await this.db
      ?.collection(CollectionNames.Users)
      .doc(userId)
      .collection(CollectionNames.Subscriptions)
      .where('status', 'in', ['trialing', 'active'])
      .get()

    const userProductsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] = []
    querySnapshot?.forEach(doc => {
      userProductsDocs.push(doc)
    })

    for (const doc of userProductsDocs) {
      const userProductData = doc.data()

      const productDoc = await userProductData.product.get()
      const product = mapProductData(productDoc.id, productDoc.data())

      const priceDoc = await userProductData.price.get()

      userProducts.push(
        convertTimestampsToDates({
          id: doc.id,
          status: userProductData.status,
          currentPeriodEnd: userProductData.current_period_end,
          product: product,
          cancelAtPeriodEnd: userProductData.cancel_at_period_end,
          price: mapProductPriceData(priceDoc.id, priceDoc.data())
        })
      )
    }

    return userProducts
  }

  getCheckoutUrl = async (
    userId: string,
    productPrice: ProductPrice,
    onResult: (result: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => void
  ) => {
    const checkoutSession: CheckoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: false,
      allow_promotion_codes: true,
      line_items: [
        { price: productPrice.id, ...(productPrice?.recurring?.usageType !== 'metered' ? { quantity: 1 } : undefined) }
      ],
      success_url: `${window.location.origin}/subscriptions`,
      cancel_url: `${window.location.origin}/subscriptions`
    }

    const docRef = await this.db
      ?.collection(CollectionNames.Users)
      .doc(userId)
      .collection(CollectionNames.CheckoutSessions)
      .add(checkoutSession)

    if (docRef) {
      docRef.onSnapshot(snap => {
        onResult(snap)
      })
    }
  }

  getPlaceRentsAndTransactionsCount = async (
    placeId: string,
    userId: string
  ): Promise<{ transactionsCount: number; rentsCount: number }> => {
    let transactionsCount = 0
    let rentsCount = 0

    const transactionsQuerySnapshot = await this.db
      ?.collection(CollectionNames.Transactions)
      .where('uid', '==', userId)
      .where('placeId', '==', placeId)
      .get()
    if (transactionsQuerySnapshot) {
      transactionsCount = transactionsQuerySnapshot.size
    }
    const rentsQuerySnapshot = await this.db
      ?.collection(CollectionNames.Rents)
      .where('uid', '==', userId)
      .where('placeId', '==', placeId)
      .get()
    if (rentsQuerySnapshot) {
      rentsCount = rentsQuerySnapshot.size
    }

    return { transactionsCount, rentsCount }
  }

  createDocument = async (data: Omit<Document, 'id' | 'created' | 'updated'>, userId: string): Promise<string> => {
    const docRef = await this.db.collection(CollectionNames.Documents).add(
      removeUndefinedFields({
        ...data,
        uid: userId,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp()
      })
    )
    if (docRef) return docRef.id
    throw new Error()
  }

  updateDocument = async (
    id: string,
    data: Partial<
      Omit<Document, 'id' | 'created' | 'placeIds' | 'transactionIds' | 'guestIds'> & {
        placeId?: string
        transactionId?: string
        guestId?: string
      }
    >
  ): Promise<boolean> => {
    const { placeId, transactionId, guestId, ...dbData } = data

    await this.db
      ?.collection(CollectionNames.Documents)
      .doc(id)
      .set(
        {
          ...removeUndefinedFields(dbData as any),
          ...(placeId !== undefined ? { placeIds: firebase.firestore.FieldValue.arrayUnion(placeId) } : undefined),
          ...(transactionId !== undefined
            ? { transactionIds: firebase.firestore.FieldValue.arrayUnion(transactionId) }
            : undefined),
          ...(guestId !== undefined ? { guestIds: firebase.firestore.FieldValue.arrayUnion(guestId) } : undefined),
          updated: firebase.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )

    return true
  }

  deleteDocumentEntityLink = async (
    id: string,
    options: {
      placeId?: string
      transactionId?: string
      guestId?: string
    }
  ): Promise<boolean> => {
    try {
      await this.db
        .collection(CollectionNames.Documents)
        .doc(id)
        .set(
          {
            ...(options.placeId !== undefined
              ? { placeIds: firebase.firestore.FieldValue.arrayRemove(options.placeId) }
              : undefined),
            ...(options.transactionId !== undefined
              ? { transactionIds: firebase.firestore.FieldValue.arrayRemove(options.transactionId) }
              : undefined),
            ...(options.guestId !== undefined
              ? { guestIds: firebase.firestore.FieldValue.arrayRemove(options.guestId) }
              : undefined)
          },
          { merge: true }
        )

      return true
    } catch (err) {
      return false
    }
  }

  deleteDocument = async (id: string, storageUrl: string): Promise<boolean> => {
    try {
      const { storage } = AppService
      const success = await storage.deleteFile(storageUrl)

      if (success) {
        await this.db.collection(CollectionNames.Documents).doc(id).delete()
      } else {
        return false
      }

      return true
    } catch (err) {
      return false
    }
  }

  getDocument = async (id: string): Promise<Document | undefined> => {
    let item: Document | undefined

    try {
      const doc = await this.db.collection(CollectionNames.Documents).doc(id).get()
      if (doc) {
        item = {
          ...convertTimestampsToDates(doc.data() as Document),
          id: doc.id
        }
      }
    } catch (err) {}

    return item
  }

  getDocuments = async (userId: string, options?: AddDocumentLocationState): Promise<Document[]> => {
    const items: Document[] = []

    let query = this.db.collection(CollectionNames.Documents).where('uid', '==', userId)

    if (options?.placeId) {
      query = query.where('placeIds', 'array-contains', options.placeId)
    }
    if (options?.transactionId) {
      query = query.where('transactionIds', 'array-contains', options.transactionId)
    }
    if (options?.guestId) {
      query = query.where('guestIds', 'array-contains', options.guestId)
    }

    const querySnapshot = await query.get()
    querySnapshot?.forEach(doc => {
      items.push({
        ...convertTimestampsToDates(doc.data() as Document),
        id: doc.id
      })
    })

    return items
  }

  getReceiptNumber = async (userId: string) => {
    let receiptNumber = 1

    try {
      const snapshot = await this.db.collection(CollectionNames.ReceiptsCount).doc(userId).get()

      const count = snapshot.data()?.count
      if (count && !isNaN(count)) {
        receiptNumber = count
      }
    } catch (err) {}

    return receiptNumber
  }

  incrementReceiptNumber = async (userId: string) => {
    await this.db
      .collection(CollectionNames.ReceiptsCount)
      .doc(userId)
      .set(
        {
          count: firebase.firestore.FieldValue.increment(1)
        },
        { merge: true }
      )
  }

  getConfirmationNumber = async (userId: string) => {
    let confirmationNumber = 1

    try {
      const snapshot = await this.db.collection(CollectionNames.ConfirmationsCount).doc(userId).get()

      const count = snapshot.data()?.count
      if (count && !isNaN(count)) {
        confirmationNumber = count
      }
    } catch (err) {}

    return confirmationNumber
  }

  incrementConfirmationNumber = async (userId: string) => {
    await this.db
      .collection(CollectionNames.ConfirmationsCount)
      .doc(userId)
      .set(
        {
          count: firebase.firestore.FieldValue.increment(1)
        },
        { merge: true }
      )
  }
}

export default DbService
