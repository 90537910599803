import { Divider, IconButton, Theme, Tooltip, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IconCheckmarkRound, IconEdit, IconReceipt } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import Colors from '../../styles/Colors'
import Transaction from '../../types/Transaction'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import { getTransactionColor, getTransactionStatus } from '../../utils/TransactionsUtils'
import Spacing from '../Spacing'

type TransactionListItemProps = {
  transaction: Transaction
  isLastChild?: boolean
  isRent?: boolean
  hasGuests?: boolean
  isBooking?: boolean
  onEdit?: (id: string) => void
  onPaidToggle?: (id: string, paid: boolean) => void
  onRentReceiptSelected?: (id: string) => void
}

const TransactionListItem: React.FC<TransactionListItemProps> = ({
  transaction,
  isLastChild,
  isRent,
  hasGuests,
  isBooking,
  onEdit,
  onPaidToggle,
  onRentReceiptSelected
}: TransactionListItemProps) => {
  const styles = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  const handleEdit = useCallback(() => {
    if (onEdit) onEdit(transaction.id)
  }, [onEdit, transaction.id])

  const handlePaidToggle = useCallback(() => {
    if (onPaidToggle) onPaidToggle(transaction.id, !transaction.paid)
  }, [onPaidToggle, transaction.id, transaction.paid])

  const handleRentReceiptSelected = useCallback(() => {
    if (onRentReceiptSelected) {
      onRentReceiptSelected(transaction.id)
    }
  }, [onRentReceiptSelected, transaction.id])

  const status = getTransactionStatus(t, transaction).status

  return (
    <div className="colstretch transaction-list-item">
      <div className={clsx('inline', 'justify-between', styles.item)}>
        <div className="colstretch">
          <div className="inline">
            <Typography variant="body1">{transaction.description}</Typography>
            {isRent && transaction.paid && (
              <React.Fragment>
                <Spacing horizontal size={1} />
                <Tooltip
                  title={
                    !hasGuests ? (
                      <Typography>
                        <strong>{t('receipt')}</strong>
                        {`: ${t(isBooking ? 'booking_receipt_guest_missing_tip' : 'rent_receipt_guest_missing_tip')}`}
                      </Typography>
                    ) : (
                      <Typography>{t('receipt')}</Typography>
                    )
                  }>
                  <div>
                    <IconButton
                      disabled={!hasGuests}
                      onClick={handleRentReceiptSelected}
                      className={styles.receiptIconButton}>
                      <IconReceipt className={clsx(!hasGuests ? styles.iconDisabled : 'icon-primary')} />
                    </IconButton>
                  </div>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
          <Typography variant="caption" color="textSecondary">
            {formatDate(transaction.dueDate)}
          </Typography>
        </div>

        <div className={clsx('colstretch', 'spacing-left-xs')}>
          <div className="inline">
            <div className={clsx('colstretch spacing-right-s', styles.valueContainer)}>
              <Typography variant="body1">
                <strong>{formatCurrency(transaction.amount, user?.currency)}</strong>
              </Typography>
              {transaction.paid && transaction.paidOn && (
                <Typography variant="caption" style={{ color: getTransactionColor(status) }}>
                  {t('transaction_paid_date', { date: formatDate(transaction.paidOn) })}
                </Typography>
              )}
            </div>

            <IconButton onClick={handleEdit} className={styles.iconButtonSmall}>
              <IconEdit />
            </IconButton>
            <IconButton onClick={handlePaidToggle} className={styles.iconButtonSmall}>
              <IconCheckmarkRound
                className={
                  status === 'paid'
                    ? styles.checkmarkActive
                    : status === 'delayed'
                    ? styles.checkmarkDelayed
                    : status === 'unpaid'
                    ? styles.checkmarkUnpaid
                    : styles.checkmark
                }
              />
            </IconButton>
          </div>
        </div>
      </div>
      {!isLastChild && <Divider />}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      minHeight: 40,
      padding: `${theme.spacing(0.5)}px 0`
    },
    checkmark: {
      '& path': {
        fill: `${Colors.IconGray} !important`
      }
    },
    checkmarkActive: {
      '& path': {
        fill: `${Colors.Green} !important`
      }
    },
    checkmarkUnpaid: {
      '& path': {
        fill: `${Colors.Red} !important`
      }
    },
    checkmarkDelayed: {
      '& path': {
        fill: `${Colors.Orange} !important`
      }
    },
    iconButtonSmall: {
      padding: 8
    },
    valueContainer: {
      textAlign: 'right'
    },
    receiptIconButton: {
      width: 32,
      height: 32,
      marginBottom: -4
    },
    iconDisabled: {
      '& path': {
        fill: theme.palette.text.secondary
      }
    }
  })
)

export default TransactionListItem
