import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PromoDialog from '../components/PromoDialog'

const PromoDialogPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ title: string; description: string; promoFor?: 'professional' | 'business' }>()
  const { title, description, promoFor } = location.state

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  return <PromoDialog open onClose={handleClose} title={title} description={description} promoFor={promoFor} />
}

export default PromoDialogPage
