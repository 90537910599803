import React, { useCallback } from 'react'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  fade,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { getUserRoleLabel, userHasAnActiveProduct } from '../utils/ProductsUtils'
import { useUser } from '../hooks/useUser'
import Colors from '../styles/Colors'
import UpgradeButton from './promo/UpgradeButton'
import Spacing from './Spacing'

type PromoDialogProps = {
  open: boolean
  title?: string
  description?: string
  promoFor?: 'professional' | 'business'
  onClose?: () => void
}

const PromoDialog: React.FC<PromoDialogProps> = ({ open, title, description, promoFor, onClose }: PromoDialogProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const styles = useStyles()

  const handleClose = useCallback(() => {
    if (onClose) onClose()
  }, [onClose])

  const userRoleLabel = getUserRoleLabel(user?.stripeRole)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <div className="colstretch align-start">
          <div className={clsx(styles.tag, 'inline')}>
            <Typography variant="button" className={clsx(styles.tagText, 'uppercase')}>
              {t(promoFor === 'professional' ? 'feature_professional' : 'feature_business')}
            </Typography>
          </div>
        </div>
        <Spacing size={2} />
        <Typography variant="h5" component="div">
          {title || ''}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {description && <DialogContentText id="alert-dialog-description">{description}</DialogContentText>}

        <Spacing size={4} />

        <Divider />

        <Spacing size={1.5} />

        <Typography variant="body1" component="div">
          {t('your_current_plan')}:{' '}
          <strong>{userHasAnActiveProduct(user?.stripeRole) ? userRoleLabel : 'Standard'}</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <UpgradeButton />
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    tag: {
      height: 20,
      padding: `0 8px`,
      borderRadius: 10,
      backgroundColor: fade(Colors.Purple, 0.2)
    },
    tagText: {
      fontSize: 10,
      color: Colors.Purple
    }
  })
)

export default PromoDialog
