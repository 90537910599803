import {
  Button,
  ButtonProps,
  createStyles,
  Divider,
  fade,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconCheckmark,
  IconCheckmarkDouble,
  IconPlanBusiness,
  IconPlanProfessional,
  IconPlanStandard
} from '../assets/Svgs'
import AppLoader from '../components/AppLoader'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import DisplayProductFeature from '../types/products/DisplayProductFeature'
import Product from '../types/products/Product'
import ProductPrice from '../types/products/ProductPrice'
import UserProduct from '../types/products/UserProduct'
import { getId, SupportEmail } from '../utils/CommonUtils'
import { formatCurrency } from '../utils/CurrencyUtils'
import { formatDate } from '../utils/DateUtils'
import { getProductDescription, getProductFeatures, userHasAnActiveProduct } from '../utils/ProductsUtils'

const getProductIcon = (productRole?: string) => {
  return productRole === 'professional' ? (
    <IconPlanProfessional />
  ) : productRole === 'business' ? (
    <IconPlanBusiness />
  ) : (
    <IconPlanStandard />
  )
}

type DisplayProduct = Product & {
  Icon?: React.ReactNode
  features: DisplayProductFeature[]
}

enum SubscriptionsTabs {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

const ActionButton = ({ text, color }: { text: string; color?: ButtonProps['color'] }) => {
  const styles = useStyles()
  return (
    <Button
      variant="contained"
      color={color}
      disabled
      className={clsx(
        styles.productButton,
        color === 'primary' ? styles.productButtonPrimaryDisabledReset : styles.productButtonDisabledReset
      )}>
      {text}
    </Button>
  )
}

const Subscriptions: React.FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const tabs = useRef([
    {
      id: SubscriptionsTabs.Monthly,
      label: t('periods.month.recurring')
    },
    {
      id: SubscriptionsTabs.Yearly,
      label: t('periods.year.recurring')
    }
  ])

  const [loading, setLoading] = useState<boolean>(true)
  const [displayProducts, setDisplayProducts] = useState<DisplayProduct[]>([])
  const [userProducts, setUserProducts] = useState<UserProduct[]>([])
  const [submittingProduct, setSubmittingProduct] = useState<string | undefined>(undefined)
  const [submittingCustomerPortal, setSubmittingCustomerPortal] = useState<boolean>(false)
  const [selectedTabId, setSelectedTabId] = useState<string>(tabs.current[0].id)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTabId(newValue)
  }

  const handleProductSelected = useCallback(
    async (productId: string, selectedPrice: ProductPrice) => {
      setSubmittingProduct(productId)

      if (user) {
        const { db } = AppService
        db.getCheckoutUrl(user.id, selectedPrice, result => {
          const checkoutDocData = result.data()

          if (checkoutDocData?.error) {
            setSubmittingProduct(undefined)
            console.error(checkoutDocData?.error)
            enqueueSnackbar(t('error'), { variant: 'error' })
          }
          if (checkoutDocData?.url) {
            setSubmittingProduct(undefined)
            window.location.assign(checkoutDocData.url)
          }
        })
      }
    },
    [user, enqueueSnackbar, t]
  )

  const handleManageProductsSelected = useCallback(async () => {
    const { functions } = AppService

    setSubmittingCustomerPortal(true)
    const customerPortalUrl = await functions.getCustomerPortalUrl()

    if (customerPortalUrl) {
      window.location.assign(customerPortalUrl)
    } else {
      enqueueSnackbar(t('error'), { variant: 'error' })
    }

    setSubmittingCustomerPortal(false)
  }, [enqueueSnackbar, t])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoading(true)

      try {
        const { db } = AppService

        const productsResult = await db.getProducts()
        if (user) {
          const userProductsResult = await db.getUserProducts(user.id)
          if (!didCancel) setUserProducts(userProductsResult)
        }

        if (!didCancel)
          setDisplayProducts(
            (
              [
                {
                  id: getId(),
                  active: true,
                  description: t('product_standard_description'),
                  name: 'Standard',
                  Icon: getProductIcon(),
                  features: getProductFeatures(t, 'free'),
                  prices: [
                    {
                      id: getId(),
                      active: true,
                      type: 'one_time',
                      currency: '',
                      unitAmount: 0
                    }
                  ]
                }
              ] as DisplayProduct[]
            ).concat(
              productsResult
                .filter(p => p.active)
                .map(p => ({
                  ...p,
                  description: getProductDescription(t, p),
                  Icon: getProductIcon(p.role),
                  features: p.role ? getProductFeatures(t, p.role) : []
                }))
            )
          )
      } catch (err) {}

      if (!didCancel) setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [user, t])

  const userActiveProduct: UserProduct | undefined =
    userProducts.find(p => p.product.name.toLowerCase() === 'business') || userProducts[0]

  const periodEndDateString = userActiveProduct?.currentPeriodEnd
    ? formatDate(userActiveProduct.currentPeriodEnd, 'PPP')
    : ''

  return (
    <NavigationDrawerContainer>
      <Typography variant="h3">{t('subscriptions')}</Typography>

      <Spacing size={4} />

      <div className={styles.topContainer}>
        {loading ? (
          [...Array(2).keys()].map(i => <Skeleton key={i} variant="rect" className={styles.cardLoader} />)
        ) : userProducts.length === 0 ? (
          <div className={clsx(styles.card, styles.freePlanGrid, { [styles.freePlanGridPremium]: user?.isPremiumApp })}>
            <div className="colstretch">
              <Typography variant="body1" component="div" color="textSecondary">
                <strong>{t('your_current_plan')}</strong>
              </Typography>

              <Spacing size={2} />

              <div className="inline justify-between">
                <Typography variant="h3" component="div">
                  {'Standard'}
                </Typography>
              </div>
            </div>
            {user?.isPremiumApp && (
              <React.Fragment>
                {isSmDown ? <Divider /> : <div className={styles.verticalDivider} />}
                <Typography variant="subtitle1" color="textSecondary" component="div">
                  {t('premium_discount_promo')}
                </Typography>
              </React.Fragment>
            )}
          </div>
        ) : (
          <React.Fragment>
            <div className={styles.card}>
              <Typography variant="body1" component="div" color="textSecondary">
                <strong>{t('your_current_plan')}</strong>
              </Typography>

              <Spacing size={2} />

              <div className={clsx(styles.userProductDetailsRow, 'inline justify-between')}>
                <Typography variant="h3" component="div">
                  {userActiveProduct?.product.name || ''}
                </Typography>

                <div className={clsx('inline', styles.userProductDetails)}>
                  <div className={styles.userProductIconContainer}>
                    {getProductIcon(userActiveProduct?.product.role)}
                  </div>

                  <div className={styles.productPriceLabelContainer}>
                    <Typography
                      variant="h3"
                      component="div"
                      className={clsx(styles.productPrice, styles.productPriceSmall)}>
                      {userActiveProduct.price.unitAmount === 0
                        ? t('free')
                        : formatCurrency(userActiveProduct.price.unitAmount / 100, userActiveProduct.price.currency)}
                    </Typography>
                    {userActiveProduct.price.unitAmount !== 0 && (
                      <Typography
                        variant="body2"
                        component="div"
                        color="textSecondary"
                        className={styles.productPriceDuration}>{`/${t(
                        `periods.${userActiveProduct.price.recurring?.interval || 'month'}.noun`
                      )}`}</Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={clsx(styles.card, 'colstretch')}>
              <Typography variant="body1" component="div" color="textSecondary">
                <strong>{t(userActiveProduct?.cancelAtPeriodEnd ? 'renewal_canceled' : 'next_renewal')}</strong>
              </Typography>

              <Spacing size={1} />

              <div className={clsx('inline justify-between fullflex', styles.userProductActionsRow)}>
                <Typography variant="button" component="div">
                  {userActiveProduct?.currentPeriodEnd
                    ? userActiveProduct?.cancelAtPeriodEnd
                      ? t(userActiveProduct.status === 'trialing' ? 'plan_end_after_free_trial' : 'plan_end', {
                          date: periodEndDateString
                        })
                      : periodEndDateString
                    : ''}
                </Typography>

                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.manageProductsButton}
                  onClick={handleManageProductsSelected}
                  disabled={submittingCustomerPortal}>
                  {submittingCustomerPortal ? <AppLoader size={20} /> : t('manage_subscription')}
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      <Spacing size={2} />

      <div className={clsx(styles.card, styles.cardSmallPadding)}>
        <div className={styles.tabsWrapper}>
          <div className={styles.tabsContainer}>
            <Tabs
              variant={isSmDown ? 'fullWidth' : 'standard'}
              value={selectedTabId}
              onChange={handleTabChange}
              TabIndicatorProps={{ children: <div /> }}
              aria-label={t('places')}
              classes={{ root: styles.tabs }}>
              {tabs.current.map(tab => (
                <Tab
                  value={tab.id}
                  label={
                    <div className="inline">
                      <Typography variant="subtitle2" className={styles.tabLabel}>
                        {tab.label}
                      </Typography>
                      {tab.id === SubscriptionsTabs.Yearly && (
                        <div className={clsx(styles.discountTagContainer, 'inline')}>
                          <Typography variant="caption" component="div" className={styles.discountTag}>
                            -20%
                          </Typography>
                        </div>
                      )}
                    </div>
                  }
                  key={tab.id}
                  className={styles.tab}
                  classes={{ selected: styles.tabSelected }}
                />
              ))}
            </Tabs>
          </div>
        </div>

        <Spacing size={10} smDownSize={8} />

        <div className={styles.grid}>
          {loading
            ? [...Array(3).keys()].map(i => (
                <div key={i}>
                  <Skeleton variant="rect" className={styles.productLoader} />
                </div>
              ))
            : displayProducts.map(product => {
                const filteredPrices = product.prices.filter(
                  price =>
                    (selectedTabId === SubscriptionsTabs.Yearly
                      ? price.recurring?.interval === 'year'
                      : price.recurring?.interval === 'month') || !price.recurring
                )

                const productPrice: ProductPrice | undefined = filteredPrices.find(
                  price => price.unitAmount === 0 || (user?.isPremiumApp ? price.isPremiumApp : !price.isPremiumApp)
                )
                const fullPrice: ProductPrice | undefined = filteredPrices.find(price =>
                  user?.isPremiumApp ? !price.isPremiumApp : false
                )
                const disabled =
                  !(productPrice && productPrice.unitAmount !== 0) ||
                  Boolean(submittingProduct) ||
                  product.isComingSoon ||
                  userProducts.some(userProduct => userProduct.product.id === product.id)

                return (
                  <div
                    key={product.id}
                    className={clsx(styles.product, {
                      [styles.productSelectable]: !disabled && !Boolean(submittingProduct)
                    })}
                    onClick={
                      !disabled && productPrice ? () => handleProductSelected(product.id, productPrice) : undefined
                    }>
                    {product.id === submittingProduct && (
                      <div className={clsx(styles.productOverlay, styles.productLoaderOverlay)}>
                        <AppLoader />
                      </div>
                    )}
                    {Boolean(submittingProduct) && (
                      <div className={clsx(styles.productOverlay, styles.productSubmittingOverlay)} />
                    )}

                    <div className={styles.productIconContainer}>{product.Icon && product.Icon}</div>

                    <Spacing size={isSmDown ? 7 : 5} />

                    <Typography variant="h3" component="div">
                      {product.name}
                    </Typography>
                    <Spacing size={1} />
                    {product.isComingSoon ? (
                      <div className={clsx(styles.comingSoonTagContainer, 'inline')}>
                        <Typography variant="h3" component="div" className={styles.comingSoonTag}>
                          {t('coming_soon')}
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant="body2" component="div" color="textSecondary">
                        <strong>{product.description || ' '}</strong>
                      </Typography>
                    )}

                    <Spacing size={5} />

                    {productPrice && (
                      <div className={styles.productPriceContainer}>
                        {fullPrice && (
                          <Typography variant="body1" color="textSecondary" className={styles.productPriceFull}>
                            {formatCurrency(fullPrice.unitAmount / 100, fullPrice.currency)}
                          </Typography>
                        )}

                        <div className={styles.productPriceLabelContainer}>
                          <Typography variant="h3" component="div" className={styles.productPrice}>
                            {productPrice.unitAmount === 0
                              ? t('free')
                              : formatCurrency(productPrice.unitAmount / 100, productPrice.currency)}
                          </Typography>
                          {productPrice.unitAmount !== 0 && (
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                              className={styles.productPriceDuration}>{`/${t(
                              `periods.${productPrice.recurring?.interval || 'month'}.noun`
                            )}`}</Typography>
                          )}
                        </div>

                        {productPrice.unitAmount !== 0 && (
                          <Typography
                            variant="body2"
                            component="div"
                            color="textSecondary"
                            className={styles.productFreeTrialText}>
                            {t('one_week_free')}
                          </Typography>
                        )}
                      </div>
                    )}

                    <Spacing size={3} />

                    <div className={styles.productFeaturesContainer}>
                      {product.features.map(feature => (
                        <div
                          key={feature.id}
                          className={clsx('inline', styles.productFeature, {
                            [styles.productFeatureActive]: feature.activeCheck
                          })}>
                          {feature.doubleCheck ? <IconCheckmarkDouble /> : <IconCheckmark />}
                          <Typography variant="subtitle2" component="div" className={styles.productFeatureLabel}>
                            {feature.label}
                          </Typography>
                        </div>
                      ))}
                    </div>

                    <Spacing size={3} />

                    {productPrice && !product.isComingSoon ? (
                      (productPrice.unitAmount === 0 && userProducts.length === 0) ||
                      user?.stripeRole === product.role ? (
                        <Tooltip
                          title={
                            // Only display billing recurrence switch tip if product is not free and for the opposite billing type
                            productPrice.unitAmount !== 0 &&
                            userProducts.some(p =>
                              selectedTabId === SubscriptionsTabs.Yearly
                                ? p.price.recurring?.interval === 'month'
                                : p.price.recurring?.interval === 'year'
                            ) ? (
                              <Typography variant="body2">{t('billing_recurrence_switch_tip')}</Typography>
                            ) : (
                              ''
                            )
                          }>
                          <div className={styles.productButton}>
                            <Button variant="contained" disabled className={styles.productButtonDisabledReset}>
                              {t('current_plan')}
                            </Button>
                          </div>
                        </Tooltip>
                      ) : !userHasAnActiveProduct(user?.stripeRole) && productPrice.unitAmount !== 0 ? (
                        <ActionButton color="primary" text={t('activate_plan')} />
                      ) : product.role === 'business' && user?.stripeRole === 'professional' ? (
                        <ActionButton color="primary" text={t('upgrade')} />
                      ) : product.role === 'professional' && user?.stripeRole === 'business' ? (
                        <ActionButton text={t('downgrade')} />
                      ) : (
                        <Spacing size={5} />
                      )
                    ) : product.isComingSoon ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={clsx(styles.productButton)}
                        href={`mailto:${SupportEmail}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {t('ask_for_free_trial')}
                      </Button>
                    ) : (
                      <Spacing size={5} />
                    )}

                    <Spacing size={3} />
                  </div>
                )
              })}
        </div>
      </div>
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridAutoRows: '1fr',
      columnGap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridAutoRows: 'auto',
        rowGap: theme.spacing(2)
      }
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoRows: '1fr',
      columnGap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing(6)
      }
    },
    freePlanGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridColumnStart: 1,
      gridColumnEnd: 3
    },
    freePlanGridPremium: {
      gridTemplateColumns: '1fr 1px 2fr',
      columnGap: theme.spacing(4),
      gridAutoRows: '1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridAutoRows: 'auto',
        rowGap: theme.spacing(2)
      }
    },
    card: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius * 2,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3)
      }
    },
    cardSmallPadding: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
      }
    },
    cardLoader: {
      borderRadius: theme.shape.borderRadius * 2,
      height: 120
    },
    manageProductsButton: {
      marginTop: 'auto',
      marginBottom: -8,
      minHeight: 40,
      minWidth: 240,
      [theme.breakpoints.down('md')]: {
        marginTop: 12,
        marginBottom: 0
      }
    },
    userProductDetailsRow: {
      marginBottom: -24,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column !important',
        alignItems: 'flex-start !important'
      }
    },
    userProductActionsRow: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column !important',
        alignItems: 'flex-start !important'
      }
    },
    userProductDetails: {
      position: 'relative'
    },
    userProductIconContainer: {
      position: 'absolute',
      left: 0,
      transform: 'translateX(-100%)',
      '& svg': {
        transform: 'scale(0.6) translateX(-40%)'
      },
      [theme.breakpoints.down('md')]: {
        position: 'static',
        transform: 'none'
      }
    },
    productLoader: {
      borderRadius: theme.shape.borderRadius * 4,
      height: 320
    },
    product: {
      flex: 1,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius * 4,
      boxShadow: `0px 0px 88px ${fade(theme.palette.text.primary, 0.06)}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      padding: `0 ${theme.spacing(1)}px`
    },
    productSelectable: {
      cursor: 'pointer',
      transition: 'background-color ease 0.2s',
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.1)
      },
      [theme.breakpoints.up('md')]: {
        '& $productIconContainer': {
          transition: 'transform ease 0.2s'
        },
        '&:hover': {
          '& $productIconContainer': {
            transform: 'translateY(8px)'
          }
        }
      }
    },
    productOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      borderRadius: theme.shape.borderRadius * 4
    },
    productLoaderOverlay: {
      zIndex: 2,
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    productSubmittingOverlay: {
      backgroundColor: fade(theme.palette.background.default, 0.6)
    },
    productIconContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 100,
      '& svg': {
        transform: 'translateY(-100%)',
        marginTop: 24
      },
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          marginTop: 40
        }
      }
    },
    productPriceContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative'
    },
    productPrice: {
      fontSize: 30,
      fontWeight: 900
    },
    productPriceSmall: {
      fontSize: 24
    },
    productPriceDuration: {
      textTransform: 'lowercase',
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    productPriceLabelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    productButton: {
      marginTop: 'auto',
      color: `${theme.palette.primary.contrastText} !important`
    },
    productButtonDisabledReset: {
      color: `${theme.palette.getContrastText(theme.palette.grey[300])} !important`,
      backgroundColor: `${theme.palette.grey[300]} !important`
    },
    productButtonPrimaryDisabledReset: {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`
    },
    productPriceFull: {
      position: 'absolute',
      top: 0,
      transform: 'translateY(-100%)',
      textDecoration: 'line-through'
    },
    productFreeTrialText: {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)'
    },
    productFeaturesContainer: {
      textAlign: 'left'
    },
    productFeature: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5)
      },
      '& svg': {
        flexShrink: 0
      },
      '& path': {
        fill: Colors.IconGray
      }
    },
    productFeatureLabel: {
      marginLeft: theme.spacing(1)
    },
    productFeatureActive: {
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    verticalDivider: {
      width: 1,
      backgroundColor: theme.palette.divider
    },
    comingSoonTagContainer: {
      backgroundColor: Colors.Orange,
      height: 16,
      padding: `0 ${theme.spacing(1)}px`,
      borderRadius: theme.shape.borderRadius * 2,
      marginLeft: theme.spacing(1)
    },
    comingSoonTag: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: Colors.White,
      fontSize: 10
    },
    tabsWrapper: {
      margin: `-24px -24px -16px`,
      padding: `24px 24px 16px`,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius * 2,
      [theme.breakpoints.down('sm')]: {
        margin: `-16px`,
        padding: 16,
        position: 'sticky',
        top: 0,
        zIndex: theme.zIndex.appBar - 1
      }
    },
    tabsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    tabs: {
      height: 32,
      minHeight: 32,
      '& .MuiTab-root': {
        minHeight: 32
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    tabLabel: {
      fontWeight: 'bold',
      opacity: 0.6
    },
    tab: {
      opacity: 1
    },
    tabSelected: {
      '& $tabLabel': {
        opacity: 1
      }
    },
    discountTagContainer: {
      backgroundColor: Colors.Purple,
      height: 16,
      padding: `0 ${theme.spacing(1)}px`,
      borderRadius: theme.shape.borderRadius * 2,
      marginLeft: theme.spacing(1)
    },
    discountTag: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: Colors.White,
      fontSize: 10
    }
  })
)

export default Subscriptions
