import createPalette from '@material-ui/core/styles/createPalette'
import AppPaletteBase from './AppPaletteBase'
import Colors from './Colors'

const AppPaletteLight = createPalette({
  type: 'light',
  ...AppPaletteBase,
  background: {
    default: Colors.White,
    paper: Colors.MediumGray
  }
})

export default AppPaletteLight
