import { Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

type ReadOnlyFieldProps = {
  value: unknown
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({ value }: ReadOnlyFieldProps) => {
  const styles = useStyles()

  return (
    <Typography variant="button" component="div" className={clsx('inline', styles.root)}>
      {typeof value === 'string' ? value : String(value)}
    </Typography>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 47,
      marginBottom: 18
    }
  })
)

export default ReadOnlyField
