import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type AlertDialogProps = {
  open: boolean
  title?: string
  confirmButtonText?: string
  description?: string
  actionDelete?: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  title,
  confirmButtonText,
  description,
  actionDelete,
  onClose,
  onConfirm
}: AlertDialogProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    if (onClose) onClose()
  }, [onClose])

  const handleConfirm = useCallback(() => {
    handleClose()
    if (onConfirm) onConfirm()
  }, [handleClose, onConfirm])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" component="div">
          {title || ''}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {description && <DialogContentText id="alert-dialog-description">{description}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          autoFocus
          className={clsx({ [styles.actionDelete]: actionDelete })}>
          {confirmButtonText || t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionDelete: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  })
)

export default AlertDialog
