import { Button, Hidden, IconButton, LinearProgress, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import AppLogo from '../AppLogo'

type HeaderAction = {
  handler?: () => void
  label?: string
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

type HeaderProps = {
  progress?: number
  actionsMdUp?: HeaderAction[]
  leftActionsSmDown?: HeaderAction[]
  title?: string
}

const Header: React.FC<HeaderProps> = ({ progress, actionsMdUp, leftActionsSmDown, title }: HeaderProps) => {
  const styles = useStyles()

  return (
    <React.Fragment>
      <div className={styles.toolbar} />
      <div className={styles.root}>
        <div className={styles.content}>
          {title ? (
            <Typography variant="body1" component="div" className={styles.title}>
              {title}
            </Typography>
          ) : (
            <AppLogo clickDisabled />
          )}
          <Hidden smDown>
            {actionsMdUp
              ? actionsMdUp.map((action, index) => (
                  <Button key={`actions_md_up_${index}`} variant="contained" color="primary" onClick={action.handler}>
                    {action.label}
                  </Button>
                ))
              : null}
          </Hidden>
          <Hidden mdUp>
            <div className={styles.actionsContainer}>
              <div className="inline">
                {leftActionsSmDown
                  ? leftActionsSmDown.map((action, index) => (
                      <IconButton
                        key={`left_actions_${index}`}
                        className={styles.actionIconButton}
                        onClick={action.handler}>
                        {action.Icon ? <action.Icon className={styles.actionIcon} /> : null}
                      </IconButton>
                    ))
                  : null}
              </div>
              <div className="inline"></div>
            </div>
          </Hidden>
        </div>
        {progress !== undefined ? <LinearProgress variant="determinate" value={progress} /> : null}
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.appBar
    },
    toolbar: theme.mixins.toolbar,
    content: {
      ...theme.mixins.toolbar,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(3)}px`,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        justifyContent: 'center'
      }
    },
    actionsContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      padding: `0 ${theme.spacing(0.5)}px`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    actionIcon: {
      width: 32,
      height: 32,
      flexShrink: 0,
      '& path': {
        fill: theme.palette.primary.main
      }
    },
    actionIconButton: {
      padding: 4
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    }
  })
)

export default Header
