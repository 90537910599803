import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'

type ChartLegendItemProps = {
  color: string
  label: string
  value: string
}

const ChartLegendItem: React.FC<ChartLegendItemProps> = ({ color, label, value }: ChartLegendItemProps) => {
  const styles = useStyles()

  return (
    <div className="inline">
      <div className={styles.color} style={{ borderColor: color }} />
      <Typography variant="caption" color="textSecondary" className={styles.label}>{`${label}:`}</Typography>
      <Typography variant="caption" className={styles.valueText} style={{ color }}>
        {value}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    color: {
      width: 8,
      height: 8,
      borderRadius: 8,
      borderWidth: 2,
      borderStyle: 'solid',
      marginRight: theme.spacing(1),
      flexShrink: 0
    },
    label: {
      overflowWrap: 'break-word'
    },
    valueText: {
      fontWeight: 'bold',
      overflowWrap: 'break-word',
      marginLeft: theme.spacing(0.5)
    }
  })
)

export default ChartLegendItem
