import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig: Record<string, Object> = {
  production: {
    apiKey: 'AIzaSyCgXRlB9mEjkuaW38YCU_U-Bjf5eWqn8TE',
    authDomain: 'gestisci-affitti.firebaseapp.com',
    databaseURL: 'https://gestisci-affitti.firebaseio.com',
    projectId: 'gestisci-affitti',
    storageBucket: 'gestisci-affitti.appspot.com',
    messagingSenderId: '680680029078',
    appId: '1:680680029078:web:5b305bce39ea5c97d4bbb7',
    measurementId: 'G-WGXLS6HHP8'
  },
  test: {
    apiKey: 'AIzaSyCnTt0ANAHz2y2QOjSSkOn89To7KxIrif8',
    authDomain: 'affittomio-test.firebaseapp.com',
    projectId: 'affittomio-test',
    storageBucket: 'affittomio-test.appspot.com',
    messagingSenderId: '363412610083',
    appId: '1:363412610083:web:ef12fac4593ef6b477e596',
    measurementId: 'G-R0BJ3WKDDN'
  }
}

const FunctionsLocation = 'europe-west1'

class FirebaseService {
  firestore!: firebase.firestore.Firestore
  auth!: firebase.auth.Auth
  storage!: firebase.storage.Storage
  functions!: firebase.functions.Functions
  analytics!: firebase.analytics.Analytics

  init = () => {
    try {
      const env = process.env.REACT_APP_ENVIRONMENT || 'production'
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig[env])
      }

      this.firestore = firebase.firestore()
      this.auth = firebase.auth()
      this.storage = firebase.storage()
      this.functions = firebase.app().functions(FunctionsLocation)
      this.analytics = firebase.analytics()
    } catch (err) {
      return false
    }
    return true
  }
}

export default new FirebaseService()
