import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { publish } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { IconClose } from '../assets/Svgs'
import InputColor from '../components/inputs/InputColor'
import InputText from '../components/inputs/InputText'
import Spacing from '../components/Spacing'
import StepsActions from '../components/steps/StepsActions'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'

type AddTransactionCategoryValues = {
  label: string
  color: string | null
}

const AddTransactionCategory: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const { transactionCategoryId } = useParams<{ transactionCategoryId?: string }>()

  const isEditing = transactionCategoryId !== 'new'

  const defaultInitialValues = useRef<AddTransactionCategoryValues>({
    label: '',
    color: null
  })
  const [initialValues, setInitialValues] = useState<AddTransactionCategoryValues>(defaultInitialValues.current)
  const [loading, setLoading] = useState<boolean>(false)

  const validationSchema = useRef(
    Yup.object({
      label: Yup.string().required(t('required_field'))
    })
  )

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const handleDeleteSelected = useCallback(() => {
    if (transactionCategoryId) {
      const { db } = AppService
      db.deleteTransactionCategory(transactionCategoryId)

      publish('transactionCategoryDeleted', { transactionCategoryId })
    }

    handleClose()
  }, [transactionCategoryId, handleClose])

  const handleSubmit = useCallback(
    async (values: AddTransactionCategoryValues, { setSubmitting }) => {
      let error = false

      if (user) {
        try {
          const { db } = AppService
          if (transactionCategoryId && isEditing) {
            const success = await db.updateTransactionCategory(transactionCategoryId, {
              label: values.label,
              color: values.color
            })
            if (success) {
              publish('transactionCategoryUpdated', { transactionCategoryId })
            }
          } else {
            const id = await db.createTransactionCategory(
              {
                label: values.label,
                color: values.color
              },
              user.id
            )
            if (id) {
              publish('transactionCategoryCreated', { transactionCategoryId: id })
            }
          }
        } catch (err) {
          error = true
        }
      } else {
        error = true
      }

      if (error) {
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else {
        handleClose()
      }

      setSubmitting(false)
    },
    [transactionCategoryId, isEditing, enqueueSnackbar, t, user, handleClose]
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!(transactionCategoryId && isEditing)) return

      if (!didCancel) setLoading(true)

      const { db } = AppService

      const result = await db.getTransactionCategory(transactionCategoryId)

      if (!didCancel) {
        if (result) {
          setInitialValues({
            label: result.label,
            color: result.color || null
          })
        }
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [transactionCategoryId, isEditing])

  return (
    <Dialog
      open
      aria-labelledby="add-rent-dialog-title"
      aria-describedby="add-rent-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.current}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <React.Fragment>
                <DialogTitle disableTypography id="alert-dialog-title">
                  <div className={clsx(styles.title, 'inline', 'align-start', 'justify-between')}>
                    <div className="inline">
                      <Typography variant="h3" component="div">
                        {t(`${isEditing ? 'edit' : 'add'}_transaction_category`)}
                      </Typography>
                    </div>

                    <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
                      <IconClose />
                    </IconButton>
                  </div>
                </DialogTitle>
              </React.Fragment>

              <DialogContent>
                <InputText
                  label={t('name')}
                  placeholder={t('name')}
                  id="label"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.label}
                  error={Boolean(errors.label) && touched.label}
                  disabled={isSubmitting}
                  loading={loading}
                  helperText={errors.label && touched.label ? errors.label : ' '}
                  fullFlex
                  smallInput
                />
                <Spacing size={0.5} />
                <InputColor
                  label={t('color')}
                  value={values.color || undefined}
                  onChange={color => setFieldValue('color', color)}
                />
              </DialogContent>

              <StepsActions
                variant="dialog"
                showOutlinedBackButton={isEditing}
                outlinedBackButtonText={t('delete')}
                outlinedButtonColor="red"
                submitButton
                continueButtonText={t(isEditing ? 'save' : 'add')}
                submitting={isSubmitting}
                onGoBack={handleDeleteSelected}
              />
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2)
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    }
  })
)

export default AddTransactionCategory
