import { Button, Fab, Hidden, Popover, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconAdd, IconAddEvent, IconExpenses, IconRent } from '../../assets/Svgs'
import { RoutesPaths } from '../../Routes'
import AppService from '../../services/AppService'
import Colors from '../../styles/Colors'
import PopoverMenu from '../PopoverMenu'

type PlaceDetailAddButtonProps = {
  placeId?: string
}

const PlaceDetailAddButton: React.FC<PlaceDetailAddButtonProps> = ({ placeId }: PlaceDetailAddButtonProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { role } = AppService

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleBookingSelected = useCallback(() => {
    if (!placeId) return

    handleClose()
    if (!role.canAddBooking()) {
      history.push(RoutesPaths.PromoDialog, {
        isModal: true,
        title: t('add_booking_promo_title'),
        description: t('add_booking_promo_description'),
        promoFor: 'business'
      })
    } else {
      history.push(RoutesPaths.AddBookingDialog.replace(':placeId', placeId), { isModal: true, isBooking: true })
    }
  }, [handleClose, history, placeId, role, t])

  const handleRentSelected = useCallback(() => {
    if (!placeId) return

    handleClose()
    history.push(RoutesPaths.AddRentDialog.replace(':placeId', placeId), { isModal: true })
  }, [history, placeId, handleClose])

  const handleExpensesSelected = useCallback(() => {
    if (!placeId) return

    handleClose()
    history.push(RoutesPaths.AddTransactionDialog.replace(':placeId', placeId), { isModal: true })
  }, [history, placeId, handleClose])

  const open = Boolean(anchorEl)
  const id = open ? 'place-detail-add-popover' : undefined

  return (
    <React.Fragment>
      <Hidden smDown>
        <div className="inline">
          <Button variant="contained" color="primary" onClick={handleClick} disabled={!placeId}>
            {t('add')}
          </Button>
        </div>
      </Hidden>

      <Hidden mdUp>
        <Fab color="primary" aria-label={t('add')} className={styles.fab} onClick={handleClick}>
          <IconAdd />
        </Fab>
      </Hidden>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        transitionDuration={0}
        onClose={handleClose}
        anchorOrigin={
          isSmDown
            ? {
                vertical: 'top',
                horizontal: 'right'
              }
            : {
                vertical: 'bottom',
                horizontal: 'right'
              }
        }
        transformOrigin={
          isSmDown
            ? {
                vertical: 'bottom',
                horizontal: 'right'
              }
            : {
                vertical: 'top',
                horizontal: 'right'
              }
        }
        classes={{ paper: styles.popoverPaper }}>
        <PopoverMenu
          items={[
            {
              text: t('add_booking.title_add'),
              Icon: <IconAddEvent />,
              iconColor: Colors.Green,
              handler: handleBookingSelected
            },
            {
              text: t('addrent.title_add'),
              Icon: <IconRent />,
              iconColor: Colors.Green,
              handler: handleRentSelected
            },
            {
              text: t('addexpense.title'),
              Icon: <IconExpenses />,
              iconColor: Colors.Red,
              handler: handleExpensesSelected
            }
          ]}
        />
      </Popover>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      borderRadius: 12,
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: -theme.spacing(1)
      }
    },
    fab: {
      position: 'fixed',
      bottom: 24,
      right: 16,
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    }
  })
)

export default PlaceDetailAddButton
