import React, { useEffect } from 'react'
import { Route, RouteComponentProps, useHistory } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import RoutingSwitch from './components/routing-switch/RoutingSwitch'
import { useUser } from './hooks/useUser'
import AddDocument from './pages/AddDocument'
import AddGuest from './pages/AddGuest'
import AddRent from './pages/AddRent'
import AddTransaction from './pages/AddTransaction'
import AddTransactionCategory from './pages/AddTransactionCategory'
import CalendarPage from './pages/CalendarPage'
import Dashboard from './pages/Dashboard'
import Documents from './pages/Documents'
import DocumentsListModal from './pages/DocumentsListModal'
import EditPlace from './pages/EditPlace'
import Guests from './pages/Guests'
import Home from './pages/Home'
import NewPlace from './pages/NewPlace'
import Notifications from './pages/Notifications'
import Permissions from './pages/Permissions'
import PlaceDetail from './pages/PlaceDetail'
import Places from './pages/Places'
import PromoDialogPage from './pages/PromoDialogPage'
import RentReceiptDialog from './pages/RentReceiptDialog'
import Settings from './pages/Settings'
import Statistics from './pages/Statistics'
import Subscriptions from './pages/Subscriptions'
import TransactionsCategories from './pages/TransactionsCategories'
import WhatsNew from './pages/WhatsNew'

export const DefaultNotAuthorizedPath = '/'

export const RoutesPaths = {
  Dashboard: '/dashboard',
  Places: '/places',
  Guests: '/guests',
  Settings: '/settings',
  Notifications: '/notifications',
  Permissions: '/permissions',
  Statistics: '/statistics',
  Documents: '/documents',
  Subscriptions: '/subscriptions',
  TransactionsCategories: '/transactionscategories',
  PlaceNew: '/place/new',
  PlaceDetail: '/place/:placeId',
  AddRentDialog: '/place/:placeId/addrent',
  AddBookingDialog: '/place/:placeId/addbooking',
  EditRentDialog: '/place/:placeId/editrent/:rentId',
  AddTransactionDialog: '/place/:placeId/addexpense',
  EditTransactionDialog: '/place/:placeId/editexpense/:transactionId',
  EditGuestDialog: '/guests/:guestId',
  EditTransactionCategoryDialog: '/transactionscategories/:transactionCategoryId',
  Calendar: '/calendar',
  EditPlaceDialog: '/place/:placeId/edit',
  EditDocumentDialog: '/documents/:documentId',
  PlaceDocumentsList: '/place/:placeId/documents',
  TransactionDocumentsList: '/transaction/:transactionId/documents',
  GuestDocumentsList: '/guest/:guestId/documents',
  RentReceipt: '/rent/:rentId/receipt/:transactionId',
  PromoDialog: '/promo',
  WhatsNew: '/whatsnew'
}

type RouteObject = {
  path: string
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  public?: boolean
  pageTitle?: string
}

const RoutesDefinition: RouteObject[] = [
  {
    path: RoutesPaths.PlaceNew,
    component: NewPlace
  },
  {
    path: RoutesPaths.Dashboard,
    component: Dashboard
  },
  {
    path: RoutesPaths.Notifications,
    component: Notifications
  },
  {
    path: RoutesPaths.Permissions,
    component: Permissions
  },
  {
    path: RoutesPaths.Documents,
    component: Documents
  },
  {
    path: RoutesPaths.Places,
    component: Places
  },
  {
    path: RoutesPaths.Guests,
    component: Guests
  },
  {
    path: RoutesPaths.Statistics,
    component: Statistics
  },
  {
    path: RoutesPaths.Settings,
    component: Settings
  },
  {
    path: RoutesPaths.Calendar,
    component: CalendarPage
  },
  {
    path: RoutesPaths.Subscriptions,
    component: Subscriptions
  },
  {
    path: RoutesPaths.TransactionsCategories,
    component: TransactionsCategories
  },
  {
    path: RoutesPaths.PlaceDetail,
    component: PlaceDetail
  },
  {
    path: DefaultNotAuthorizedPath,
    component: Home,
    public: true,
    pageTitle: 'Home'
  }
]

const ModalsDefinition: RouteObject[] = [
  {
    path: RoutesPaths.AddRentDialog,
    component: AddRent
  },
  {
    path: RoutesPaths.AddBookingDialog,
    component: AddRent
  },
  {
    path: RoutesPaths.EditRentDialog,
    component: AddRent
  },
  {
    path: RoutesPaths.AddTransactionDialog,
    component: AddTransaction
  },
  {
    path: RoutesPaths.EditTransactionDialog,
    component: AddTransaction
  },
  {
    path: RoutesPaths.EditGuestDialog,
    component: AddGuest
  },
  {
    path: RoutesPaths.EditDocumentDialog,
    component: AddDocument
  },
  {
    path: RoutesPaths.EditTransactionCategoryDialog,
    component: AddTransactionCategory
  },
  {
    path: RoutesPaths.EditPlaceDialog,
    component: EditPlace
  },
  {
    path: RoutesPaths.PlaceDocumentsList,
    component: DocumentsListModal
  },
  {
    path: RoutesPaths.TransactionDocumentsList,
    component: DocumentsListModal
  },
  {
    path: RoutesPaths.GuestDocumentsList,
    component: DocumentsListModal
  },
  {
    path: RoutesPaths.RentReceipt,
    component: RentReceiptDialog
  },
  {
    path: RoutesPaths.PromoDialog,
    component: PromoDialogPage
  },
  {
    path: RoutesPaths.WhatsNew,
    component: WhatsNew
  }
]

const Routes: React.FC = () => {
  const { user } = useUser()
  const history = useHistory()

  useEffect(() => {
    const checkWhatsNew = async () => {
      if (user && !user.whatsNewViewed) {
        history.push(RoutesPaths.WhatsNew)
      }
    }

    checkWhatsNew()
  }, [history, user])

  return (
    <React.Fragment>
      <RoutingSwitch>
        {RoutesDefinition.map(route =>
          route.public ? (
            <Route key={route.path} path={route.path} component={route.component} />
          ) : (
            <PrivateRoute key={route.path} path={route.path} component={route.component} />
          )
        )}
      </RoutingSwitch>
      {ModalsDefinition.map(route =>
        route.public ? (
          <Route key={route.path} path={route.path} component={route.component} />
        ) : (
          <PrivateRoute key={route.path} path={route.path} component={route.component} />
        )
      )}
    </React.Fragment>
  )
}

export default Routes
