import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import { DefaultNotAuthorizedPath } from '../Routes'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useUser()

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: DefaultNotAuthorizedPath, state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
