import { Button, Fab, Hidden, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IconAdd, ImageTransactionsCategoriesEmpty } from '../assets/Svgs'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import TransactionsCategoriesList from '../components/transactions-categories/TransactionsCategoriesList'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import TransactionCategory from '../types/TransactionCategory'

const TransactionsCategories: React.FC = () => {
  const styles = useStyles()
  const { user } = useUser()
  const history = useHistory()
  const { t } = useTranslation()

  const [transactionsCategories, setTransactionsCategories] = useState<TransactionCategory[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const initialized = useRef<boolean>(false)

  const handleNewSelected = useCallback(
    () =>
      history.push(RoutesPaths.EditTransactionCategoryDialog.replace(':transactionCategoryId', 'new'), {
        isModal: true
      }),
    [history]
  )

  const fetchTransactionsCategories = useCallback(async (userId: string) => {
    let items: TransactionCategory[] = []

    try {
      const { db } = AppService
      items = await db.getTransactionCategories(userId)
    } catch (err) {}

    return { items }
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel && !initialized.current) {
        setLoading(true)
      }

      const result = await fetchTransactionsCategories(user.id)

      if (!didCancel) {
        setTransactionsCategories(result.items)
        setLoading(false)

        initialized.current = true
      }
    }

    fetchData()

    const itemCreatedEventToken = subscribe('transactionCategoryCreated', () => fetchData())
    const itemUpdatedEventToken = subscribe('transactionCategoryUpdated', () => fetchData())
    const itemDeletedEventToken = subscribe(
      'transactionCategoryDeleted',
      (eventName: string, { transactionCategoryId }: { transactionCategoryId: string }) =>
        setTransactionsCategories(current => current.filter(t => t.id !== transactionCategoryId))
    )

    return () => {
      didCancel = true

      unsubscribe(itemCreatedEventToken)
      unsubscribe(itemUpdatedEventToken)
      unsubscribe(itemDeletedEventToken)
    }
  }, [fetchTransactionsCategories, user])

  return (
    <NavigationDrawerContainer>
      <Hidden mdUp>
        <Fab color="primary" aria-label={t('add')} className={styles.addButtonFloating} onClick={handleNewSelected}>
          <IconAdd />
        </Fab>
      </Hidden>

      <div className={styles.toolbar}>
        <Typography variant="h3">{t('transactions_categories_label')}</Typography>
        <Hidden smDown>
          <Button variant="contained" color="primary" onClick={handleNewSelected}>
            {t('add')}
          </Button>
        </Hidden>
      </div>

      <Spacing size={5} />

      {transactionsCategories.length === 0 && !loading ? (
        <div className={styles.noResults}>
          <ImageTransactionsCategoriesEmpty />

          <div className="spacing-block-vertical-m" />

          <Typography variant="h6" component="div">
            {t('add_transaction_categories_hint_title')}
          </Typography>

          <div className="spacing-block-vertical-xs" />

          <Typography variant="caption" component="div" color="textSecondary" align="center">
            {t('add_transaction_categories_hint_subtitle')}
          </Typography>
        </div>
      ) : (
        <TransactionsCategoriesList loading={loading} items={transactionsCategories} />
      )}

      <Spacing size={5} smDownSize={10} />
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    noResults: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1
    },
    addButtonFloating: {
      position: 'fixed',
      bottom: 24,
      right: 16,
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    }
  })
)

export default TransactionsCategories
