import { useMediaQuery, useTheme } from '@material-ui/core'
import { endOfMonth } from 'date-fns'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Colors from '../../styles/Colors'
import Transaction from '../../types/Transaction'
import { formatDate } from '../../utils/DateUtils'
import {
  getRentsTransactionsSummaryPeriodDateRanges,
  getRentsTransactionsSummaryPeriodDates,
  getTransactionsAmountForPeriod,
  getTransactionsByTrendStatus
} from '../../utils/RentUtils'
import ChartLegend from '../charts/ChartLegend'
import ChartTooltip from '../charts/ChartTooltip'
import Spacing from '../Spacing'

type StatisticsTotalsChartProps = {
  transactions: Transaction[]
  monthsRange: number
}

const StatisticsTotalsChart: React.FC<StatisticsTotalsChartProps> = ({
  transactions,
  monthsRange
}: StatisticsTotalsChartProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const periodDates = useRef(getRentsTransactionsSummaryPeriodDates(monthsRange))

  const { startDate, endDate } = getRentsTransactionsSummaryPeriodDateRanges(periodDates.current)

  const { inTransactions, outTransactions } = getTransactionsByTrendStatus(transactions)

  const totalIn = getTransactionsAmountForPeriod(startDate, endDate, inTransactions)
  const totalOut = getTransactionsAmountForPeriod(startDate, endDate, outTransactions)

  const data: any[] = periodDates.current.map(p => ({
    name: p,
    date: p,
    in: getTransactionsAmountForPeriod(p, endOfMonth(p), inTransactions),
    out: getTransactionsAmountForPeriod(p, endOfMonth(p), outTransactions)
  }))

  return (
    <div className="colstretch fullflex">
      <ChartLegend
        items={[
          {
            color: Colors.Green,
            label: t('takings'),
            value: totalIn,
            isCurrency: true
          },
          {
            color: Colors.Red,
            label: t('expenses'),
            value: totalOut,
            isCurrency: true
          }
        ]}
      />

      <Spacing size={4} />

      <ResponsiveContainer width="100%" height={isSmDown ? 240 : 320}>
        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorIn" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Colors.Green} stopOpacity={0.2} />
              <stop offset="90%" stopColor={Colors.Green} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorOut" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={Colors.Red} stopOpacity={0.2} />
              <stop offset="90%" stopColor={Colors.Red} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            interval={1}
            tickFormatter={value => formatDate(value, 'MMM')}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Tooltip content={<ChartTooltip />} />
          <Area
            type="monotone"
            dataKey="in"
            strokeWidth={2}
            stroke={Colors.Green}
            fillOpacity={1}
            fill="url(#colorIn)"
          />
          <Area
            type="monotone"
            dataKey="out"
            strokeWidth={2}
            stroke={Colors.Red}
            fillOpacity={1}
            fill="url(#colorOut)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StatisticsTotalsChart
