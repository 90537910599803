import FullCalendar, { EventClickArg, EventInput, EventSourceInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { setDate } from 'date-fns'
import { useHistory } from 'react-router-dom'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import useFetchData from '../hooks/useFetchData'
import AppService from '../services/AppService'
import { useUser } from '../hooks/useUser'
import { formatDate } from '../utils/DateUtils'
import Colors from '../styles/Colors'
import { IconAddEvent, IconArrowLeft, IconClose, IconExpenses, IconRent, raster_IconCalendar } from '../assets/Svgs'
import ColorIcon from '../components/ColorIcon'
import { RoutesPaths } from '../Routes'
import { getRentLabel } from '../utils/RentUtils'
import EmptyContentPromoDialog from '../components/EmptyContentPromoDialog'

type EventTooltip = {
  id: string
  title: string
  description?: string
  placeName: string
  placeId: string
  start: Date
  end: Date
  isRent: boolean
  isBooking: boolean
}

const PromoData: EventSourceInput = [
  {
    id: '1',
    title: 'Rent monthly',
    start: setDate(new Date(), 10),
    end: setDate(new Date(), 20),
    allDay: true,
    backgroundColor: Colors.Green,
    borderColor: 'transparent'
  },
  {
    id: '2',
    title: 'Rent nightly',
    start: setDate(new Date(), 16),
    end: setDate(new Date(), 18),
    allDay: true,
    backgroundColor: Colors.Blue,
    borderColor: 'transparent'
  },
  {
    id: '3',
    title: 'Expense',
    start: setDate(new Date(), 12),
    end: setDate(new Date(), 12),
    allDay: true,
    backgroundColor: Colors.Red,
    borderColor: 'transparent'
  },
  {
    id: '4',
    title: 'Rent nightly',
    start: setDate(new Date(), 25),
    end: setDate(new Date(), 27),
    allDay: true,
    backgroundColor: Colors.Blue,
    borderColor: 'transparent'
  },
  {
    id: '5',
    title: 'Expense',
    start: setDate(new Date(), 31),
    end: setDate(new Date(), 31),
    allDay: true,
    backgroundColor: Colors.Red,
    borderColor: 'transparent'
  }
]

const getCalendarHeightOffset = (isSmDown: boolean) => 32 + (isSmDown ? 16 : 32) + 40
const getCalendarHeight = (isSmDown: boolean) => `calc(100% - ${getCalendarHeightOffset(isSmDown)}px)`

const CalendarPage: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useUser()
  const history = useHistory()

  const canManage = AppService.role.canManageCalendar()

  const getUserRents = useCallback(() => {
    const { db } = AppService
    return user ? db.getUserRents(user.id) : undefined
  }, [user])

  const getUserTransactions = useCallback(() => {
    const { db } = AppService
    return user ? db.getUserTransactions(user.id) : undefined
  }, [user])

  const getUserPlaces = useCallback(() => {
    const { db } = AppService
    return user ? db.getPlaces(user.id) : undefined
  }, [user])

  const userRents = useFetchData(canManage ? getUserRents : undefined)
  const userTransactions = useFetchData(canManage ? getUserTransactions : undefined)
  const userPlaces = useFetchData(canManage ? getUserPlaces : undefined)

  const [date, setDate] = useState<Date>(new Date())
  const [eventTooltip, setEventTooltip] =
    useState<
      | {
          top: number
          left: number
          event: EventTooltip
        }
      | undefined
    >(undefined)

  const ref = useRef<FullCalendar | null>(null)

  const handleEventClick = useCallback(
    (args: EventClickArg) => {
      if (!canManage) return

      const rent = args.event.extendedProps?.isRent
        ? userRents.data?.find(i => i.id === args.event.extendedProps?.rentId)
        : undefined
      const transaction = userTransactions.data?.find(i => i.id === args.event.id)

      const place = userPlaces.data?.find(i => i.id === rent?.placeId || transaction?.placeId)

      if (rent || transaction) {
        setEventTooltip({
          top: args.jsEvent.pageY,
          left: args.jsEvent.pageX,
          event: {
            id: args.event.id,
            title: t(rent ? (rent.isBooking ? 'booking' : 'rent') : 'expense'),
            description: transaction?.description,
            placeName: args.event.extendedProps?.placeName || '',
            placeId: place?.id || '',
            start: rent?.from || transaction?.dueDate || new Date(),
            end: rent?.to || transaction?.dueDate || new Date(),
            isRent: args.event.extendedProps?.isRent,
            isBooking: Boolean(rent?.isBooking)
          }
        })
      }
    },
    [canManage, t, userPlaces.data, userRents.data, userTransactions.data]
  )

  const handleEventTooltipSelected = useCallback(() => {
    if (!eventTooltip) return

    history.push(
      (eventTooltip.event.isRent ? RoutesPaths.EditRentDialog : RoutesPaths.EditTransactionDialog)
        .replace(':placeId', eventTooltip.event.placeId)
        .replace(eventTooltip.event.isRent ? ':rentId' : ':transactionId', eventTooltip.event.id),
      { isModal: true }
    )
  }, [eventTooltip, history])

  const handleEventTooltipClose = useCallback(() => {
    setEventTooltip(undefined)
  }, [])

  const setCalendarDate = useCallback(() => {
    if (ref.current) {
      setDate(ref.current.getApi().getDate())
    }
  }, [])

  const handleTodaySelected = useCallback(() => {
    ref.current?.getApi().today()
    setCalendarDate()
  }, [setCalendarDate])

  const handleChangePeriodSelected = useCallback(
    (isPrevious?: boolean) => {
      const calendarApi = ref.current?.getApi()
      if (isPrevious) {
        calendarApi?.prev()
      } else {
        calendarApi?.next()
      }
      setCalendarDate()
    },
    [setCalendarDate]
  )

  const events: EventSourceInput = canManage
    ? (
        userTransactions.data
          ?.filter(t => t.rentId && t.isRent && !t.isBooking)
          .map<EventInput>(t => {
            const placeName = userPlaces.data?.find(
              place => place.id === userRents.data?.find(rent => rent.id === t.rentId)?.placeId
            )?.name
            return {
              id: t.id,
              title: `${t.description} - ${placeName}`,
              start: t.dueDate,
              end: t.dueDate,
              allDay: true,
              backgroundColor: userRents.data?.find(rent => rent.id === t.rentId)?.isBooking
                ? Colors.Blue
                : Colors.Green,
              borderColor: 'transparent',
              placeName,
              isRent: true,
              rentId: t.rentId
            }
          }) || []
      )
        .concat(
          userTransactions.data
            ?.filter(t => t.placeId)
            .map<EventInput>(transaction => ({
              id: transaction.id,
              title: transaction.description,
              start: transaction.dueDate,
              end: transaction.dueDate,
              allDay: true,
              backgroundColor: Colors.Red,
              borderColor: 'transparent',
              placeName: userPlaces.data?.find(place => place.id === transaction.placeId)?.name,
              isRent: false
            })) || []
        )
        .concat(
          userRents.data
            ?.filter(r => r.isBooking)
            .map<EventInput>(rent => ({
              id: rent.id,
              title: getRentLabel(t, rent),
              start: rent.from,
              end: rent.to,
              allDay: true,
              backgroundColor: Colors.Blue,
              borderColor: 'transparent',
              placeName: userPlaces.data?.find(place => place.id === rent.placeId)?.name,
              isRent: true,
              rentId: rent.id
            })) || []
        )
    : PromoData

  return (
    <NavigationDrawerContainer showBackdrop={!canManage}>
      {!canManage && (
        <EmptyContentPromoDialog
          title={t('calendar_promo_title')}
          description={t('calendar_promo_description')}
          image={raster_IconCalendar}
          gradient={'linear-gradient(283.85deg, rgba(136, 145, 248, 0.5) 0%, rgba(19, 100, 207, 0.5) 100%)'}
        />
      )}

      <div className={styles.container}>
        <Typography variant="h3">{t('calendar')}</Typography>

        <Spacing size={2} />

        <div className={styles.calendarContainer}>
          <div className="inline justify-between">
            <Typography variant="button" className={styles.calendarDate}>
              {formatDate(date, 'MMMM yyyy')}
            </Typography>

            <div className="inline">
              <Button variant="contained" onClick={handleTodaySelected}>
                {t('today')}
              </Button>
              <Spacing size={2} horizontal />
              <IconButton onClick={() => handleChangePeriodSelected(true)} className="icon-button-contained">
                <IconArrowLeft />
              </IconButton>
              <Spacing size={1} horizontal />
              <IconButton onClick={() => handleChangePeriodSelected()} className="icon-button-contained">
                <IconArrowLeft className="rotate180" />
              </IconButton>
            </div>
          </div>
          <Spacing size={2} />
          <FullCalendar
            ref={ref}
            plugins={[dayGridPlugin]}
            events={events}
            initialView="dayGridMonth"
            headerToolbar={false}
            nowIndicator
            height={getCalendarHeight(isSmDown)}
            eventClick={handleEventClick}
          />

          <Popover
            open={Boolean(eventTooltip)}
            anchorReference="anchorPosition"
            anchorPosition={eventTooltip ? { top: eventTooltip.top, left: eventTooltip.left } : undefined}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            onClose={handleEventTooltipClose}>
            <div className={styles.eventTooltip}>
              <div className="inline justify-between">
                <Typography variant="overline" component="div">
                  {eventTooltip ? formatDate(eventTooltip.event.start) : ' '}
                </Typography>
                <IconButton onClick={handleEventTooltipClose} size="small">
                  <IconClose />
                </IconButton>
              </div>
              <Spacing size={1} />

              <div className="inline">
                <ColorIcon
                  Icon={
                    eventTooltip?.event.isRent ? (
                      eventTooltip?.event.isBooking ? (
                        <IconAddEvent />
                      ) : (
                        <IconRent />
                      )
                    ) : (
                      <IconExpenses />
                    )
                  }
                  color={eventTooltip?.event.isRent ? Colors.Green : Colors.Red}
                />
                <Spacing horizontal size={1} />
                <Typography variant="button" component="div">
                  {eventTooltip?.event.title}
                </Typography>
              </div>

              <Spacing size={1} />

              {eventTooltip?.event.isRent && (
                <React.Fragment>
                  {`${formatDate(eventTooltip.event.start)} - ${formatDate(eventTooltip.event.end)}`}
                  <Spacing size={1} />
                </React.Fragment>
              )}

              {eventTooltip?.event.description && (
                <React.Fragment>
                  <Spacing size={0.5} />
                  <Typography variant="body2" component="div" color="textSecondary">
                    {eventTooltip?.event.description}
                  </Typography>
                </React.Fragment>
              )}

              <Spacing size={2} />

              <Typography variant="body2" component="div">
                {`${t('place.noun')}: `}
                <strong>{eventTooltip?.event.placeName}</strong>
              </Typography>

              <Spacing size={2} />

              <Button variant="contained" onClick={handleEventTooltipSelected}>
                {t('view')}
              </Button>
            </div>
          </Popover>
        </div>
      </div>
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      overflow: 'hidden',
      position: 'relative'
    },
    calendarContainer: {
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      '& .fc-daygrid-event': {
        borderRadius: theme.shape.borderRadius * 2
      },
      '& .fc-event': {
        cursor: 'pointer',
        padding: `0 4px`
      }
    },
    calendarDate: {
      textTransform: 'capitalize'
    },
    eventTooltip: {
      padding: theme.spacing(2)
    }
  })
)

export default CalendarPage
