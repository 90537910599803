import { IconButton, InputBase, Theme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'
import { IconClose } from '../../assets/Svgs'

type InputSearchProps = {
  fullWidth?: boolean
  className?: string
  value?: string
  disabled?: boolean
  onChange?: (value: string) => void
}

const InputSearch: React.FC<InputSearchProps> = ({
  fullWidth,
  className,
  value,
  disabled,
  onChange
}: InputSearchProps) => {
  const styles = useStyles()

  const handleClear = useCallback(() => {
    if (onChange) onChange('')
  }, [onChange])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <div className={clsx(styles.search, { [styles.fullWidth]: fullWidth, [styles.disabled]: disabled }, className)}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: styles.inputRoot,
          input: styles.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        endAdornment={
          value ? (
            <IconButton onClick={handleClear} className={styles.clear}>
              <IconClose />
            </IconButton>
          ) : (
            <div className={styles.clear} />
          )
        }
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      height: 40,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.background.default
      },
      marginLeft: 0,
      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        flex: 1
      }
    },
    fullWidth: {
      width: '100%'
    },
    disabled: {
      opacity: 0.6
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: 20,
        height: 20,
        '& path': {
          fill: theme.palette.text.secondary
        }
      }
    },
    inputRoot: {
      color: 'inherit',
      [theme.breakpoints.down('sm')]: {
        flex: 1
      }
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch'
        }
      }
    },
    clear: {
      marginRight: theme.spacing(0.5),
      width: 28,
      height: 28,
      padding: 0,
      flexShrink: 0,
      '& svg': {
        width: 20,
        height: 20,
        flexShrink: 0,
        '& path': {
          fill: theme.palette.text.secondary
        }
      }
    }
  })
)

export default InputSearch
