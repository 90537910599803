import { Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { useUser } from '../../hooks/useUser'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatNumber } from '../../utils/TextUtils'
import ChartLegendItem from './ChartLegendItem'

type ChartLegendProps = {
  items: {
    color: string
    label: string
    value: number
    isCurrency?: boolean
  }[]
}

const ChartLegend: React.FC<ChartLegendProps> = ({ items }: ChartLegendProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useUser()

  return (
    <div className={clsx(isSmDown ? 'colstretch' : 'inline', styles.legendContainer)}>
      {items.map((item, index) => (
        <ChartLegendItem
          key={index}
          color={item.color}
          label={item.label}
          value={item.isCurrency ? formatCurrency(item.value, user?.currency) : formatNumber(item.value)}
        />
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legendContainer: {
      '& > div:not(:first-child)': {
        marginLeft: theme.spacing(1.5)
      },
      [theme.breakpoints.down('md')]: {
        '& > div:not(:first-child)': {
          marginLeft: 0
        }
      }
    }
  })
)

export default ChartLegend
