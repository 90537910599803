import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { fade, Theme } from '@material-ui/core'
import Colors from '../styles/Colors'

const BackdropOverlay: React.FC = () => {
  const styles = useStyles()

  return <div className={styles.root} />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appBar - 2,
      backgroundColor: fade(Colors.Black, 0.5)
    }
  })
)

export default BackdropOverlay
