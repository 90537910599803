import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ListTable, { ListTableRowItem } from '../../data-display/ListTable'
import { RoutesPaths } from '../../Routes'
import TransactionCategory from '../../types/TransactionCategory'
import TransactionCategoryColor from '../transactions/TransactionCategoryColor'

type TransactionsCategoriesListProps = {
  loading: boolean
  items: TransactionCategory[]
}

const TransactionsCategoriesList: React.FC<TransactionsCategoriesListProps> = ({
  loading,
  items
}: TransactionsCategoriesListProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleItemSelected = useCallback(
    (id: string) => {
      history.push(RoutesPaths.EditTransactionCategoryDialog.replace(':transactionCategoryId', id), { isModal: true })
    },
    [history]
  )

  const columns = [
    {
      field: 'color',
      headerName: t('color'),
      width: 48
    },
    {
      field: 'label',
      headerName: t('name')
    }
  ]

  const rows: ListTableRowItem[] = items.map(i => ({
    id: i.id,
    items: {
      label: { label: i.label },
      color: {
        label: i.color || undefined,
        renderLabel: (label: string | undefined) => (label ? <TransactionCategoryColor color={label} /> : null)
      }
    }
  }))

  return <ListTable loading={loading} columns={columns} rows={rows} onItemSelected={handleItemSelected} />
}

export default TransactionsCategoriesList
