import firebase from 'firebase'

class StorageService {
  storage: firebase.storage.Storage | undefined

  constructor(storage?: firebase.storage.Storage) {
    this.storage = storage
  }

  private uploadFile = (
    pathname: string,
    file: File | Blob,
    metadata: firebase.storage.UploadMetadata = { contentType: file.type },
    onProgress?: (progress: number) => void,
    fileName?: string
  ): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!this.storage) return

      const storageRef = this.storage.ref()

      const fileId = `${new Date().getTime()}_${file instanceof File ? file.name : fileName || 'file'}`

      const uploadTask = storageRef.child(`${pathname}${fileId}`).put(file, metadata)

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break
            case firebase.storage.TaskState.RUNNING:
              break
          }

          if (onProgress) onProgress(progress)
        },
        error => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch ((error as any).code) {
            case 'storage/unauthorized':
              break
            case 'storage/canceled':
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
          }

          reject(new Error('File upload error.'))
        },
        async () => {
          const downloadUrl: string = await uploadTask.snapshot.ref.getDownloadURL()

          resolve(downloadUrl)
        }
      )
    })

  deleteFile = async (downloadUrl: string) => {
    let success = true

    try {
      await this.storage?.refFromURL(downloadUrl).delete()
    } catch (err) {
      success = false
    }

    return success
  }

  public uploadPlaceImage = async (file: File) => this.uploadFile('images/places/', file)

  public uploadDocument = async (file: File) => this.uploadFile('documents/', file)

  public uploadReceipt = async (blob: Blob, fileName?: string) =>
    this.uploadFile('receipts/', blob, undefined, undefined, fileName)

  public uploadBookingConfirmation = async (blob: Blob, fileName?: string) =>
    this.uploadFile('booking_confirmations/', blob, undefined, undefined, fileName)
}

export default StorageService
