import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Spacing from '../Spacing'
import UpgradeButton from './UpgradeButton'

type FeaturePromoUpgradeProps = {
  text?: string
  className?: string
  maxWidth?: boolean
  notFull?: boolean
  vertical?: boolean
  onClick?: () => void
}

const FeaturePromoUpgrade: React.FC<FeaturePromoUpgradeProps> = ({
  text,
  maxWidth = true,
  className,
  notFull,
  vertical,
  onClick
}: FeaturePromoUpgradeProps) => {
  const styles = useStyles()

  return (
    <div
      className={clsx(vertical ? 'colstretch align-center' : 'inline', className, {
        fullflex: !Boolean(text) && !notFull
      })}>
      {text && (
        <React.Fragment>
          <Typography
            variant="caption"
            align={vertical ? 'center' : 'right'}
            component="div"
            color="textSecondary"
            className={clsx({ [styles.textMaxWidth]: maxWidth })}>
            {text}
          </Typography>

          <Spacing size={2} horizontal={!vertical} />
        </React.Fragment>
      )}

      <UpgradeButton fullWidth={!Boolean(text)} onClick={onClick} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textMaxWidth: {
      maxWidth: 200,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 140
      }
    }
  })
)

export default FeaturePromoUpgrade
