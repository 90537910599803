import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { TooltipProps } from 'recharts'
import { useUser } from '../../hooks/useUser'
import Colors from '../../styles/Colors'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import Spacing from '../Spacing'

const ChartTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label
}: TooltipProps<number, string>) => {
  const styles = useStyles()
  const { user } = useUser()

  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <Typography variant="caption" className={styles.tooltipLabel}>
          {formatDate(label, 'MMMM yyyy')}
        </Typography>

        <Spacing size={1} />

        <div className={styles.tooltipValuesContainer}>
          {payload.map((item, index) => (
            <div className="inline" key={index}>
              <div className={styles.tooltipColor} style={{ backgroundColor: item.color }} />
              <Typography variant="overline" className={styles.tooltipValue}>
                {item.value !== undefined ? formatCurrency(item.value, user?.currency) : ''}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: Colors.DarkGray,
      padding: 8,
      borderRadius: theme.shape.borderRadius * 2,
      color: Colors.White
    },
    tooltipColor: {
      width: 8,
      height: 8,
      borderRadius: 8,
      marginRight: theme.spacing(1),
      flexShrink: 0
    },
    tooltipValue: {
      color: Colors.White
    },
    tooltipLabel: {
      textTransform: 'capitalize'
    },
    tooltipValuesContainer: {
      '& > div:not(:last-child)': {
        marginBottom: theme.spacing(0.5)
      }
    }
  })
)

export default ChartTooltip
