import { useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'

type SpacingProps = { size?: number; horizontal?: boolean; smDownSize?: number }

const Spacing: React.FC<SpacingProps> = ({ size = 1.5, horizontal, smDownSize }: SpacingProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const computedSize = theme.spacing(isSmDown && smDownSize !== undefined ? smDownSize : size)

  return (
    <div
      className={styles.root}
      style={{
        height: horizontal ? undefined : computedSize,
        width: horizontal ? computedSize : undefined
      }}
    />
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexShrink: 0
    }
  })
)

export default Spacing
