import { v4 as uuidv4 } from 'uuid'
import { formatNumber } from './TextUtils'

export const getId = () => {
  return uuidv4()
}

export const isFileMimeTypeImage = (file: File) => {
  return (
    file &&
    file.type &&
    (file.type.includes('image/jpg') ||
      file.type.includes('image/png') ||
      file.type.includes('image/jpeg') ||
      file.type.includes('image/svg+xml') ||
      file.type.includes('image/webp'))
  )
}

export const isFileUrlExtensionImage = (fileUrl: string) => {
  return fileUrl.includes('.png') || fileUrl.includes('.jpg') || fileUrl.includes('.jpeg') || fileUrl.includes('.webp')
}

export const getHumanFileSize = (bytes: number) => {
  const dp = 1
  const thresh = 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return formatNumber(bytes, dp, undefined, undefined, undefined, true) + ' ' + units[u]
}

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.target = '_blank'
  link.rel = 'noopener noreferrer'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getFileNameWithoutExtension = (fileName: string): string => fileName.split('.').slice(0, -1).join('.')

export const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const PrivacyPolicyUrl = 'https://www.iubenda.com/privacy-policy/91291369'
export const TermsAndConditionsUrl = 'https://www.iubenda.com/terms-and-conditions/91291369'
export const SupportEmail = 'info@affittomio.com'
