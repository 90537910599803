import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import clsx from 'clsx'
import { startOfMonth } from 'date-fns'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { publish } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { IconAddEvent, IconClose, IconEdit, IconInfo, IconRent } from '../assets/Svgs'
import AddRentStepAmounts, { AddRentStepAmountsProps } from '../components/add-rent/AddRentStepAmounts'
import AddRentStepContract, { AddRentStepContractProps } from '../components/add-rent/AddRentStepContract'
import AddRentStepSummary, { AddRentStepSummaryProps } from '../components/add-rent/AddRentStepSummary'
import AppLoader from '../components/AppLoader'
import ColorIcon from '../components/ColorIcon'
import AlertDialog from '../components/dialogs/AlertDialog'
import Spacing from '../components/Spacing'
import StepsActions from '../components/steps/StepsActions'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import Guest from '../types/Guest'
import Place from '../types/place/Place'
import Rent from '../types/place/Rent'
import Transaction from '../types/Transaction'
import { getDefaultRentTransactions, getMonthsDaysDuration, getRentDurationLessThanAMonth } from '../utils/RentUtils'

type AddRentValues = {
  from: Date | null
  to: Date | null
  guests: Guest[]
  paymentMethod?: Rent['paymentMethod']
  recurrence: Rent['recurrence']
  totalAmount: Rent['totalAmount']
  recurringPayment: Rent['recurringPayment']
  recurringPaymentValue: Rent['recurringPaymentValue']
  recurringPaymentDay: Rent['recurringPaymentDay']
  sourceOfBooking?: Rent['sourceOfBooking']
  transactions?: Transaction[]
  adults: number | null
  children: number | null
  bookingNotes?: string
}
export type AddRentStepValues1 = Pick<AddRentValues, 'from' | 'to' | 'paymentMethod' | 'guests' | 'adults' | 'children'>
export type AddRentStepValues2 = Pick<
  AddRentValues,
  | 'from'
  | 'to'
  | 'recurrence'
  | 'totalAmount'
  | 'recurringPayment'
  | 'recurringPaymentValue'
  | 'recurringPaymentDay'
  | 'sourceOfBooking'
  | 'transactions'
>
export type AddRentStepValues3 = Pick<
  AddRentValues,
  | 'from'
  | 'to'
  | 'totalAmount'
  | 'guests'
  | 'paymentMethod'
  | 'recurrence'
  | 'recurringPayment'
  | 'recurringPaymentValue'
  | 'recurringPaymentDay'
  | 'transactions'
  | 'adults'
  | 'children'
  | 'bookingNotes'
>

type AddRentStepInitialValues = Partial<AddRentStepValues1> | Partial<AddRentStepValues2> | Partial<AddRentStepValues3>

type AddRentStep = {
  id: number
  Component: React.FC<AddRentStepContractProps> | React.FC<AddRentStepAmountsProps> | React.FC<AddRentStepSummaryProps>
  initialValues: AddRentStepInitialValues
  validationSchema: Yup.ObjectSchema<{}>
}

const AddRent: React.FC = () => {
  const styles = useStyles()
  const history = useHistory<{ isBooking?: boolean }>()
  const { user } = useUser()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { placeId, rentId } = useParams<{ placeId: string; rentId?: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const steps = useRef<AddRentStep[]>(
    [...Array(3).keys()].map(i => ({
      id: i,
      Component: i === 0 ? AddRentStepContract : i === 1 ? AddRentStepAmounts : AddRentStepSummary,
      initialValues:
        i === 0
          ? {
              from: null,
              to: null,
              paymentMethod: null,
              guests: [],
              adults: null,
              children: null
            }
          : i === 1
          ? {
              totalAmount: null,
              recurrence: 'month',
              recurringPayment: false,
              paymentRecurrenceType: 'month',
              recurringPaymentValue: 1,
              recurringPaymentDay: startOfMonth(new Date()).getDate(),
              sourceOfBooking: null,
              transactions: [],
              bookingNotes: ''
            }
          : {},
      validationSchema: Yup.object(
        i === 0
          ? {
              from: Yup.date().nullable().required(t('required_field')).typeError(t('invalid_date')),
              to: Yup.date().nullable().required(t('required_field')).typeError(t('invalid_date'))
            }
          : i === 1
          ? {
              totalAmount: Yup.number().nullable().required(t('required_field'))
            }
          : {}
      )
    }))
  )

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [initialValues, setInitialValues] = useState<AddRentStepInitialValues>(
    steps.current[currentStepIndex].initialValues
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [editDisabled, setEditDisabled] = useState<boolean>(Boolean(rentId))
  const [canEdit, setCanEdit] = useState<boolean>(true)
  const [alertDialog, setAlertDialog] =
    useState<{ title: string; confirmButtonText: string; onConfirm: () => void } | undefined>()
  const [isBooking, setIsBooking] = useState<boolean>(Boolean(history.location?.state?.isBooking))
  const [place, setPlace] = useState<Place | undefined>(undefined)

  const formValues = useRef<Partial<AddRentValues> | undefined>()
  const editingRentTransactions = useRef<Transaction[] | undefined>()

  const isLastStep = currentStepIndex === steps.current.length - 1
  const isFirstStep = currentStepIndex === 0

  const updateInitialValues = useCallback(
    (step: number) => {
      const newInitialValues = steps.current[step].initialValues

      if (formValues.current) {
        if (step === 1) {
          if (!formValues.current.recurrence) {
            const recurrence =
              isBooking || getMonthsDaysDuration(formValues.current.from, formValues.current.to).months <= 0
                ? 'night'
                : 'month'
            formValues.current.recurrence = recurrence
            if (recurrence === 'month') {
              formValues.current.recurringPayment = true
            }
          }
        }
        if (step === 2 && !Boolean(rentId)) {
          formValues.current.bookingNotes = formValues.current.bookingNotes || place?.notes
          formValues.current.transactions = (formValues.current.transactions?.filter(t => !t.isRent) || []).concat(
            getDefaultRentTransactions(
              t,
              formValues.current.totalAmount,
              formValues.current.from,
              formValues.current.to,
              formValues.current.recurringPayment,
              formValues.current.recurringPaymentValue,
              formValues.current.recurringPaymentDay,
              isBooking
            )
          )
        }
      }

      setInitialValues({
        ...newInitialValues,
        ...formValues.current
      })
    },
    [rentId, isBooking, place?.notes, t]
  )

  const handleClose = useCallback(() => {
    if (isFirstStep || (Boolean(rentId) && editDisabled)) {
      history.goBack()
    } else {
      const newStep = currentStepIndex >= 1 ? currentStepIndex - 1 : 0
      setCurrentStepIndex(newStep)
      updateInitialValues(newStep)
    }
  }, [isFirstStep, history, currentStepIndex, updateInitialValues, rentId, editDisabled])

  const handleAlertDialogClose = useCallback(() => {
    setAlertDialog(undefined)
  }, [])

  const handleDelete = useCallback(async () => {
    handleAlertDialogClose()

    if (!(rentId && user)) return

    const { db } = AppService
    const success = await db.deleteRent(rentId, user.id)

    if (success) {
      enqueueSnackbar(t(isBooking ? 'booking_deleted' : 'rent_deleted'), { variant: 'warning' })
      publish('rentDeleted', { rentId })
      history.goBack()
    }
  }, [handleAlertDialogClose, rentId, user, enqueueSnackbar, t, isBooking, history])

  const handleDeleteSelected = useCallback(() => {
    setAlertDialog({
      title: t('delete_rent_confirm'),
      confirmButtonText: t('delete'),
      onConfirm: handleDelete
    })
  }, [handleDelete, t])

  const handleEditSelected = useCallback(() => {
    setEditDisabled(false)
    setCurrentStepIndex(0)
    updateInitialValues(0)
  }, [updateInitialValues])

  const handleExit = useCallback(() => {
    history.push(RoutesPaths.PlaceDetail.replace(':placeId', placeId))
  }, [history, placeId])

  const getRentData = useCallback(
    (values: Omit<Rent, 'id' | 'created' | 'transactions'>): Omit<Rent, 'id' | 'created' | 'transactions'> => ({
      placeId: values.placeId,
      from: values.from,
      to: values.to,
      guests: values.guests || [],
      recurrence: values.recurrence,
      totalAmount: values.totalAmount,
      recurringPayment: values.recurringPayment,
      recurringPaymentValue: values.recurringPaymentValue,
      recurringPaymentDay: values.recurringPaymentDay,
      sourceOfBooking: values.sourceOfBooking,
      paymentMethod: values.paymentMethod,
      adults: values.adults,
      children: values.children,
      isBooking,
      bookingNotes: values.bookingNotes
    }),
    [isBooking]
  )

  const validate = useCallback(
    (values: AddRentValues) => {
      const durationLessThanAMonth = getRentDurationLessThanAMonth(values.from, values.to)

      return isBooking || (!isBooking && !durationLessThanAMonth)
    },
    [isBooking]
  )

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      if (!validate({ ...formValues.current, ...values })) return

      formValues.current = { ...formValues.current, ...values }
      if (!isLastStep) {
        const newStep = currentStepIndex + 1
        setCurrentStepIndex(newStep)
        updateInitialValues(newStep)
        return
      }

      let error = false

      try {
        if (
          formValues.current &&
          formValues.current.from &&
          formValues.current.to &&
          formValues.current.recurrence !== undefined &&
          formValues.current.totalAmount !== undefined &&
          formValues.current.recurringPayment !== undefined &&
          formValues.current.transactions !== undefined &&
          user
        ) {
          const { db } = AppService
          if (rentId) {
            if (editingRentTransactions.current) {
              if (editDisabled) {
                // Only save transactions data from ids already present in rent
                if (formValues.current && formValues.current.transactions) {
                  await db.updateTransactions(formValues.current.transactions)
                } else {
                  error = true
                }
              } else {
                const transactionsToDelete = editingRentTransactions.current.filter(
                  t => !formValues.current?.transactions?.some(currentTransaction => currentTransaction.id === t.id)
                )
                const transactionsToCreate =
                  formValues.current.transactions?.filter(
                    t => !editingRentTransactions.current?.some(oldTransaction => oldTransaction.id === t.id)
                  ) || []
                const transactionsToUpdate =
                  formValues.current.transactions?.filter(t =>
                    editingRentTransactions.current?.some(oldTransaction => oldTransaction.id === t.id)
                  ) || []
                await db.deleteTransactions(transactionsToDelete.map(t => t.id))

                await db.updateTransactions(transactionsToUpdate.map(t => ({ ...t, rentId })))

                await db.createTransactions(
                  transactionsToCreate.map(t => ({ ...t, rentId })),
                  user.id
                )
              }

              await db.updateRent(
                {
                  ...getRentData({ ...formValues.current, placeId } as Omit<Rent, 'id' | 'created' | 'transactions'>),
                  id: rentId
                },
                user.id
              )

              if (!error) {
                enqueueSnackbar(t(isBooking ? 'booking_updated' : 'rent_updated'), { variant: 'success' })
                publish('rentUpdated', { rentId })
              }
            } else {
              error = true
            }
          } else {
            const rentId = await db.createRent(
              getRentData({ ...formValues.current, placeId } as Omit<Rent, 'id' | 'created'>),
              user.id
            )

            if (rentId) {
              await db.createTransactions(
                formValues.current.transactions.map(t => ({ ...t, rentId })),
                user.id
              )

              enqueueSnackbar(t(isBooking ? 'booking_added' : 'rent_added'), { variant: 'success' })

              publish('rentCreated', { rentId })
            } else {
              error = true
            }
          }
        } else {
          error = true
        }
      } catch (err) {
        error = true
      }

      if (!error) {
        handleExit()
      } else {
        enqueueSnackbar(t('error'), { variant: 'error' })
      }

      setSubmitting(false)
    },
    [
      validate,
      isLastStep,
      currentStepIndex,
      updateInitialValues,
      user,
      rentId,
      editDisabled,
      getRentData,
      placeId,
      enqueueSnackbar,
      t,
      isBooking,
      handleExit
    ]
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoading(true)

      const { db } = AppService

      if (rentId && user) {
        const rent = await db.getRent(user.id, rentId)

        if (rent) {
          formValues.current = rent
          editingRentTransactions.current = [...rent.transactions]

          if (!didCancel) {
            const canEditValue = !Boolean(formValues.current.transactions?.filter(t => t.isRent).some(t => t.paid))

            setInitialValues(formValues.current)
            setCurrentStepIndex(Boolean(rentId) ? 2 : 0)
            setCanEdit(canEditValue)
            setIsBooking(Boolean(rent.isBooking))
          }
        }
      }
      if (placeId) {
        const placeResult = await db.getPlace(placeId)

        if (!didCancel && placeResult) {
          setPlace(placeResult)
        }
      }

      if (!didCancel) setLoading(false)
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [placeId, rentId, user])

  const currentStep: AddRentStep | undefined = steps.current[currentStepIndex]

  return (
    <Dialog
      open
      aria-labelledby="add-rent-dialog-title"
      aria-describedby="add-rent-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={currentStep.validationSchema}
        onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <React.Fragment>
                {!editDisabled && !loading && (
                  <LinearProgress variant="determinate" value={((currentStepIndex + 1) / steps.current.length) * 100} />
                )}
                <DialogTitle disableTypography id="alert-dialog-title">
                  {(!(!Boolean(rentId) && !loading) || isSmDown) && <Spacing size={2} />}
                  <div className={clsx('inline', 'justify-between')}>
                    <div className="inline">
                      <ColorIcon
                        Icon={loading ? null : isBooking ? <IconAddEvent /> : <IconRent />}
                        color={Colors.Green}
                        withBackground
                        className={styles.spacingRight}
                      />
                      <Typography variant="h3" component="div">
                        {loading
                          ? ''
                          : t(
                              `${isBooking ? 'add_booking' : 'addrent'}.title_${
                                Boolean(rentId) ? (editDisabled ? 'view' : 'edit') : 'add'
                              }`
                            )}
                      </Typography>
                    </div>

                    <div className="inline">
                      {!loading && Boolean(rentId) && canEdit && editDisabled && (
                        <IconButton onClick={handleEditSelected}>
                          <IconEdit />
                        </IconButton>
                      )}
                      {!loading && Boolean(rentId) && editDisabled && (
                        <IconButton onClick={handleClose} className={clsx(styles.buttonClose, 'spacing-left-xs')}>
                          <IconClose />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </DialogTitle>

                <DialogContent>
                  {loading && Boolean(rentId) ? (
                    <div className={styles.contentLoaderContainer}>
                      <AppLoader />
                    </div>
                  ) : (
                    <React.Fragment>
                      {!canEdit && (
                        <React.Fragment>
                          <div className={clsx('inline', styles.editDisabledTextContainer)}>
                            <IconInfo />
                            <Typography component="div" variant="body1">
                              {t(isBooking ? 'booking_edit_disabled' : 'rent_edit_disabled')}
                            </Typography>
                          </div>
                          <div className="spacing-block-vertical-m" />
                        </React.Fragment>
                      )}

                      {!currentStep ? null : (
                        <currentStep.Component
                          submitting={isSubmitting}
                          loading={loading}
                          editDisabled={editDisabled}
                          values={values as any}
                          errors={errors}
                          touched={touched as any}
                          isEdit={Boolean(rentId)}
                          rentId={rentId}
                          placeId={placeId}
                          isBooking={isBooking}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </React.Fragment>
                  )}
                </DialogContent>

                <StepsActions
                  variant="dialog"
                  showOutlinedBackButton
                  outlinedBackButtonText={
                    !isFirstStep && !editDisabled
                      ? t('back')
                      : Boolean(rentId) && editDisabled
                      ? t('delete')
                      : undefined
                  }
                  outlinedButtonColor={Boolean(rentId) && editDisabled ? 'red' : undefined}
                  submitButton
                  continueButtonText={isLastStep ? t(Boolean(rentId) ? 'save' : 'add') : undefined}
                  submitting={isSubmitting}
                  onGoBack={Boolean(rentId) && editDisabled ? handleDeleteSelected : handleClose}
                  hidden={loading}
                />
              </React.Fragment>
            </Form>
          )
        }}
      </Formik>

      <AlertDialog
        open={Boolean(alertDialog)}
        title={alertDialog?.title}
        actionDelete
        confirmButtonText={alertDialog?.confirmButtonText}
        onClose={handleAlertDialogClose}
        onConfirm={alertDialog?.onConfirm}
      />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacingRight: {
      marginRight: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar,
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    editDisabledTextContainer: {
      color: theme.palette.warning.main,
      '& svg': {
        width: 16,
        height: 16,
        marginRight: theme.spacing(1.5),
        flexShrink: 0,
        '& path': {
          fill: theme.palette.warning.main
        }
      }
    },
    contentLoaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 400
    },
    buttonClose: {
      padding: 4,
      '& path': {
        fill: Colors.Gray
      }
    }
  })
)

export default AddRent
