import { fade } from '@material-ui/core'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import Colors from './Colors'

const AppPaletteBase: PaletteOptions = {
  primary: {
    main: Colors.Blue
  },
  secondary: {
    main: Colors.DarkBlue
  },
  text: {
    secondary: Colors.Gray
  },
  divider: fade(Colors.LightGray, 0.4),
  grey: {
    200: Colors.SubtleBlueGray,
    400: Colors.DisabledGrey,
    500: Colors.DarkGray
  },
  success: {
    main: Colors.Green,
    contrastText: Colors.White
  },
  warning: {
    main: Colors.Orange
  }
}

export default AppPaletteBase
