import { ButtonBase, Popover, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import User from '../types/User'
import AlertDialog from './dialogs/AlertDialog'
import PopoverMenu from './PopoverMenu'
import UserAvatar from './UserAvatar'

type UserMenuProps = {
  iconOnly?: boolean
}

const UserName = ({ user }: { user: User }) => {
  const styles = useStyles()
  return (
    <React.Fragment>
      {user.name && (
        <Typography variant="h6" component="div" className={clsx(styles.name, styles.alignLeft)}>
          {user.name}
        </Typography>
      )}
      <Typography variant="caption" component="div" className={clsx(styles.email, styles.alignLeft)}>
        {user.email}
      </Typography>
    </React.Fragment>
  )
}

const UserMenu: React.FC<UserMenuProps> = ({ iconOnly }: UserMenuProps) => {
  const styles = useStyles()
  const theme = useTheme()
  const { user } = useUser()
  const { t } = useTranslation()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [alertDialog, setAlertDialog] =
    useState<{ title: string; confirmButtonText: string; onConfirm: () => void } | undefined>()

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleAlertDialogClose = useCallback(() => {
    setAlertDialog(undefined)
  }, [])

  const handleLogout = useCallback(async () => {
    const { auth } = AppService
    await auth.logout()
  }, [])

  const handleLogoutSelected = useCallback(() => {
    setAlertDialog({
      title: t('logout_confirm'),
      confirmButtonText: t('logout'),
      onConfirm: handleLogout
    })
  }, [handleLogout, t])

  const open = Boolean(anchorEl)
  const id = open ? 'user-menu-popover' : undefined

  return user ? (
    <React.Fragment>
      <ButtonBase className={clsx(styles.button, { [styles.buttonIconOnly]: iconOnly })} onClick={handleClick}>
        <UserAvatar pictureUrl={user.pictureUrl} name={user.name} />
        {!iconOnly && (
          <div className={styles.content}>
            <UserName user={user} />
          </div>
        )}
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        transitionDuration={0}
        onClose={handleClose}
        anchorOrigin={
          isSmDown && iconOnly
            ? {
                vertical: 'bottom',
                horizontal: 'right'
              }
            : {
                vertical: 'top',
                horizontal: 'left'
              }
        }
        transformOrigin={
          isSmDown && iconOnly
            ? {
                vertical: 'top',
                horizontal: 'right'
              }
            : {
                vertical: 'bottom',
                horizontal: 'left'
              }
        }
        classes={{
          paper: styles.popoverPaper
        }}
        PaperProps={
          !isSmDown || (isSmDown && !iconOnly)
            ? {
                style: {
                  width: anchorEl ? anchorEl.clientWidth : undefined
                }
              }
            : undefined
        }>
        <div className={styles.popoverContent}>
          {iconOnly && (
            <div className={styles.username}>
              <UserName user={user} />
            </div>
          )}
          <PopoverMenu
            items={[
              {
                text: t('logout'),
                Icon: <ExitToAppIcon />,
                handler: handleLogoutSelected
              }
            ]}
          />
        </div>
      </Popover>

      <AlertDialog
        open={Boolean(alertDialog)}
        title={alertDialog?.title}
        confirmButtonText={alertDialog?.confirmButtonText}
        onClose={handleAlertDialogClose}
        onConfirm={alertDialog?.onConfirm}
      />
    </React.Fragment>
  ) : null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    buttonIconOnly: {
      padding: 0,
      borderRadius: '50%'
    },
    content: {
      minWidth: 0,
      flex: 1,
      marginLeft: theme.spacing(1.5)
    },
    alignLeft: {
      textAlign: 'left'
    },
    name: {
      marginBottom: theme.spacing(0.5)
    },
    email: {
      color: theme.palette.text.secondary,
      overflowWrap: 'break-word'
    },
    popoverPaper: {
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      [theme.breakpoints.up('md')]: {
        left: `12px !important`
      }
    },
    popoverContent: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
      boxShadow: `0 16px 32px 2px rgb(97 97 97 / 10%), 0 8px 16px 2px rgb(97 97 97 / 10%)`,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0.5)
      }
    },
    username: {
      padding: theme.spacing(2)
    }
  })
)

export default UserMenu
