import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type ContainerProps = {
  noPadding?: boolean
  relative?: boolean
  backgroundPaper?: boolean
}

const Container: React.FC<PropsWithChildren<ContainerProps>> = ({
  children,
  noPadding,
  relative,
  backgroundPaper
}: PropsWithChildren<ContainerProps>) => {
  const styles = useStyles()
  return (
    <div
      className={clsx(styles.root, {
        [styles.rootNoPadding]: noPadding,
        [styles.relative]: relative,
        [styles.backgroundPaper]: backgroundPaper
      })}>
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      overflowY: 'auto',
      padding: `${theme.spacing(5)}px 0`,
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    rootNoPadding: {
      padding: 0
    },
    relative: {
      position: 'relative'
    },
    backgroundPaper: {
      backgroundColor: theme.palette.background.paper
    }
  })
)

export default Container
