import { ButtonBase, fade, Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { differenceInCalendarDays } from 'date-fns'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { RoutesPaths } from '../../Routes'
import AppService from '../../services/AppService'
import Colors from '../../styles/Colors'
import Place from '../../types/place/Place'
import Rent from '../../types/place/Rent'
import Transaction from '../../types/Transaction'
import { getTransactionPaymentLabel } from '../../utils/RentUtils'
import { lineClamp } from '../../utils/StyleUtils'
import FeaturePromoUpgrade from '../promo/FeaturePromoUpgrade'
import Spacing from '../Spacing'
import Tag from '../Tag'

const ItemImageSize = 192
const ItemSmallImageSize = 128

type PlacesListProps = {
  loading?: boolean
  items: Place[]
  activeRents: Rent[]
}

const PlacesList: React.FC<PlacesListProps> = ({ loading, items, activeRents }: PlacesListProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const { user } = useUser()

  const nowDate = useRef(new Date())

  const handlePlaceSelected = useCallback(
    (place: Place) => {
      history.push(RoutesPaths.PlaceDetail.replace(':placeId', place.id))
    },
    [history]
  )

  const canAccessAllPlaces = AppService.role.canAccessAllPlaces()
  const activePlaceId = canAccessAllPlaces
    ? undefined
    : [...items].sort((a, b) => {
        return a.created.getTime() - b.created.getTime()
      })[0]?.id

  return (
    <Grid container spacing={2}>
      {loading
        ? [...Array(2).keys()].map(i => (
            <Grid className={clsx(styles.itemContainer, styles.itemLoader)} lg={3} md={4} xs={12} item key={i}>
              <Skeleton variant="rect" className={styles.loader} />
            </Grid>
          ))
        : items.map(item => {
            const placeActiveRents = activeRents.filter(r => r.placeId === item.id)
            const nextTransaction = placeActiveRents
              .reduce((arr, r) => (r.nextTransaction ? arr.concat(r.nextTransaction) : arr), [] as Transaction[])
              .sort((a, b) => {
                // Get next closest transaction among all rents
                const diffA = differenceInCalendarDays(a.dueDate, nowDate.current)
                const diffB = differenceInCalendarDays(b.dueDate, nowDate.current)
                return diffA - diffB
              })[0]
            const hasActiveRent = placeActiveRents.length > 0
            const canAccess = activePlaceId === undefined || activePlaceId === item.id
            return (
              <Grid lg={3} md={4} xs={12} className={styles.itemContainer} item key={item.id}>
                {!canAccess && (
                  <div className={styles.itemDisabledOverlay}>
                    <FeaturePromoUpgrade notFull />
                  </div>
                )}

                <ButtonBase className={styles.item} disabled={!canAccess} onClick={() => handlePlaceSelected(item)}>
                  <div className={styles.pictureContainer}>
                    {item.pictureUrl && <img src={item.pictureUrl} alt={item.name} className={styles.picture} />}
                  </div>
                  <div className={styles.content}>
                    <Typography variant="body1" className={styles.name}>
                      {item.name}
                    </Typography>
                    {hasActiveRent && nextTransaction ? (
                      <React.Fragment>
                        <Spacing size={0.5} />
                        <Typography variant="body2" component="div" className={styles.textLeft}>
                          <Typography
                            variant="body2"
                            component="span"
                            color="textSecondary"
                            className="spacing-right-xxs">{`${t('next_rent')}:`}</Typography>
                          {getTransactionPaymentLabel(nextTransaction, t, user?.currency)}
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <Typography variant="body2" className={styles.text}>
                        {t(hasActiveRent && !nextTransaction ? 'rent_paid' : 'no_rentals')}
                      </Typography>
                    )}
                    <Tag
                      text={t(hasActiveRent ? 'rent_active' : 'no_rent')}
                      status={hasActiveRent ? 'success' : 'default'}
                      backgroundColor={isSmDown ? 'contrast' : 'default'}
                      className={styles.tag}
                    />
                  </div>
                </ButtonBase>
              </Grid>
            )
          })}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'relative'
    },
    item: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      flex: 1,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row'
      }
    },
    itemDisabledOverlay: {
      borderRadius: theme.shape.borderRadius,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10,
      backgroundColor: fade(Colors.Black, 0.3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(1)
    },
    itemLoader: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 278,
      [theme.breakpoints.down('sm')]: {
        height: 144
      }
    },
    loader: {
      flex: 1,
      borderRadius: theme.shape.borderRadius
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    name: {
      fontWeight: 'bold',
      textAlign: 'left'
    },
    tag: {
      marginTop: 0,
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        marginTop: theme.spacing(1),
        left: 0,
        top: 0
      }
    },
    text: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      ...lineClamp(2)
    },
    pictureContainer: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.background.paper, 0.6),
      height: ItemImageSize,
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexShrink: 0,
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        height: ItemSmallImageSize,
        width: ItemSmallImageSize,
        marginBottom: 0,
        marginRight: theme.spacing(1)
      }
    },
    picture: {
      flex: 1,
      flexShrink: 0,
      height: ItemImageSize,
      objectFit: 'cover',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('sm')]: {
        height: ItemSmallImageSize
      }
    },
    textLeft: {
      textAlign: 'left'
    }
  })
)

export default PlacesList
