import pack from './../../package.json'
import metadata from './../metadata.json'
import AuthService from './AuthService'
import DbService from './DbService'
import FirebaseService from './FirebaseService'
import FunctionsService from './FunctionsService'
import RoleService from './RoleService'
import StorageService from './StorageService'

const DefaultCulture = 'it'
const SupportedCultures = ['it']

class AppService {
  version = `${process.env.REACT_APP_ENVIRONMENT === 'test' ? `TEST-` : ''}${pack.version || '1.0.0'}-${
    metadata.build || '1'
  }`
  env: 'test' | 'prod' = process.env.REACT_APP_ENVIRONMENT === 'test' ? 'test' : 'prod'

  db!: DbService
  auth!: AuthService
  storage!: StorageService
  functions!: FunctionsService
  role!: RoleService

  googleMapsApiKey = 'AIzaSyCgXRlB9mEjkuaW38YCU_U-Bjf5eWqn8TE'

  init = () => {
    let success = true

    success = FirebaseService.init()

    try {
      this.db = new DbService(FirebaseService.firestore)
      this.auth = new AuthService(FirebaseService.auth)
      this.storage = new StorageService(FirebaseService.storage)
      this.functions = new FunctionsService(FirebaseService.functions)
      this.role = new RoleService()
    } catch (err) {
      success = false
    }

    return success
  }

  getDeviceCulture = (): string => {
    let culture = DefaultCulture

    try {
      if (navigator.languages) {
        const langs = [...navigator.languages]

        if (langs.length > 0) {
          culture = langs[0]
        }
      } else {
        culture = navigator.language
      }
    } catch (error) {}

    if (!SupportedCultures.includes(culture)) {
      culture = DefaultCulture
    }

    return culture
  }
}

export default new AppService()
