import firebase from 'firebase'
import { TFunction } from 'i18next'
import React from 'react'
import { StyledFirebaseAuth } from 'react-firebaseui'
import { useTranslation } from 'react-i18next'
import AppService from '../../services/AppService'

const getUiConfig = (t: TFunction) => {
  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: t('login_google')
      },
      {
        provider: 'apple.com',
        fullLabel: t('login_apple')
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
        const { auth } = AppService
        auth.login(authResult)
        return false
      }
    }
  }

  return uiConfig
}

const Auth: React.FC = () => {
  const { t } = useTranslation()

  return <StyledFirebaseAuth uiConfig={getUiConfig(t)} firebaseAuth={firebase.auth()} />
}

export default Auth
