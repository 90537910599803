import React, { useCallback, useRef, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Dialog, DialogContent, IconButton, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Swiper as SwiperType } from 'swiper'
import Spacing from '../components/Spacing'
import {
  IconClose,
  raster_IconCalendar,
  raster_IconCheck,
  raster_IconDocument,
  raster_IconMoneyBag,
  raster_IconPlanBusiness
} from '../assets/Svgs'
import Colors from '../styles/Colors'
import { RoutesPaths } from '../Routes'
import StepsActions from '../components/steps/StepsActions'
import AppService from '../services/AppService'
import { useUser } from '../hooks/useUser'
import 'swiper/swiper.scss'

type WhatsNewItem = {
  id: string
  background: string
  image: string
  title: string
  description: string
}

const WhatsNew: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { user, setUser } = useUser()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [viewingSteps, setViewingSteps] = useState<boolean>(false)
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const swiperRef = useRef<SwiperType | null>(null)

  const items: WhatsNewItem[] = viewingSteps
    ? [
        {
          id: 'recurring_expenses',
          background: `linear-gradient(283.85deg, rgba(68, 148, 255, 0.5) 0%, rgba(209, 99, 140, 0.5) 100%)`,
          image: raster_IconMoneyBag,
          title: t('recurring_expenses'),
          description: t('recurring_expenses_promo_description')
        },
        {
          id: 'booking',
          background: `linear-gradient(283.85deg, rgba(136, 145, 248, 0.5) 0%, rgba(19, 100, 207, 0.5) 100%)`,
          image: raster_IconCalendar,
          title: t('booking_promo_title'),
          description: t('booking_promo_description')
        },
        {
          id: 'documents',
          background: `linear-gradient(283.85deg, rgba(0, 83, 201, 0.4) 0%, rgba(215, 151, 232, 0.4) 100%)`,
          image: raster_IconDocument,
          title: t('documents_promo_title'),
          description: t('documents_promo_description')
        },
        {
          id: 'receipts_booking_confirmations',
          background: `linear-gradient(283.85deg, rgba(110, 197, 225, 0.5) 0%, rgba(166, 212, 91, 0.5) 100%)`,
          image: raster_IconCheck,
          title: t('receipts_booking_confirmations_promo_title'),
          description: t('receipts_booking_confirmations_promo_description')
        }
      ]
    : [
        {
          id: 'whats_new',
          background: `linear-gradient(283.85deg, rgba(110, 197, 225, 0.5) 0%, rgba(19, 100, 207, 0.5) 100%)`,
          image: raster_IconPlanBusiness,
          title: t('business_plan'),
          description: t('business_plan_description')
        }
      ]

  const isLastStep = stepIndex === items.length - 1

  const handleClose = useCallback(async () => {
    if (user && !user.whatsNewViewed) {
      const { db } = AppService

      setSubmitting(true)
      await db.updateUser(user.id, { whatsNewViewed: true })
      if (setUser) {
        setUser(current => (current ? { ...current, whatsNewViewed: true } : current))
      }
      setSubmitting(false)
    }

    history.replace(RoutesPaths.Places)
  }, [history, setUser, user])

  const handleNextSelected = useCallback(() => {
    if (!viewingSteps) {
      setViewingSteps(true)
    } else if (isLastStep) {
      handleClose()
    } else {
      swiperRef.current?.slideNext()
    }
  }, [handleClose, isLastStep, viewingSteps])

  const handlePreviousSelected = useCallback(() => {
    swiperRef.current?.slidePrev()

    if (stepIndex - 1 < 0) {
      setViewingSteps(false)
    }
  }, [stepIndex])

  const handleSnapIndexChange = useCallback((swiper: SwiperType) => {
    setStepIndex(swiper.activeIndex)
  }, [])

  const ItemsComponent = items.map((item, itemIndex) => {
    const ItemComponent = (
      <div className="colstretch">
        <div className={clsx(styles.carouselImageContainer, 'contentcenter')} style={{ background: item.background }}>
          <img className={styles.carouselImage} src={item.image} />
        </div>

        <DialogContent>
          <Spacing size={3} />

          {!viewingSteps ? (
            <Typography variant="overline" className={styles.newsText}>
              {t('news')}
            </Typography>
          ) : (
            <Typography variant="overline" color="textSecondary">
              {t('value_of_total', { value: itemIndex + 1, total: items.length })}
            </Typography>
          )}

          <Spacing size={2} />

          <Typography variant="h3">{item.title}</Typography>

          <Spacing size={2} />

          <Typography variant="body1" color="textSecondary">
            {item.description}
          </Typography>
        </DialogContent>
      </div>
    )

    return viewingSteps ? (
      <SwiperSlide key={item.id}>{ItemComponent}</SwiperSlide>
    ) : (
      <React.Fragment key={item.id}>{ItemComponent}</React.Fragment>
    )
  })

  return (
    <Dialog
      open
      onClose={handleClose}
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'sm'}
      aria-describedby="alert-dialog-description">
      <div className={styles.header}>
        <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
          <IconClose />
        </IconButton>
      </div>

      {viewingSteps ? (
        <div className={styles.swiperContainer}>
          <Swiper onSwiper={instance => (swiperRef.current = instance)} onSnapIndexChange={handleSnapIndexChange}>
            {ItemsComponent}
          </Swiper>
        </div>
      ) : (
        ItemsComponent
      )}

      {viewingSteps && (
        <React.Fragment>
          <Spacing size={2} />

          <div className="inline justify-center">
            {items.map((item, index) => (
              <div
                key={item.id}
                className={styles.paginationDot}
                style={{ backgroundColor: index === stepIndex ? theme.palette.primary.main : Colors.IconGray }}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      <Spacing size={2} />

      <StepsActions
        variant="dialog"
        showOutlinedBackButton={viewingSteps}
        centerActions={!viewingSteps}
        outlinedBackButtonText={t('previous')}
        submitButton
        bottomSpacingLarge
        insetSpacing={false}
        submitting={submitting}
        continueButtonText={t(!viewingSteps ? 'see_whats_new' : isLastStep ? 'close' : 'continue')}
        onContinue={handleNextSelected}
        onGoBack={handlePreviousSelected}
      />
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'absolute',
      top: 24,
      right: 24,
      zIndex: 10
    },
    swiperContainer: {
      width: '100%'
    },
    newsText: {
      color: theme.palette.success.main
    },
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    carouselImageContainer: {
      height: 216
    },
    carouselImage: {
      height: 168
    },
    paginationDot: {
      width: 8,
      height: 8,
      borderRadius: 8,
      margin: `0 ${theme.spacing(0.5)}px`
    }
  })
)

export default WhatsNew
