import { TextField, TextFieldProps, Theme, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import ReadOnlyField from '../ReadOnlyField'

type InputTextProps = TextFieldProps & {
  rootClassName?: string
  fullFlex?: boolean
  smallInput?: boolean
  readOnly?: boolean
  loading?: boolean
}

const InputText: React.FC<InputTextProps> = ({
  label,
  rootClassName,
  fullFlex,
  smallInput,
  readOnly,
  loading,
  ...rest
}: InputTextProps) => {
  const styles = useStyles()

  return (
    <div className={clsx(styles.root, rootClassName, { ['fullflex']: fullFlex })}>
      {label ? (
        <Typography variant="body1" component="div" className={styles.label}>
          {label}
        </Typography>
      ) : null}
      {loading ? (
        <Skeleton variant="rect" className={clsx(styles.loader, { [styles.loaderSmall]: smallInput })} />
      ) : readOnly ? (
        <ReadOnlyField value={rest.value} />
      ) : (
        <TextField
          {...rest}
          InputProps={{
            ...rest.InputProps,
            classes: {
              ...rest.InputProps?.classes,
              input: clsx(rest.InputProps?.classes?.input, { [styles.inputSmall]: smallInput })
            }
          }}
          variant="outlined"
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      marginBottom: theme.spacing(1)
    },
    inputSmall: {
      padding: 10
    },
    loader: {
      height: 47,
      marginBottom: 18,
      borderRadius: theme.shape.borderRadius
    },
    loaderSmall: {
      height: 39
    }
  })
)

export default InputText
