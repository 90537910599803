import {
  addMonths,
  addWeeks,
  addYears,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  startOfDay
} from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import { TFunction } from 'i18next'
import Colors from '../styles/Colors'
import EditTransactionRecurrence from '../types/EditTransactionRecurrence'
import Transaction from '../types/Transaction'
import TransactionStatus from '../types/transactions/TransactionStatus'
import { formatDate } from './DateUtils'

export const getTransactionPaymentDateLabel = (t: TFunction, transaction: Transaction) => {
  return t(transaction.paid ? (transaction.paidOn ? 'transaction_paid_date' : 'paid_f') : 'transaction_due_date', {
    date: formatDate(transaction.paid && transaction.paidOn ? transaction.paidOn : transaction.dueDate)
  })
}

export const getTransactionStatus = (
  t: TFunction,
  transaction: Transaction
): {
  label: string
  status: TransactionStatus
} => {
  const isLate = transaction.paid
    ? transaction.paidOn
      ? startOfDay(transaction.paidOn).getTime() > startOfDay(transaction.dueDate).getTime()
      : false
    : startOfDay(new Date()).getTime() > startOfDay(transaction.dueDate).getTime()
  return {
    label: t(transaction.paid ? (isLate ? 'paid_late' : 'paid_f') : isLate ? 'unpaid' : 'to_pay'),
    status: transaction.paid ? (isLate ? 'delayed' : 'paid') : isLate ? 'unpaid' : 'default'
  }
}

export const getTransactionColor = (transactionStatus: TransactionStatus) =>
  transactionStatus === 'paid'
    ? Colors.Green
    : transactionStatus === 'delayed'
    ? Colors.Orange
    : transactionStatus === 'unpaid'
    ? Colors.Red
    : Colors.DisabledGrey

export const sortTransactions = (array: Transaction[]): Transaction[] => {
  const nowDate = new Date()

  const sortByClosest = (a: Record<string, unknown>, b: Record<string, unknown>, inverse?: boolean) => {
    const diffA = differenceInCalendarDays(a.dueDate as Date, nowDate)
    const diffB = differenceInCalendarDays(b.dueDate as Date, nowDate)
    return inverse ? diffB - diffA : diffA - diffB
  }

  const filterFuture = (item: Transaction) => differenceInCalendarDays(item.dueDate as Date, nowDate) > 0
  const upcomingUnpaid = array.filter(item => filterFuture(item) && !item.paid)
  const previousUnpaid = array.filter(item => !filterFuture(item) && !item.paid)

  return previousUnpaid
    .sort((a, b) => sortByClosest(a, b, true))
    .concat(upcomingUnpaid.sort(sortByClosest))
    .concat(array.filter(item => item.paid).sort((a, b) => b.dueDate.getTime() - a.dueDate.getTime()))
}

export const getTransactionRecurrenceMinDate = (
  recurrence: EditTransactionRecurrence | undefined,
  dueDate: Date | null
) =>
  recurrence && dueDate
    ? recurrence === 'year'
      ? addYears(dueDate, 1)
      : recurrence === 'month'
      ? addMonths(dueDate, 1)
      : addWeeks(dueDate, 1)
    : new Date()

export const getTransactionRecurrenceAmount = (
  recurrence: EditTransactionRecurrence,
  dueDate: Date,
  repeatUntil: Date
) => {
  const recurrenceAmount = Math.abs(
    recurrence === 'none'
      ? 0
      : recurrence === 'year'
      ? differenceInCalendarYears(dueDate, repeatUntil)
      : recurrence === 'month'
      ? differenceInCalendarMonths(dueDate, repeatUntil)
      : differenceInCalendarWeeks(dueDate, repeatUntil)
  )

  return recurrenceAmount
}
