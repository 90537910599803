import { Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { endOfMonth } from 'date-fns'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ImageTotalsChartEmpty } from '../../assets/Images'
import { useUser } from '../../hooks/useUser'
import Colors from '../../styles/Colors'
import Rent from '../../types/place/Rent'
import Transaction from '../../types/Transaction'
import { formatCurrency } from '../../utils/CurrencyUtils'
import { formatDate } from '../../utils/DateUtils'
import {
  getPaidPlaceTransactions,
  getRentsTransactionsByPaymentStatus,
  getRentsTransactionsSummaryPeriodDateRanges,
  getRentsTransactionsSummaryPeriodDates,
  getTransactionsAmountForPeriod
} from '../../utils/RentUtils'
import Spacing from '../Spacing'
import ChartLegend from './ChartLegend'
import ChartTooltip from './ChartTooltip'

type PlaceDetailTotalsChartProps = {
  rents: Rent[]
  transactions: Transaction[]
}

const PlaceDetailTotalsChart: React.FC<PlaceDetailTotalsChartProps> = ({
  rents,
  transactions
}: PlaceDetailTotalsChartProps) => {
  const styles = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  const isEmpty = rents.length === 0 && transactions.length === 0

  const { paidTransactions, delayedTransactions } = getRentsTransactionsByPaymentStatus(rents)

  const paidPlaceTransactions = getPaidPlaceTransactions(transactions)

  const periodDates = useRef(getRentsTransactionsSummaryPeriodDates())

  const { startDate, endDate } = getRentsTransactionsSummaryPeriodDateRanges(periodDates.current)

  const totalNet =
    getTransactionsAmountForPeriod(startDate, endDate, paidTransactions) +
    getTransactionsAmountForPeriod(startDate, endDate, delayedTransactions) -
    getTransactionsAmountForPeriod(startDate, endDate, paidPlaceTransactions)

  const data: any[] = periodDates.current.map(p => ({
    name: p,
    date: p,
    in:
      getTransactionsAmountForPeriod(p, endOfMonth(p), paidTransactions) +
      getTransactionsAmountForPeriod(p, endOfMonth(p), delayedTransactions),
    out: getTransactionsAmountForPeriod(p, endOfMonth(p), paidPlaceTransactions)
  }))

  return (
    <div className={styles.root}>
      {isEmpty ? (
        <img src={ImageTotalsChartEmpty} className={styles.empty} />
      ) : (
        <React.Fragment>
          <div className="colstretch">
            <Typography variant="body2" component="div">
              {`${t('net')}: `}
              <strong className={totalNet < 0 ? 'negative' : 'positive'}>
                {formatCurrency(totalNet, user?.currency)}
              </strong>
            </Typography>

            <Spacing size={1} />

            <ChartLegend
              items={[
                {
                  color: Colors.Green,
                  label: t('takings'),
                  value:
                    getTransactionsAmountForPeriod(startDate, endDate, paidTransactions) +
                    getTransactionsAmountForPeriod(startDate, endDate, delayedTransactions),
                  isCurrency: true
                },
                {
                  color: Colors.Red,
                  label: t('expenses'),
                  value: getTransactionsAmountForPeriod(startDate, endDate, paidPlaceTransactions),
                  isCurrency: true
                }
              ]}
            />
          </div>

          <Spacing size={2} />

          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorIn" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={Colors.Green} stopOpacity={0.2} />
                  <stop offset="90%" stopColor={Colors.Green} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorOut" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={Colors.Red} stopOpacity={0.2} />
                  <stop offset="90%" stopColor={Colors.Red} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="name"
                tickLine={false}
                axisLine={false}
                interval={1}
                tickFormatter={value => formatDate(value, 'MMM')}
              />
              <YAxis tickLine={false} axisLine={false} />
              <Tooltip content={<ChartTooltip />} />
              <Area
                type="monotone"
                dataKey="in"
                strokeWidth={2}
                stroke={Colors.Green}
                fillOpacity={1}
                fill="url(#colorIn)"
              />
              <Area
                type="monotone"
                dataKey="out"
                strokeWidth={2}
                stroke={Colors.Red}
                fillOpacity={1}
                fill="url(#colorOut)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1
    },
    empty: {
      width: '100%',
      height: '100%'
    }
  })
)

export default PlaceDetailTotalsChart
