import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import clsx from 'clsx'
import EmptyContentPromo, { EmptyContentPromoProps } from './EmptyContentPromo'
import Spacing from './Spacing'

type EmptyContentPromoDialogProps = EmptyContentPromoProps

const EmptyContentPromoDialog: React.FC<EmptyContentPromoDialogProps> = (props: EmptyContentPromoDialogProps) => {
  const styles = useStyles()

  return (
    <div className={clsx(styles.root, 'contentcenter')}>
      <div className={clsx(styles.content, 'colstretch')}>
        <EmptyContentPromo {...props} variant="dialog" />
        <Spacing size={4} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appBar - 1
    },
    content: {
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
      borderRadius: 12,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
        margin: `0 ${theme.spacing(2)}px`
      }
    }
  })
)

export default EmptyContentPromoDialog
