import { Button, FormControl, MenuItem, Select, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { add } from 'date-fns'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { AddRentStepValues1 } from '../../pages/AddRent'
import { RoutesPaths } from '../../Routes'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import Guest from '../../types/Guest'
import PaymentMethod from '../../types/PaymentMethod'
import { formatDate } from '../../utils/DateUtils'
import { getNightsDuration, getRentDurationLessThanAMonth } from '../../utils/RentUtils'
import EditGuestsInput from '../edit-guests-input/EditGuestsInput'
import InputDate from '../inputs/InputDate'
import InputNumber from '../inputs/InputNumber'
import ReadOnlyField from '../ReadOnlyField'
import RentContractDurationLabel from '../rent/RentContractDurationLabel'
import Spacing from '../Spacing'

export type AddRentStepContractProps = FormStepComponentBase<AddRentStepValues1> & {
  placeId: string
  isBooking?: boolean
}

const AddRentStepContract: React.FC<AddRentStepContractProps> = ({
  submitting,
  values,
  errors,
  touched,
  loading,
  isBooking,
  placeId,
  handleBlur,
  setFieldValue
}: AddRentStepContractProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()

  const handleDateFromChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setFieldValue('from', date)
    },
    [setFieldValue]
  )

  const handleDateToChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setFieldValue('to', date)
    },
    [setFieldValue]
  )

  const handlePaymentMethodChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const newValue = event.target.value as PaymentMethod | 'none'

      setFieldValue('paymentMethod', newValue === 'none' ? null : newValue)
    },
    [setFieldValue]
  )

  const handleGuestsChange = useCallback(
    (newGuests: Guest[]) => {
      setFieldValue('guests', newGuests)
    },
    [setFieldValue]
  )

  const handleNumberChange = useCallback(
    (id: string, value: number | undefined) => {
      setFieldValue(id, value)
    },
    [setFieldValue]
  )

  const handleUpgradeSelected = useCallback(() => {
    history.push(RoutesPaths.Subscriptions)
  }, [history])

  const handleAddBookingSelected = useCallback(() => {
    history.push(RoutesPaths.AddBookingDialog.replace(':placeId', placeId), { isModal: true, isBooking: true })
  }, [history, placeId])

  const durationLessThanAMonth = getRentDurationLessThanAMonth(values.from, values.to)

  return (
    <div>
      {!isBooking && (
        <div className={clsx('inline', styles.spacingBlock)}>
          <Typography variant="body1" color="textSecondary" className={styles.spacingRight}>{`${t(
            'addrent.contract_duraton'
          )}`}</Typography>
          <RentContractDurationLabel from={values.from} to={values.to} />
        </div>
      )}

      <div className={clsx(styles.block, 'inline', 'align-start')}>
        <InputDate
          fullWidth
          name="from"
          label={t(isBooking ? 'check_in' : 'from')}
          value={values.from || null}
          placeholder={`${formatDate(new Date(), 'dd/MM/yyyy')}*`}
          onChange={date => handleDateFromChange(date)}
          onBlur={handleBlur}
          error={Boolean(errors.from) && Boolean(touched.from)}
          helperText={errors.from && touched.from ? errors.from : ' '}
          classes={{ root: 'fullflex' }}
          inputVariant="outlined"
          disabled={submitting}
          format="dd/MM/yyyy"
          inputProps={{
            autoComplete: 'off',
            ['data-lpignore']: 'true'
          }}
        />

        {!isSmDown && <Spacing horizontal size={2} />}

        <InputDate
          fullWidth
          name="to"
          label={t(isBooking ? 'check_out' : 'to')}
          value={values.to || null}
          placeholder={'--/--/----*'}
          onChange={date => handleDateToChange(date)}
          onBlur={handleBlur}
          minDate={add(values.from || new Date(), { days: 1 })}
          error={(durationLessThanAMonth && !isBooking) || (Boolean(errors.to) && Boolean(touched.to))}
          helperText={
            durationLessThanAMonth && !isBooking ? (
              <React.Fragment>
                <Typography variant="caption" color="error">
                  {`${t(user?.stripeRole === 'business' ? 'buiness_rent_less_than_a_month' : 'rent_duration_promo')} `}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={styles.upgradeButton}
                  onClick={user?.stripeRole === 'business' ? handleAddBookingSelected : handleUpgradeSelected}>
                  {t(user?.stripeRole === 'business' ? 'add_booking.title_add' : 'upgrade')}
                </Button>
              </React.Fragment>
            ) : errors.to && touched.to ? (
              errors.to
            ) : (
              ' '
            )
          }
          classes={{ root: 'fullflex' }}
          inputVariant="outlined"
          disabled={submitting}
          format="dd/MM/yyyy"
          inputProps={{
            autoComplete: 'off',
            ['data-lpignore']: 'true'
          }}
        />
        {isBooking && (
          <React.Fragment>
            {!isSmDown && <Spacing horizontal size={4} />}
            <div className="colstretch">
              <Typography variant="body1" color="textSecondary" className={styles.label}>{`${t(
                'periods.night.noun_plural'
              )}`}</Typography>
              <ReadOnlyField value={values.from && values.to ? getNightsDuration(values.from, values.to) : '––'} />
            </div>
          </React.Fragment>
        )}
      </div>

      {isBooking && (
        <div className={styles.block}>
          <div className="inline">
            <InputNumber
              label={t('adults')}
              id="adults"
              variant="default"
              placeholder={'1'}
              showModifierButtons
              value={values.adults || undefined}
              min={1}
              max={100}
              onChange={handleNumberChange}
              onBlur={handleBlur}
              error={Boolean(errors.adults) && touched.adults}
              helperText={errors.adults && touched.adults ? errors.adults : ' '}
              disabled={submitting}
              className={clsx({ fullflex: isSmDown, [styles.inputNumberFull]: isSmDown })}
            />

            <Spacing horizontal size={2} />

            <InputNumber
              label={t('children')}
              id="children"
              variant="default"
              placeholder={'1'}
              showModifierButtons
              value={values.children || undefined}
              min={1}
              max={100}
              onChange={handleNumberChange}
              onBlur={handleBlur}
              error={Boolean(errors.children) && touched.children}
              helperText={errors.children && touched.children ? errors.children : ' '}
              disabled={submitting}
              className={clsx({ fullflex: isSmDown, [styles.inputNumberFull]: isSmDown })}
            />
          </div>
        </div>
      )}

      <div className={styles.block}>
        <EditGuestsInput
          value={values.guests}
          error={touched.guests && Boolean(errors.guests)}
          helperText={touched.guests && errors.guests ? (errors.guests as string) : ' '}
          disabled={loading}
          onChange={handleGuestsChange}
        />
      </div>

      <div className={clsx(styles.block, 'colstretch', styles.fullBlock)}>
        <FormControl variant="outlined" className={clsx('fullflex', styles.selectHalfContainer)}>
          <Typography variant="body1" component="div" className={styles.label}>
            {t('payment_method_label')}
          </Typography>
          <Select
            disabled={loading}
            value={values.paymentMethod || 'none'}
            onChange={handlePaymentMethodChange}
            className="fullflex"
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              classes: {
                list: styles.selectMenu
              }
            }}
            renderValue={selected => {
              const val = selected as string
              return val === 'none' ? (
                <span className={styles.selectPlaceholder}>{t('addrent.payment_method_placeholder')}</span>
              ) : (
                t(`paymentmethod.${val}`)
              )
            }}>
            {['none'].concat(Object.values(PaymentMethod)).map(item => (
              <MenuItem key={item} value={item} className={clsx({ [styles.selectNone]: item === 'none' })}>
                {t(item === 'none' ? `none` : `paymentmethod.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacingRight: {
      marginRight: theme.spacing(2)
    },
    block: {
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        alignItems: 'stretch !important'
      }
    },
    spacingBlock: {
      marginBottom: theme.spacing(3)
    },
    spacingHorizontal: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    selectMenu: {
      paddingTop: 0,
      paddingBottom: 0
    },
    selectItem: {
      minHeight: 40,
      '&.MuiListItem-root.Mui-disabled': {
        opacity: 1,
        '&.MuiListItem-button:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    selectItemSelected: {
      backgroundColor: 'transparent',
      '& .MuiListItemText-root > span': {
        fontWeight: 600
      }
    },
    selectPlaceholder: {
      color: '#919191'
    },
    selectHalfContainer: {
      marginBottom: 18
    },
    label: {
      marginBottom: theme.spacing(1)
    },
    spacingSmall: {
      height: theme.spacing(2),
      flexShrink: 0
    },
    upgradeButton: {
      marginTop: theme.spacing(0.5)
    },
    selectNone: {
      fontStyle: 'italic'
    },
    inputNumberFull: {
      maxWidth: 'unset'
    },
    fullBlock: {
      alignItems: 'stretch !important'
    }
  })
)

export default AddRentStepContract
