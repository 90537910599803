import { createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { differenceInCalendarDays, startOfDay } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconExpenses, IconRent } from '../assets/Svgs'
import BlurOverlay from '../components/BlurOverlay'
import BoxContainer from '../components/BoxContainer'
import ColorIcon from '../components/ColorIcon'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import FeaturePromoUpgrade from '../components/promo/FeaturePromoUpgrade'
import Spacing from '../components/Spacing'
import StatisticsPlacesChart from '../components/statistics/StatisticsPlacesChart'
import StatisticsSummary from '../components/statistics/StatisticsSummary'
import StatisticsTotalsChart from '../components/statistics/StatisticsTotalsChart'
import StatisticsContainer from '../components/StatisticsContainer'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import Colors from '../styles/Colors'
import Transaction from '../types/Transaction'
import { formatCurrency } from '../utils/CurrencyUtils'
import { formatDate } from '../utils/DateUtils'
import {
  getCurrentPeriodTransaction,
  getRentsTransactionsSummaryPeriodDateRanges,
  getRentsTransactionsSummaryPeriodDates
} from '../utils/RentUtils'
import { formatNumber, lowercaseFirstLetter } from '../utils/TextUtils'

const sumTransactions = (transactions: Transaction[]) => transactions.reduce((sum, item) => sum + item.amount, 0)

const averageTransactionsAmount = (transactions: Transaction[]) =>
  transactions.length > 0 ? sumTransactions(transactions) / transactions.length : 0

const Statistics: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const { user } = useUser()

  const canViewAllStatistics = AppService.role.canViewAllStatistics()

  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const monthsAmount = useRef(canViewAllStatistics ? 12 : 3)
  const periodDates = useRef(
    getRentsTransactionsSummaryPeriodDateRanges(getRentsTransactionsSummaryPeriodDates(monthsAmount.current))
  )

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel) {
        setLoading(true)
      }

      const { db } = AppService
      const transactionsResult = await db.getUserTransactions(user.id)

      if (!didCancel) {
        setLoading(false)
        setTransactions(transactionsResult)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [user])

  const paidRentsTransactions = transactions.filter(
    t =>
      t.isRent &&
      t.paid &&
      t.paidOn &&
      getCurrentPeriodTransaction(t.paidOn, periodDates.current.startDate, periodDates.current.endDate) &&
      startOfDay(t.paidOn).getTime() <= startOfDay(t.dueDate).getTime()
  )
  const delayedRentsTransactions = transactions.filter(
    t =>
      t.isRent &&
      t.paid &&
      t.paidOn &&
      getCurrentPeriodTransaction(t.paidOn, periodDates.current.startDate, periodDates.current.endDate) &&
      startOfDay(t.paidOn).getTime() > startOfDay(t.dueDate).getTime()
  )
  const unpaidRentsTransactions = transactions.filter(
    t =>
      t.isRent &&
      !t.paid &&
      getCurrentPeriodTransaction(t.dueDate, periodDates.current.startDate, periodDates.current.endDate) &&
      new Date().getTime() > startOfDay(t.dueDate).getTime()
  )
  const delayedRentsTransactionsDaysDelay = delayedRentsTransactions.map(t =>
    differenceInCalendarDays(t.paidOn || new Date(), t.dueDate)
  )
  const paidRentsCount = paidRentsTransactions.length + delayedRentsTransactions.length
  const totalRentsCount = paidRentsCount + unpaidRentsTransactions.length

  const paidExpensesTransactions = transactions.filter(
    t =>
      !t.isRent &&
      Boolean(t.placeId) &&
      t.paid &&
      t.paidOn &&
      getCurrentPeriodTransaction(t.paidOn, periodDates.current.startDate, periodDates.current.endDate) &&
      startOfDay(t.paidOn).getTime() <= startOfDay(t.dueDate).getTime()
  )
  const delayedExpensesTransactions = transactions.filter(
    t =>
      !t.isRent &&
      Boolean(t.placeId) &&
      t.paid &&
      t.paidOn &&
      getCurrentPeriodTransaction(t.paidOn, periodDates.current.startDate, periodDates.current.endDate) &&
      startOfDay(t.paidOn).getTime() > startOfDay(t.dueDate).getTime()
  )
  const unpaidExpensesTransactions = transactions.filter(
    t =>
      !t.isRent &&
      Boolean(t.placeId) &&
      !t.paid &&
      getCurrentPeriodTransaction(t.dueDate, periodDates.current.startDate, periodDates.current.endDate) &&
      new Date().getTime() > startOfDay(t.dueDate).getTime()
  )
  const delayedExpensesTransactionsDaysDelay = delayedExpensesTransactions.map(t =>
    differenceInCalendarDays(t.paidOn || new Date(), t.dueDate)
  )

  const doneExpenses = paidExpensesTransactions.concat(delayedExpensesTransactions)
  const expensesDaysDifference = doneExpenses
    .sort((a, b) => (a.paidOn && b.paidOn ? a.paidOn.getTime() - b.paidOn.getTime() : 0))
    .reduce((arr, t, index) => {
      const prevTransaction = doneExpenses[index - 1]
      return index !== 0 && t.paidOn && prevTransaction?.paidOn
        ? arr.concat(differenceInCalendarDays(t.paidOn || new Date(), prevTransaction.paidOn))
        : arr
    }, [] as number[])
  const paidExpensesCount = doneExpenses.length
  const totalExpensesCount = paidExpensesCount + unpaidExpensesTransactions.length

  return (
    <NavigationDrawerContainer>
      <div className={styles.header}>
        <Typography variant="h3">{t('statistics')}</Typography>
        {!canViewAllStatistics && (
          <FeaturePromoUpgrade className={styles.headerRight} text={t('view_all_stats_promo')} maxWidth={!isSmDown} />
        )}
      </div>

      <Spacing size={4} />

      <div className={styles.grid}>
        <div className="colstretch fullflex overflow-hidden">
          <div className={clsx('colstretch', styles.chartsContainer)}>
            <div className={clsx('inline justify-between', styles.chartsHeader)}>
              <div />
              <Typography variant="body2" align="right" color="textSecondary">{`${t(
                isSmDown ? 'last_x_months' : 'stats_for_last_x_months',
                {
                  amount: monthsAmount.current
                }
              )} (${formatDate(periodDates.current.startDate)} - ${formatDate(
                periodDates.current.endDate
              )})`}</Typography>
            </div>

            <StatisticsContainer
              title={
                <div className="inline">
                  <ColorIcon
                    color={theme.palette.primary.main}
                    Icon={<IconExpenses />}
                    withBackground
                    className={styles.icon}
                  />
                  <Typography variant="subtitle1" component="div">
                    {t('trend')}
                  </Typography>
                </div>
              }
              Component={<StatisticsTotalsChart transactions={transactions} monthsRange={monthsAmount.current} />}
              classes={{ component: styles.chartContent }}
            />

            <div className="relative">
              {!canViewAllStatistics && <BlurOverlay />}
              <StatisticsContainer
                title={
                  <div className="inline">
                    <ColorIcon
                      color={theme.palette.primary.main}
                      Icon={<IconExpenses />}
                      withBackground
                      className={styles.icon}
                    />
                    <Typography variant="subtitle1" component="div">
                      {t('places')}
                    </Typography>
                  </div>
                }
                Component={
                  <StatisticsPlacesChart
                    transactions={transactions}
                    monthsRange={monthsAmount.current}
                    canView={canViewAllStatistics}
                  />
                }
                classes={{ component: styles.chartContent }}
              />
            </div>
          </div>
        </div>

        <div className="colstretch">
          <BoxContainer loading={loading} loaderHeight={420} bottomSpacing={2}>
            <StatisticsContainer
              title={t('rents')}
              subtitle={`${t('totals')}: ${formatNumber(totalRentsCount)}`}
              TitleIcon={
                <ColorIcon withBackground Icon={<IconRent />} color={Colors.Green} className="spacing-right-xs" />
              }
              Component={
                <React.Fragment>
                  {!canViewAllStatistics && <BlurOverlay />}
                  <StatisticsSummary
                    total={
                      canViewAllStatistics ? sumTransactions(paidRentsTransactions.concat(delayedRentsTransactions)) : 0
                    }
                    items={[
                      {
                        label: t('paid_rents'),
                        value: canViewAllStatistics
                          ? `${paidRentsTransactions.concat(delayedRentsTransactions).length}`
                          : '0'
                      },
                      {
                        label: t('average_rent'),
                        value: canViewAllStatistics
                          ? `${formatCurrency(
                              averageTransactionsAmount(paidRentsTransactions.concat(delayedRentsTransactions)),
                              user?.currency
                            )}`
                          : '0'
                      }
                    ]}
                    backgroundColor={Colors.Green}
                    contrastText
                  />

                  <Spacing size={2} />

                  <StatisticsSummary
                    total={canViewAllStatistics ? sumTransactions(delayedRentsTransactions) : 0}
                    textColor={Colors.Orange}
                    items={[
                      {
                        label: t('delayed_rents'),
                        value: canViewAllStatistics ? `${delayedRentsTransactions.length}` : '0'
                      },
                      {
                        label: t('on_average'),
                        value: canViewAllStatistics
                          ? `${
                              delayedRentsTransactionsDaysDelay.length > 0
                                ? delayedRentsTransactionsDaysDelay.reduce((sum, t) => sum + t, 0) /
                                  delayedRentsTransactionsDaysDelay.length
                                : 0
                            } ${lowercaseFirstLetter(t('periods.day.noun_plural'))}`
                          : '-',
                        suffix: t('of_delay')
                      },
                      {
                        label: t('percentage'),
                        value: canViewAllStatistics
                          ? `${Math.round(
                              paidRentsCount > 0 ? (delayedRentsTransactions.length / paidRentsCount) * 100 : 0
                            )}%`
                          : '0'
                      }
                    ]}
                  />

                  <Spacing size={2} />

                  <StatisticsSummary
                    total={canViewAllStatistics ? sumTransactions(unpaidRentsTransactions) : 0}
                    textColor={Colors.Red}
                    items={[
                      {
                        label: t('unpaid_rents'),
                        value: canViewAllStatistics ? `${unpaidRentsTransactions.length}` : '0'
                      },
                      {
                        label: t('percentage'),
                        value: canViewAllStatistics
                          ? `${Math.round(
                              totalRentsCount > 0 ? (unpaidRentsTransactions.length / totalRentsCount) * 100 : 0
                            )}%`
                          : '0'
                      }
                    ]}
                  />
                </React.Fragment>
              }
            />
          </BoxContainer>

          <BoxContainer loading={loading} loaderHeight={420} bottomSpacing={2}>
            <StatisticsContainer
              title={t('expenses')}
              subtitle={`${t('totals')}: ${formatNumber(totalExpensesCount)}`}
              TitleIcon={
                <ColorIcon withBackground Icon={<IconExpenses />} color={Colors.Red} className="spacing-right-xs" />
              }
              Component={
                <React.Fragment>
                  {!canViewAllStatistics && <BlurOverlay />}
                  <StatisticsSummary
                    total={
                      canViewAllStatistics
                        ? sumTransactions(paidExpensesTransactions.concat(delayedExpensesTransactions))
                        : 0
                    }
                    items={[
                      {
                        label: t('paid_expenses'),
                        value: canViewAllStatistics ? `${doneExpenses.length}` : '0'
                      },
                      {
                        label: t('average_expense'),
                        value: canViewAllStatistics
                          ? `${formatCurrency(
                              averageTransactionsAmount(paidExpensesTransactions.concat(delayedExpensesTransactions)),
                              user?.currency
                            )}`
                          : '0'
                      },
                      {
                        label: t('on_average_every'),
                        value: canViewAllStatistics
                          ? `${t('periods.day.plural', {
                              amount:
                                doneExpenses.length > 1
                                  ? expensesDaysDifference.reduce((sum, i) => sum + i, 0) / (doneExpenses.length - 1)
                                  : 0
                            })}`
                          : '0'
                      }
                    ]}
                    backgroundColor={Colors.Red}
                    contrastText
                  />

                  <Spacing size={2} />

                  <StatisticsSummary
                    total={canViewAllStatistics ? sumTransactions(delayedExpensesTransactions) : 0}
                    textColor={Colors.Orange}
                    items={[
                      {
                        label: t('delayed_expenses'),
                        value: canViewAllStatistics ? `${delayedExpensesTransactions.length}` : '0'
                      },
                      {
                        label: t('on_average'),
                        value: canViewAllStatistics
                          ? `${
                              delayedExpensesTransactionsDaysDelay.length > 0
                                ? delayedExpensesTransactionsDaysDelay.reduce((sum, t) => sum + t, 0) /
                                  delayedExpensesTransactionsDaysDelay.length
                                : 0
                            } ${lowercaseFirstLetter(t('periods.day.noun_plural'))}`
                          : '-',
                        suffix: t('of_delay')
                      },
                      {
                        label: t('percentage'),
                        value: canViewAllStatistics
                          ? `${Math.round(
                              paidExpensesCount > 0 ? (delayedExpensesTransactions.length / paidExpensesCount) * 100 : 0
                            )}%`
                          : '0'
                      }
                    ]}
                  />

                  <Spacing size={2} />

                  <StatisticsSummary
                    total={canViewAllStatistics ? sumTransactions(unpaidExpensesTransactions) : 0}
                    textColor={Colors.Red}
                    items={[
                      {
                        label: t('unpaid_expenses'),
                        value: canViewAllStatistics ? `${unpaidExpensesTransactions.length}` : '0'
                      },
                      {
                        label: t('percentage'),
                        value: canViewAllStatistics
                          ? `${Math.round(
                              totalExpensesCount > 0
                                ? (unpaidExpensesTransactions.length / totalExpensesCount) * 100
                                : 0
                            )}%`
                          : '0'
                      }
                    ]}
                  />
                </React.Fragment>
              }
            />
          </BoxContainer>
        </div>
      </div>
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      columnGap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr'
      }
    },
    headerRight: {
      justifySelf: 'end'
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      columnGap: theme.spacing(2),
      rowGap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr'
      }
    },
    chartsContainer: {
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden'
    },
    chartsHeader: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`
    },
    chartContent: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    icon: {
      marginRight: theme.spacing(2)
    }
  })
)

export default Statistics
