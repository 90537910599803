import DateFnsUtils from '@date-io/date-fns'
import { createStyles, fade, Grow, IconButton, makeStyles, Theme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { IconClose } from './assets/Svgs'
import AppLoader from './components/AppLoader'
import ErrorMessage from './components/ErrorMessage'
import { UserContext } from './hooks/context/UserContext'
import UserProvider from './hooks/context/UserProvider'
import * as I18n from './i18n/i18nConfig'
import Routes from './Routes'
import AppService from './services/AppService'
import Colors from './styles/Colors'
import GlobalStyles from './styles/Global.styles'

const App: React.FC = () => {
  const styles = useStyles()

  const [initializing, setInitializing] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const snackbarRef = useRef<SnackbarProvider | null>(null)

  const onClickDismiss = (key: SnackbarKey) => () => {
    snackbarRef.current?.closeSnackbar(key)
  }

  useEffect(() => {
    let error: string | undefined

    try {
      const initResult = AppService.init()

      error = !initResult ? 'An error occurred. Please try again.' : undefined
    } catch (err) {
      error = JSON.stringify(err)
    }

    setInitializing(false)
    setErrorMessage(error)
  }, [])

  return (
    <div className={styles.root}>
      {initializing ? null : errorMessage ? (
        <ErrorMessage errorMessage={errorMessage} />
      ) : (
        <Router>
          <UserProvider>
            <SnackbarProvider
              maxSnack={2}
              ref={r => (snackbarRef.current = r)}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              classes={{
                variantError: styles.snackbarVariant,
                variantInfo: styles.snackbarVariant,
                variantSuccess: styles.snackbarVariant,
                variantWarning: styles.snackbarVariant
              }}
              TransitionComponent={Grow as any}
              action={key => (
                <IconButton onClick={onClickDismiss(key)}>
                  <IconClose className={styles.snackbarClose} />
                </IconButton>
              )}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={I18n.dateFnsLocales[AppService.getDeviceCulture()]}>
                <UserContext.Consumer>
                  {({ initializingUser }) => (initializingUser ? <AppLoader size={48} /> : <Routes />)}
                </UserContext.Consumer>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </UserProvider>
        </Router>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...GlobalStyles(theme),
    root: {
      display: 'flex',
      overflow: 'hidden',
      flex: 1
    },
    snackbarVariant: {
      '&::after': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      },
      '& .MuiIconButton-root path': {
        fill: `${Colors.White} !important`
      }
    },
    snackbarClose: {
      '& path': {
        fill: theme.palette.type === 'dark' ? fade(Colors.Black, 0.6) : fade(Colors.White, 0.8)
      }
    }
  })
)

export default App
