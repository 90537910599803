import { fade, Theme, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import Colors from '../styles/Colors'

const Size = 40
const SizeSmall = 32

type UserAvatarProps = {
  pictureUrl?: string
  name?: string
  className?: string
  showSecondChar?: boolean
  size?: 'default' | 'small'
  variant?: 'default' | 'primary'
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  pictureUrl,
  name,
  className,
  showSecondChar,
  size,
  variant = 'primary'
}: UserAvatarProps) => {
  const styles = useStyles()

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.small]: size === 'small',
        [styles.variantDefault]: variant === 'default',
        [styles.variantPrimary]: variant === 'primary'
      })}>
      {pictureUrl ? (
        <img src={pictureUrl} alt="avatar" className={styles.picture} />
      ) : name ? (
        <Typography variant={size === 'small' ? 'h6' : 'h5'} component="div">
          {`${name.charAt(0)}${showSecondChar ? `${name.charAt(1)}` : ''}`}
        </Typography>
      ) : (
        <AccountCircleIcon />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: Size,
      height: Size,
      borderRadius: Size,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    variantPrimary: {
      backgroundColor: fade(theme.palette.primary.main, 0.2)
    },
    variantDefault: {
      backgroundColor: Colors.SubtleBlueGray
    },
    small: {
      width: SizeSmall,
      height: SizeSmall,
      borderRadius: SizeSmall,
      '& $picture': {
        width: SizeSmall,
        height: SizeSmall,
        borderRadius: SizeSmall
      }
    },
    picture: {
      width: Size,
      height: Size,
      borderRadius: Size
    }
  })
)

export default UserAvatar
