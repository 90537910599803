import { TFunction } from 'i18next'
import DisplayProductFeature from '../types/products/DisplayProductFeature'
import Product from '../types/products/Product'
import ProductPrice from '../types/products/ProductPrice'
import User from '../types/User'
import { getId } from './CommonUtils'

export const getProductPriceRecurrenceLabel = (t: TFunction, productPrice: ProductPrice) => {
  if (!productPrice.recurring) return ''

  return t('every_period', {
    period:
      productPrice.recurring.intervalCount === 1
        ? t(`periods.${productPrice.recurring.interval}.noun`).toLowerCase()
        : t(`periods.${productPrice.recurring.interval}.plural`, { amount: productPrice.recurring.intervalCount })
  })
}

export const getUserRoleLabel = (userRole: User['stripeRole']) => {
  return userRole === 'business' ? 'Business' : userRole === 'professional' ? 'Professional' : ''
}

export const userHasAnActiveProduct = (userRole: User['stripeRole']) => {
  return userRole === 'business' || userRole === 'professional'
}

export const getProductFeatures = (
  t: TFunction,
  productRole: 'free' | 'professional' | 'business'
): DisplayProductFeature[] => {
  if (productRole === 'professional') {
    return [
      {
        id: getId(),
        label: t('multi_device'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('unlimited_properties'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('detailed_stats'),
        activeCheck: true
      }
    ]
  }
  if (productRole === 'business') {
    return [
      {
        id: getId(),
        label: t('multi_device'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('unlimited_properties'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('detailed_stats'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('recurring_expenses'),
        activeCheck: true,
        doubleCheck: true
      },
      {
        id: getId(),
        label: t('booking_package'),
        activeCheck: true,
        doubleCheck: true
      },
      {
        id: getId(),
        label: t('docs_management'),
        activeCheck: true,
        doubleCheck: true
      },
      {
        id: getId(),
        label: t('receipts_generation'),
        activeCheck: true,
        doubleCheck: true
      },
      {
        id: getId(),
        label: t('whatsapp_integration'),
        activeCheck: true,
        doubleCheck: true
      }
    ]
  }
  if (productRole === 'free') {
    return [
      {
        id: getId(),
        label: t('multi_device'),
        activeCheck: true
      },
      {
        id: getId(),
        label: t('one_property')
      },
      {
        id: getId(),
        label: t('limited_stats')
      }
    ]
  }
  return []
}

export const getProductDescription = (t: TFunction, product: Product) => {
  let description = ''

  if (product.role) {
    if (product.role === 'professional') {
      description = t('product_professional_description')
    }
  }

  return description
}
