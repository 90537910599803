import { CircularProgress, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { LogoSvg } from '../assets/Svgs'

type AppLoaderProps = {
  size?: number
  style?: React.CSSProperties | undefined
}

const AppLoader: React.FC<AppLoaderProps> = ({ size = 32, style }: AppLoaderProps) => {
  const styles = useStyles()

  const logoSize = size / 2

  return (
    <div className={styles.loaderContainer} style={style}>
      <CircularProgress size={size} />
      <LogoSvg className={styles.appLogo} style={{ width: logoSize, height: logoSize }} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flex: 1
    },
    appLogo: {
      position: 'absolute',
      marginBottom: 2,
      '& path': {
        fill: theme.palette.primary.main
      }
    }
  })
)

export default AppLoader
