import { Button, Divider, Paper, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppLoader from '../AppLoader'
import BackButton from '../buttons/BackButton'
import ContentContainer from '../ContentContainer'
import Spacing from '../Spacing'

export const StepsActionsHeight = 96

type StepsActionsProps = {
  variant?: 'fixed' | 'dialog'
  showBackButton?: boolean
  showOutlinedBackButton?: boolean
  outlinedBackButtonText?: string
  continueButtonText?: string
  submitButton?: boolean
  submitting?: boolean
  hidden?: boolean
  outlinedButtonColor?: 'red'
  centerActions?: boolean
  bottomSpacingLarge?: boolean
  insetSpacing?: boolean
  leftButtonSubmitting?: boolean
  onGoBack?: () => void
  onContinue?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const StepsActions: React.FC<StepsActionsProps> = ({
  variant = 'fixed',
  showBackButton,
  showOutlinedBackButton,
  outlinedBackButtonText,
  continueButtonText,
  submitButton,
  submitting,
  hidden,
  outlinedButtonColor,
  centerActions,
  bottomSpacingLarge,
  insetSpacing = true,
  leftButtonSubmitting,
  onGoBack,
  onContinue
}: StepsActionsProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const Content = (
    <React.Fragment>
      <div
        className={clsx(styles.content, {
          [styles.contentDialog]: variant === 'dialog',
          [styles.centerActions]: centerActions
        })}>
        {showOutlinedBackButton ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={onGoBack}
            disabled={submitting}
            component="div"
            fullWidth={isSmDown}
            className={clsx(styles.outlinedBackButton, {
              [styles.outlinedButtonRed]: outlinedButtonColor === 'red'
            })}>
            {leftButtonSubmitting ? <AppLoader size={20} /> : outlinedBackButtonText || t('cancel')}
          </Button>
        ) : showBackButton ? (
          <BackButton
            color="primary"
            onClick={onGoBack}
            disabled={submitting}
            iconOnly={isSmDown}
            className={clsx({ [styles.backButtonSpacing]: isSmDown })}
          />
        ) : (
          <div />
        )}
        <Button
          variant="contained"
          color="primary"
          component="button"
          type={submitButton ? 'submit' : undefined}
          onClick={onContinue}
          disabled={submitting}
          fullWidth={isSmDown}
          className={clsx({ [styles.submitButton]: submitButton })}>
          {submitting && !leftButtonSubmitting ? <AppLoader size={20} /> : continueButtonText || t('continue')}
        </Button>
      </div>
      {bottomSpacingLarge && !isSmDown && <Spacing size={4} />}
    </React.Fragment>
  )

  return hidden ? (
    <div className={styles.actionsPlaceholder} />
  ) : (
    <React.Fragment>
      {insetSpacing && <div className={styles.spacing} />}

      <Paper
        elevation={isSmDown ? 2 : 0}
        className={clsx(styles.container, { [styles.containerDialog]: variant === 'dialog' && !isSmDown })}>
        {variant === 'dialog' ? (
          Content
        ) : (
          <ContentContainer>
            {!isSmDown && <Divider />}

            {Content}
          </ContentContainer>
        )}
      </Paper>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0
    },
    containerDialog: {
      position: 'sticky'
    },
    content: {
      height: StepsActionsHeight,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    centerActions: {
      justifyContent: 'center'
    },
    spacing: {
      display: 'flex',
      height: StepsActionsHeight,
      flexShrink: 0
    },
    submitButton: {
      minHeight: 40,
      [theme.breakpoints.up('md')]: {
        minWidth: 140
      }
    },
    outlinedBackButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        minWidth: 140
      }
    },
    outlinedButtonRed: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.main
      }
    },
    backButtonSpacing: {
      marginRight: theme.spacing(2)
    },
    contentDialog: {
      padding: `0 ${theme.spacing(8)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `0 ${theme.spacing(4)}px`
      }
    },
    actionsPlaceholder: {
      height: 24,
      flexShrink: 0
    }
  })
)

export default StepsActions
