import React from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import { TFunction } from 'i18next'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { formatDate } from '../utils/DateUtils'
import { getNightsDuration } from '../utils/RentUtils'
import Colors from '../styles/Colors'
import Spacing from './Spacing'

export type BookingConfirmationData = {
  from: Date
  to: Date
  guestName: string
  placeName: string
  adults?: number
  children?: number
  bookingNotes?: string
}

type PdfBookingConfirmationProps = BookingConfirmationData & { confirmationNumber?: number; t: TFunction }

const PdfBookingConfirmation: React.FC<PdfBookingConfirmationProps> = ({
  from,
  to,
  guestName,
  placeName,
  adults,
  children,
  bookingNotes,
  confirmationNumber = 1,
  t
}: PdfBookingConfirmationProps) => {
  const styles = useStyles()

  const nights = getNightsDuration(from, to)

  return (
    <div>
      <div className={clsx(styles.inline, styles.justifyBetween)}>
        <div />

        <div className={clsx(styles.col, styles.alignEnd)}>
          <Typography>{`${t('booking')} #: ${confirmationNumber}-${new Date().getFullYear()}`}</Typography>
          <Typography>{formatDate(new Date(), 'dd/MM/yyyy')}</Typography>
        </div>
      </div>

      <Spacing size={6} />

      <Typography className={styles.textLarge}>{t('booking_confirmation')}</Typography>
      <Typography className={styles.textButton}>{placeName}</Typography>

      <Spacing size={2} />

      <div className={styles.inline}>
        <Typography className={styles.textSecondary}>{t('to_mr_mrs')}</Typography>
        <Spacing horizontal size={1} />
        <Typography>{guestName}</Typography>
      </div>

      <Spacing size={2} />

      <div className={styles.inline}>
        <div className={clsx(styles.fullflex, styles.inline)}>
          <Typography className={styles.textSecondary}>{t('check_in')}</Typography>
          <Spacing horizontal size={1} />
          <Typography>{formatDate(from, 'dd/MM/yyyy')}</Typography>
        </div>
        <div className={clsx(styles.fullflex, styles.inline)}>
          <Typography className={styles.textSecondary}>{t('check_out')}</Typography>
          <Spacing horizontal size={1} />
          <Typography>{formatDate(to, 'dd/MM/yyyy')}</Typography>
        </div>
      </div>

      <Spacing size={2} />

      <div className={styles.inline}>
        <Typography className={styles.textSecondary}>{t('periods.night.noun_plural')}</Typography>
        <Spacing horizontal size={1} />
        <Typography>{nights}</Typography>
      </div>

      <Spacing size={2} />

      <div className={styles.inline}>
        <div className={clsx(styles.fullflex, styles.inline)}>
          <Typography className={styles.textSecondary}>{t('adults')}</Typography>
          <Spacing horizontal size={1} />
          <Typography>{adults || '––'}</Typography>
        </div>
        <div className={clsx(styles.fullflex, styles.inline)}>
          <Typography className={styles.textSecondary}>{t('children')}</Typography>
          <Spacing horizontal size={1} />
          <Typography>{children || '––'}</Typography>
        </div>
      </div>

      {bookingNotes ? (
        <React.Fragment>
          <Spacing size={2} />

          <Typography className={styles.textSecondary}>{t('notes_for_guests')}</Typography>
          <Spacing size={1} />
          <Typography>{bookingNotes}</Typography>
        </React.Fragment>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    inline: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    justifyBetween: {
      justifyContent: 'space-between'
    },
    fullflex: {
      flex: 1
    },
    col: {
      display: 'flex',
      flexDirection: 'column'
    },
    alignEnd: {
      alignItems: 'flex-end'
    },
    textSecondary: {
      color: Colors.Gray
    },
    textLarge: {
      fontSize: 20,
      fontWeight: 600
    },
    textButton: {
      fontSize: 16,
      fontWeight: 700
    }
  })
)

export default PdfBookingConfirmation
