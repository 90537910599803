import { it } from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import itIT from './translations/it-IT.json'

export const resources = {
  it: {
    translation: itIT
  }
} as const

export const dateFnsLocales: any = { it }

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'it',
  resources
})
