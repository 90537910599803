import { createContext } from 'react'
import User from '../../types/User'

export const UserContext = createContext<
  Partial<{
    user: User | undefined
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>
    initializingUser: boolean
    loadingUser: boolean
  }>
>({})
