import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback } from 'react'

export type ListTableColumn = {
  field: string
  headerName: string
  width?: number | string
}

export type ListTableRowItem = {
  id: string
  items: {
    [key: string]: {
      label?: string | undefined
      renderLabel?: (label: string | undefined) => React.ReactNode
      Component?: React.ReactNode
    }
  }
}

type ListTableProps = {
  columns: ListTableColumn[]
  rows: ListTableRowItem[]
  showHeader?: boolean
  transparent?: boolean
  loading?: boolean
  onItemSelected?: (id: string) => void
}

const ListTable: React.FC<ListTableProps> = ({
  columns,
  rows,
  showHeader = true,
  transparent,
  loading,
  onItemSelected
}: ListTableProps) => {
  const styles = useStyles()

  const handleItemSelected = useCallback(
    (id: string) => {
      if (onItemSelected) onItemSelected(id)
    },
    [onItemSelected]
  )

  return (
    <div className={styles.root}>
      <TableContainer
        className={clsx(!transparent && styles.tableBackground)}
        elevation={transparent ? 0 : undefined}
        component={Paper}>
        <Table className={styles.table} aria-label="simple table">
          {showHeader && (
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} classes={{ head: styles.tableHeaderCell }} style={{ width: column.width }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {(loading ? [...Array(1).keys()].map<ListTableRowItem>(i => ({ id: i.toString(), items: {} })) : rows).map(
              row => (
                <TableRow
                  className={styles.row}
                  key={row.id}
                  onClick={() => {
                    if (row.id) handleItemSelected(row.id)
                  }}>
                  {columns.map((column, columnIndex) => {
                    const rowItem = row.items[column.field]

                    return (
                      <TableCell
                        key={`${columnIndex}_${row.id}`}
                        className={clsx(styles.tableCell, transparent && styles.tableCellTransparent)}
                        style={{ width: column.width }}>
                        {loading ? (
                          <Skeleton variant="text" />
                        ) : rowItem.Component ? (
                          rowItem.Component
                        ) : rowItem?.renderLabel ? (
                          rowItem.renderLabel(rowItem.label)
                        ) : (
                          rowItem?.label || ''
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1
    },
    tableBackground: {
      backgroundColor: theme.palette.background.default
    },
    table: {
      width: '100%'
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    tableHeaderCell: {
      lineHeight: 'inherit'
    },
    tableCell: {
      padding: `8px 16px`,
      height: 48
    },
    tableCellTransparent: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  })
)

export default ListTable
