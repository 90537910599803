import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { CirclePicker, ColorResult } from 'react-color'

type InputColorProps = {
  label?: string
  value?: string
  onChange?: (value: string | null) => void
}

const DefaultColors = [
  '#EC5446',
  '#F1A43C',
  '#F7D848',
  '#68CE69',
  '#89C2F9',
  '#3C82F6',
  '#5D5CDE',
  '#EC5D7A',
  '#C883EE',
  '#C2A67A',
  '#757C86',
  '#E2B7B0'
]

const InputColor: React.FC<InputColorProps> = ({ label, value, onChange }: InputColorProps) => {
  const styles = useStyles()

  const [color, setColor] = useState<string | null>(value !== undefined ? value : null)

  const handleChange = useCallback(
    ({ hex }: ColorResult) => {
      const newColor = hex === color ? null : hex

      setColor(newColor)

      if (onChange) onChange(newColor)
    },
    [color, onChange]
  )

  useEffect(() => {
    if (value) {
      setColor(value)
    }
  }, [value])

  return (
    <div className="colstretch">
      {label && (
        <Typography variant="body1" component="div" className={styles.label}>
          {label}
        </Typography>
      )}

      <CirclePicker
        color={color || ''}
        width="100%"
        circleSpacing={0}
        className={styles.picker}
        onChangeComplete={handleChange}
        colors={DefaultColors}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: theme.spacing(1)
    },
    picker: {
      display: 'grid !important',
      gridTemplateColumns: 'repeat(auto-fill, 28px)',
      justifyContent: 'space-between',
      gridGap: '8px'
    }
  })
)

export default InputColor
