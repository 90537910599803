import {
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconAddCircleOutlined } from '../../assets/Svgs'
import { useUser } from '../../hooks/useUser'
import { AddRentStepValues2 } from '../../pages/AddRent'
import FormStepComponentBase from '../../types/forms/FormStepComponentBase'
import Transaction from '../../types/Transaction'
import { formatCurrency, getCurrencySymbol } from '../../utils/CurrencyUtils'
import { getNightsDuration } from '../../utils/RentUtils'
import { getDecimalSeparator } from '../../utils/TextUtils'
import EditTransactionDialog from '../edit-transaction/EditTransactionDialog'
import InputNumber from '../inputs/InputNumber'
import ReadOnlyField from '../ReadOnlyField'
import Spacing from '../Spacing'
import TransactionListItem from '../transactions/TransactionListItem'

const NumberOfTransactionsToDisplay = 3

enum RecurringPaymentValues {
  NotRepeat = 'not_repeat',
  Repeats = 'repeats'
}

export type AddRentStepAmountsProps = FormStepComponentBase<AddRentStepValues2> & { isBooking?: boolean }

const AddRentStepAmounts: React.FC<AddRentStepAmountsProps> = ({
  submitting,
  values,
  errors,
  touched,
  isBooking,
  handleBlur,
  setFieldValue
}: AddRentStepAmountsProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [editTransactionDialogOpen, setEditTransactionDialogOpen] = useState<boolean>(false)
  const [viewAllTransactions, setViewAllTransactions] = useState<boolean>(false)
  const [editTransactionDialogItem, setEditTransactionDialogItem] = useState<Transaction | undefined>(undefined)

  const nights = getNightsDuration(values.from, values.to)

  const handleNewTransactionSelected = useCallback(() => {
    setEditTransactionDialogOpen(current => !current)
  }, [])

  const handleEditTransactionClose = useCallback(() => {
    setEditTransactionDialogOpen(false)
  }, [])

  const handleViewAllTransactionsToggle = useCallback(() => {
    setViewAllTransactions(current => !current)
  }, [])

  const handleTransactionEdit = useCallback(
    (id: string) => {
      if (!values.transactions) return

      setEditTransactionDialogOpen(true)
      setEditTransactionDialogItem(values.transactions.find(t => t.id === id))
    },
    [values.transactions]
  )

  const handleTransactionPaidToggle = useCallback(
    (id: string, paid: boolean) => {
      if (!values.transactions) return

      setFieldValue(
        'transactions',
        values.transactions.map(t => (t.id === id ? { ...t, paid, paidOn: paid ? new Date() : undefined } : t))
      )
    },
    [setFieldValue, values.transactions]
  )

  const handleTransactionDeleted = useCallback(
    async (id: string) => {
      setFieldValue(
        'transactions',
        values.transactions?.filter(t => t.id !== id)
      )
      return true
    },
    [setFieldValue, values.transactions]
  )

  const handleTransactionChange = useCallback(
    async (transactionValues: Transaction, id?: string) => {
      let newTransactions = values.transactions || []
      if (id) {
        newTransactions = newTransactions.map(t =>
          t.id === id
            ? {
                ...t,
                ...transactionValues
              }
            : t
        )
      } else {
        newTransactions.push(transactionValues)
      }

      setFieldValue('transactions', newTransactions)

      return true
    },
    [setFieldValue, values.transactions]
  )

  const handleTotalAmountChange = useCallback(
    (id: string, value: number | undefined) => {
      setFieldValue('totalAmount', value !== undefined ? value : null)
    },
    [setFieldValue]
  )

  const handleRecurringPaymentChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      setFieldValue('recurringPayment', event.target.value === RecurringPaymentValues.Repeats)
    },
    [setFieldValue]
  )

  const handleRecurringPaymentValueChange = useCallback(
    (id: string, value: number | undefined) => {
      setFieldValue(id, value)
    },
    [setFieldValue]
  )

  const handleSourceOfBookingChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const newValue = event.target.value

      setFieldValue('sourceOfBooking', newValue === 'none' ? null : newValue)
    },
    [setFieldValue]
  )

  const handleRecurringPaymentDayChange = useCallback(
    (id: string, value: number | undefined) => {
      setFieldValue(id, value)
    },
    [setFieldValue]
  )

  const extrasTransactions = values.transactions
    ?.filter(t => !t.isRent)
    .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime())

  return (
    <div>
      <div className={clsx({ inline: !isSmDown })}>
        <InputNumber
          name="totalAmount"
          id="totalAmount"
          label={t('addrent.total')}
          value={values.totalAmount !== null ? values.totalAmount : undefined}
          placeholder={`${isBooking ? 100 * nights : 5000}${getDecimalSeparator()}00`}
          onChange={handleTotalAmountChange}
          onBlur={handleBlur}
          error={Boolean(errors.totalAmount) && Boolean(touched.totalAmount)}
          helperText={errors.totalAmount && touched.totalAmount ? errors.totalAmount : ' '}
          disabled={submitting}
          decimals={2}
          max={100000000}
          min={0.01}
          fullWidth
          rootClassName={clsx({ [styles.fieldSpacingRight]: isBooking }, 'fullflex')}
          endAdornment={<InputAdornment position="end">{getCurrencySymbol(user?.currency)}</InputAdornment>}
        />

        {isBooking && (
          <React.Fragment>
            {!isSmDown && <Spacing horizontal size={2} />}

            <div className="colstretch fullflex">
              <Typography variant="body1" color="textSecondary" className={styles.label}>{`${t(
                'price_per_night'
              )}`}</Typography>
              <ReadOnlyField
                value={values.totalAmount ? formatCurrency(values.totalAmount / nights, user?.currency) : '––'}
              />
            </div>

            {!isSmDown && <Spacing horizontal size={4} />}

            <div className="colstretch">
              <Typography variant="body1" color="textSecondary" className={styles.label}>{`${t(
                'periods.night.noun_plural'
              )}`}</Typography>
              <ReadOnlyField value={nights} />
            </div>
          </React.Fragment>
        )}
      </div>

      {!isBooking && (
        <div className={styles.block}>
          <FormControl variant="outlined" fullWidth>
            <Select
              disabled={values.recurrence === 'night'}
              value={values.recurringPayment ? RecurringPaymentValues.Repeats : RecurringPaymentValues.NotRepeat}
              onChange={handleRecurringPaymentChange}
              inputProps={{ 'aria-label': 'Without label', id: 'guests-input' }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                classes: {
                  list: styles.selectMenu
                }
              }}>
              {[
                {
                  id: RecurringPaymentValues.NotRepeat,
                  label: t('payment_does_not_repeat_option')
                },
                {
                  id: RecurringPaymentValues.Repeats,
                  label: t('payment_repeats_option')
                }
              ].map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  classes={{ root: styles.selectItem, selected: styles.selectItemSelected }}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      {!isBooking && values.recurringPayment && (
        <div className={clsx(styles.block, 'inline')}>
          <div className={clsx('fullflex', 'inline', styles.flexStart)}>
            <InputNumber
              id="recurringPaymentValue"
              value={values.recurringPaymentValue}
              onChange={handleRecurringPaymentValueChange}
              className={styles.inputNumberSmall}
              onBlur={handleBlur}
              error={Boolean(errors.recurringPaymentValue) && Boolean(touched.recurringPaymentValue)}
              helperText={
                errors.recurringPaymentValue && touched.recurringPaymentValue ? errors.recurringPaymentValue : ' '
              }
            />
            <Spacing size={1} horizontal />

            <ReadOnlyField
              value={t(
                `periods.${values.recurrence}.${
                  values.recurringPaymentValue && values.recurringPaymentValue > 1 ? 'noun_plural' : 'noun'
                }`
              )}
            />
          </div>
          <div className={clsx('fullflex', 'inline', styles.flexStart)}>
            <Spacing size={2} horizontal />
            <Typography
              variant="subtitle1"
              className={clsx(styles.regularText, styles.spacingRight, styles.numberLabel)}>
              {t('on_day')}
            </Typography>
            <InputNumber
              id="recurringPaymentDay"
              value={values.recurringPaymentDay}
              onChange={handleRecurringPaymentDayChange}
              className={styles.inputNumberSmall}
              onBlur={handleBlur}
              error={Boolean(errors.recurringPaymentDay) && Boolean(touched.recurringPaymentDay)}
              helperText={errors.recurringPaymentDay && touched.recurringPaymentDay ? errors.recurringPaymentDay : ' '}
            />
          </div>
        </div>
      )}

      <div className={clsx(styles.block, { [styles.blockSpacingTop]: !isBooking || (isBooking && !isSmDown) })}>
        <EditTransactionDialog
          open={editTransactionDialogOpen}
          isBooking={isBooking}
          onClose={handleEditTransactionClose}
          onSubmit={handleTransactionChange}
          onDelete={handleTransactionDeleted}
          transaction={editTransactionDialogItem}
        />

        {!extrasTransactions || (extrasTransactions && extrasTransactions.length === 0) ? (
          <Button
            variant="text"
            color="primary"
            className={styles.addTransactionButton}
            onClick={handleNewTransactionSelected}>
            <IconAddCircleOutlined className="spacing-right-xs" />
            {t('add_extra')}
          </Button>
        ) : (
          <div className="inline">
            <Typography variant="subtitle1" component="div">
              {t(`extra_amount`, {
                amount: extrasTransactions ? extrasTransactions.length : 0
              })}
            </Typography>
            <IconButton
              className={clsx(styles.addTransactionButton, 'rounded', 'spacing-left-xs')}
              onClick={handleNewTransactionSelected}>
              <IconAddCircleOutlined />
            </IconButton>
          </div>
        )}

        {extrasTransactions && (
          <div className={clsx(styles.spacingBlock, styles.transactionsList)}>
            {extrasTransactions.slice(0, NumberOfTransactionsToDisplay).map(transaction => (
              <TransactionListItem
                key={transaction.id}
                transaction={transaction}
                onEdit={handleTransactionEdit}
                onPaidToggle={handleTransactionPaidToggle}
              />
            ))}
            {extrasTransactions.length > 3 && (
              <div>
                <Collapse in={viewAllTransactions}>
                  {extrasTransactions
                    .slice(NumberOfTransactionsToDisplay, extrasTransactions.length)
                    .map(transaction => (
                      <TransactionListItem
                        key={transaction.id}
                        transaction={transaction}
                        onEdit={handleTransactionEdit}
                        onPaidToggle={handleTransactionPaidToggle}
                      />
                    ))}
                </Collapse>

                <div className="spacing-block-vertical-xs" />
                <Button variant="text" color="primary" size="small" onClick={handleViewAllTransactionsToggle}>
                  {t(viewAllTransactions ? 'hide' : 'see_all_f')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={clsx(styles.block, styles.blockSpacingTop, 'colstretch')}>
        <FormControl variant="outlined" className={clsx('fullflex', 'align-self-stretch', styles.selectHalfContainer)}>
          <Typography variant="body1" component="div">
            {t('booking_source_label')}
          </Typography>
          <Spacing size={1} />
          <Select
            value={values.sourceOfBooking || 'none'}
            placeholder={t('addrent.source_of_booking_placeholder')}
            onChange={handleSourceOfBookingChange}
            className="fullflex"
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              classes: {
                list: styles.selectMenu
              }
            }}
            renderValue={selected => {
              const val = selected as string
              return val === 'none' ? (
                <span className={styles.selectPlaceholder}>{t('addrent.source_of_booking_placeholder')}</span>
              ) : (
                t(`booking_source.${val}`)
              )
            }}>
            {['none', 'google', 'airbnb', 'booking', 'tripadvisor', 'agency'].map(item => (
              <MenuItem key={item} value={item} className={clsx({ [styles.selectNone]: item === 'none' })}>
                {t(item === 'none' ? 'none' : `booking_source.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexStart: {
      alignItems: 'flex-start !important'
    },
    spacingRight: {
      marginRight: theme.spacing(2)
    },
    spacingLeft: {
      marginLeft: theme.spacing(2)
    },
    fieldSpacingRight: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    spacingBlock: {
      marginBottom: theme.spacing(3)
    },
    blockEnd: {
      alignItems: 'flex-end !important',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'stretch'
      }
    },
    block: {
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        alignItems: 'stretch !important'
      }
    },
    blockSpacingTop: {
      marginTop: theme.spacing(6)
    },
    selectMenu: {
      paddingTop: 0,
      paddingBottom: 0
    },
    selectItem: {
      minHeight: 40,
      '&.MuiListItem-root.Mui-disabled': {
        opacity: 1,
        '&.MuiListItem-button:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    selectItemSelected: {
      backgroundColor: 'transparent',
      '& .MuiListItemText-root > span': {
        fontWeight: 600
      }
    },
    disabled: {
      opacity: 0.5
    },
    regularText: {
      fontWeight: 400
    },
    inputNumberSmall: {
      width: 56
    },
    numberLabel: {
      height: 48,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    addTransactionButton: {
      '& svg': {
        width: 20,
        height: 20,
        '& path': {
          fill: theme.palette.primary.main
        }
      }
    },
    transactionsList: {
      '& .transaction-list-item:last-child .MuiDivider-root': {
        display: 'none'
      }
    },
    selectHalfContainer: {
      marginBottom: 18
    },
    optionDisabledHintContainer: {
      marginLeft: 'auto'
    },
    optionDisabledHint: {
      fontSize: 10
    },
    upgradeButton: {
      marginLeft: theme.spacing(0.5),
      pointerEvents: 'auto'
    },
    selectNone: {
      fontStyle: 'italic'
    },
    selectPlaceholder: {
      color: '#919191'
    },
    label: {
      marginBottom: theme.spacing(1)
    }
  })
)

export default AddRentStepAmounts
