import { fade, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'

type ColorIconProps = {
  color?: string
  Icon?: React.ReactNode
  className?: string
  withBackground?: boolean
}

const ColorIcon: React.FC<ColorIconProps> = ({ color, Icon, className, withBackground }: ColorIconProps) => {
  const styles = useStyles({ color })

  return <div className={clsx(styles.root, className, { [styles.withBackground]: withBackground })}>{Icon}</div>
}

const useStyles = makeStyles<Theme, ColorIconProps>((theme: Theme) =>
  createStyles({
    root: ({ color }) => ({
      '& path': {
        fill: color ? color : theme.palette.text.secondary
      }
    }),
    withBackground: ({ color }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 32,
      height: 32,
      borderRadius: 4,
      backgroundColor: fade(color ? color : theme.palette.text.secondary, 0.2)
    })
  })
)

export default ColorIcon
