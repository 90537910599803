import { Button, createStyles, makeStyles, Switch, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { publish } from 'pubsub-js'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconEmail } from '../assets/Svgs'
import BoxContainer from '../components/BoxContainer'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import AppService from '../services/AppService'
import { PrivacyPolicyUrl, SupportEmail, TermsAndConditionsUrl } from '../utils/CommonUtils'

const Settings: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { user, setUser } = useUser()

  const userId = user?.id

  const [prefersDarkMode, setPrefersDarkMode] = useState<boolean>(user?.prefersDarkMode || false)

  const storePrefersDarkMode = useCallback(
    (value: boolean) => {
      if (!userId) return

      const { db } = AppService
      db.updateUser(userId, { prefersDarkMode: value })
    },
    [userId]
  )

  const debouncedStorePrefersDarkMode = useMemo(
    () => debounce(storePrefersDarkMode, 500, { leading: true }),
    [storePrefersDarkMode]
  )

  const handleThemeChange = useCallback(
    (e, checked) => {
      setPrefersDarkMode(checked)

      publish('themeTypeChanged', { isDark: checked })

      if (setUser) {
        setUser(current => (current ? { ...current, prefersDarkMode: checked } : current))
      }

      debouncedStorePrefersDarkMode(checked)
    },
    [debouncedStorePrefersDarkMode, setUser]
  )

  return (
    <NavigationDrawerContainer>
      <Typography variant="h3">{t('settings')}</Typography>

      <Spacing size={4} />

      <div className={styles.grid}>
        <BoxContainer className={clsx(styles.box, styles.gridStart)}>
          <Typography variant="button" component="div">
            {t('appearance')}
          </Typography>
          <Spacing size={4} />
          <div className="inline">
            <Typography variant="body1">{t('dark_theme')}</Typography>

            <Switch
              name="paid"
              color="primary"
              checked={prefersDarkMode}
              onChange={handleThemeChange}
              className="spacing-left-s"
            />
          </div>
        </BoxContainer>

        <BoxContainer className={clsx(styles.box, styles.gridStart)}>
          <Typography variant="button" component="div">
            {t('privacy')}
          </Typography>
          <Spacing size={2} />
          <Button
            variant="text"
            size="small"
            href={TermsAndConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.buttonTextPrimary}>
            {t('terms_and_conditions')}
          </Button>
          <Spacing size={1} />
          <Button
            variant="text"
            size="small"
            href={PrivacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.buttonTextPrimary}>
            {t('privacy_policy')}
          </Button>
        </BoxContainer>

        <BoxContainer className={clsx(styles.box, styles.gridStart)}>
          <Typography variant="button" component="div">
            {t('contact_us')}
          </Typography>
          <Spacing size={4} />
          <Button
            variant="text"
            size="small"
            href={`mailto:${SupportEmail}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.buttonTextPrimary}>
            <IconEmail />
            <Spacing size={1} horizontal />
            {t('send_us_an_email')}
          </Button>
        </BoxContainer>
      </div>

      <Typography variant="caption" color="textSecondary" align="center" className={styles.version}>{`${t('version')} ${
        AppService.version
      }`}</Typography>

      <Spacing size={1} />
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      rowGap: theme.spacing(4),
      columnGap: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
        rowGap: theme.spacing(2)
      }
    },
    gridStart: {
      gridColumnStart: 1,
      gridColumnEnd: 2
    },
    box: {
      padding: theme.spacing(2),
      alignItems: 'flex-start'
    },
    buttonTextPrimary: {
      color: theme.palette.primary.main,
      '&:visited': {
        color: `${theme.palette.primary.main} !important`
      }
    },
    version: {
      marginTop: 'auto'
    }
  })
)

export default Settings
