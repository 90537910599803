import { useEffect, useState } from 'react'

export const getData = async <T extends {}>(
  fetcher?: () => Promise<T | undefined> | undefined
): Promise<{ error: boolean; data: T | undefined }> => {
  let error = false
  let data: T | undefined

  if (fetcher) {
    try {
      data = await fetcher()
    } catch (err) {
      console.error(err)
      error = true
    }
  }

  return { error, data }
}

const useFetchData = <T extends {}>(fetcher?: () => Promise<T | undefined> | undefined) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [data, setData] = useState<T | undefined>(undefined)

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!didCancel) setLoading(true)

      const result = await getData(fetcher)

      if (!didCancel) {
        setLoading(false)
        setError(result.error)
        setData(result.data)
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [fetcher])

  return { loading, error, data }
}

export default useFetchData
