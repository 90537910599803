import { Button, Fab, Hidden, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { subscribe, unsubscribe } from 'pubsub-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { IconAdd, ImagePlacesEmpty } from '../assets/Svgs'
import InputSearch from '../components/inputs/InputSearch'
import NavigationDrawerContainer from '../components/navigation/NavigationDrawerContainer'
import PlacesList from '../components/places-list/PlacesList'
import FeaturePromoUpgrade from '../components/promo/FeaturePromoUpgrade'
import Spacing from '../components/Spacing'
import { useUser } from '../hooks/useUser'
import { RoutesPaths } from '../Routes'
import AppService from '../services/AppService'
import Place from '../types/place/Place'
import Rent from '../types/place/Rent'

const Places: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const { user } = useUser()
  const { t } = useTranslation()

  const [places, setPlaces] = useState<Place[]>([])
  const [activeRents, setActiveRents] = useState<Rent[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const initialized = useRef<boolean>(false)

  const handleNewPlaceClick = useCallback(
    () => history.push(RoutesPaths.PlaceNew, { isFirstPlace: places.length === 0 }),
    [history, places.length]
  )

  const handleSearchTextChange = useCallback((value: string) => setSearchText(value), [])

  const fetchPlaces = useCallback(async (searchTextFilter: string, userId: string) => {
    let items: Place[] = []

    try {
      const { db } = AppService
      items = await db.getPlaces(userId)

      if (searchTextFilter !== '') {
        items = items.filter(item => item.name.toLowerCase().includes(searchTextFilter.toLowerCase()))
      }
    } catch (err) {}

    return { items }
  }, [])

  const fetchRents = useCallback(async (userId: string) => {
    let items: Rent[] = []

    try {
      const { db } = AppService
      items = await db.getUserRents(userId, true)
    } catch (err) {}

    return { items }
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel && !initialized.current) {
        setLoading(true)
      }

      const result = await fetchPlaces(searchText, user.id)
      const rentsResult = await fetchRents(user.id)

      if (!didCancel) {
        setPlaces(result.items)
        setActiveRents(rentsResult.items)
        setLoading(false)

        initialized.current = true
      }
    }

    fetchData()

    return () => {
      didCancel = true
    }
  }, [fetchPlaces, fetchRents, searchText, user])

  useEffect(() => {
    const fetchPlace = async (placeId: string, isUpdate?: boolean) => {
      try {
        const { db } = AppService
        const item = await db.getPlace(placeId)

        if (item) {
          setPlaces(current => (isUpdate ? current.map(c => (c.id === placeId ? item : c)) : current.concat([item])))
        }
      } catch (err) {}
    }

    const placeDeletedEventToken = subscribe('placeDeleted', (eventName: string, { placeId }: { placeId: string }) =>
      setPlaces(current => current.filter(p => p.id !== placeId))
    )
    const placeUpdatedEventToken = subscribe('placeUpdated', (eventName: string, { placeId }: { placeId: string }) =>
      fetchPlace(placeId)
    )

    return () => {
      unsubscribe(placeDeletedEventToken)
      unsubscribe(placeUpdatedEventToken)
    }
  }, [])

  const canCreatePlace = !loading && AppService.role.canCreatePlace(places.length)

  return (
    <NavigationDrawerContainer>
      <Hidden mdUp>
        <div className={clsx('inline', styles.addToolbarFloating)}>
          {!canCreatePlace && !loading && (
            <React.Fragment>
              <FeaturePromoUpgrade />
              <Spacing horizontal size={2} />
            </React.Fragment>
          )}
          <Fab
            color="primary"
            disabled={!canCreatePlace}
            aria-label={t('add')}
            className={styles.addButtonFloating}
            onClick={handleNewPlaceClick}>
            <IconAdd />
          </Fab>
        </div>
      </Hidden>

      <div className={styles.toolbar}>
        <InputSearch
          value={searchText}
          onChange={handleSearchTextChange}
          disabled={places.length === 0 && searchText === ''}
        />

        <div className="inline">
          <Hidden smDown>
            {!canCreatePlace && !loading && (
              <React.Fragment>
                <FeaturePromoUpgrade text={t('add_more_properties_promo')} />
                <Spacing horizontal size={2} />
              </React.Fragment>
            )}
            <Button disabled={!canCreatePlace} variant="contained" color="primary" onClick={handleNewPlaceClick}>
              {t('add')}
            </Button>
          </Hidden>
        </div>
      </div>

      <Spacing size={5} />

      {places.length === 0 && !loading ? (
        <div className={styles.noResults}>
          {searchText !== '' ? (
            <Typography variant="body1" component="div">
              {t('no_results')}
            </Typography>
          ) : (
            <React.Fragment>
              <ImagePlacesEmpty />

              <div className="spacing-block-vertical-m" />

              <Typography variant="h6" component="div">
                {t('add_property_hint_title')}
              </Typography>

              <div className="spacing-block-vertical-xs" />

              <Typography variant="caption" component="div" color="textSecondary">
                {t('add_property_hint_subtitle')}
              </Typography>
            </React.Fragment>
          )}
        </div>
      ) : (
        <PlacesList loading={loading} items={places} activeRents={activeRents} />
      )}
    </NavigationDrawerContainer>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    addToolbarFloating: {
      position: 'fixed',
      bottom: 24,
      right: 16,
      left: 16,
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    addButtonFloating: {
      flexShrink: 0,
      '& path': {
        fill: theme.palette.primary.contrastText
      }
    },
    noResults: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1
    }
  })
)

export default Places
