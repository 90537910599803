import { publish } from 'pubsub-js'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { RoutesPaths } from '../../Routes'
import AppService from '../../services/AppService'
import FirebaseService from '../../services/FirebaseService'
import User from '../../types/User'
import { DefaultCurrency } from '../../utils/CurrencyUtils'
import { PlanType } from '../../types/PlanType'
import { UserContext } from './UserContext'

const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory()

  const [user, setUser] = useState<User | undefined>(undefined)
  const [initializingUser, setInitializingUser] = useState<boolean>(true)
  const [loadingUser, setLoadingUser] = useState<boolean>(false)

  useEffect(() => {
    const unsubscriber = FirebaseService.auth?.onAuthStateChanged(async user => {
      try {
        if (user) {
          setLoadingUser(true)

          const { db, auth } = AppService
          if (auth.processingAuth?.additionalUserInfo?.isNewUser) {
            await auth.register(auth.processingAuth)
          }

          const appUser = await db.getUser(user.uid)

          if (appUser) {
            const authUserData = await auth.getAuthUserData()

            const stripeRole: PlanType = authUserData?.claims.stripeRole
            const revenueCatEntitlements: string[] = authUserData?.claims.revenueCatEntitlements

            appUser.stripeRole =
              stripeRole !== null
                ? stripeRole
                : revenueCatEntitlements !== null && Array.isArray(revenueCatEntitlements)
                ? revenueCatEntitlements.includes('professional')
                  ? ('professional' as const)
                  : revenueCatEntitlements.includes('business')
                  ? ('business' as const)
                  : undefined
                : undefined

            if (!appUser.currency) {
              // Set default currency to eur
              appUser.currency = DefaultCurrency
            }
          }
          setUser({
            ...appUser,
            id: user.uid
          })
          db.userId = user.uid

          if (appUser?.prefersDarkMode !== undefined) {
            publish('themeTypeChanged', { isDark: appUser?.prefersDarkMode })
          }

          if (auth.processingAuth) {
            history.replace(RoutesPaths.Places)
            auth.processingAuth = undefined
          }

          setLoadingUser(false)
        } else setUser(undefined)
      } catch (error) {
        setUser(undefined)
      } finally {
        setInitializingUser(false)
      }
    })

    return () => {
      if (unsubscriber) unsubscriber()
    }
  }, [history])

  useEffect(() => {
    const { role } = AppService
    role.stripeRole = user?.stripeRole
  }, [user?.stripeRole])

  return (
    <UserContext.Provider value={{ user, setUser, initializingUser, loadingUser }}>{children}</UserContext.Provider>
  )
}

export default UserProvider
