import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/styles'
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  DialogContent,
  Popover,
  ListItem,
  Divider
} from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { subscribe, unsubscribe } from 'pubsub-js'
import Spacing from '../components/Spacing'
import { IconAdd, IconClose, IconDocuments } from '../assets/Svgs'
import Colors from '../styles/Colors'
import AppService from '../services/AppService'
import Document from '../types/Document'
import { useUser } from '../hooks/useUser'
import StepsActions from '../components/steps/StepsActions'
import { RoutesPaths } from '../Routes'
import DocumentsList from '../components/documents-list/DocumentsList'
import PopoverMenu from '../components/PopoverMenu'
import AppLoader from '../components/AppLoader'
import { AddDocumentLocationState } from './AddDocument'

const DocumentsListModal: React.FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useUser()
  const {
    state: { title }
  } = useLocation<{ title?: string }>()
  const { transactionId, placeId, guestId } = useParams<AddDocumentLocationState>()

  const [items, setItems] = useState<Document[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<HTMLButtonElement | undefined>(undefined)
  const [addMenuSelectDocument, setAddMenuSelectDocument] = useState<boolean>(false)
  const [allDocuments, setAllDocuments] = useState<Document[] | undefined>(undefined)
  const [allDocumentsLoading, setAllDocumentsLoading] = useState<boolean>(true)

  const initialized = useRef<boolean>(false)

  const fetchItems = useCallback(
    async (userId: string, all?: boolean) => {
      let items: Document[] = []

      try {
        const { db } = AppService
        items = await db.getDocuments(userId, all ? undefined : { placeId, transactionId, guestId })
      } catch (err) {}

      return { items }
    },
    [guestId, placeId, transactionId]
  )

  const handleAddMenuClose = useCallback(() => {
    setAddMenuAnchorEl(undefined)
    setAddMenuSelectDocument(false)
  }, [])

  const handleAddSelected = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAddMenuAnchorEl(event.currentTarget)
  }, [])

  const handleLinkExistingDocumentSelected = useCallback(
    async (documentId: string) => {
      handleAddMenuClose()

      const { db } = AppService

      const success = await db.updateDocument(documentId, {
        placeId,
        transactionId,
        guestId
      })

      if (success && user) {
        const result = await fetchItems(user.id)
        setItems(result.items)
      }
    },
    [fetchItems, guestId, handleAddMenuClose, placeId, transactionId, user]
  )

  const handleDeleteLink = useCallback(
    async (documentId: string) => {
      const { db } = AppService

      const success = await db.deleteDocumentEntityLink(documentId, { placeId, transactionId, guestId })

      if (success && user) {
        const result = await fetchItems(user.id)
        setItems(result.items)
      }
    },
    [fetchItems, guestId, placeId, transactionId, user]
  )

  const handleAddNewSelected = useCallback(() => {
    handleAddMenuClose()
    history.push(RoutesPaths.EditDocumentDialog.replace(':documentId', 'new'), {
      isModal: true,
      transactionId,
      placeId,
      guestId
    })
  }, [guestId, handleAddMenuClose, history, placeId, transactionId])

  const handleSelectExistingSelected = useCallback(() => {
    setAddMenuSelectDocument(true)
  }, [])

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (!user) return

      if (!didCancel && !initialized.current) {
        setLoading(true)
      }

      const result = await fetchItems(user.id)

      if (!didCancel) {
        setItems(result.items)
        setLoading(false)

        initialized.current = true
      }
    }

    fetchData()

    const documentCreatedEventToken = subscribe('documentCreated', () => fetchData())
    const documentUpdatedEventToken = subscribe('documentUpdated', () => fetchData())
    const documentDeletedEventToken = subscribe(
      'documentDeleted',
      (eventName: string, { documentId }: { documentId: string }) =>
        setItems(current => current.filter(i => i.id !== documentId))
    )

    return () => {
      didCancel = true

      unsubscribe(documentCreatedEventToken)
      unsubscribe(documentUpdatedEventToken)
      unsubscribe(documentDeletedEventToken)
    }
  }, [fetchItems, user])

  useEffect(() => {
    const fetchData = async () => {
      if (addMenuSelectDocument) {
        setAllDocumentsLoading(true)

        if (!allDocuments && user) {
          const result = await fetchItems(user.id, true)
          setAllDocuments(result.items)
        }

        setAllDocumentsLoading(false)
      }
    }

    fetchData()
  }, [addMenuSelectDocument, allDocuments, fetchItems, user])

  const open = Boolean(addMenuAnchorEl)
  const id = open ? 'add-menu-popover' : undefined

  const unlinkedDocuments = allDocuments
    ? allDocuments.filter(document => !items.map(d => d.id).includes(document.id))
    : undefined

  return (
    <Dialog
      open
      aria-labelledby="add-rent-dialog-title"
      aria-describedby="add-rent-dialog-description"
      fullScreen={isSmDown}
      fullWidth
      maxWidth={isSmDown ? undefined : 'md'}
      onClose={handleClose}>
      <DialogTitle disableTypography id="alert-dialog-title">
        <Spacing size={2} />
        <div className={'inline justify-between'}>
          <div className="inline">
            <Typography variant="h3" component="div">
              {title || t('documents')}
            </Typography>
          </div>

          <IconButton size="small" className={styles.closeButton} onClick={handleClose}>
            <IconClose />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {!loading && items.length === 0 ? (
          <Typography variant="body1">{t('no_linked_documents')}</Typography>
        ) : (
          <DocumentsList compact onUnlink={handleDeleteLink} items={items} loading={loading} />
        )}
      </DialogContent>

      <StepsActions variant="dialog" continueButtonText={t('add')} onContinue={handleAddSelected} />

      <Popover
        id={id}
        open={open}
        anchorEl={addMenuAnchorEl}
        transitionDuration={0}
        onClose={handleAddMenuClose}
        anchorOrigin={
          isSmDown
            ? {
                vertical: 'top',
                horizontal: 'center'
              }
            : {
                vertical: 'top',
                horizontal: 'right'
              }
        }
        transformOrigin={
          isSmDown
            ? {
                vertical: 'bottom',
                horizontal: 'center'
              }
            : {
                vertical: 'bottom',
                horizontal: 'right'
              }
        }
        classes={{ paper: styles.popoverPaper }}>
        {addMenuSelectDocument ? (
          <div
            className={styles.documentsContainer}
            style={isSmDown ? { width: addMenuAnchorEl?.clientWidth || 240 } : undefined}>
            {allDocumentsLoading ? (
              <div className="contentcenter">
                <Spacing size={4} />
                <AppLoader size={20} />
                <Spacing size={4} />
              </div>
            ) : (
              <div>
                {unlinkedDocuments && unlinkedDocuments.length > 0 ? (
                  unlinkedDocuments.map((document, documentIndex) => (
                    <React.Fragment key={document.id}>
                      <ListItem
                        button
                        onClick={() => handleLinkExistingDocumentSelected(document.id)}
                        className={styles.existingDocumentItem}>
                        <Typography>{document.name}</Typography>
                      </ListItem>
                      {documentIndex !== unlinkedDocuments.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <div>
                    <Spacing size={2} />
                    <div className="inline">
                      <Spacing horizontal size={4} />
                      <Typography>{t('no_documents')}</Typography>
                      <Spacing horizontal size={4} />
                    </div>
                    <Spacing size={2} />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div style={isSmDown ? { width: addMenuAnchorEl?.clientWidth || 240 } : undefined}>
            <PopoverMenu
              items={[
                {
                  text: t('new'),
                  Icon: <IconAdd />,
                  handler: handleAddNewSelected
                },
                {
                  text: t('existing'),
                  Icon: <IconDocuments />,
                  handler: handleSelectExistingSelected
                }
              ]}
            />
          </div>
        )}
      </Popover>
    </Dialog>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    closeButton: {
      '& path': {
        fill: Colors.Gray
      }
    },
    popoverPaper: {
      borderRadius: 12
    },
    documentsContainer: {
      maxHeight: 400,
      overflowY: 'auto'
    },
    existingDocumentItem: {
      padding: `8px 24px`
    }
  })
)

export default DocumentsListModal
